import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import Pdf from "react-to-pdf";
import {
  create_open_market_supplier_live,
  list_open_market_supplier_live,
  remove_open_market_supplier_live,
} from "../../api/api-open_market_supplier";
import { list_crop_live} from "../../api/api-crop";
import { create_shipment_live } from "../../api/api-shipment";
import auth from "../../api/auth-helper";
import { create_farmer_live, list_farmer_live } from "../../api/api-farmer";
import { list_OMS_cooperative_live } from "../../api/api-cooperative";
import { read_farmer_crop_live } from "../../api/api-farmer_crop";
import Nav from "./Nav";
import SideBar from "./SideBar";
import NaijaStates from "naija-state-local-government";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

const ref = React.createRef();

class OpenMarketSuppliers extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      modal: false,
      open_market_modal: false,
      price_modal: false,
      state_modal: false,
      gender_modal: false,
      title_modal: false,
      delete_modal: false,
      open_market_id: "",
      title: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      gender: "",
      state: "",
      LGA: "",
      address: "",
      weighbridge_number: "",
      bankmodal: false,
      bank: "",
      account_number: "",
      vehicle_no: "",
      type: "",
      party_name: "",
      price: "",
      paddy_price: [],
      farmers: [],
      gross_weight: "",
      crops: [],
      crop:"",
      prices: [],
      crop_price: "",
      crop_id: "",
      currentPrice: "",
      OMScooperative: [],
      dateUpdated: "",
      open_market_suppliers: [],
      redirectToShipment: false,
      error: "",
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      modals:{
        state: false,
        crop: false
      }
    };
    //this.handleListFarmers();
    this.handleListCrop();
    this.handleListCooperatives();
  }

  componentDidMount() {
    document.title = "Market Suppliers";
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handlePriceModal = () => {
    this.setState((prevState) => ({
      price_modal: !prevState.price_modal,
      error: "",
    }));
  };

  handleBankModal = () => {
    this.setState((prevState) => ({
      bankmodal: !prevState.bankmodal,
      error: "",
    }));
  };

  handleGenderModal = () => {
    this.setState((prevState) => ({
      gender_modal: !prevState.gender_modal,
      error: "",
    }));
  };

  // handleTitleModal = () => {
  //   this.setState((prevState) => ({
  //     title_modal: !prevState.title_modal,
  //     error: "",
  //   }));
  // };

  handlestateModal = () => {
    this.setState((prevState) => ({
      state_modal: !prevState.state_modal,
      error: "",
    }));
  };

  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleListOpenMarket = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        console.log(data);
        this.setState({ open_market_suppliers: data });
        let oms = data.filter(
          oms => oms.cooperativeLiveId === this.state.OMScooperative._id
        )
        this.setState({ open_market_suppliers: oms})
      }
    });
  };

  handleListCooperatives = () => {
    list_OMS_cooperative_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        console.log(data);
        this.setState({OMScooperative: data})
        this.handleListOpenMarket()

      }
    });
  };

    handleFarmerCrop =  async(farmerId) => {
    let farmer_crops = await read_farmer_crop_live({
      farmerId: farmerId,
    })
    let crops = [];
    farmer_crops.farmer_crop.crops.map((fcrop) => {
      this.state.crops.map((crop) => {
        if (crop._id === fcrop) {
          crops.push(crop.name);
        }
      });
    });
    return crops;
  };




  
   checkForm = () => {
    if (!this.state.crop_price) {
      this.setState({
        error: "Price is required",
      });
    }

    if(!this.state.gross_weight) {
      this.setState({
        error: "Gross Weight is required"
      })
    }

    if (!this.state.crop) {
      this.setState({
        error: "Crop is required",
      });
    }

    if (!this.state.vehicle_no) {
      this.setState({
        error: "Vehicle Number is required",
      });
    }

    if (!this.state.weighbridge_number) {
      this.setState({
        error: "Vehicle Number is required",
      });
    }

    if (this.state.crop_price && this.state.vehicle_no && this.state.crop && this.state.gross_weight && this.state.weighbridge_number) {
      this.handleSubmit();
    }

    if (!this.state.price && !this.state.vehicle_no && !this.state.crop && !this.state.gross_weight && !this.state.weighbridge_number) {
      this.setState({
        error: "All Fields are required",
      });
    }
  };

  handleSubmit = () => {
    const jwt = auth.isAuthenticated();
    const shipment = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      cooperativeLiveId: this.state.cooperativeLiveId,
      vehicleNumber: this.state.vehicle_no,
      type: this.state.type,
      partyName: this.state.party_name,
      price: this.state.price,
      status: "nothing",
      crop: this.state.crop_id,
      crop_price: this.state.crop_price,
      weighbridge_number: this.state.weighbridge_number,
      reason: "",
      gross_weight: this.state.gross_weight
    };
    create_shipment_live(shipment, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.setState({
          redirectToShipment: true,
        });
        this.setState({
          vehicle_no: "",
          type: "",
          party_name: "",
          price: "",
          modal: false,
        });
        /*
				const shipment = {
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					cooperativeId: this.state.cooperativeId,
					cooperativeLiveId: this.state.cooperativeLiveId,
					liveId: data.data._id,
					vehicle_number: this.state.vehicle_no,
					type: this.state.type,
					party_name: this.state.party_name,
					price: this.state.price,
				};
				create_shipment(shipment, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} if(data) {
						this.setState({
							redirectToShipment: true,
						});
						this.setState({
							vehicle_no: '',
							type: '',
							party_name: '',
							price: '',
							modal: false,
						});
					}
				});
				*/
      }
    });
  };

  handleDeleteOpenMarket = () => {
    remove_open_market_supplier_live({
      openMarketSupplierId: this.state.open_market_liveId,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        this.handleListOpenMarket();
        this.setState({
          delete_modal: false,
          success_notification: false,
          mssg: "Supplier Deleted Successfully",
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);

        /*
				remove_open_market_supplier(
					{
						_id: this.state.open_market_id,
					},
					
				).then(data => {
					if (!data) {
						return;
					} if(data.length !== 0) {
						this.handleListOpenMarket();
					}
				});
		
				this.setState({ delete_modal: false });
				*/
      }
    });
  };
  render() {
    if (this.state.redirectToShipment) {
      return <Redirect to="/weighbridge_shipment" />;
    }
    const { search } = this.state;
    const open_market_suppliers = this.state.open_market_suppliers.filter(
      (open_market_supplier) => {
        return open_market_supplier.first_name
          .toLowerCase()
          .includes(search.toLowerCase());
      }
    );
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="container-fluid">
              {this.state.success_notification ? (
                <div className="alert alert-success">
                  <button
                    onClick={() =>
                      this.setState({ success_notification: false })
                    }
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : this.state.error_notification ? (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">attach_money</i>
                      </div>
                      <p className="card-category">Current Crop Price</p>
                      <h3 className="card-title">
                        ₦{this.state.currentPrice}/Kg
                      </h3>
                    </div>
                    <div
                       className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              currentPrice: data.price[0].price,
                                              crop: data.name,
                                              dateUpdated: data.created
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                    <div className="card-footer">
                      <div className="stats">
                        {!this.state.dateUpdated ? " ": <div>
                          Price was Created on:
                          <div>
                            <Moment format="D-MM-YYYY" withTitle>
                              {this.state.dateUpdated}
                            </Moment>
                          </div>
                        </div>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header card-header-primary card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">people</i>
                      </div>
                      <h4 className="card-title">Open Market Suppliers</h4>
                    </div>
                    <div className="card-body">
                      <div className="toolbar">
                        <Link
                            to={{
                              pathname: "/add_open_market_supplier",
                              state: {
                                cooperativeId: this.state.OMScooperative._id,
                                cooperativeLiveId: this.state.OMScooperative._id,
                                name: this.state.OMScooperative.name
                              },
                            }}
                            className="btn btn-primary btn-round"
                          >
                            Create New Supplier
                          </Link>
                      </div>
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          <div
                            id="farmers_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                           {/* <div className="dt-buttons btn-group">
                                  <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-secondary buttons-excel buttons-html5"
                                table="open_market_suppliers"
                                filename="open_market_suppliersxls"
                                sheet="open_market_suppliersxls"
                                buttonText="Export Excel"
                              />{" "}
                              </div> */}
                            <div
                              id="open_market_suppliers_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <span className="bmd-form-group bmd-form-group-sm">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="open_market_suppliers"
                                    onChange={(event) =>
                                      this.setState({
                                        search: event.target.value,
                                      })
                                    }
                                  />
                                </span>
                              </label>
                            </div>
                            <table
                              title="Farmers"
                              id="open_market_suppliers"
                              className="table table-striped table-no-bordered table-hover dataTable"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                              role="grid"
                              aria-describedby="farmers_info"
                            >
                              <thead>
                                <tr role="row">
                                  {/* <th
                                    className="sorting_asc"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="ID: activate to sort column descending"
                                    style={{ width: "49px" }}
                                  >
                                    ID
                                  </th> */}
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Name: activate to sort column ascending"
                                    style={{ width: "77px" }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Phone Number: activate to sort column ascending"
                                    style={{ width: "109px" }}
                                  >
                                    Phone Number
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="State: activate to sort column ascending"
                                    style={{ width: "52px" }}
                                  >
                                    State
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Date Added: activate to sort column ascending"
                                    style={{ width: "48px" }}
                                  >
                                    Date Added
                                  </th>
                                  <th
                                    className="disabled-sorting text-right sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{ width: "168px" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  {/* <th rowSpan="1" colSpan="1">
                                    ID
                                  </th> */}
                                  <th rowSpan="1" colSpan="1">
                                    Name
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Phone Number
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    State
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Date Added
                                  </th>
                                  <th
                                    
                                    rowSpan="1"
                                    colSpan="1"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {open_market_suppliers.map((data) => (
                                  <tr key={data._id} role="row" className="odd">
                                    {/* <td className="sorting_1">SUPP/3</td> */}
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.phone_number}</td>
                                    <td>{data.state}</td>
                                    <td><Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment></td>
                                    <td className="text-right inline-block">
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          this.setState({
                                            party_name: `${data.first_name} ${data.last_name}`,
                                            type: "Open Market Supplier",
                                            modal: true,
                                          })
                                        }
                                      >
                                        Create Shipment
                                        <div className="ripple-container" />
                                      </button>
                                      {/* <div>
                                        <input
                                          type="hidden"
                                          name="csrfmiddlewaretoken"
                                          value="5Crdeg3giNiJGCwC0hE8qo2G1pJvWRVWKauKH0JC3n4EvG73Yq1KHZK0Bc6B96kd"
                                        />
                                        <input
                                          type="hidden"
                                          name="delete_supplier"
                                          value="3"
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Supplier"
                                          onClick={() =>
                                            this.setState({
                                              open_market_id: data._id,
                                              open_market_liveId: data._id,
                                              delete_modal: true,
                                            })
                                          }
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="farmers_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 1 of 1 entries
                            </div>
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="farmers_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="farmers_previous"
                                >
                                  <a
                                    href = "#/"
                                    aria-controls="farmers"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="farmers_next"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!this.state.open_market_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addSupplierModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{ marginTop: "-80px", display: "block" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create New Supplier</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={() =>
                            this.setState({
                              open_market_modal: false,
                              error: "",
                            })
                          }
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="iHy3PEkhGQUYkTxYN4VuzKA4RrNh6iLVAPTsqNRBkdPUE38DWIMgcKT9IOG2dJws"
                              />
                              <div className="col-md-6">
                                {/* <div className="form-group">
                                  <div className="dropdown bootstrap-select show">
                                    <button
                                      type="button"
                                      className="dropdown-toggle bs-placeholder btn btn-success"
                                      data-toggle="dropdown"
                                      title="Choose Title"
                                      onClick={this.handleTitleModal}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.title
                                              ? "Choose Title"
                                              : this.state.title}
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </button>
                                    <div
                                      id = "title"
                                      className={
                                        !this.state.title_modal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      x-placement="bottom-start"
                                      aria-expanded="true"
                                      aria-controls="tilte"
                                      style={{
                                        maxHeight: "162px",
                                        overflow: "hidden",
                                        minWidth: "452px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "152px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              href = "#/"
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Choose Title
                                              </span>
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.title !== "Mr."
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  title: "Mr.",
                                                  title_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">Mr.</span>
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.title !== "Alh."
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  title: "Alh.",
                                                  title_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">Alh.</span>
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.title !== "Mrs."
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  title: "Mrs.",
                                                  title_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">Mrs.</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <div
                                  className={
                                    this.state.first_name
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    for="editfirst_name"
                                    className="bmd-label-floating"
                                  >
                                    Enter First Name
                                  </label>
                                  <input
                                    required=""
                                    name="first_name"
                                    type="text"
                                    className="form-control"
                                    id="editfirst_name"
                                    onChange={(event) =>
                                      this.setState({
                                        first_name: event.target.value,
                                        error: "",
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className={
                                    this.state.last_name
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    for="editlast_name"
                                    className="bmd-label-floating"
                                  >
                                    Enter Last Name
                                  </label>
                                  <input
                                    required=""
                                    name="last_name"
                                    type="text"
                                    className="form-control"
                                    id="editlast_name"
                                    onChange={(event) =>
                                      this.setState({
                                        last_name: event.target.value,
                                        error: "",
                                      })
                                    }
                                  />
                                </div>
                                <div
                                  className={
                                    this.state.phone_number
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    for="editphone_number"
                                    className="bmd-label-floating"
                                  >
                                    Enter Phone Number
                                  </label>
                                  <input
                                    required=""
                                    name="phone_number"
                                    type="number"
                                    className="form-control"
                                    id="editphone_number"
                                    onChange={(event) =>
                                      this.setState({
                                        phone_number: event.target.value,
                                        error: "",
                                      })
                                    }
                                  />
                                </div>

                                <div
                                  className={
                                    this.state.address
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    for="editaddress"
                                    className="bmd-label-floating"
                                  >
                                    Enter Address
                                  </label>
                                  <input
                                    required=""
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    id="editaddress"
                                    onChange={(event) =>
                                      this.setState({
                                        address: event.target.value,
                                        error: "",
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="dropdown bootstrap-select show">
                                    <button
                                      type="button"
                                      className="dropdown-toggle bs-placeholder btn btn-success"
                                      data-toggle="dropdown"
                                      title="Choose Gender"
                                      onClick={this.handleGenderModal}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.gender
                                              ? "Choose Gender"
                                              : this.state.gender}
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </button>
                                    <div
                                      id="genderDropdown"
                                      className={
                                        !this.state.gender_modal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-expanded = "true"
                                      aria-controls = "genderDropdown"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "162px",
                                        overflow: "hidden",
                                        minWidth: "452px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "152px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              href = "#/"
                                              role="option"
                                              className="dropdown-item"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Choose Gender
                                              </span>
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.gender !== "Male"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  gender: "Male",
                                                  gender_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">Male</span>
                                            </a>
                                          </li>

                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.gender !== "Female"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  gender: "Female",
                                                  gender_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Female
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                <div
                                    className={
                                      !this.state.modals.state
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() => this.handleModal("state")}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.state
                                              ? "Choose State"
                                              : this.state.state}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="states"
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="states"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.state ||
                                                  "Choose State"}
                                              </span>
                                            </a>
                                          </li>
                                          {NaijaStates.states().map(
                                            (state, id) => (
                                              <li key={id}>
                                                <a
                                                  role="option"
                                                  className={
                                                    this.state.state === ""
                                                      ? "dropdown-item"
                                                      : "dropdown-item selected active"
                                                  }
                                                  aria-disabled="false"
                                                  tabIndex="0"
                                                  aria-selected="false"
                                                  onClick={() => {
                                                    this.handleModal("state");
                                                    this.setState({
                                                      state: state,
                                                    });
                                                  }}
                                                >
                                                  <span className=" bs-ok-default check-mark" />
                                                  <span className="text">
                                                    {state}
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                    className={
                                      !this.state.modals.LGA
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() => this.handleModal("LGA")}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.LGA
                                              ? "Choose LGA"
                                              : this.state.LGA}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="LGAs"
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="LGAs"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.LGA || "Choose LGA"}
                                              </span>
                                            </a>
                                          </li>
                                          {!this.state.state
                                            ? " "
                                            : NaijaStates.lgas(
                                                this.state.state
                                              ).lgas.map((LGA, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.LGA === ""
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleModal("LGA");
                                                      this.setState({
                                                        LGA: LGA,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {LGA}
                                                    </span>
                                                  </a>
                                                </li>
                                              ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                <div className="form-group">
                                  <div className="dropdown bootstrap-select show">
                                    <button
                                      type="button"
                                      className="dropdown-toggle bs-placeholder btn btn-success"
                                      data-toggle="dropdown"
                                      title="Choose Bank"
                                      onClick={this.handleBankModal}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.bank
                                              ? "Choose Bank"
                                              : this.state.bank}
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </button>
                                    <div
                                      id="bankDropdown"
                                      className={
                                        !this.state.bankmodal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-expanded = "true"
                                      aria-controls = "bankDropdown"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "162px",
                                        overflow: "hidden",
                                        minWidth: "452px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "152px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              href = "#/"
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Choose Bank
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Access Bank Plc"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "Access Bank Plc",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Access Bank Plc
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "ADB Bank Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "ADB Bank Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                ADB Bank Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Bank of Africa Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Bank of Africa Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Bank of Africa Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Barclays Bank of Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Barclays Bank of Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Barclays Bank of Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "CAL Bank Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "CAL Bank Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                CAL Bank Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Consolidated Bank of Ghana"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Consolidated Bank of Ghana",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Consolidated Bank Ghana
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Ecobank Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "Ecobank Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Ecobank Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "First Atlantic Bank"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "First Atlantic Bank",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                First Atlantic Bank
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "FBNBank Bank Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "FBNBank Bank Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                FBNBank Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Fidelity Bank Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Fidelity Bank Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Fidelity Bank Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "GCB Bank Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "GCB Bank Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                GCB Bank Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "GN Bank Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank: "GN Bank Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                GN Bank Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Guaranty Trust Bank Plc"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Guaranty Trust Bank Plc",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Guaranty Trust Bank Plc
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "National Investment Bank"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "National Investment Bank",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                National Investment Bank
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Prudential Bank Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Prudential Bank Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Prudential Bank Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Republic bank (HFC Bank Ghana Limited)"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Republic bank (HFC Bank Ghana Limited)",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Republic bank (HFC Bank Ghana
                                                Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Société Générale Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Société Générale Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Société Générale Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Stanbic Bank Ghana Limited"
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Stanbic Bank Ghana Limited",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Stanbic Bank Ghana Limited
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Standard Chartered Bank Ghana LimitedUnited Bank for Africa Plc "
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Standard Chartered Bank Ghana LimitedUnited Bank for Africa Plc ",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Standard Chartered Bank Ghana
                                                LimitedUnited Bank for Africa
                                                Plc
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.bank !==
                                                "Universal Merchant Bank Ghana LimitedZenith Bank Ghana "
                                                  ? "dropdown-item"
                                                  : "dropdown-item selected active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  bank:
                                                    "Universal Merchant Bank Ghana LimitedZenith Bank Ghana ",
                                                  bankmodal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Universal Merchant Bank Ghana
                                                LimitedZenith Bank Ghana
                                              </span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.account_number
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    for="editaccount_number"
                                    className="bmd-label-floating"
                                  >
                                    Enter Account Number
                                  </label>
                                  <input
                                    name="account_number"
                                    type="number"
                                    className="form-control"
                                    id="editaccount_number"
                                    onChange={(event) =>
                                      this.setState({
                                        account_number: event.target.value,
                                        error: "",
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_supplier"
                                value="1"
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm2}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                                <div className="ripple-container" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.delete_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addCooperativeModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Delete Shipment?</h4>
                        <button
                          onClick={() => this.setState({ delete_modal: false })}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              Are you sure you want to delete this Open Supplier
                            </div>

                            <button
                              type="submit"
                              onClick={this.handleDeleteOpenMarket}
                              className="btn btn-fill btn-success"
                            >
                              Delete
                            </button>
                            <button
                              type="submit"
                              className="btn btn-fill btn-danger"
                              onClick={() =>
                                this.setState({ delete_modal: false })
                              }
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="btn btn-fill btn-primary"
                              id="create_offline"
                              style={{ display: "none" }}
                            >
                              Create Offline
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addShipmentModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create New Shipment</h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            this.setState({ modal: false, error: "" })
                          }
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="KzqureCJk7bTWLWJ58AHvaMO07xHken9p7t1UYi55HXOLPxa3hXjMLu8AUUNxtMq"
                              />

                              <div
                                className={
                                  this.state.vehicle_no
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for="vehicle_no"
                                  className="bmd-label-floating"
                                >
                                  Enter Vehicle Number
                                </label>
                                <input
                                  required=""
                                  name="vehicle_no"
                                  type="text"
                                  className="form-control"
                                  id="vehicle_no"
                                  onChange={(event) =>
                                    this.setState({
                                      vehicle_no: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.gross_weight
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="gross_weight"
                                  className="bmd-label-floating"
                                >
                                  Enter Gross Weight
                                </label>
                                <input
                                  required=""
                                  name="gross_weight"
                                  type="text"
                                  className="form-control"
                                  id="gross_weight"
                                  onChange={(event) =>
                                    this.setState({
                                      gross_weight: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.weighbridge_number
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for="weighbridge_number"
                                  className="bmd-label-floating"
                                >
                                  Enter WeighBridge Number
                                </label>
                                <input
                                  required=""
                                  name="weighbridge_number"
                                  type="text"
                                  className="form-control"
                                  id="weighbridge_number"
                                  onChange={(event) =>
                                    this.setState({
                                      weighbridge_number: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                              prices: data.price
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select show">
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn  btn-success"
                                    data-toggle="dropdown"
                                    title="Choose Price"
                                    aria-expanded="true"
                                    onClick={this.handlePriceModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          { !this.state.crop_price
                                            ? "Choose Price"
                                            : `${this.state.crop_price}`}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.price_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href = "#/"
                                            role="option"
                                            className={
                                              !this.state.category
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Price
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.prices.map((data) => (
                                          <li key={data._id}>
                                            <a
                                              href= "#/"
                                              role="option"
                                              className={
                                                this.state.crop_price !== data.price
                                                  ? "dropdown-item"
                                                  : "dropdown-item active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  crop_price: data.price,
                                                  price_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                              ₦{data.price} -{" "}
                                              <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                              </span>
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_shipment"
                                value=""
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OpenMarketSuppliers;
