import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import {
  create_crop_live,
  list_crop_live,
  remove_crop_live,
} from "../../api/api-crop";
import { list_crop_price_live } from "../../api/api-crop_price";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import { Link } from "react-router-dom";

class Crop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delete_modal: false,
      editmodal: false,
      id: "",
      name: "",
      price: "",
      crops: [],
      crop_id: "",
      current_crop_price: "",
      cropId: "",
      allocated_loan_per_hectare: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
    };
    this.handleList();
    this.handleListPrice();
  }

  componentDidMount = () => {
    if (this.props.location.state === undefined) {
      console.log("error");
    } else if (this.props.location.state.update_notification) {
      this.setState({
        success_notification: true,
        mssg: "Crop updated successfully",
      });
      setTimeout(() => this.setState({ success_notification: false }), 3000);
    }
    document.title = "Crops";
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleOpenEditModal = (id, name, price, allocated_loan_per_hectare) => {
    this.setState({
      editmodal: true,
      id: id,
      name: name,
      price: price,
      allocated_loan_per_hectare: allocated_loan_per_hectare,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
    });
  };

  handleList = () => {
    if (navigator.onLine) {
      list_crop_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.error) {
          this.setState({
            mssg: data.error,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.message) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data) {
          this.setState({ crops: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleListPrice = () => {
    if (navigator.onLine) {
      list_crop_price_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length !== 0) {
          this.setState({ current_crop_price: data });
        } else {
          this.setState({ current_crop_price: "" });
        }
      });
    } else if (!navigator.onLine) {
      /*
			list_cropprice().then(data => {
				if (data.error) {
					console.log(data.error);
				}
				if (data.length !== 0) {
					this.setState({ current_crop_price: data });
				} else {
					this.setState({ current_crop_price: '' });
				}
			});
			*/
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  /*

	editForm = () => {
		const jwt = auth.isAuthenticated();

		const crop = {
			_id: this.state.id,
			name: this.state.name,
			type: this.state.type,
			price: this.state.price,
		};
		update_crop(crop, {
			t: jwt.token,
		}).then(data => {
			if (data.error) {
				this.setState({ error: data.error });
			} else {
				this.handleList();
		
			this.setState({
				loading: false,
				update_notification: true,
				mssg: 'crop updated successfully',
			});
			this.handleCloseEditModal();
			}
		});
		
	};
	*/

  checkForm = () => {
    if (!this.state.name) {
      this.setState({
        error: "Crop Name is required",
      });
    }

    if (this.state.allocated_loan_per_hectare < 0) {
      this.setState({
        error: "Enter a Valid Amount",
      });
    }

    if (!this.state.allocated_loan_per_hectare) {
      this.setState({
        error: "Allocated Loan is required",
      });
    }

    if (this.state.name  &&
      this.state.allocated_loan_per_hectare > 0
    ) {
      this.submitForm();
    }

    if (!this.state.name && !this.allocated_loan_per_hectare) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const crop = {
        deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        name: this.state.name,
        price: 0,
        allocated_loan_per_hectare: this.state.allocated_loan_per_hectare,
      };

      create_crop_live(crop, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          this.setState({
            mssg: `Could not Create Crop`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        }
        if (data) {
          this.handleList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Crop created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  };

  deleteData = () => {
    remove_crop_live({
      cropId: this.state.crop_id,
    }).then((data) => {
      window.location.reload();
      // if(data) {
      //   this.handleList();
      //   this.setState({
      //     loading: false,
      //     error_notification: true,
      //     mssg: "Crop deleted successfully",
      //   });
      //   setTimeout(() => this.setState({ error_notification: false }), 3000);
      //   this.handleDeleteModal();
      // }
    });
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.update_notification ? (
                  <div className="alert alert-warning">
                    <button
                      onClick={() =>
                        this.setState({ update_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Crops</h4>
                      </div>
                      <div className="card-body">
                        {/* <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create a new Crop
                          </button>
                        </div> */}
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Allocated Loan</th>
                                  <th>Date Added</th>
                                  {/* <th className="disabled-sorting text-right">
                                    Actions
                                  </th> */}
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Allocated Loan</th>
                                  <th>Date Added</th>
                                  {/* <th className="text-right">Actions</th> */}
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.crops.map((data) => {
                                  return (
                                    <tr key={data._id}>
                                      <td>{data.name}</td>
                                      <td>
                                        ₦{data.allocated_loan_per_hectare}
                                      </td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      {/* <td className="text-right inline-block">
                                        <Link
                                          className="btn btn-link btn-just-icon btn-info edit"
                                          to={{
                                            pathname: "/ext_plus_crop_price",
                                            state: {
                                              id: data._id,
                                              liveId: data._id,
                                              name: data.name,
                                              allocated_loan_per_hectare:
                                                data.allocated_loan_per_hectare,
                                            },
                                          }}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit Crop"
                                        >
                                          <i className="material-icons">dvr</i>
                                        </Link>
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            onClick={() =>
                                              this.setState({
                                                crop_id: data._id,
                                                delete_modal: true,
                                              })
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Crop "
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Crop?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Crop
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.deleteData()}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  Crop.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createcropModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          {/* <div className="modal-header">
                            <h4 className="modal-title">Create a new Crop</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div> */}
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Crop
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                            error: "",
                                          })
                                        }
                                        required
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        for="inputType"
                                        className="bmd-label-floating"
                                      >
                                        Enter Allocated Loan Per Hectare
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            allocated_loan_per_hectare:
                                              event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="type"
                                        type="text"
                                        className="form-control"
                                        id="inputAllocatedLoan"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_crop"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    onClick={this.checkForm}
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  Crop.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.editmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Edit Crop</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleCloseEditModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        for="editinputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Crop
                                      </label>
                                      <input
                                        onChange={this.handleChange("name")}
                                        value={this.state.name}
                                        type="text"
                                        className="form-control"
                                        id="editinputName"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label
                                        for="editinputPrice"
                                        className="bmd-label-floating"
                                      >
                                        Enter Unit Price of Crop (Per Kg)
                                      </label>
                                      <input
                                        onChange={this.handleChange("price")}
                                        value={this.state.price}
                                        type="number"
                                        className="form-control"
                                        id="editinputPrice"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    id="editinputID"
                                    type="hidden"
                                    name="edit_faem_input"
                                    value=""
                                  />
                                  <button
                                    onClick={this.editForm}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseEditModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to edit this
                                  Crop.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Crop;
