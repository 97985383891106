import React from 'react';
import Nav from './Admin/Nav';
import UserContext from './UserContext';
import ExtensionSideBar from './ExtensionDepartment/SideBar';
import AdminSideBar from './Admin/SideBar';
import AccountantSideBar from './Accountant/SideBar';
import WarehouseSideBar from './WareHouse/SideBar';

const NotFound = () => {
  const checkType = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.role === 'admin') return <AdminSideBar />;
    if (user.role === 'extension_manager') return <ExtensionSideBar />;
    if (user.role === 'warehouse_manager') return <WarehouseSideBar />;
    if (user.role === 'accountant') return <AccountantSideBar />;
  };

  return (
    <div className='wrapper'>
      {checkType()}
      <div className='main-panel'>
        <Nav title={'Not Found'} />
        <div className='content'>
          <div className='container-fluid'>
            <h1>Oops! That page can't be found.</h1>
            <p>
              Nothing was found in this url. Try one of links in the menu or
              press back to go back to the previous page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

NotFound.contextType = UserContext;

export default NotFound;
