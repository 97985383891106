import { fetchRequest } from "../utils";

export const create_outgoing_crop = (outgoing_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/outgoing_crop/`,
    method: "POST",
    data: outgoing_crop,
  });

export const list_outgoing_crop = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/outgoing_crop/`,
  });

export const read_outgoing_crop = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/outgoing_crop/` +
      params.outgoing_cropId,
  });

export const update_outgoing_crop = (outgoing_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/outgoing_crop/`,
    method: "PUT",
    data: outgoing_crop,
  });

export const remove_outgoing_crop = (outgoing_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/outgoing_crop/`,
    method: "DELETE",
    data: outgoing_crop,
  });

export const create_outgoing_crop_live = (outgoing_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/outgoing_crop/`,
    method: "POST",
    data: outgoing_crop,
  });

export const list_outgoing_crop_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/outgoing_crop/`,
  });

export const remove_outgoing_crop_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/outgoing_crop/` +
      params.outgoing_cropId,
    method: "DELETE",
  });
