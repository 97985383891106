import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { changePassword, update } from "../../api/api-users";
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";
class WareHouseProfile extends Component {
  state = {
    oldpassword: "",
    newpassword: "",
    error: "",
    success: "",
    email: this.props.auth.isAuthenticated().user.email,
    first_name: this.props.auth.isAuthenticated().user.first_name,
    last_name: this.props.auth.isAuthenticated().user.last_name,
    loading: false,
  };

  componentDidMount() {
    document.title = "Profile";
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCloseError = () => {
    this.setState({
      error: "",
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: "",
    });
  };

  passwordReset = () => {
    const jwt = auth.isAuthenticated();
    this.setState({ loading: true });
    const user = {
      email: auth.isAuthenticated().user.email,
      _id: auth.isAuthenticated().user._id,
      oldPassword: this.state.oldpassword,
      newPassword: this.state.newpassword,
    };
    changePassword(user, {
      t: jwt.token,
    })
      .then((data) => {
        console.log(data);
        if (data.error) {
          this.setState({
            error: `Sorry could not change your password at this time`,
            success: "",
            loading: false,
          });
          setTimeout(() => this.setState({ error: "" }), 3000);
        } else {
          this.setState({
            success: "Password changed Successfully",
            error: "",
            loading: false,
          });
          setTimeout(() => this.setState({ success: "" }), 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, success: "", loading: false });
        setTimeout(() => this.setState({ error: "" }), 3000);
      });
  };

  updateUser = async (e) => {
    e.preventDefault();

    const { first_name, last_name} = this.state;
    const data = { first_name, last_name };
    this.setState({ loading: true });

    try {
      const {error} = await update(data);

      if (error) {
        this.setState({
          error: `Could not update user`,
          success: "",
          loading: false,
        });
        setTimeout(() => this.setState({ error: "" }), 3000);
      }
      if (data) {
        auth.updateUser({
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
        });
        this.setState({
          success: `Updated user successfully`,
          error: "",
          loading: false,
        });
        setTimeout(() => this.setState({ success: "" }), 3000);
      } else {
        this.setState({
          error: `Could not update user`,
          success: "",
          loading: false,
        });
        setTimeout(() => this.setState({ error: "" }), 3000);
      }
    } catch (err) {
      console.log(err);
      this.setState({
        error: `Could not update user`,
        success: "",
        loading: false,
      });
      setTimeout(() => this.setState({ error: "" }), 3000);
    }
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.error && !this.state.success ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={this.handleCloseError}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span> {this.state.error} </span>
                  </div>
                ) : !this.state.error && this.state.success ? (
                  <div className="alert alert-success">
                    <button
                      onClick={this.handleCloseSuccess}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span> {this.state.success} </span>
                  </div>
                ) : (
                  ""
                )}
                {this.state.loading ? (
                  <Loader
                    style={{ marginTop: "30vh", textAlign: "center" }}
                    type="Oval"
                    color="green"
                    height={50}
                    width={50}
                  />
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header card-header-primary card-header-icon">
                          <div className="card-icon">
                            <i className="material-icons">info</i>
                          </div>
                          <h4 className="card-title">Basic Information</h4>
                        </div>
                        <form onSubmit={this.updateUser}>
                          <div className="card-body ">
                            <div className="form-group is-filled bmd-form-group">
                              <label
                                htmlFor="email"
                                className="bmd-label-floating"
                              >
                                Email
                              </label>
                              <input
                                required
                                name="email"
                                type="email"
                                className="form-control"
                                id="email"
                                disabled
                                value={auth.isAuthenticated().user.email}
                              />
                            </div>
                            <div className="form-group is-filled bmd-form-group">
                              <label
                                htmlFor="firstName"
                                className="bmd-label-floating"
                              >
                                First Name
                              </label>
                              <input
                                required
                                name="first_name"
                                type="text"
                                className="form-control"
                                id="firstName"
                                value={this.state.first_name}
                                onChange={this.handleChange("first_name")}
                              />
                            </div>
                            <div className="form-group is-filled bmd-form-group">
                              <label
                                htmlFor="lastName"
                                className="bmd-label-floating"
                              >
                                Last Name
                              </label>
                              <input
                                required
                                name="last_name"
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={this.state.last_name}
                                onChange={this.handleChange("last_name")}
                              />
                            </div>
                          </div>
                          <div className="card-footer ">
                            <input type="hidden" name="basic" value="1" />
                            <button
                              type="submit"
                              className="btn btn-fill btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header card-header-primary card-header-icon">
                          <div className="card-icon">
                            <i className="material-icons">lock</i>
                          </div>
                          <h4 className="card-title">Change Password</h4>
                        </div>
                        <div>
                          <div className="card-body ">
                            <div
                              className={
                                this.state.oldpassword
                                  ? "is-filled form-group bmd-form-group"
                                  : "form-group bmd-form-group"
                              }
                            >
                              <label
                                htmlFor="old_password"
                                className="bmd-label-floating"
                              >
                                Old Password
                              </label>
                              <input
                                onChange={this.handleChange("oldpassword")}
                                required
                                name="old_password"
                                type="password"
                                className="form-control"
                                id="old_password"
                              />
                            </div>
                            <div
                              className={
                                this.state.newpassword
                                  ? "is-filled form-group bmd-form-group"
                                  : "form-group bmd-form-group"
                              }
                            >
                              <label
                                htmlFor="new_password"
                                className="bmd-label-floating"
                              >
                                New Password
                              </label>
                              <input
                                onChange={this.handleChange("newpassword")}
                                required
                                name="new_password"
                                type="password"
                                className="form-control"
                                id="new_password"
                              />
                            </div>
                          </div>
                          <div className="card-footer ">
                            <button
                              onClick={this.passwordReset}
                              type="submit"
                              className="btn btn-fill btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default WareHouseProfile;
