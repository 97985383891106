import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import {
  
  listseasons_live,
  
} from "../../api/api-seasons";
import Moment from "react-moment";
class ExtensionSeason extends Component {
  constructor() {
    super();
    this.state = {
      seasons: [],
      mssg: '',
      error_notification: false
    };
    this.handleList();
  }
  handleList = () => {
    console.log("geg");
    listseasons_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ seasons: data });
      }
    });
  };

  componentDidMount() {
    document.title = "Seasons";
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
            {this.state.error_notification && (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              )}

              <div className="container-fluid">
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <h4 className="card-title">Farm Seasons</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Start Month</th>
                                  <th>End Month</th>
                                  <th>Year</th>

                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Type</th>
                                  <th>Start Month</th>
                                  <th>End Month</th>
                                  <th>Year</th>

                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              {this.state.seasons === [] ? (
                                <tbody>
                                  <h3>Nothing to see here yet.</h3>
                                </tbody>
                              ) : (
                                <tbody>
                                  {this.state.seasons.map((data, id) => (
                                    <tr key={id}>
                                      <td>{data.type}</td>
                                      <td>{data.start_month}</td>
                                      <td>{data.end_month}</td>
                                      <td>{data.year}</td>

                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <Link
                                          to={{
                                            pathname:
                                              "/ext_plus_season_cooperative",
                                            state: {
                                              seasonId: data._id,
                                              seasonLiveId: data._id,
                                              name: data.name,
                                              type: data.type,
                                              start_month: data.start_month,
                                              end_month: data.end_month,
                                              year: data.year,
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="createSeasonModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create New Farm Season</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <form action="." method="post">
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div className="form-group">
                                <select
                                  required
                                  name="type"
                                  id="type"
                                  className="selectpicker"
                                  data-size="4"
                                  data-style="btn btn-round btn-info"
                                  title="Choose Type"
                                  defaultValue={"Choose Type"}
                                >
                                  <option disabled value="Choose Type">
                                    Choose Type
                                  </option>
                                  <option value="Dry">Dry</option>
                                  <option value="Wet">Wet</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <select
                                  required
                                  name="start"
                                  id="start"
                                  className="selectpicker"
                                  data-size="13"
                                  data-style="btn btn-round btn-info"
                                  title="Choose Start Month"
                                  defaultValue={"Choose Start Month"}
                                >
                                  <option disabled value="Choose Start Month">
                                    Choose Start Month
                                  </option>
                                  <option value="January">January</option>
                                  <option value="February">February</option>
                                  <option value="March">March</option>
                                  <option value="April">April</option>
                                  <option value="May">May</option>
                                  <option value="June">June</option>
                                  <option value="July">July</option>
                                  <option value="August">August</option>
                                  <option value="September">September</option>
                                  <option value="October">October</option>
                                  <option value="November">November</option>
                                  <option value="December">December</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <select
                                  required
                                  name="end"
                                  id="end"
                                  className="selectpicker"
                                  data-size="13"
                                  data-style="btn btn-round btn-info"
                                  title="Choose End Month"
                                  defaultValue="Choose End Month"
                                >
                                  <option disabled>Choose End Month</option>
                                  <option value="January">January</option>
                                  <option value="February">February</option>
                                  <option value="March">March</option>
                                  <option value="April">April</option>
                                  <option value="May">May</option>
                                  <option value="June">June</option>
                                  <option value="July">July</option>
                                  <option value="August">August</option>
                                  <option value="September">September</option>
                                  <option value="October">October</option>
                                  <option value="November">November</option>
                                  <option value="December">December</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <select
                                  required
                                  name="year"
                                  id="year"
                                  className="selectpicker"
                                  data-size="4"
                                  data-style="btn btn-round btn-info"
                                  title="Choose Year"
                                >
                                  <option value="2018">2018</option>
                                </select>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="add_all_cari"
                                    id="add_all_cari"
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    defaultChecked
                                  />{" "}
                                  Add All CARI Cooperatives to Season
                                  <span className="form-check-sign">
                                    <span className="check" />
                                  </span>
                                </label>
                              </div>
                              <div className="form-check">
                                <label className="form-check-label">
                                  <input
                                    name="add_all_labana"
                                    id="add_all_labana"
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    defaultChecked
                                  />{" "}
                                  Add All Labana Cooperatives to Season
                                  <span className="form-check-sign">
                                    <span className="check" />
                                  </span>
                                </label>
                              </div>
                              <input
                                type="hidden"
                                name="create_season_live"
                                value="1"
                              />

                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                id="create_online"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <button
                        className="btn btn-fill btn-primary"
                        id="create_offline"
                        // onClick="insertIntoIndexedDB()"
                        style={{ display: "none" }}
                      >
                        Create Offline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ExtensionSeason;
