import React, { Component } from "react";
import auth from "../../api/auth-helper";
import {Link } from "react-router-dom";
class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      cooperative: false,
      farmer: false,
      price: false,
    };
  }

  handleExtension = () => {
    this.setState((prevState) => ({
      price: !prevState.price,
    }));
  };

  handleCooperative = () => {
    this.setState((prevState) => ({
      cooperative: !prevState.cooperative,
    }));
  };

  handleFarmer = () => {
    this.setState((prevState) => ({
      farmer: !prevState.farmer,
    }));
  };
  render() {
    return (
      <div
        className="sidebar"
        data-color="green"
        data-background-color="black"
        data-image="/static/assets/img/sidebar-6.jpg"
      >
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            SB
          </a>

          <a href="/" className="simple-text logo-normal">
            Auditor
          </a>
        </div>

        <div className="sidebar-wrapper">
          <div className="user">
            <div className="user-info">
              <a href="/" className="username">
                <span>
                  {` ${auth.isAuthenticated().user.first_name}  ${
                    auth.isAuthenticated().user.last_name
                  } `}
                </span>
              </a>
            </div>
          </div>
          <ul className="nav">
            {/* <li className="nav-item {{ extension_page }}">
              <a className="nav-link" href="#/" onClick={this.handleExtension}>
                <i className="material-icons">attach_money</i>
                <p>
                  {" "}
                  Prices
                  <b className="caret"></b>
                </p>
              </a>

              <div className={this.state.price ? "" : "collapse"}>
                <ul className="nav">
                  <li className="nav-item {{ extension_managers_page }}">
                    <Link className="nav-link" to="/auditor_crop_price">
                      <span className="sidebar-mini"> CC </span>
                      <span className="sidebar-normal"> Crop Prices </span>
                    </Link>
                  </li>
                  <li className="nav-item {{ extension_farm_inputs_page }}">
                    <Link className="nav-link" to="/auditor_farm_inputs">
                      <span className="sidebar-mini"> FI </span>
                      <span className="sidebar-normal"> Farm Inputs </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li
              className="nav-item {{ farmers_page }}"
              onClick={this.handleFarmer}
            >
              <Link className="nav-link" to="/auditor_cooperatives">
                <i className="material-icons">people</i>
                <p> Cooperatives</p>
              </Link>
            </li>

            <li
              className="nav-item {{ farmers_page }}"
            >
              <Link className="nav-link" to="/auditor_payment_schedule">
                <i className="material-icons">credit_card</i>
                <p> Payment Schedule</p>
              </Link>
            </li>

            <li
              className="nav-item {{ farmers_page }}"
              onClick={this.handleFarmer}
            >
              <Link className="nav-link" to="/auditor_farmers">
                <i className="material-icons">wc</i>
                <p> Farmers</p>
              </Link>
            </li>
            <li
              className="nav-item {{ farmers_page }}"
              onClick={this.handleFarmer}
            >
              <Link className="nav-link" to="/auditor_open_market_suppliers">
                <i className="material-icons">people</i>
                <p> Open Market Suppliers</p>
              </Link>
            </li>

            <li className="nav-item {{ coop_page }}">
              <Link className="nav-link" to="/auditor_shipment">
                <i className="material-icons">airport_shuttle</i>
                <p> Shipments</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
