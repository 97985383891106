import { fetchRequest } from "../utils";

export const create_shipment = (shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment/`,
    method: "POST",
    data: shipment,
  });

export const list_shipment = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment/` });

export const read_shipment = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment/` + params.shipmentId,
  });

export const update_shipment = (shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment/`,
    method: "PUT",
    data: shipment,
  });

export const remove_shipment = (shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment/`,
    method: "DELETE",
    data: shipment,
  });

export const create_shipment_live = (shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment/`,
    method: "POST",
    data: shipment,
  });

export const list_shipment_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment/`,
  });

export const update_shipment_live = (shipment, params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment/` +params.shipmentId,
    method: "PUT",
    data: shipment,
  });

export const remove_shipment_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment/` +
      params.shipmentId,
    method: "DELETE",
  });
