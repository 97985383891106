import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { list_crop_live } from "../../api/api-crop";
import {create_quality_metric_type_live, list_quality_metric_type_live, remove_quality_metric_type_live, update_quality_metric_type_live} from "../../api/api-quality_metric_type"
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import { Link } from "react-router-dom";

class QualityMetric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delete_modal: false,
      edit_modal: false,
      id: "",
      name: "",
      type: "",
      price: "",
      quantity: '',
      quality_metric_type: [],
      quality_metric_type_id: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      warning_level: 0,
      crops: [],
      crop: "",
      crop_id:"",
      cropmodal: false,
      modals:{
          crop: false
      }
    };
    this.handleList();
    this.handleCropList()
  }

  componentDidMount = () => {
    if (this.props.location.state === undefined) {
      console.log("error");
    } else if (this.props.location.state.update_notification) {
      this.setState({
        success_notification: true,
        mssg: "Quality Metric updated successfully",
      });
      setTimeout(() => this.setState({ success_notification: false }), 3000);
    }
    document.title = "Quality Metrics";
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleModals = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };


  handleCropModal = () => {
    this.setState((prevState) => ({
      cropmodal: !prevState.cropmodal,
    }));
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleEditModal = () => {
    this.setState((prevState) => ({
      edit_modal: !prevState.edit_modal,
    }));
  };


  handleCropList = () => {
    if (navigator.onLine) {
      list_crop_live().then((data) => {
        if (!data) {
          this.setState({
            error_notification: true,
            mssg: "There seems to be a problem, reload this page or login out",
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length === 0) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
            crops: [],
          });
        } else if (data.error) {
          this.setState({
            mssg: data.error,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ crops: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };



  handleList = () => {
    if (navigator.onLine) {
      list_quality_metric_type_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ quality_metric_type: data });

        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  checkForm = () => {
    if (!this.state.crop) {
      this.setState({
        error: "Crop is required",
      });
    }

    if (!this.state.metric) {
      this.setState({
        error: " Metric is required",
      });
    }

    if (!this.state.unit) {
      this.setState({
        error: "Unit is required",
      });
    }

    if (this.state.crop && this.state.metric && this.state.unit) {
      this.submitForm();
    }

    if (!this.state.crop && !this.state.metric && !this.state.unit) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const quality_metric_type = {
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        crop: this.state.crop_id,
        metric: this.state.metric,
        unit: this.state.unit,
      };

      create_quality_metric_type_live(quality_metric_type, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Quality Metric created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleList()
          this.handleModal();
        }
      });
    }
  };

   editForm = () => {
    const jwt = auth.isAuthenticated();
    const quality_metric_type ={
        crop: this.state.crop_id,
        metric: this.state.metric,
        unit: this.state.unit,
    }
    update_quality_metric_type_live(quality_metric_type,{
      quality_metric_typeId: this.state.quality_metric_type_id
    },
    {
      t: jwt.token
    }
    ).then((data)=>{
      if(!data){
        this.handleEditModal()
        return;
      }
      if (data) {
        this.handleList();
        this.handleEditModal()
      }
    })
  }

  deleteData = () => {
    remove_quality_metric_type_live({
      quality_metric_typeId: this.state.quality_metric_type_id,
    }).then((data) => {
      if (!data) {
        return;
      } else {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Quality Metric deleted successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.update_notification ? (
                  <div className="alert alert-warning">
                    <button
                      onClick={() =>
                        this.setState({ update_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Quality Metrics</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Quality Metrics
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Crop</th>
                                  <th>Metric</th>
                                  <th>Unit</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Crop</th>
                                  <th>Metric</th>
                                  <th>Unit</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.quality_metric_type.map((data) => (
                                  <tr key={data._id}>
                                    <td>{data.crop.name}</td>
                                    <td>{data.metric}</td>
                                    <td>{data.unit}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                                type="submit"
                                                className="btn btn-link btn-just-icon btn-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Edit Shipment"
                                                onClick={() =>
                                                  this.setState({
                                                    quality_metric_type_id: data._id,
                                                    edit_modal: true,
                                                    metric: data.metric,
                                                    crop: data.crop.name,
                                                    unit: data.unit,
                                                    crop_id: data.crop._id
                                                  })
                                                }
                                              >
                                                <i className="material-icons">
                                                  dvr
                                                </i>
                                              </button>
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.setState({
                                              quality_metric_type_id: data._id,
                                              delete_modal: true,
                                            })
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Quality Metric"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Quality Metric?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Quality Metric Type
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.deleteData()}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  Quality Metric.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createQualityMetricTypeModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Create Quality Metric</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Metric of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            metric: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="metric"
                                        type="text"
                                        className="form-control"
                                        id="inputMetric"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        for="inputType"
                                        className="bmd-label-floating"
                                      >
                                        Enter Unit of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            unit: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="type"
                                        type="text"
                                        className="form-control"
                                        id="inputType"
                                      />
                                    </div>

                                <div
                                  className={
                                    !this.state.modals.crop
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => this.handleModals("crop")}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.crop
                                            ? "Choose Crop"
                                            : this.state.crop}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.modals.crop
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      minWidth: "250px",
                                      overflow: "hidden",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled">
                                          <a
                                            role="option"
                                            className="dropdown-item disabled"
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="false"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              {this.state.crop || "Choose Crop"}
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.crops.map((data) => {
                                          return (
                                            <li key={data._id}>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.crop === ""
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {
                                                  console.log(data._id);
                                                  this.handleModals("crop");
                                                  this.setState({
                                                    crop: data.name,
                                                    crop_id: data._id,
                                                  });
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.name}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                              </div>
                                    
                              </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_quality_metric_type"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    onClick={this.checkForm}
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  Quality Metric.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.edit_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Edit Quality Metric</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleEditModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
    
                                    <div className="form-group">
                                      <label
                                        for="editinputType"
                                        className="bmd-label-floating"
                                      >
                                        Enter Metric of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            metric: event.target.value,
                                            error: "",
                                          })
                                        }
                                        value={this.state.metric}
                                        name="metric"
                                        type="text"
                                        className="form-control"
                                        id="EditMetric"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label
                                        for="editinputPrice"
                                        className="bmd-label-floating"
                                      >
                                        Enter Unit of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            unit: event.target.value,
                                            error: "",
                                          })
                                        }
                                        value={this.state.unit}
                                        name="unit"
                                        type="text"
                                        className="form-control"
                                        id="EditUnit"
                                      />
                                    </div>
                                  </div>

                                   <div
                                  className={
                                    !this.state.modals.crop
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => this.handleModals("crop")}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.crop
                                            ? "Choose Crop"
                                            : this.state.crop}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.modals.crop
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      minWidth: "250px",
                                      overflow: "hidden",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled">
                                          <a
                                            role="option"
                                            className="dropdown-item disabled"
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="false"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              {this.state.crop || "Choose Crop"}
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.crops.map((data) => {
                                          return (
                                            <li key={data._id}>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.crop === ""
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {
                                                  console.log(data._id);
                                                  this.handleModals("crop");
                                                  this.setState({
                                                    crop: data.name,
                                                    crop_id: data._id,
                                                  });
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.name}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                              </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    id="editinputID"
                                    type="hidden"
                                    name="edit_quality_metric_type"
                                    value=""
                                  />
                                  <button
                                    onClick={this.editForm}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleEditModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to edit this
                                  Quality Metric.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default QualityMetric;
