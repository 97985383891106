import React, { Component } from "react";
import auth from "../../api/auth-helper";
import { Link } from "react-router-dom";
class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      cooperative: false,
      farmer: false,
    };
  }

  handleCooperative = () => {
    this.setState((prevState) => ({
      cooperative: !prevState.cooperative,
    }));
  };

  handleFarmer = () => {
    this.setState((prevState) => ({
      farmer: !prevState.farmer,
    }));
  };
  render() {
    return (
      <div
        className="sidebar perfect-scrollbar-off nav-open"
        data-color="green"
        data-background-color="black"
        data-image="/static/assets/img/sidebar-6.jpg"
      >
        <div className="logo">
          <a href="/extension/" className="simple-text logo-mini">
            SB
          </a>

          <a href="/extension/" className="simple-text logo-normal">
            Extension
          </a>
        </div>

        <div className="sidebar-wrapper">
          <div className="user">
            <div className="user-info">
              <Link to="/extension" className="username">
                <span>
                  <strong>
                    {` ${auth.isAuthenticated().user.first_name}  ${
                      auth.isAuthenticated().user.last_name
                    } `}
                  </strong>
                </span>
              </Link>
            </div>
          </div>
          <ul className="nav">
            <li className="nav-item {{ shipments_page }}">
              <Link className="nav-link" to="/extension_season">
                <i className="material-icons">gradient</i>
                <p>
                  {" "}
                  <strong>Season</strong>
                </p>
              </Link>
            </li>

            <li className="nav-item {{ shipments_page }}">
              <Link className="nav-link" to="/extension_project">
                <i className="material-icons">grass</i>
                <p>
                  {" "}
                  <strong>Project</strong>
                </p>
              </Link>
            </li>

            <li className="nav-item {{ shipments_page }}">
              <Link className="nav-link" to="/extension_crop">
                <i className="material-icons">grass</i>
                <p>
                  {" "}
                  <strong>Crop</strong>
                </p>
              </Link>
            </li>

            <li className="nav-item {{ shipments_page }}">
              <Link className="nav-link" to="/extension_farm_input">
                <i className="material-icons">input</i>
                <p>
                  {" "}
                  <strong>Farm Input</strong>
                </p>
              </Link>
            </li>

            <li className="nav-item {{ coop_page }}">
              <Link className="nav-link" to="/cooperative">
                <i className="material-icons">people</i>
                <p>
                  {" "}
                  <strong>Cooperatives</strong>
                </p>
              </Link>
            </li>

            <li
              className="nav-item {{ farmers_page }}"
              onClick={this.handleFarmer}
            >
              <Link className="nav-link" to="/farmers">
                <i className="material-icons">wc</i>
                <p>
                  {" "}
                  <strong>Farmers</strong>
                </p>
              </Link>
            </li>
            <li className="nav-item {{ shipments_page }}">
              <Link className="nav-link" to="/shipments">
                <i className="material-icons">airport_shuttle</i>
                <p>
                  {" "}
                  <strong>Shipments</strong>
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
