import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";

import Login from "./components/Login/Login";
import UserContext from "./components/UserContext";
import SignUp from "./components/Login/Signup";

// Admin Dashboard
import FarmInputs from "./components/Admin/FarmInputs";
import Crop from "./components/Admin/Crop";
import CropPrice from "./components/Admin/CropPrice";
import Accounting from "./components/Admin/Accounting";
import AdminSeason from "./components/Admin/AdminSeason";
import AdminFarmers from "./components/Admin/Farmers";
import AdminAddFarmer from "./components/Admin/AddFarmer";
import AdminProfile from "./components/Admin/AdminProfile";
import AdminFarmerDetail from "./components/Admin/FarmerDetail";
import AdminCooperative from "./components/Admin/Cooperative";
import FarmInputPrice from "./components/Admin/FarmInputPrice";
import RecoveryCenter from "./components/Admin/RecoveryCenter";
import ExtensionManagers from "./components/Admin/ExtensionManagers";
import WareHouseManagers from "./components/Admin/WareHouseManagers";
import AdminCooperativeDetails from "./components/Admin/CooperativeDetails";
import AdminProject from "./components/Admin/Project"

// Extension Dashboard
import Farmers from "./components/ExtensionDepartment/Farmers";
import AddFarmer from "./components/ExtensionDepartment/AddFarmer";
import Shipments from "./components/ExtensionDepartment/Shipments";
import FarmerDetail from "./components/ExtensionDepartment/FarmerDetail";
import Cooperative from "./components/ExtensionDepartment/Cooperative";
import ShipmentOptions from "./components/ExtensionDepartment/ShipmentOptions";
import ExtensionSeason from "./components/ExtensionDepartment/ExtensionSeason";
import ExtensionProfile from "./components/ExtensionDepartment/ExtensionProfile";
import ShipmentRecovery from "./components/ExtensionDepartment/ShipmentRecovery";
import CooperativeDetails from "./components/ExtensionDepartment/CooperativeDetails";
import ExtensionSeasonCooperative from "./components/ExtensionDepartment/ExtensionSeasonCooperative";
import ExtensionCrop from "./components/ExtensionDepartment/Crop"
import ExtensionFarmInputPrice from "./components/ExtensionDepartment/FarmInputPrice"
import ExtensionCropPrice from "./components/ExtensionDepartment/CropPrice";
import ExtensionFarmInputs from "./components/ExtensionDepartment/FarmInputs";
import Project from "./components/ExtensionDepartment/Project";
import OpenMarketSupplierDetails from "./components/ExtensionDepartment/OpenMarketSupplierDetails"

//Extension Plus Dashboard

import ExtPlusFarmers from "./components/ExtensionDepartmentPlus/Farmers";
import ExtPlusAddFarmer from "./components/ExtensionDepartmentPlus/AddFarmer";
import ExtPlusFarmerDetail from "./components/ExtensionDepartmentPlus/FarmerDetail";
import ExtPlusCooperative from "./components/ExtensionDepartmentPlus/Cooperative";
import ExtPlusShipmentOptions from "./components/ExtensionDepartmentPlus/ShipmentOptions";
import ExtensionPlusSeason from "./components/ExtensionDepartmentPlus/ExtensionSeason";
import ExtensionPlusProfile from "./components/ExtensionDepartmentPlus/ExtensionProfile";
import ExtPlusCooperativeDetails from "./components/ExtensionDepartmentPlus/CooperativeDetails";
import ExtensionPlusSeasonCooperative from "./components/ExtensionDepartmentPlus/ExtensionSeasonCooperative";
import ExtensionPlusCrop from "./components/ExtensionDepartmentPlus/Crop"
import ExtensionPlusFarmInputPrice from "./components/ExtensionDepartmentPlus/FarmInputPrice"
import ExtensionPlusCropPrice from "./components/ExtensionDepartmentPlus/CropPrice";
import ExtensionPlusFarmInputs from "./components/ExtensionDepartmentPlus/FarmInputs";

// WareHouse DashBoard
import WareHouses from "./components/WareHouse/WareHouses";
import WareHouseFarmers from "./components/WareHouse/Farmers";
import WareHouseShipment from "./components/WareHouse/Shipments";
import WareHouseDetail from "./components/WareHouse/WareHouseDetail";
import WareHouseProfile from "./components/WareHouse/WareHouseProfile";
import WareHouseCooperatives from "./components/WareHouse/Cooperatives";
import OpenMarketSuppliers from "./components/WareHouse/OpenMarketSuppliers";
import WareHouseShipmentDetails from "./components/WareHouse/ShipmentDetails"

// WeighBridge DashBoard
import WeighBridgeFarmers from "./components/WeighBridge/Farmers";
import WeighBridgeProfile from "./components/WeighBridge/WeighBridgeProfile";
import WeighBridgeShipment from "./components/WeighBridge/Shipments";
import WeighBridgeCooperatives from "./components/WeighBridge/Cooperatives";
import WeighBridgeOpenMarketSuppliers from "./components/WeighBridge/OpenMarketSuppliers";
import AddOMS from "./components/WeighBridge/AddOMS"

// Accountant DashBoard
import AccountantFarmers from "./components/Accountant/Farmers";
import AccountantShipment from "./components/Accountant/Shipment";
import AccountantPaddyPrice from "./components/Accountant/PaddyPrice";
import AccountantFarmInputs from "./components/Accountant/FarmInputs";
import AccountantFarmerDetail from "./components/Accountant/FarmerDetail";
import AccountantProfile from "./components/Accountant/AccountantProfile";
import AccountantCooperatives from "./components/Accountant/Cooperatives";
import AccountantShipmentDetail from "./components/Accountant/ShipmentDetail";
import AccountantCooperativeDetail from "./components/Accountant/CooperativeDetail";
import AccountantOpenMarketSuppliers from "./components/Accountant/OpenMarketSuppliers";
import AccountantMillDeliveriesDetail from "./components/Accountant/MillDeliveriesDetail";
import AccountantOpenMarketSuppliersDetail from "./components/Accountant/OpenMarketSuppliersDetail";
import PaymentSchedule from "./components/Accountant/PaymentSchedule";
import PaymentScheduleVouchers from "./components/Accountant/PaymentScheduleVouchers";
import PaymentScheduleDetails from "./components/Accountant/PaymentScheduleDetail"

// Quality Control Dashboard
import QualityControlProfile from "./components/QualityControl/QualityControlProfile";
import QualityControlShipment from "./components/QualityControl/Shipments";
import QualityMetric from  "./components/QualityControl/QualityMetric";
import QualityControlShipmentDetails from "./components/QualityControl/ShipmentDetails";

// Auditor Dashboard
import AuditorFarmers from "./components/Audit/Farmers";
import AuditorShipment from "./components/Audit/Shipment";
// import AuditorPaddyPrice from "./components/Audit/PaddyPrice";
// import AuditorFarmInputs from "./components/Audit/FarmInputs";
import AuditorFarmerDetail from "./components/Audit/FarmerDetail";
import AuditorCooperatives from "./components/Audit/Cooperatives";
import AuditorShipmentDetail from "./components/Audit/ShipmentDetail";
import AuditorCooperativeDetail from "./components/Audit/CooperativeDetail";
import AuditorOpenMarketSuppliers from "./components/Audit/OpenMarketSuppliers";
// import AuditorMillDeliveriesDetail from "./components/Audit/MillDeliveriesDetail";
import AuditorOpenMarketSuppliersDetail from "./components/Audit/OpenMarketSuppliersDetail";
import AuditorPaymentSchedule from "./components/Audit/PaymentSchedule";
import AuditorPaymentScheduleVouchers from "./components/Audit/PaymentScheduleVouchers";
import AuditorPaymentScheduleDetails from "./components/Audit/PaymentScheduleDetail";
import AuditProfile from "./components/Audit/AuditProfile";

// Error pages
import NotFound from "./components/NotFound";

import auth from "./api/auth-helper";


const App = () => {
  // const [successText, setSuccessText] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    /*
        api.get('/test')
            .then(({data}) => setSuccessText(data))
            .catch(err => console.error(err));
            */
  });

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser }}>
        <Switch>
          <Route
            exact
            path="/"
            name="Login"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/signup"
            name="Signup"
            render={(props) => <SignUp {...props} />}
          />

          <Route exact path="/summary" name="Home" component={Home} />

          {/*Admin Dashboard Routes*/}
          <Route
            path="/admin"
            name="AdminProfile"
            render={(props) => <AdminProfile {...props} auth={auth} />}
          />
          <Route
            path="/admin_season"
            name="AdminSeason"
            component={AdminSeason}
          />
          <Route
          path="/admin_project"
          name="AdminProject"
          component={AdminProject}
          />
          <Route
            path="/extension_managers"
            name="ExtensionManagers"
            component={ExtensionManagers}
          />
          <Route path="/farm_inputs" name="FarmInputs" component={FarmInputs} />
          <Route
            path="/farm_input_price"
            name="FarmInputPrice"
            component={FarmInputPrice}
          />
          <Route
            path="/recovery_center"
            name="RecoveryCenter"
            component={RecoveryCenter}
          />
          <Route
            path="/warehouse_managers"
            name="WareHouseManagers"
            component={WareHouseManagers}
          />
          <Route
            path="/admin_cooperative"
            name="Cooperative"
            component={AdminCooperative}
          />
          <Route
            path="/admin_cooperative_details"
            name="CooperativeDetails"
            component={AdminCooperativeDetails}
          />
          <Route
            path="/admin_add_farmer"
            name="AddFarmer"
            component={AdminAddFarmer}
          />
          <Route
            path="/admin_farmer_detail"
            name="FarmerDetail"
            component={AdminFarmerDetail}
          />
          <Route
            path="/admin_farmers"
            name="Farmers"
            component={AdminFarmers}
          />
          <Route path="/accounting" name="Accounting" component={Accounting} />
          <Route
            path="/crop"
            name="import Crop from './Admin/Crop'"
            component={Crop}
          />
          <Route path="/crop_price" name="CropPrice" component={CropPrice} />
          {/*Extension Dashboard Routes*/}
          <Route
            path="/extension"
            name="ExtensionProfile"
            render={(props) => <ExtensionProfile {...props} auth={auth} />}
          />
          <Route
            path="/extension_season"
            name="ExtensionSeason"
            component={ExtensionSeason}
          />
          <Route
            path="/extension_season_cooperative"
            name="ExtensionSeasonCooperative"
            component={ExtensionSeasonCooperative}
          />
          <Route path="/extension_crop_price" name="ExtensionCropPrice" component={ExtensionCropPrice} />
          <Route path="/extension_farm_input" name="ExtensionFarmInputs" component={ExtensionFarmInputs} />
          <Route path="/extension_farm_input_price" name="ExtensionFarmInputPrice" component={ExtensionFarmInputPrice} />
          <Route
            path="/extension_crop"
            name="ExtensionCrop"
            component={ExtensionCrop}
          />
          <Route
            path="/extension_project"
            name="Project"
            component={Project}
          />
          <Route
            path="/cooperative"
            name="Cooperative"
            component={Cooperative}
          />
          <Route
            path="/extension_open_market_supplier_details"
            name="OpenMarketSupplierDetail"
            component={OpenMarketSupplierDetails}
          />
          <Route
            path="/cooperative_details"
            name="CooperativeDetails"
            component={CooperativeDetails}
          />
          <Route path="/add_farmer" name="AddFarmer" component={AddFarmer} />
          <Route
            path="/farmer_detail"
            name="FarmerDetail"
            component={FarmerDetail}
          />
          <Route path="/farmers" name="Farmers" component={Farmers} />
          <Route path="/shipments" name="Shipments" component={Shipments} />
          <Route
            path="/shipment_options"
            name="ShipmentOptions"
            component={ShipmentOptions}
          />
          <Route
            path="/shipment_recovery"
            name="ShipmentRecovery"
            component={ShipmentRecovery}
          />

          {/*Extension Plus Dashboard */}
           <Route
            path="/ext_plus"
            name="ExtensionPlusProfile"
            render={(props) => <ExtensionPlusProfile {...props} auth={auth} />}
          />
          <Route
            path="/ext_plus_season"
            name="ExtensionPlusSeason"
            component={ExtensionPlusSeason}
          />
          <Route
            path="/ext_plus_season_cooperative"
            name="ExtensionPlusSeasonCooperative"
            component={ExtensionPlusSeasonCooperative}
          />
          <Route path="/ext_plus_crop_price" name="ExtensionPlusCropPrice" component={ExtensionPlusCropPrice} />
          <Route path="/ext_plus_farm_inputs" name="ExtensionPlusFarmInputs" component={ExtensionPlusFarmInputs} />
          <Route path="/ext_plus_farm_input_price" name="ExtensionPlusFarmInputPrice" component={ExtensionPlusFarmInputPrice} />
          <Route
            path="/ext_plus_crop"
            name="ExtensionPlusCrop"
            component={ExtensionPlusCrop}
          />
          <Route
            path="/ext_plus_cooperative"
            name="ExtPlusCooperative"
            component={ExtPlusCooperative}
          />
          <Route
            path="/ext_plus_cooperative_details"
            name="ExtPlusCooperativeDetails"
            component={ExtPlusCooperativeDetails}
          />
          <Route path="/ext_plus_add_farmer" name="ExtPlusAddFarmer" component={ExtPlusAddFarmer} />
          <Route
            path="/ext_plus_farmer_detail"
            name="ExtPlusFarmerDetail"
            component={ExtPlusFarmerDetail}
          />
          <Route path="/ext_plus_farmers" name="Farmers" component={ExtPlusFarmers} />

          {/*WareHouse Dashboard Routes*/}
          <Route
            path="/warehouse"
            name="WareHouseProfile"
            render={(props) => <WareHouseProfile {...props} auth={auth} />}
          />
          <Route
            path="/warehouse_shipment"
            name="WareHouseShipment"
            component={WareHouseShipment}
          />
          <Route path="/warehouses" name="WareHouses" component={WareHouses} />
          <Route
            path="/warehouse_cooperatives"
            name="WareHouseCooperatives"
            component={WareHouseCooperatives}
          />
          <Route
            path="/warehouse_farmers"
            name="WareHouseFarmers"
            component={WareHouseFarmers}
          />
          <Route
            path="/open_market_suppliers"
            name="OpenMarketSuppliers"
            component={OpenMarketSuppliers}
          />
          <Route
            path="/warehouse_detail"
            name="WareHouseDetail"
            component={WareHouseDetail}
          />
          <Route
            path="/warehouse_shipment_details"
            name="WareHouseShipmentDetails"
            component={WareHouseShipmentDetails}
          />
          {/* WeighBridge Dashboard Routes */}
          <Route
            path="/weighbridge"
            name="WeighBridge"
            render={(props) => <WeighBridgeProfile {...props} auth={auth} />}
          />
          <Route
            path="/weighbridge_cooperatives"
            name="WeighBridgeCooperatives"
            component={WeighBridgeCooperatives}
          />
          <Route
            path="/weighbridge_shipment"
            name="WeighBridgeShipment"
            component={WeighBridgeShipment}
          />
          <Route
            path="/weighbridge_farmers"
            name="WeighBridgeFarmers"
            component={WeighBridgeFarmers}
          />
          <Route
            path="/weighbridge_open_market_suppliers"
            name="WeighBridgeOpenMarketSuppliers"
            component={WeighBridgeOpenMarketSuppliers}
          /><Route
            path="/add_open_market_supplier"
            name="AddOMS"
            component={AddOMS}
          />


          {/*Qaulity Control Dashboard Routes*/}
          <Route
            path="/quality_control"
            name="QualityControl"
            render={(props) => <QualityControlProfile {...props} auth={auth} />}
          />
          <Route
            path="/quality_control_shipment"
            name="QualityControlShipment"
            component={QualityControlShipment}
          />
          <Route
            path="/quality_control_shipment_details"
            name="QualityControlShipmentDetails"
            component={QualityControlShipmentDetails}
          />
          <Route
            path="/quality_metric"
            name="QualityMetric"
            component={QualityMetric}
          />

          {/*Accountant Dashboard Routes*/}
          <Route
            path="/accountant"
            name="AccountantProfile"
            render={(props) => <AccountantProfile {...props} auth={auth} />}
          />
          <Route
            path="/accountant_crop_price"
            name="AccountantPaddyPrice"
            component={AccountantPaddyPrice}
          />
          <Route
            path="/accountant_farm_inputs"
            name="AccountantFarmInputs"
            component={AccountantFarmInputs}
          />
          <Route
            path="/payment_schedule"
            name="PaymentSchedule"
            component={PaymentSchedule}
          />
          <Route
            path="/payment_schedule_vouchers"
            name="PaymentScheduleVouchers"
            component={PaymentScheduleVouchers}
          />
          <Route
            path="/accountant_cooperatives"
            name="AccountantCooperatives"
            component={AccountantCooperatives}
          />
          <Route
            path="/accountant_cooperative_detail"
            name="AccountantCooperativeDetail"
            component={AccountantCooperativeDetail}
          />
          <Route
            path="/accountant_farmers"
            name="AccountantFarmers"
            component={AccountantFarmers}
          />
          <Route
            path="/accountant_farmer_detail"
            name="AccountantFarmerDetail"
            component={AccountantFarmerDetail}
          />
          <Route
            path="/accountant_open_market_suppliers"
            name="AccountantOpenMarketSuppliers"
            component={AccountantOpenMarketSuppliers}
          />
          <Route
            path="/accountant_open_market_suppliers_detail"
            name="AccountantOpenMarketSuppliersDetail"
            component={AccountantOpenMarketSuppliersDetail}
          />
          <Route
            path="/accountant_shipment"
            name="AccountantShipment"
            component={AccountantShipment}
          />
          <Route
            path="/accountant_shipment_detail"
            name="AccountantShipmentDetail"
            component={AccountantShipmentDetail}
          />
          <Route
          path="/accountant_payment_schedule_details"
          name="PaymentScheduleDetails"
          component={PaymentScheduleDetails}
          />

        {/* Audit Dashboard */}
        <Route
            path="/auditor"
            name="AuditProfile"
            render={(props) => <AuditProfile {...props} auth={auth} />}
          />
          <Route
            path="/auditor_payment_schedule"
            name="AuditorPaymentSchedule"
            component={AuditorPaymentSchedule}
          />
          <Route
            path="/auditor_payment_schedule_vouchers"
            name="AuditorPaymentScheduleVouchers"
            component={AuditorPaymentScheduleVouchers}
          />
          <Route
            path="/auditor_cooperatives"
            name="AuditorCooperatives"
            component={AuditorCooperatives}
          />
          <Route
            path="/auditor_cooperative_detail"
            name="AuditorCooperativeDetail"
            component={AuditorCooperativeDetail}
          />
          <Route
            path="/auditor_farmers"
            name="AuditorFarmers"
            component={AuditorFarmers}
          />
          <Route
            path="/auditor_farmer_detail"
            name="AuditorFarmerDetail"
            component={AuditorFarmerDetail}
          />
          <Route
            path="/auditor_open_market_suppliers"
            name="AuditorOpenMarketSuppliers"
            component={AuditorOpenMarketSuppliers}
          />
          <Route
            path="/auditor_open_market_suppliers_detail"
            name="AuditorOpenMarketSuppliersDetail"
            component={AuditorOpenMarketSuppliersDetail}
          />
          <Route
            path="/auditor_shipment"
            name="AuditorShipment"
            component={AuditorShipment}
          />
          <Route
            path="/auditor_shipment_detail"
            name="AuditorShipmentDetail"
            component={AuditorShipmentDetail}
          />
          <Route
          path="/auditor_payment_schedule_details"
          name="AuditorPaymentScheduleDetails"
          component={AuditorPaymentScheduleDetails}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default App;
