import {CSVLink} from "react-csv";
import { list_farmer_live } from "../api/api-farmer";
import {list_farmer_next_of_kin, list_farmer_next_of_kin_live} from "../api/api-farmer_next_of_kin";
import { read_farm_live } from "../api/api-farm";
import { read_farmer_crop_live } from "../api/api-farmer_crop";
import { read_crop_live, list_crop_live } from "../api/api-crop";

class CSVExport {
    constructor (props){
        this.state ={
            farmers: [],
            crops: [],
            csv_header: [],
            csv_data: [],
        }
    }

    getCrop = () => {
        list_crop_live().then((data) => {
        if (!data) {
            return;
        } else {
            console.log(data);
            this.setState({ crops: data });
        }
        });
    };

    handleFarmerCrop = async (farmerId) => {
        let farmer_crops = await read_farmer_crop_live({
        farmerId: farmerId,
        });
        let crops = [];
        farmer_crops.farmer_crop.crops.map((fcrop) => {
        this.state.crops.map((crop) => {
            if (crop._id === fcrop) {
            crops.push(crop.name);
            }
        });
        });
        return crops;
    };

    handleNextOfKin = async (Id) => {
        let data2 = await list_farmer_next_of_kin_live({ farmer_id: Id });
        console.log(data2);
        if (data2.error) {
        return `No Next Of Kin`;
        } else {
        return `${data2.farmer_next_of_kin.relationship}`
        }
    };

    handleFarm = async (Id) => {
        let data2 = await read_farm_live({ farmerId: Id });
        console.log(data2);
        if (data2.error) {
        return `No Farm yet`;
        } else {
        return `${data2.farm.size} ${data2.farm.unit}`;
        }
    };

    exportFarmers = async  ()=>{
        await list_farmer_live().then(async (data) => {
      // console.log(data);
      if (!data || data.message) {
        return
        
      } else if (data !== 0) {
        const headers = [
          { label: "Applicant_BVN", key: "bvn" },
          { label: "Applicant_ACC_NO", key: "acct_no" },
          { label: "Applicant_Bank", key: "bank" },
          { label: "Applicant_Firstname", key: "first_name" },
          { label: "Applicant_Middlename", key: "middle_name" },
          { label: "Applicant_Lastname", key: "last_name" },
          { label: "Applicant_Gender", key: "gender" },
          { label: "Farmers_Crop", key: "crop" },
          { label: "Farmer_Size", key: "farm_size" },
          { label: "Marital_Status", key: "marital_status" },
          { label: "Address", key: "address" },
          { label: "State", key: "state" },
          { label: "LGA", key: "LGA" },
          { label: "LGA_Code", key: "lga_code" },
          { label: "NOK_Relationship", key: "next_relationship" },
          { label: "Occupation", key: "occupation" },
          { label: "Spouse_BVN", key: "spouse_bvn" },
        ];

        const all_data = data;
        let cvsdata = [];
        await all_data.map(async (data) => {
          let farmer_crop = await (
            await this.handleFarmerCrop(data._id)
          ).toString();
          console.log(farmer_crop);

          let cvsData = {
            bvn: data.bvn,
            acct_no: data.account_number,
            bank: data.bank,
            first_name: data.first_name.toUpperCase(),
            middle_name: data.middle_name.toUpperCase(),
            last_name: data.last_name.toUpperCase(),
            gender: data.gender.charAt(0),
            crop: farmer_crop,
            occupation: "Farmer",
            farm_size: await this.handleFarm(data._id),
            marital_status: data.marital_status.charAt(0),
            address: data.address.toUpperCase(),
            state: data.state.toUpperCase(),
            LGA: data.LGA.toUpperCase(),
            next_relationship: await this.handleNextOfKin(data._id),
            spouse_bvn: "",
          };
          cvsdata.push(cvsData);
          console.log(cvsdata);
          this.setState({
            csv_header: headers,
            csv_data: cvsdata,
          });
        });
        console.log(cvsdata);
      } else {
        this.setState({ farmers: [] });
      }
    });
    }
}

export default new CSVExport();