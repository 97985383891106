import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import auth from '../../api/auth-helper';
import UserContext from '../UserContext';

const Logout = withRouter(props => {
  const { setUser } = useContext(UserContext);

  return (
    <div style={{ cursor: 'pointer' }}>
      {navigator.onLine ? (
        <a
          href="#/"
          onClick={() => {
            auth.signout(() => {
              setUser(null);
              localStorage.removeItem('user');

              props.history.push('/');
            });
          }}
          className='nav-link'
        >
          <i className='material-icons'>power_settings_new</i>
          <p className='d-lg-none d-md-block'>Log Out</p>
        </a>
      ) : (
        <a href="#/" className='nav-link' onClick={() => alert('You are offline')}>
          <i className='material-icons'>power_settings_new</i>
          <p className='d-lg-none d-md-block'>Log Out</p>
        </a>
      )}
    </div>
  );
});

export default Logout;
