import { fetchRequest } from "../utils";

export const list_deposit = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/deposit/`,
  });

export const read_deposit = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deposit/` +
      params.farmer_id,
  });

export const update_deposit = (deposit, params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deposit/` +
      params.farmer_id,
    method: "PUT",
    data: deposit,
  });
};

export const remove_deposit = (deposit) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/deposit/`,
    method: "DELETE",
    data: deposit,
  });

export const list_deposit_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deposit/` +
      params.farmer_id,
  });

export const remove_deposit_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deposit/` +
      params.depositId,
    method: "DELETE",
  });
