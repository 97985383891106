import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import Pdf from "react-to-pdf";
import { listseasons_live } from "../../api/api-seasons";
import {
  list_shipment_live,
  remove_shipment_live,
  update_shipment_live
} from "../../api/api-shipment";
import Nav from "./Nav";
import SideBar from "./SideBar";
import auth from "../../api/auth-helper";
import {CSVLink} from "react-csv";

const ref = React.createRef();
class Shipments extends Component {
  constructor() {
    super();
    this.state = {
      shipments: [],
      shipment_id: "",
      shipment_live_id: "",
      delete_modal: false,
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      search: "",
      gross_weight: "",
      net_weight:"",
      tare_neight:"",
      loan_per_acre: "",
      modals: {
        seasons: false,
      },
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      modal: false,
      csv_data:[],
      csv_header: []
    };
    this.handleListShipment();
    this.handleListSeason();
    this.handleCSV()
  }

  componentDidMount() {
    document.title = "Shipments";
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

    handleCSV = async () => {
    await list_shipment_live().then(async (data)=> {
      if(!data || data.message) {
        return
      }
      else if (data.length !== 0){
        const headers = [
          {label:"Vehicle Number", key: "vehicleNumber"},
          {label: "Weighbridge Number", key: "weighbridge_number"},
          {label:"Party Name", key: "partyName"},
          {label:"Type", key: "type"},
          {label:"Gross Weight", key: "gross_weight"},
          {label:"Net Weight", key: "net_weight"},
          {label:"Tare Weight", key: "tare_weight"},
          {label: "Crop", key: "crop"},
          {label: "Crop Price", key: "crop_price"},
          {label: "Status", key: "status"},
          {label: "Created By", key:"createdBy"},
          {label: "Date Created", key: "created"}
        ];

        const all_data = data;
        let csvdata = []

        await all_data.map(async (data)=>{
          let csvData ={
            vehicleNumber: data.vehicleNumber,
            weighbridge_number: data.weighbridge_number,
            partyName: data.partyName,
            type: data.type,
            gross_weight: data.gross_weight,
            net_weight: data.net_weight,
            tare_weight: data.tare_weight,
            crop: data.crop.name,
            crop_price: data.crop_price,
            status: data.status,
            createdBy: data.createdBy,
            created: data.created.substr(0, 9)
          };
          csvdata.push(csvData)
          console.log(csvdata)
          this.setState({
            csv_header: headers,
            csv_data: csvdata,
          });
        })
      }else{
        this.setState({shipments :[]})
      }
    })
  }

  handleListShipment = () => {
    list_shipment_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.setState({ shipments: data });
      }
    });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
            loan_per_acre: "",
          });
        }
      }
    });
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleDeleteShipment = () => {
    remove_shipment_live({
      shipmentId: this.state.shipment_id,
    }).then((data) => {
      if (!data) {
        return;
      } else {
        this.handleListShipment();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Shipment successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });
  };

  checkForm = () => {
    if(!this.state.tare_weight) {
      this.setState({
        error: "Tare Weight is required"
      })
    }

    if(this.state.tare_weight){
      this.handleSubmit()
        this.handleModal()
    }
  }

  handleSubmit = () => {
    const jwt = auth.isAuthenticated();
    const shipment ={
      tare_weight: this.state.tare_weight,
      net_weight: (Number(this.state.gross_weight) - Number(this.state.tare_weight))
    }
    update_shipment_live(shipment,{
      shipmentId: this.state.shipment_id
    },
    {
      t: jwt.token
    }
    ).then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.handleListShipment()
      }
    })
  }
  render() {
    const { search } = this.state;
    const shipments = this.state.shipments.filter((shipment) => {
      return shipment.partyName.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          {/* <div className="dropdown">
                            <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={() => this.handleModal("seasons")}
                            >
                              Change Season
                            </button>
                            <div
                              className={
                                !this.state.modals.seasons
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "452px",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      href="#/"
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        Select Season
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.seasons.map((season, id) => (
                                    <li key={id}>
                                      <a
                                        href = "#/"
                                        role="option"
                                        className="dropdown-item"
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() => {
                                          let {
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          } = season;
                                          this.setState({
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          });
                                          this.handleModal("seasons");
                                        }}
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          {season.type}
                                          {"  "}
                                          {season.year}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <p className="card-category">Total Shipments</p>
                        <h3 className="card-title">
                          {this.state.shipments.length}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            {/*<h3>Nothing to see here yet.</h3>*/}
                            <div
                              id="updated_shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Shipments.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="updated_shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="updated_shipments_length"
                                        aria-controls="updated_shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="updated_shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="updated_shipments"
                                          onChange={(event) =>
                                            this.setState({
                                              search: event.target.value,
                                            })
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="updated_shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Vehicle No: activate to sort column descending"
                                          style={{ width: "101px" }}
                                        >
                                          Vehicle No
                                        </th>
                                        
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop Price: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop Price
                                        </th>

                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Party Name: activate to sort column ascending"
                                          style={{ width: "112px" }}
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Gross Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Gross Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Tare Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Tare Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Net Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Net Wt
                                        </th>
                                        
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "47px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          style={{ width: "128px" }}
                                        >
                                          Date Created
                                        </th>
                                        <th
                                          className="sorting text-center"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          style={{ width: "118px" }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan="1" colSpan="1">
                                          Vehicle No
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop Price
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Party Name
                                        </th>
                                         <th rowSpan={1} colSpan={1}>
                                          Gross Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Tare Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Net Wt
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Type
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Date Created
                                        </th>
                                        <th class="text-center" rowSpan="1" colSpan="1">
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {shipments.map((data, id) => (
                                        <tr key={id}>
                                          <td className="sorting_1">
                                            {data.vehicleNumber}
                                          </td>
                                          <td>{data.crop.name}</td>
                                          <td>₦{data.crop_price}</td>
                                          <td>{data.partyName}</td>
                                          <td>{data.gross_weight}kg</td>
                                          <td>{data.tare_weight ? `${data.tare_weight}kg` : `No Tare Weight`}</td>
                                           <td>{data.net_weight ? `${data.net_weight}kg` : `No Net Weight`}</td>
                                          <td>{data.type}</td>

                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                          <td className="text-center inline-block">
                                            <div>
                                              <input
                                                type="hidden"
                                                name="csrfmiddlewaretoken"
                                                value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                              />
                                              <input
                                                type="hidden"
                                                name="edit_shipment"
                                                value="1"
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-link btn-just-icon btn-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Edit Shipment"
                                                onClick={() =>
                                                  this.setState({
                                                    shipment_id: data._id,
                                                    shipment_live_id:
                                                      data.liveId,
                                                    modal: true,
                                                    gross_weight: data.gross_weight
                                                  })
                                                }
                                              >
                                                <i className="material-icons">
                                                  dvr
                                                </i>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="updated_shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="updated_shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="updated_shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="0"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next disabled"
                                        id="updated_shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="1"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createFarmInputModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Edit Shipment
                          </h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      for="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Tare Weight
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          tare_weight: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="price"
                                      type="number"
                                      className="form-control"
                                      id="inputPrice"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  type="hidden"
                                  name="create_farm_input"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_online"
                                  onClick={this.checkForm}
                                  style={{ display: "block" }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

            {!this.state.delete_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                id="addCooperativeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{
                  display: "block",
                  paddingRight: "5px",
                  paddingTop: "100px",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete Shipment?</h4>
                      <button
                        onClick={() => this.setState({ delete_modal: false })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Shipment
                          </div>

                          <button
                            type="submit"
                            onClick={this.handleDeleteShipment}
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            className="btn btn-fill btn-danger"
                            onClick={() =>
                              this.setState({ delete_modal: false })
                            }
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Shipments;
