import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import Nav from './Nav';

class MillDeliveriesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='wrapper '>
        <SideBar />
        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='content'>
              <Link
                className='btn btn-primary btn-round'
                to='/accountant_open_market_suppliers_detail'
              >
                Go Back
              </Link>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>people</i>
                        </div>
                        <p className='card-category'>Party Name</p>
                        <h3 className='card-title'>Buhari Atiku</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_strong</i>
                        </div>
                        <p className='card-category'>Gross Weight</p>
                        <h3 className='card-title'>22022.0Kg</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_weak</i>
                        </div>
                        <p className='card-category'>Tare Weight</p>
                        <h3 className='card-title'>11011.0Kg</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>crop_free</i>
                        </div>
                        <p className='card-category'>Net Weight</p>
                        <h3 className='card-title'>11011.0Kg</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>format_color_fill</i>
                        </div>
                        <p className='card-category'>Moisture Content</p>
                        <h3 className='card-title'>11.0%</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>grain</i>
                        </div>
                        <p className='card-category'>Foreign Matter</p>
                        <h3 className='card-title'>0.8%</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>sms</i>
                        </div>
                        <p className='card-category'>Remark</p>
                        <h3 className='card-title'>Satisfactory</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>spa</i>
                        </div>
                        <p className='card-category'>Variety</p>
                        <h3 className='card-title'>Farro 44</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <form method='post' action='.'>
                    <input
                      type='hidden'
                      name='csrfmiddlewaretoken'
                      defaultValue='tN2iIQfqcGhqWySbCRg1Papp6xbMyxY3briIypLO0niaIybNFCm9Vwk8jJXrfNjW'
                    />
                    <div className='form-group'>
                      <div className='dropdown bootstrap-select'>
                        <select
                          required
                          name='price'
                          className='selectpicker'
                          data-size={4}
                          data-style='btn btn-round btn-info'
                          title='Choose Price'
                          tabIndex={-98}
                          defaultValue={1}
                        >
                          <option className='bs-title-option' value />
                          <option value={1}>
                            ₦122.00 - Nov. 28, 2018, 10:17 a.m.
                          </option>
                        </select>
                        <button
                          type='button'
                          className='dropdown-toggle btn btn-round btn-info'
                          data-toggle='dropdown'
                          title='₦122.00
                                      - Nov. 28, 2018, 10:17 a.m.'
                          aria-expanded='false'
                        >
                          <div className='filter-option'>
                            <div className='filter-option-inner'>
                              <div className='filter-option-inner-inner'>
                                ₦122.00 - Nov. 28, 2018, 10:17 a.m.
                              </div>
                            </div>{' '}
                          </div>
                          <div className='ripple-container' />
                        </button>
                        <div
                          className='dropdown-menu'
                          role='combobox'
                          x-placement='top-start'
                          style={{
                            overflow: 'hidden',
                            position: 'absolute',
                            top: '3px',
                            left: '1px',
                            willChange: 'top, left'
                          }}
                        >
                          <div
                            className='inner show'
                            role='listbox'
                            aria-expanded='false'
                            tabIndex={-1}
                            style={{ overflowY: 'auto' }}
                          >
                            <ul className='dropdown-menu inner show'>
                              <li className='selected active'>
                                <a
                                  href= "#/"
                                  role='option'
                                  className='dropdown-item selected active'
                                  aria-disabled='false'
                                  tabIndex={0}
                                  aria-selected='true'
                                >
                                  <span className=' bs-ok-default check-mark' />
                                  <span className='text'>
                                    ₦122.00 - Nov. 28, 2018, 10:17 a.m.
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      id ="update_price"
                      type='hidden'
                      name='update_price'
                      defaultValue={1}
                    />
                    <button type='submit' className='btn btn-fill btn-primary'>
                      Update Price
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                © 2019 , made with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default MillDeliveriesDetail;
