import React from 'react';

const NotificationComp = ({ message, handleClick, notifications }) => {
  const { error, success } = notifications;

  if (error || success) {
    const type = error ? 'danger' : 'success';

    return (
      <div className={`alert alert-${type}`}>
        <button
          type='button'
          className='close'
          aria-label='Close'
          data-dismiss='alert'
          onClick={handleClick}
        >
          <i className='material-icons'>close</i>
        </button>
        <span> {message} </span>
      </div>
    );
  }

  return null;
};

export default NotificationComp;
