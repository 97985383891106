import { fetchRequest } from "../utils";

//Local SERVER Api endpoints process.env.REACT_APP_LOCAL_SERVER
export const create_season = (season) => {
  console.log("api create season");
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/`,
    method: "POST",
    data: season,
  });
};

export const listseasons = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/` });

export const readseason = (params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/` + params.seasonId,
  });

export const updateseason = (season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/`,
    method: "PUT",
    data: season,
  });

export const update_season_add_cooperative = (params, season) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/add_cooperative/` +
      params.seasonId,
    method: "PUT",
    data: season,
  });

export const update_season_remove_cooperative = (params, season) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/remove_cooperative/` +
      params.seasonId,
    method: "PUT",
    data: season,
  });

export const removeseason = (season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons/`,
    method: "DELETE",
    data: season,
  });

export const remove_season_deleted_live = (season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/seasons_deleted_live`,
    method: "DELETE",
    data: season,
  });

// Live Server Api endpoints process.env.REACT_APP_LIVE_SERVER_PROD

export const create_season_live = (season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/`,
    method: "POST",
    data: season,
  });

export const listseasons_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/`,
  });

export const readseason_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/` +
      params.seasonId,
  });

export const updateseason_live = (params, season) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/` +
      params.seasonId,
    method: "PUT",
    data: season,
  });

export const update_season_add_cooperative_live = (params, season) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/add_cooperative/` +
      params.seasonId,
    method: "PUT",
    data: season,
  });

export const update_season_remove_cooperative_live = (params, season) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/remove_cooperative/` +
      params.seasonId,
    method: "PUT",
    data: season,
  });

export const removeSeason_live = (seasonId) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/seasons/` + seasonId,
    method: "DELETE",
  });
