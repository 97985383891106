import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import {
  create_farminput_live,
  list_farminput_live,
  remove_farminput_live,
} from "../../api/api-farm_inputs";
import {list_farminputinventory_live} from '../../api/api-farm_input_inventory'
import { list_farminputprice_live } from "../../api/api-farm_input_price";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import { Link } from "react-router-dom";

class FarmInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delete_modal: false,
      editmodal: false,
      id: "",
      name: "",
      type: "",
      price: "",
      quantity: '',
      farm_inputs: [],
      farm_input_id: "",
      current_farm_input_price: "",
      current_farm_input_quantity: '',
      farmInputId: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      warning_level: 0,
    };
    this.handleList();
    this.handleListPrice();
    this.handleListQuantity();
  }

  componentDidMount = () => {
    if (this.props.location.state === undefined) {
      console.log("error");
    } else if (this.props.location.state.update_notification) {
      this.setState({
        success_notification: true,
        mssg: "Farm Input updated successfully",
      });
      setTimeout(() => this.setState({ success_notification: false }), 3000);
    }
    document.title = "Farm Inputs";
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleOpenEditModal = (id, name, type, price, quantity, warning_level) => {
    this.setState({
      editmodal: true,
      id: id,
      name: name,
      type: type,
      price: price,
      quantity: quantity,
      inventory_warning_level: warning_level
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
    });
  };

  handleList = () => {
    if (navigator.onLine) {
      list_farminput_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ farm_inputs: data });

        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleListQuantity = () => {
    if (navigator.onLine) {
      list_farminputinventory_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length !== 0) {
          this.setState({ current_farm_input_quantity: data });
        } else {
          this.setState({ current_farm_input_quantity: "" });
        }
      });
    }
  }

  handleListPrice = () => {
    if (navigator.onLine) {
      list_farminputprice_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length !== 0) {
          this.setState({ current_farm_input_price: data });
        } else {
          this.setState({ current_farm_input_price: "" });
        }
      });
    } else if (!navigator.onLine) {
      /*
			list_farminputprice().then(data => {
				if (data.error) {
					console.log(data.error);
				}
				if (data.length !== 0) {
					this.setState({ current_farm_input_price: data });
				} else {
					this.setState({ current_farm_input_price: '' });
				}
			});
			*/
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  /*

	editForm = () => {
		const jwt = auth.isAuthenticated();

		const farminput = {
			_id: this.state.id,
			name: this.state.name,
			type: this.state.type,
			price: this.state.price,
		};
		update_farminput(farminput, {
			t: jwt.token,
		}).then(data => {
			if (data.error) {
				this.setState({ error: data.error });
			} else {
				this.handleList();
		
			this.setState({
				loading: false,
				update_notification: true,
				mssg: 'Farm Input updated successfully',
			});
			this.handleCloseEditModal();
			}
		});
		
	};
	*/

  checkForm = () => {
    if (!this.state.name) {
      this.setState({
        error: "Farm Input Name is required",
      });
    }

    if (!this.state.type) {
      this.setState({
        error: "Farm Input Type is required",
      });
    }

    if (this.state.name && this.state.type) {
      this.submitForm();
    }

    if (!this.state.name && !this.state.type) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const farminput = {
        deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        name: this.state.name,
        type: this.state.type,
        price: 0,
        quantity: 0,
        inventory_warning_level: this.state.warning_level
      };

      create_farminput_live(farminput, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Farm Input created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  };

  deleteData = () => {
    remove_farminput_live({
      farmInputId: this.state.farm_input_id,
    }).then((data) => {
      if (!data) {
        return;
      } else {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Farm Input deleted successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.update_notification ? (
                  <div className="alert alert-warning">
                    <button
                      onClick={() =>
                        this.setState({ update_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Farm Inputs</h4>
                      </div>
                      <div className="card-body">
                        {/* <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Farm Inputs
                          </button>
                        </div> */}
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Unit Price</th>
                                  <th>Quantity</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Unit Price</th>
                                  <th>Quantity</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.farm_inputs.map((data) => (
                                  <tr key={data._id}>
                                    <td>{data.name}</td>
                                    <td>{data.type}</td>
                                    <td>₦{data.price}</td>
                                    <td>
                                      {data.quantity} {" "}
                                {data.quantity > data.inventory_warning_level ? <span  data-toggle="tooltip"
                                          data-placement="top"
                                          title="Good Level of Stock" class="badge badge-success">{" "}</span> : <span 
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Low level of Stock"
                                          class="badge badge-danger">{" "}</span> }
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <Link
                                        className="btn btn-link btn-just-icon btn-info edit"
                                        to={{
                                          pathname: "/ext_plus_farm_input_price",
                                          state: {
                                            id: data._id,
                                            liveId: data._id,
                                            name: data.name,
                                            type: data.type,
                                            quantity: data.quantity
                                          },
                                        }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit Farm Input"
                                      >
                                        <i className="material-icons">dvr</i>
                                      </Link>
                                      {/* <div>
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.setState({
                                              farm_input_id: data._id,
                                              delete_modal: true,
                                            })
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Farm Input"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Farm Input?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Farm
                                  Input
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.deleteData()}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  farm input.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createFarmInputModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          {/* <div className="modal-header">
                            <h4 className="modal-title">Create Farm Input</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div> */}
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Farm Input
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="inputName"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        for="inputType"
                                        className="bmd-label-floating"
                                      >
                                        Enter Type of Farm Input
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            type: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="type"
                                        type="text"
                                        className="form-control"
                                        id="inputType"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Inventory Warning Level
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            warning_level: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="inputWarning"
                                        min="0"
                                      />
                                    </div>
                                    <p className="card-description text-center">
                                        The Inventory Warning Level indicate when the farm input is low and needs to be restocked. Enter a number to signify when the farm input inventory is low. The default is zero.
                              </p>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_farm_input"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    onClick={this.checkForm}
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  farm input.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.editmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Edit Farm Input</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleCloseEditModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        for="editinputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Farm Input
                                      </label>
                                      <input
                                        onChange={this.handleChange("name")}
                                        value={this.state.name}
                                        type="text"
                                        className="form-control"
                                        id="editinputName"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        for="editinputType"
                                        className="bmd-label-floating"
                                      >
                                        Enter Type of Farm Input
                                      </label>
                                      <input
                                        onChange={this.handleChange("type")}
                                        value={this.state.type}
                                        type="text"
                                        className="form-control"
                                        id="editinputType"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label
                                        for="editinputPrice"
                                        className="bmd-label-floating"
                                      >
                                        Enter Unit Price of Farm Input
                                      </label>
                                      <input
                                        onChange={this.handleChange("price")}
                                        value={this.state.price}
                                        type="number"
                                        className="form-control"
                                        id="editinputPrice"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    id="editinputID"
                                    type="hidden"
                                    name="edit_farm_input"
                                    value=""
                                  />
                                  <button
                                    onClick={this.editForm}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseEditModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to edit this
                                  farm input.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default FarmInputs;
