import { fetchRequest } from "../utils";

export const create_paddyprice = (paddyprice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/paddy_price/`,
    method: "POST",
    data: paddyprice,
  });

export const list_paddyprice = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/paddy_price/`,
  });

export const read_paddyprice = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/paddy_price/` +
      params.paddypriceId,
  });

export const update_paddyprice = (paddyprice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/paddy_price/`,
    method: "PUT",
    data: paddyprice,
  });

export const remove_paddyprice = (paddyprice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/paddy_price/`,
    method: "DELETE",
    data: paddyprice,
  });

//Live Api

export const create_paddyprice_live = (paddyprice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/paddy_price/`,
    method: "POST",
    data: paddyprice,
  });

export const list_paddyprice_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/paddy_price/`,
  });

export const remove_paddyprice_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/paddy_price/` +
      params.paddyPriceId,
    method: "DELETE",
  });
