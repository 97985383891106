import { fetchRequest } from "../utils";

export const create_farmer_guarantor = (farmer_guarantor) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor/`,
    method: "POST",
    data: farmer_guarantor,
  });

  export const list_farmer_guarantor = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor/` +
      params.farmer_id,
  });

export const read_farmer_guarantor = (params) => {
  console.log("bug");
  console.log(params);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor/` + params.farmer_guarantorId,
  });
};

export const update_farmer_guarantor = (farmer_guarantor) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor/`,
    method: "PUT",
    data: farmer_guarantor,
  });

export const update_lead_farmer_guarantor = (farmer_guarantor) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor_lead/`,
    method: "PUT",
    data: farmer_guarantor,
  });


export const remove_farmer_guarantor = (farmer_guarantor) => {
  console.log(farmer_guarantor);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_guarantor/`,
    method: "DELETE",
    data: farmer_guarantor,
  });
};

export const create_farmer_guarantor_live = (farmer_guarantor) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_guarantor/`,
    method: "POST",
    data: farmer_guarantor,
  });

  export const list_farmer_guarantor_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_guarantor/` +
      params.farmer_id,
  });

export const read_farmer_guarantor_live = (params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_guarantor/` + params.farmer_guarantorId,
  });
};

export const update_farmer_guarantor_live = (farmer_guarantor, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_guarantor/` + params.farmer_guarantorId,
    method: "PUT",
    data: farmer_guarantor,
  });

export const remove_farmer_guarantor_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_guarantor/` + params.farmer_guarantorId,
    method: "DELETE",
  });

export const create_deleted_farmer_guarantor_live = (farmer_guarantor) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer_guarantor/`,
    method: "POST",
    data: farmer_guarantor,
  });

export const list_deleted_farmer_guarantor_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer_guarantor/`,
  });
