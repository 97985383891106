import React, { Component } from "react";
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Redirect } from "react-router-dom";
import Pdf from "react-to-pdf";
import { list_farmer_live } from "../../api/api-farmer";
import { list_paddyprice_live } from "../../api/api-paddy_price";
import { create_shipment_live } from "../../api/api-shipment";
import { list_crop_live} from "../../api/api-crop";
import {list_crop_price_live} from "../../api/api-crop_price";
import { list_OMS_cooperative_live } from "../../api/api-cooperative";
import { read_farmer_crop_live } from "../../api/api-farmer_crop";
import { read_farm_live } from "../../api/api-farm";
import {list_farmer_next_of_kin, list_farmer_next_of_kin_live} from "../../api/api-farmer_next_of_kin";
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";
import { CSVLink } from "react-csv";

const ref = React.createRef();
class Farmers extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      price_modal: false,
      vehicle_no: "",
      type: "",
      party_name: "",
      crop: "",
      crop_id:"",
      prices: [],
      crop_price:"",
      crops: [],
      farmers: [],
      redirectToShipment: false,
      error: "",
      search: "",
      cooperativeId: "",
      cooperativeLiveId: "",
      gross_weight: "",
      modals: {
        crop: false,
      },
      weighbridge_number: "",
      OMScooperative: [],
      csv_header: [],
      csv_data: []
    };
    this.handleListCrop();
    this.handleListCooperatives();
    this.handleCSV()
  }

  componentDidMount() {
    document.title = "Farmers";
  }

  handleListCooperatives = () => {
    list_OMS_cooperative_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        console.log(data);
        this.setState({OMScooperative: data})
        this.handleListFarmers()
      }
    });
  };

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handlePriceModal = () => {
    this.setState((prevState) => ({
      price_modal: !prevState.price_modal,
      error: "",
    }));
  };

  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };


  handleListFarmers = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        console.log(data);
        let farmers = data.filter(
          farmer => farmer.cooperativeLiveId !== this.state.OMScooperative._id
        )
        this.setState({ farmers: farmers });
      }
    });
  };

  checkForm = () => {
    if (!this.state.crop_price) {
      this.setState({
        error: "Price is required",
      });
    }

    if(!this.state.gross_weight) {
      this.setState({
        error: "Gross Weight is required"
      })
    }

    if (!this.state.crop) {
      this.setState({
        error: "Crop is required",
      });
    }

    if (!this.state.vehicle_no) {
      this.setState({
        error: "Vehicle Number is required",
      });
    }

    if (!this.state.weighbridge_number) {
      this.setState({
        error: "Vehicle Number is required",
      });
    }

    if (this.state.crop_price && this.state.vehicle_no && this.state.crop && this.state.gross_weight && this.state.weighbridge_number) {
      this.handleSubmit();
    }

    if (!this.state.price && !this.state.vehicle_no && !this.state.crop && !this.state.gross_weight && !this.state.weighbridge_number) {
      this.setState({
        error: "All Fields are required",
      });
    }
  };

  handleFarmerCrop =  async(farmerId) => {
    let farmer_crops = await read_farmer_crop_live({
      farmerId: farmerId,
    })
    let crops = [];
    farmer_crops.farmer_crop.crops.map((fcrop) => {
      this.state.crops.map((crop) => {
        if (crop._id === fcrop) {
          crops.push(crop.name);
        }
      });
    });
    return crops;
  };

    handleNextOfKin = async (Id) => {
    let data2 = await list_farmer_next_of_kin_live({ farmer_id: Id });
    console.log(data2);
    if (data2.error) {
      return `No Next Of Kin`;
    } else {
      return `${data2.farmer_next_of_kin.relationship}`
    }
  };

  handleFarm = async (Id) => {
    let data2 = await read_farm_live({ farmerId: Id });
    console.log(data2);
    if (data2.error) {
      return "No Farm Yet";
    } else {
      return `${data2.farm.size} ${data2.farm.unit}`;
    }
  };

    handleCSV = async () => {
    await list_farmer_live().then(async(data) => {
      // console.log(data);
      if (!data || data.message) {
        this.setState({
          mssg: `Could not fetch farmers, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else if (data !== 0) {
        const headers = [
          { label: "Applicant_BVN", key: "bvn" },
          { label: "Applicant_ACC_NO", key: "acct_no" },
          { label: "Applicant_Bank", key: "bank" },
          { label: "Applicant_Firstname", key: "first_name" },
          { label: "Applicant_Middlename", key: "middle_name" },
          { label: "Applicant_Lastname", key: "last_name" },
          { label: "Applicant_Gender", key: "gender" },
          { label: "Farmers_Crop", key: "crop" },
          { label: "Farmer_Size", key: "farm_size" },
          { label: "Marital_Status", key: "marital_status" },
          { label: "Address", key: "address" },
          { label: "State", key: "state" },
          { label: "LGA", key: "LGA" },
          { label: "NOK_Relationship", key: "next_relationship" },
          { label: "Occupation", key: "occupation" },
      
        ];

        const all_data = data;
        let cvsdata = [];
        await all_data.map(async (data) => {
          let farmer_crop = await (await this.handleFarmerCrop(data._id)).toString();
          console.log(farmer_crop);
          
          let cvsData = {
            bvn: data.bvn,
            acct_no: data.account_number,
            bank: data.bank,
            first_name: data.first_name.toUpperCase(),
            middle_name: data.middle_name.toUpperCase(),
            last_name: data.last_name.toUpperCase(),
            gender: data.gender.charAt(0),
            crop: farmer_crop,
            occupation: "Farmer",
            farm_size: await this.handleFarm(data._id),
            marital_status: data.marital_status.charAt(0),
            address: data.address.toUpperCase(),
            state: data.state.toUpperCase(),
            LGA: data.LGA.toUpperCase(),
            next_relationship: await this.handleNextOfKin(data._id),
          
          };
          cvsdata.push(cvsData);
          console.log(cvsdata)
          this.setState({
            csv_header: headers,
            csv_data: cvsdata,
          });
        });
        console.log(cvsdata);

       
      } else {
        this.setState({ farmers: [] });
      }
    });
  };

  handleSubmit = () => {
    const jwt = auth.isAuthenticated();
    const shipment = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      cooperativeLiveId: this.state.cooperativeLiveId,
      vehicleNumber: this.state.vehicle_no,
      type: this.state.type,
      partyName: this.state.party_name,
      price: this.state.price,
      crop: this.state.crop_id,
      crop_price: this.state.crop_price,
      status: "nothing",
      weighbridge_number: this.state.weighbridge_number,
      reason: "",
      gross_weight: this.state.gross_weight
    };
    create_shipment_live(shipment, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.setState({
          redirectToShipment: true,
        });
        this.setState({
          vehicle_no: "",
          type: "",
          party_name: "",
          price: "",
          modal: false,
        });
        /*
				const shipment = {
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					cooperativeId: this.state.cooperativeId,
					cooperativeLiveId: this.state.cooperativeLiveId,
					liveId: data.data._id,
					vehicle_number: this.state.vehicle_no,
					type: this.state.type,
					party_name: this.state.party_name,
					price: this.state.price,
				};
				create_shipment(shipment, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} if(data) {
						this.setState({
							redirectToShipment: true,
						});
						this.setState({
							vehicle_no: '',
							type: '',
							party_name: '',
							price: '',
							modal: false,
						});
					}
				});
				*/
      }
    });
  };
  render() {
    if (this.state.redirectToShipment) {
      return <Redirect to="/weighbridge_shipment" />;
    }
    const { search } = this.state;
    const farmers = this.state.farmers.filter((farmer) => {
      return farmer.first_name.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header card-header-primary card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">wc</i>
                      </div>
                      <h4 className="card-title">Farmers</h4>
                    </div>
                    <div className="card-body">
                      <div className="toolbar" />
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          <div
                            id="farmers_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Farmers.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                              </div>
                            <div
                              id="farmers_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <span className="bmd-form-group bmd-form-group-sm">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="farmers"
                                    onChange={(event) =>
                                      this.setState({
                                        search: event.target.value,
                                      })
                                    }
                                  />
                                </span>
                              </label>
                            </div>
                            <table
                              title="Farmers"
                              id="farmers"
                              className="table table-striped table-no-bordered table-hover dataTable"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                              role="grid"
                              aria-describedby="farmers_info"
                            >
                              <thead>
                                <tr role="row">
                                  {/* <th
                                    className="sorting_asc"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="ID: activate to sort column descending"
                                    style={{ width: "50px" }}
                                  >
                                    ID
                                  </th> */}
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Name: activate to sort column ascending"
                                    style={{ width: "77px" }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Phone Number: activate to sort column ascending"
                                    style={{ width: "109px" }}
                                  >
                                    Phone Number
                                  </th>

                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Cooperative: activate to sort column ascending"
                                    style={{ width: "109px" }}
                                  >
                                    Cooperative
                                  </th>

                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Address: activate to sort column ascending"
                                    style={{ width: "87px" }}
                                  >
                                    Address
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="LGA: activate to sort column ascending"
                                    style={{ width: "38px" }}
                                  >
                                    LGA
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="State: activate to sort column ascending"
                                    style={{ width: "53px" }}
                                  >
                                    State
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Date Added: activate to sort column ascending"
                                    style={{ width: "48px" }}
                                  >
                                    Date Added
                                  </th>
                                  <th
                                    className="disabled-sorting text-right sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{ width: "168px" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  {/* <th rowSpan="1" colSpan="1">
                                    ID
                                  </th> */}
                                  <th rowSpan="1" colSpan="1">
                                    Name
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Phone Number
                                  </th>

                                  <th rowSpan="1" colSpan="1">
                                    Cooperative
                                  </th>

                                  <th rowSpan="1" colSpan="1">
                                    Address
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    LGA
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    State
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Date Added
                                  </th>
                                  <th
                                    className="text-right"
                                    rowSpan="1"
                                    colSpan="1"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {farmers.map((data) => (
                                  <tr role="row" className="odd">
                                    {/* <td className="sorting_1">1</td> */}
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.phone_number}</td>
                                <td>{data.cooperative_name}</td>
                                    <td>{data.address}</td>
                                    <td>{data.LGA}</td>
                                    <td>{data.state}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          this.setState({
                                            party_name: `${data.first_name} ${data.last_name}`,
                                            type: "Farmer",
                                            cooperativeId: data.cooperativeId,
                                            cooperativeLiveId:
                                              data.cooperativeLiveId,
                                            modal: true,
                                          })
                                        }
                                      >
                                        Create Shipment
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="farmers_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 2 of 2 entries
                            </div>
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="farmers_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="farmers_previous"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="farmers_next"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!this.state.modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addShipmentModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create New Shipment</h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() => this.setState({ modal: false })}
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="KzqureCJk7bTWLWJ58AHvaMO07xHken9p7t1UYi55HXOLPxa3hXjMLu8AUUNxtMq"
                              />

                              <div
                                className={
                                  this.state.vehicle_no
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for="vehicle_no"
                                  className="bmd-label-floating"
                                >
                                  Enter Vehicle Number
                                </label>
                                <input
                                  required=""
                                  name="vehicle_no"
                                  type="text"
                                  className="form-control"
                                  id="vehicle_no"
                                  onChange={(event) =>
                                    this.setState({
                                      vehicle_no: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.gross_weight
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="gross_weight"
                                  className="bmd-label-floating"
                                >
                                  Enter Gross Weight
                                </label>
                                <input
                                  required=""
                                  name="gross_weight"
                                  type="text"
                                  className="form-control"
                                  id="gross_weight"
                                  onChange={(event) =>
                                    this.setState({
                                      gross_weight: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.weighbridge_number
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for="weighbridge_number"
                                  className="bmd-label-floating"
                                >
                                  Enter WeighBridge Number
                                </label>
                                <input
                                  required=""
                                  name="weighbridge_number"
                                  type="text"
                                  className="form-control"
                                  id="weighbridge_number"
                                  onChange={(event) =>
                                    this.setState({
                                      weighbridge_number: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                              prices: data.price
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select show">
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn  btn-success"
                                    data-toggle="dropdown"
                                    title="Choose Price"
                                    aria-expanded="true"
                                    onClick={this.handlePriceModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          { !this.state.crop_price
                                            ? "Choose Price"
                                            : `${this.state.crop_price}`}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.price_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href = "#/"
                                            role="option"
                                            className={
                                              !this.state.category
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Price
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.prices.map((data) => (
                                          <li key={data._id}>
                                            <a
                                              href= "#/"
                                              role="option"
                                              className={
                                                this.state.crop_price !== data.price
                                                  ? "dropdown-item"
                                                  : "dropdown-item active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  crop_price: data.price,
                                                  price_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                              ₦{data.price} -{" "}
                                              <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                              </span>
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_shipment"
                                value=""
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Farmers;
