import { fetchRequest } from '../utils';

export const create_non_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/non_season_cooperative/',
    method: 'POST',
    data: season
  });

export const list_non_season_cooperative = () =>
  fetchRequest({ url: 'http://localhost:9001/api/non_season_cooperative/' });

export const read_non_season_cooperative = params =>
  fetchRequest({
    url: 'http://localhost:9001/api/non_season_cooperative/' + params.seasonId
  });

export const update_non_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/non_season_cooperative/',
    method: 'PUT',
    data: season
  });

export const remove_non_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/non_season_cooperative/',
    method: 'DELETE',
    data: season
  });

export const remove_all_non_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/all/non_season_cooperative/',
    method: 'DELETE',
    data: season
  });
