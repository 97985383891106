import { fetchRequest } from "../utils";

export const create_farminput = (farmInput) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_inputs/`,
    method: "POST",
    data: farmInput,
  });

export const list_farminput = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_inputs/`,
  });

export const read_farminput = ({ farmInputId }) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_inputs/` + farmInputId,
  });

export const update_farminput = (farmInput) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_inputs/`,
    method: "PUT",
    data: farmInput,
  });

export const update_farminput_price = (farmInput) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/farm_inputs_price`,
    method: "PUT",
    data: farmInput,
  });

export const remove_farminput = (farmInput) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_inputs/`,
    method: "DELETE",
    data: farmInput,
  });

//Live server endpoint
export const create_farminput_live = (farmInput) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_inputs/`,
    method: "POST",
    data: farmInput,
  });

export const list_farminput_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_inputs/`,
  });

export const update_farminput_live = (farmInput, { farmInputId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_inputs/` +
      farmInputId,
    method: "PUT",
    data: farmInput,
  });

export const remove_farminput_live = ({ farmInputId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_inputs/` +
      farmInputId,
    method: "DELETE",
  });
