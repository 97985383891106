const auth = {
  isAuthenticated() {
    if (typeof window == "undefined") return false;
    else if (localStorage.getItem("jwt"))
      return JSON.parse(localStorage.getItem("jwt"));
    else return false;
  },
  authenticate(jwt, cb) {
    if (typeof window !== "undefined")
      localStorage.setItem("jwt", JSON.stringify(jwt));
    cb();
  },
  organizationData() {
    if (typeof window !== undefined)
      return JSON.parse(localStorage.getItem("organization"));
  },
  signout(cb) {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      localStorage.removeItem("token");
    }
    cb();
    //optional
    // signout().then(data => {
    //   document.cookie = 't=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // });
  },
  updateUser(user, cb) {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('jwt')) {
        let auth = JSON.parse(localStorage.getItem('jwt'));
        auth.user = user;
        localStorage.setItem('jwt', JSON.stringify(auth));
        cb && cb();
      }
    }
  }
};

export default auth;
