import React, { useState } from 'react';

const LoginForm = ({ error, handleSubmit }) => {
  const [state, setState] = useState({ email: '', password: '' });

  const handleChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const submitForm = (event, options) => {
    event.preventDefault();
    handleSubmit(state, options);
  };
  

  return (
    <form onSubmit={submitForm}>
      <div className='card card-login'>
        <div className='card-header card-header-success text-center'>
          <h4 className='card-title'>Log In</h4>
          <p style={{ color: 'red' }}>{error}</p>
        </div>
        <div className='card-body '>
          <span className='bmd-form-group'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>
                  <i className='material-icons'>email</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.email}
                name='email'
                type='email'
                className='form-control'
                placeholder='Email...'
              />
            </div>
          </span>
          <span className='bmd-form-group'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>
                  <i className='material-icons'>lock_outline</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.password}
                name='password'
                type='password'
                className='form-control'
                placeholder='Password...'
              />
            </div>
          </span>
        </div>
        <div className='card-footer justify-content-center'>
          <button type='submit' className='btn btn-success btn-lg'>
            Log In
          </button>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
