import { fetchRequest } from "../utils";

export const create_shipment_incoming_warehouse = (shipment_incoming_warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_incoming_warehouse/`,
    method: "POST",
    data: shipment_incoming_warehouse,
  });

export const list_shipment_incoming_warehouse = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_incoming_warehouse/`,
  });

export const read_shipment_incoming_warehouse = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_incoming_warehouse/` +
      params.shipmentId,
  });

export const update_shipment_incoming_warehouse = (shipment_incoming_warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_incoming_warehouse/`,
    method: "PUT",
    data: shipment_incoming_warehouse,
  });

export const remove_shipment_incoming_warehouse = (shipment_incoming_warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_incoming_warehouse/`,
    method: "DELETE",
    data: shipment_incoming_warehouse,
  });

export const create_shipment_incoming_warehouse_live = (shipment_incoming_warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_incoming_warehouse/`,
    method: "POST",
    data: shipment_incoming_warehouse,
  });

export const list_shipment_incoming_warehouse_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_incoming_warehouse/`,
  });

export const remove_shipment_incoming_warehouse_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_incoming_warehouse/` +
      params.shipment_incoming_warehouseId,
    method: "DELETE",
  });

export const read_shipment_incoming_warehouse_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_incoming_warehouse/` +
      params.shipmentId,
  });
