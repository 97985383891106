import { fetchRequest } from "../utils";

// Live Server Api endpoints process.env.REACT_APP_LIVE_SERVER_PROD

export const list_farm_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm/`,
  });

export const read_farm_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/farm/` +
      params.farmerId,
  });

export const remove_farm_live = (farmId) => {
  console.log(farmId);
  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm/` + farmId.farmId,
    method: "DELETE",
  });
};
