import { fetchRequest } from "../utils";

export const create_cooperative_lead_farmer = (cooperative_lead_farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_lead_farmer/`,
    method: "POST",
    data: cooperative_lead_farmer,
  });

export const list_cooperative_lead_farmer = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_lead_farmer/`,
  });

export const read_cooperative_lead_farmer = ({ cooperativeLeadFarmerId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_lead_farmer/` +
      cooperativeLeadFarmerId,
  });

export const update_cooperative_lead_farmer = (cooperative_lead_farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_lead_farmer/`,
    method: "PUT",
    data: cooperative_lead_farmer,
  });

export const remove_cooperative_lead_farmer = (cooperative_lead_farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_lead_farmer/`,
    method: "DELETE",
    data: cooperative_lead_farmer,
  });
//Live Api

export const create_cooperative_lead_farmer_live = (cooperative_lead_farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_lead_farmer/`,
    method: "POST",
    data: cooperative_lead_farmer,
  });

export const list_cooperative_lead_farmer_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_lead_farmer/`,
  });

export const update_cooperative_lead_farmer_live = (
  cooperative_lead_farmer,
  { cooperativeLeadFarmerId }
) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_lead_farmer/` +
      cooperativeLeadFarmerId,
    method: "PUT",
    data: cooperative_lead_farmer,
  });

export const remove_cooperative_lead_farmer_live = ({
  cooperativeLeadFarmerId,
}) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_lead_farmer/` +
      cooperativeLeadFarmerId,
    method: "DELETE",
  });
