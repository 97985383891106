import { fetchRequest } from "../utils";

export const create_quality_metric = (quality_metric_type) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/quality_metric_type/`,
    method: "POST",
    data: quality_metric_type,
  });

export const list_quality_metric_type = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/quality_metric_type/`,
  });

export const read_quality_metric_type = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/quality_metric_type/` +
      params.quality_metric_typeId,
  });

export const update_quality_metric_type = (quality_metric_type) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/quality_metric_type/`,
    method: "PUT",
    data: quality_metric_type,
  });

export const remove_quality_metric_type = (quality_metric_type) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/quality_metric_type/`,
    method: "DELETE",
    data: quality_metric_type,
  });

export const create_quality_metric_type_live = (quality_metric_type) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/quality_metric_type/`,
    method: "POST",
    data: quality_metric_type,
  });

export const list_quality_metric_type_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/quality_metric_type/`,
  });

export const update_quality_metric_type_live = (quality_metric_type, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/quality_metric_type/` +
      params.quality_metric_typeId,
    method: "PUT",
    data: quality_metric_type,
  });

export const remove_quality_metric_type_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/quality_metric_type/` +
      params.quality_metric_typeId,
    method: "DELETE",
  });

// export const create_deleted_quality_metric_live = (quality_metric) =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_quality_metric/`,
//     method: "POST",
//     data: quality_metric,
//   });

// export const list_deleted_quality_metric_live = () =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_quality_metric/`,
//   });
