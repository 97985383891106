import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  list_shipment_live,
  update_shipment_live
} from '../../api/api-shipment';
import {list_quality_metric_live, create_quality_metric_live} from "../../api/api-quality_metric";
import {list_quality_metric_type_live, remove_quality_metric_type_live} from "../../api/api-quality_metric_type";
import {list_shipment_quality_live, create_shipment_quality_live} from "../../api/api-shipment_quality_metric"
import SideBar from './SideBar';
import auth from "../../api/auth-helper";
import {read_quality_metric_live} from "../../api/api-quality_metric";
import Nav from './Nav';
import Moment from 'react-moment';

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        shipment: {},
        redirectToShipment: false,
        quality_metric: [],
        quality_metric_id: "",
        modal: false,
        modals: {
            quality_metric_type: false
        },
        quality_metric_types: [],
        quality_metric_type: "",
        value: ""
    };
    this.handleListShipment();
    this.handleListQM();
    this.handleQualityMetricType();
    this.GetQualityMetric()
  }

   componentDidMount(){
    document.title = "Shipment Details"
  }

   handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleModals = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleListShipment = () => {
    list_shipment_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let shipment = data.filter(
          shipment => shipment._id === this.props.location.state.shipmentId
        );
        this.setState({
          status: shipment[0].status,
          shipment: shipment[0]
        });
      }
    });
  };

  GetQualityMetric = () => {
    read_quality_metric_live({shipmentId: this.state.shipment_id}).then((data) =>{
      if(!data){
        return
      }
      else if(data){
        console.log(data.quality_metric)
        this.setState({
          quality_metric: data.quality_metric_type})
          console.log(this.state.quality_metric)
          this.submitQualityMetricShipment()

      }
    })
  }

  handleQualityMetricType = () =>{
      if (navigator.onLine) {
      list_quality_metric_type_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ quality_metric_types: data });

        }
      });
    } else if (!navigator.onLine) {
    }
  }

    handleListQM = () => {
    list_quality_metric_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let quality_metric = data.filter(
          quality_metric => quality_metric.shipment_id === this.props.location.state.shipmentId
        );
        this.setState({
          quality_metric: quality_metric
        });
      }
    });
  };

    checkForm = () => {
    if (!this.state.value) {
      this.setState({
        error: "Value is required",
      });
    }

    if (!this.state.quality_metric_type) {
      this.setState({
        error: " Quality Metric Type is required",
      });
    }

    if (this.state.value && this.state.quality_metric_type) {
      this.submitForm();
    }

    if (!this.state.value && !this.state.quality_metric_type) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

    submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const quality_metric_type = {
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        value:this.state.value,
        quality_metric_type: this.state.quality_metric_type_id,
        shipment_id: this.props.location.state.shipmentId
      };

      create_quality_metric_live(quality_metric_type, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleListQM();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Quality Metric created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  };


  render() {
    return (
      <div className='wrapper '>
        <SideBar />
        <div className='main-panel'>
          <Nav />

          <div className='content'>
            <div className='content'>
              <Link
                className='btn btn-primary btn-round'
                to='/quality_control_shipment'
              >
                Go Back
              </Link>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>people</i>
                        </div>
                        <p className='card-category'>Party Name</p>
                        <h3 className='card-title'>{this.state.shipment.partyName}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_strong</i>
                        </div>
                        <p className='card-category'>Gross Weight</p>
                        <h3 className='card-title'>{this.state.shipment.gross_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_weak</i>
                        </div>
                        <p className='card-category'>Tare Weight</p>
                        <h3 className='card-title'>{this.state.shipment.tare_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>crop_free</i>
                        </div>
                        <p className='card-category'>Net Weight</p>
                        <h3 className='card-title'>{this.state.shipment.net_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Quality Metrics</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Quality Metrics
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Metric</th>
                                  <th>Value</th>
                                  <th> Unit</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Metric</th>
                                  <th>Value</th>
                                  <th>Unit</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.quality_metric.map((data) => {
                                  return data.quality_metric_type.map(function(quality_metric_type){
                                   return <tr key={data._id}>
                                    <td>{quality_metric_type.metric}</td>
                                    <td>{data.value}</td>
                                    <td>{quality_metric_type.unit}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.setState({
                                                
                                              quality_metric_id: data._id,
                                              delete_modal: true,
                                            })
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Quality Metric"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                  })
                                }
                                  
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createQualityMetricTypeModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Create Quality Metric</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Value of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            value: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="metric"
                                        type="text"
                                        className="form-control"
                                        id="inputMetric"
                                      />
                                    </div>

                                <div
                                  className={
                                    !this.state.modals.quality_metric_type
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => this.handleModals("quality_metric_type")}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.quality_metric_type
                                            ? "Choose Quality Metric Type"
                                            : this.state.quality_metric_type}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.modals.quality_metric_type
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      minWidth: "250px",
                                      overflow: "hidden",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled">
                                          <a
                                            role="option"
                                            className="dropdown-item disabled"
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="false"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              {this.state.quality_metric_type || "Choose Quality Metric Type"}
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.quality_metric_types.map((data) => {
                                          return (
                                            <li key={data._id}>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.quality_metric_type === ""
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {
                                                  console.log(data._id);
                                                  this.handleModals("quality_metric_type");
                                                  this.setState({
                                                    quality_metric_type: data.metric,
                                                    quality_metric_type_id: data._id,
                                                  });
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.metric}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                    
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_quality_metric"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    onClick={this.checkForm}
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  Quality Metric.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                © 2021 , made with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ShipmentDetail;
