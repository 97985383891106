import { fetchRequest } from "../utils";

export const create_farmer_next_of_kin = (farmer_next_of_kin) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin/`,
    method: "POST",
    data: farmer_next_of_kin,
  });

  export const list_farmer_next_of_kin = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin/` +
      params.farmer_id,
  });

export const read_farmer_next_of_kin = (params) => {
  console.log("bug");
  console.log(params);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin/` + params.farmer_next_of_kinId,
  });
};

export const update_farmer_next_of_kin = (farmer_next_of_kin) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin/`,
    method: "PUT",
    data: farmer_next_of_kin,
  });

export const update_lead_farmer_next_of_kin = (farmer_next_of_kin) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin_lead/`,
    method: "PUT",
    data: farmer_next_of_kin,
  });

export const remove_farmer_next_of_kin = (farmer_next_of_kin) => {
  console.log(farmer_next_of_kin);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_next_of_kin/`,
    method: "DELETE",
    data: farmer_next_of_kin,
  });
};

export const create_farmer_next_of_kin_live = (farmer_next_of_kin) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_next_of_kin/`,
    method: "POST",
    data: farmer_next_of_kin,
  });

  export const list_farmer_next_of_kin_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_next_of_kin/` +
      params.farmer_id,
  });

export const read_farmer_next_of_kin_live = (params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_next_of_kin/` + params.farmer_next_of_kinId,
  });
};

export const update_farmer_next_of_kin_live = (farmer_next_of_kin, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_next_of_kin/` + params.farmer_next_of_kinId,
    method: "PUT",
    data: farmer_next_of_kin,
  });

export const remove_farmer_next_of_kin_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_next_of_kin/` + params.farmer_next_of_kinId,
    method: "DELETE",
  });

export const create_deleted_farmer_next_of_kin_live = (farmer_next_of_kin) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer_next_of_kin/`,
    method: "POST",
    data: farmer_next_of_kin,
  });

export const list_deleted_farmer_next_of_kin_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer_next_of_kin/`,
  });
