import { fetchRequest } from "../utils";

export const create_recovery_center = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center/`,
    method: "POST",
    data: recovery,
  });

export const list_recovery_center = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center/`,
  });

export const read_recovery_center = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center/` +
      params.recoveryId,
  });

export const update_recovery_center = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center/`,
    method: "PUT",
    data: recovery,
  });

export const remove_recovery_center = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center/`,
    method: "DELETE",
    data: recovery,
  });

//Live Api endpoints

export const create_recovery_center_live = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center/`,
    method: "POST",
    data: recovery,
  });

export const list_recovery_center_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center/`,
  });

export const update_recovery_center_live = (recovery, params) => {
  console.log(recovery);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center/` +
      params.recoveryCenterId,
    method: "PUT",
    data: recovery,
  });
};

export const remove_recovery_center_live = (params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center/` +
      params.recoveryCenterId,
    method: "DELETE",
  });
};
