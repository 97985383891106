import React, { Component } from "react";
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Redirect } from "react-router-dom";
import Pdf from "react-to-pdf";
import { list_farmer_live } from "../../api/api-farmer";
import { list_paddyprice_live } from "../../api/api-paddy_price";
import { create_shipment_live } from "../../api/api-shipment";
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";

const ref = React.createRef();
class Farmers extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      price_modal: false,
      vehicle_no: "",
      type: "",
      party_name: "",
      price: "",
      paddy_price: [],
      farmers: [],
      redirectToShipment: false,
      error: "",
      search: "",
      cooperativeId: "",
      cooperativeLiveId: "",
    };
    this.handleListFarmers();
    this.handleListPrice();
  }

  componentDidMount() {
    document.title = "Farmers";
  }

  handlePriceModal = () => {
    this.setState((prevState) => ({
      price_modal: !prevState.price_modal,
      error: "",
    }));
  };

  handleListPrice = () => {
    list_paddyprice_live().then((data) => {
      if (data.error) {
        return;
      }
      if (data.length !== 0) {
        this.setState({ paddy_price: data, currentPrice: data[0].price });
      }
    });
  };

  handleListFarmers = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        console.log(data);
        this.setState({ farmers: data });
      }
    });
  };

  checkForm = () => {
    if (!this.state.price) {
      this.setState({
        error: "Price is required",
      });
    }

    if (!this.state.vehicle_no) {
      this.setState({
        error: "Vehicle Number is required",
      });
    }

    if (this.state.price && this.state.vehicle_no) {
      this.handleSubmit();
    }

    if (!this.state.price && !this.state.vehicle_no) {
      this.setState({
        error: "All Fields are required",
      });
    }
  };

  handleSubmit = () => {
    const jwt = auth.isAuthenticated();
    const shipment = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      cooperativeLiveId: this.state.cooperativeLiveId,
      vehicleNumber: this.state.vehicle_no,
      type: this.state.type,
      partyName: this.state.party_name,
      price: this.state.price,
      status: "nothing",
      reason: "",
    };
    create_shipment_live(shipment, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.setState({
          redirectToShipment: true,
        });
        this.setState({
          vehicle_no: "",
          type: "",
          party_name: "",
          price: "",
          modal: false,
        });
        /*
				const shipment = {
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					cooperativeId: this.state.cooperativeId,
					cooperativeLiveId: this.state.cooperativeLiveId,
					liveId: data.data._id,
					vehicle_number: this.state.vehicle_no,
					type: this.state.type,
					party_name: this.state.party_name,
					price: this.state.price,
				};
				create_shipment(shipment, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} if(data) {
						this.setState({
							redirectToShipment: true,
						});
						this.setState({
							vehicle_no: '',
							type: '',
							party_name: '',
							price: '',
							modal: false,
						});
					}
				});
				*/
      }
    });
  };
  render() {
    if (this.state.redirectToShipment) {
      return <Redirect to="/warehouse_shipment" />;
    }
    const { search } = this.state;
    const farmers = this.state.farmers.filter((farmer) => {
      return farmer.first_name.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header card-header-primary card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">wc</i>
                      </div>
                      <h4 className="card-title">Farmers</h4>
                    </div>
                    <div className="card-body">
                      <div className="toolbar" />
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          <div
                            id="farmers_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="dt-buttons btn-group">
                              {" "}
                              <Pdf targetRef={ref} filename="farmers.pdf">
                                {({ toPdf }) => (
                                  <button
                                    className="btn btn-secondary buttons-pdf buttons-html5"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    type="button"
                                    onClick={toPdf}
                                  >
                                    <span>Export PDF</span>
                                  </button>
                                )}
                              </Pdf>{" "}
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-secondary buttons-excel buttons-html5"
                                table="farmers"
                                filename="farmersxls"
                                sheet="farmersxls"
                                buttonText="Export Excel"
                              />{" "}
                            </div>
                            <div
                              id="farmers_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <span className="bmd-form-group bmd-form-group-sm">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="farmers"
                                    onChange={(event) =>
                                      this.setState({
                                        search: event.target.value,
                                      })
                                    }
                                  />
                                </span>
                              </label>
                            </div>
                            <table
                              title="Farmers"
                              id="farmers"
                              className="table table-striped table-no-bordered table-hover dataTable"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                              role="grid"
                              aria-describedby="farmers_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="sorting_asc"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-sort="ascending"
                                    aria-label="ID: activate to sort column descending"
                                    style={{ width: "50px" }}
                                  >
                                    ID
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Name: activate to sort column ascending"
                                    style={{ width: "77px" }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Phone Number: activate to sort column ascending"
                                    style={{ width: "109px" }}
                                  >
                                    Phone Number
                                  </th>

                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Address: activate to sort column ascending"
                                    style={{ width: "87px" }}
                                  >
                                    Address
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="LGA: activate to sort column ascending"
                                    style={{ width: "38px" }}
                                  >
                                    LGA
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="State: activate to sort column ascending"
                                    style={{ width: "53px" }}
                                  >
                                    State
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Date Added: activate to sort column ascending"
                                    style={{ width: "50px" }}
                                  >
                                    Date Added
                                  </th>
                                  <th
                                    className="disabled-sorting text-right sorting"
                                    tabIndex="0"
                                    aria-controls="farmers"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Actions: activate to sort column ascending"
                                    style={{ width: "168px" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th rowSpan="1" colSpan="1">
                                    ID
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Name
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Phone Number
                                  </th>

                                  <th rowSpan="1" colSpan="1">
                                    Address
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    LGA
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    State
                                  </th>
                                  <th rowSpan="1" colSpan="1">
                                    Date Added
                                  </th>
                                  <th
                                    className="text-right"
                                    rowSpan="1"
                                    colSpan="1"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {farmers.map((data) => (
                                  <tr role="row" className="odd">
                                    <td className="sorting_1">1</td>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.phone_number}</td>

                                    <td>{data.address}</td>
                                    <td>{data.LGA}</td>
                                    <td>{data.state}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          this.setState({
                                            party_name: `${data.first_name} ${data.last_name}`,
                                            type: "Farmer",
                                            cooperativeId: data.cooperativeId,
                                            cooperativeLiveId:
                                              data.cooperativeLiveId,
                                            modal: true,
                                          })
                                        }
                                      >
                                        Create Shipment
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="farmers_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 2 of 2 entries
                            </div>
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="farmers_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="farmers_previous"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="farmers_next"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!this.state.modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addShipmentModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create New Shipment</h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() => this.setState({ modal: false })}
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="KzqureCJk7bTWLWJ58AHvaMO07xHken9p7t1UYi55HXOLPxa3hXjMLu8AUUNxtMq"
                              />

                              <div
                                className={
                                  this.state.vehicle_no
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for="vehicle_no"
                                  className="bmd-label-floating"
                                >
                                  Enter Vehicle Number
                                </label>
                                <input
                                  required=""
                                  name="vehicle_no"
                                  type="text"
                                  className="form-control"
                                  id="vehicle_no"
                                  onChange={(event) =>
                                    this.setState({
                                      vehicle_no: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select show">
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn  btn-success"
                                    data-toggle="dropdown"
                                    title="Choose Price"
                                    aria-expanded="true"
                                    onClick={this.handlePriceModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.price
                                            ? "Choose Price"
                                            : `${this.state.price}`}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    id="price"
                                    className={
                                      !this.state.price_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    aria-controls = "price"
                                    aria-expanded="true"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href= "#/"
                                            role="option"
                                            className={
                                              !this.state.category
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Price
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.paddy_price.map((data) => (
                                          <li>
                                            <a
                                              href = "#/"
                                              role="option"
                                              className={
                                                this.state.price !== data.price
                                                  ? "dropdown-item"
                                                  : "dropdown-item active"
                                              }
                                              aria-disabled="false"
                                              tabIndex="0"
                                              aria-selected="false"
                                              onClick={() =>
                                                this.setState({
                                                  price: data.price,
                                                  price_modal: false,
                                                })
                                              }
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                ₦{data.price} - {data.createdAt}
                                              </span>
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_shipment"
                                value=""
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Farmers;
