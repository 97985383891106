import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from '../../api/auth-helper';

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      cooperative: false,
      farmer: false
    };
  }

  handleCooperative = () => {
    this.setState(prevState => ({
      cooperative: !prevState.cooperative
    }));
  };

  handleFarmer = () => {
    this.setState(prevState => ({
      farmer: !prevState.farmer
    }));
  };

  render() {
    return (
      <div
        className='sidebar'
        data-color='green'
        data-background-color='black'
        data-image='/static/assets/img/sidebar-6.jpg'
      >
        <div className='logo'>
          <a href='/quality_control/' className='simple-text logo-mini'>
            SB
          </a>

          <a href='/quality_control/' className='simple-text logo-normal'>
            QualityControl
          </a>
        </div>

        <div className='sidebar-wrapper'>
          <div className='user'>
            <div className='user-info'>
              <Link to='/quality Control' className='username'>
                <span>
                  {` ${auth.isAuthenticated().user.first_name}  ${
                    auth.isAuthenticated().user.last_name
                  } `}
                </span>
              </Link>
            </div>
          </div>
          <ul className='nav'>

          <li className='nav-item {{ shipments_page }}'>
              <Link className='nav-link' to='/quality_metric'>
                <i className='material-icons'>store</i>
                <p> Quality Metric </p>
              </Link>
            </li>
      
            <li
              className='nav-item {{ farmers_page }}'>
              <Link className='nav-link' to='/quality_control_shipment'>
                <i className='material-icons'>store</i>
                <p> Shipment</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
