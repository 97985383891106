import { fetchRequest } from "../utils";

export const list_farmer_season = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_season/`,
  });

export const read_farmer_season = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_season/` +
      params.farmer_id,
  });

export const update_farmer_season = (farmer_season, params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_season/` +
      params.farmer_id,
    method: "PUT",
    data: farmer_season,
  });
};

export const remove_farmer_season = (farmer_season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_season/`,
    method: "DELETE",
    data: farmer_season,
  });

export const list_farmer_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_season/`
  });

export const read_farmer_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_season/` +
      params.farmer_id,
  });

export const remove_farmer_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_season/` +
      params.farmer_seasonId,
    method: "DELETE",
  });
