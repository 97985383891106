import React, { Component } from 'react';
import SideBar from './SideBar';
import Nav from './Nav';
import { Link, Redirect } from 'react-router-dom';
import auth from '../../api/auth-helper';
import {create_payment_voucher_live, list_payment_voucher_live, update_payment_voucher_live} from "../../api/api-payment_voucher";
import {create_payment_schedule_live} from "../../api/api-payment_schedule"
import {create_recovery_shipment_live} from '../../api/api-recovery_shipment';
import Moment from "react-moment";
class PaymentScheduleVouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disapprove_modal: false,
      reason: '',
      error: '',
      status: '',
      recoveries: [],
      shipment: {},
      recovery_shipment: [],
      vouchers: [],
      cooperativeId: '',
      redirectToPaymentSchedule: false,
      recovery_modal: false,
      update_modal: false,
      voucher_ids: []
    };
    this.handleListVoucher();
  }

  componentDidMount(){
    document.title = "Payment Schedule Vouchers"
    console.log(this.props.location.state)
  }



  handleListVoucher = () => {
    list_payment_voucher_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let voucher = data.filter(
          voucher =>
            voucher.auditor_status !==  "pending" 
        );
        console.log(voucher)
        this.setState({
          vouchers: voucher
        });
      }
    });
   };

   updateVoucher = () => {
    const jwt = auth.isAuthenticated();
    this.state.voucher_ids.map(id => {
      const payment_voucher= {
        auditor_status: 'pending',
    };
    update_payment_voucher_live(payment_voucher,{ payment_voucherId: id },{
      t: jwt.token,

    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.submitModal();
    })
    this.setState({ redirectToPaymentSchedule: true });
   }


  submitModal = () => {
    this.setState(prevState => ({ update_modal: !prevState.update_modal }));
  };

  createPaymentSchedule = () => {
    const jwt = auth.isAuthenticated();
    const payment_schedule = {
      voucher: this.state.voucher_ids,
      createdBy: auth.isAuthenticated().user.first_name +
      ' ' +
        auth.isAuthenticated().user.last_name,
      accountant_status: "pending",
    }
    create_payment_schedule_live(payment_schedule,{
      t: jwt.token,

    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.submitModal();
    this.updateVoucher()
  };

  handleCheckBox = (event, Id) => {
    if (event.target.checked) {
      //append to array
      console.log(this.state.recovery_shipment)
      this.setState({
        voucher_ids: this.state.voucher_ids.concat([Id]),
      });
    } else {
      //remove from array
      this.setState({
        voucher_ids: this.state.voucher_ids.filter(function(val) {
          return val !== Id;
        })
      });
    }
  };


  render() {
    if (this.state.redirectToPaymentSchedule) {
      return (
        <Redirect
          to={{
            pathname: '/auditor_payment_schedule',
   
          }}
        />
      );
    }
    return (
      <div className='wrapper '>
        <SideBar />

        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header card-header-primary card-header-icon'>
                      <div className='card-icon'>
                        <i className='material-icons'>ballot</i>
                      </div>
                      <h4 className='card-title'>Vouchers</h4>
                      <h6 className='card-title'>
                        Select Vouchers and click apply below
                      </h6>
                    </div>
                    <div className='card-body'>
                      <div>
                        <input
                          type='hidden'
                          name='aggregate_shipment'
                          value='1'
                        />
                        <div className='toolbar'>
                          <button
                            onClick={this.submitModal}
                            className='btn btn-primary btn-round'
                            type='submit'
                          >
                            Apply
                          </button>
                        </div>
                        <h4 style={{ color: 'red', alignSelf: 'center' }}>
                          {this.state.error}
                        </h4>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='pending_aggregations'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                              <tr role='row'>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Farmer: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Farmer
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Number of Bags: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Amount
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Weight: activate to sort column ascending'
                                          style={{ width: '59px' }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Paid By
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Aggregation Centre: activate to sort column ascending'
                                          style={{ width: '163px' }}
                                        >
                                          Voucher Number
                                        </th>
                                        
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        
                                      </tr>
                              </thead>
                              <tfoot>
                                      <tr>
                                      <th rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Farmer
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Amount
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Paid By
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Voucher Number
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                         
                                      </tr>
                                    </tfoot>
                              <tbody>
                              {this.state.vouchers.map((data) =>(
                                        
                                    <tr role='row' className='odd'>
                                        <td className=''>
                                         <div className='text-center form-check'>
                                            <label className='form-check-label'>
                                             <input
                                                name={data._id}
                                                className='form-check-input'
                                                type='checkbox'
                                                value={data._id}
                                                onChange={(event) =>{
                                                    this.handleCheckBox(
                                                event,
                                                data._id
                                              )
                                              this.state.recovery_shipment.push(event.target.value)
                                              console.log(this.state.recovery_shipment)
                                            }
                                            }
                                          />
                                          <span className='form-check-sign'>
                                            <span className='check'></span>
                                                    </span>
                                                </label>
                                            </div>
                                        </td>
                                       <td>{data.recovery.farmer}</td>
                                       <td>₦{data.amount}</td>
                                       <td>{data.status}</td>
                                       <td>{data.updatedBy}</td>
                                       <td>{data.voucher_no}</td>
                                       <td>
                                     <Moment format="D MMM YYYY" withTitle>
                                       {data.created}
                                     </Moment>
                                    </td>
                                 </tr>                    
                             ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.update_modal ? (
                ''
              ) : (
                <div
                  className='modal fade show'
                  id='addCooperativeModal'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='myModalLabel'
                  style={{
                    display: 'block',
                    paddingRight: '5px',
                    paddingTop: '100px'
                  }}
                >
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 className='modal-title'>Create Payment Schedule ?</h4>
                        <button
                          onClick={this.submitModal}
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-hidden='true'
                        >
                          <i className='material-icons'>clear</i>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='card '>
                          {this.state.voucher_ids.length !== 0 ? (
                            <div className='card-body '>
                              <div>Are you sure you want to create a payment schedule with the following vouchers?</div>

                              <button
                                type='submit'
                                onClick={() => this.createPaymentSchedule()}
                                className='btn btn-fill btn-success'
                              >
                                Create
                              </button>
                              <button
                                type='submit'
                                onClick={this.submitModal}
                                className='btn btn-fill btn-danger'
                              >
                                Cancel
                              </button>

                              <button
                                type='submit'
                                className='btn btn-fill btn-primary'
                                id='create_offline'
                                style={{ display: 'none' }}
                              >
                                Create Offline
                              </button>
                            </div>
                          ) : (
                            <div className='card-body '>
                              <div>No Vouchers</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentScheduleVouchers;
