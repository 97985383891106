import { fetchRequest } from "../utils";

export const create_recovery_center_cooperative = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative/`,
    method: "POST",
    data: recovery,
  });

export const list_recovery_center_cooperative = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative/`,
  });

export const read_recovery_center_cooperative = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative/` +
      params.recoveryId,
  });

export const update_recovery_center_cooperative = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative/`,
    method: "PUT",
    data: recovery,
  });

export const update_recovery_center_cooperative_shipment = (recovery, params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center_cooperative/` +params.recovery_id,
    method: "PUT",
    data: recovery,
  });

export const update_recovery_center_cooperative_shipment_status = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative_shipment_status/`,
    method: "PUT",
    data: recovery,
  });

export const remove_recovery_center_cooperative = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/recovery_center_cooperative/`,
    method: "DELETE",
    data: recovery,
  });

export const create_recovery_center_cooperative_live = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center_cooperative/`,
    method: "POST",
    data: recovery,
  });

export const list_recovery_center_cooperative_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center_cooperative/`,
  });

export const update_recovery_center_cooperative_live = (recovery, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center_cooperative/` +
      params.recoveryCenterCooperativeId,
    method: "PUT",
    data: recovery,
  });

export const remove_recovery_center_cooperative_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/recovery_center_cooperative/` +
      params.recoveryCenterCooperativeId,
    method: "DELETE",
  });

export const create_deleted_recovery_center_cooperative_live = (recovery) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_recovery_center_cooperative/`,
    method: "POST",
    data: recovery,
  });

export const list_deleted_recovery_center_cooperative_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_recovery_center_cooperative/`,
  });
