import React, { Component } from 'react';
import SideBar from './SideBar';
import Nav from './Nav';
import { Link} from 'react-router-dom';
import auth from '../../api/auth-helper';
import {
  list_shipment_live,
  update_shipment_live
} from '../../api/api-shipment';
import {
  list_recovery_center_cooperative_live,
  update_recovery_center_cooperative_shipment_status, update_recovery_center_cooperative_shipment
} from '../../api/api-recovery_center_cooperative';
import {list_recovery_shipment_live, remove_recovery_shipment_live} from "../../api/api-recovery_shipment";
import Moment from "react-moment";
class ShipmentOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disapprove_modal: false,
      delete_modal: false,
      reason: '',
      error: '',
      status: '',
      shipment: {},
      redirectToShipment: false,
      recoveries: [],
      total_recovery_weight: '',
      recoveryId: '',
      disable_disapproved: false,
      disable_approved: false
    };
    this.handleListShipment();
    this.handleListRecoveries();
  }

  componentDidMount(){
    document.title = "Shipment Options"
  }

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };


  handleListShipment = () => {
    list_shipment_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let shipment = data.filter(
          shipment => shipment._id === this.props.location.state.shipmentId
        );
        this.setState({
          status: shipment[0].status,
          shipment: shipment[0]
        });
        console.log(this.state.status)
      }
    });
  };
  
  UpdateDeletedRecovery = () => {
     const jwt = auth.isAuthenticated();
      const recovery= {
      updatedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      status: 'pending',
      }
    update_recovery_center_cooperative_shipment(recovery,{recovery_id: this.state.recoveryId },{
      t: jwt.token,
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.handleListRecoveries()
  }

  deleteData = () => {
    console.log(this.state.recoveryId)
    remove_recovery_shipment_live({
      recoveryId: this.state.recoveryId,
    }).then((data)=> {
      if(!data){
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Recovery Could Not Delete",
          delete_modal: false
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
      else if (data){
        this.setState({
          loading: false,
          success_notification: true,
          mssg: "Recovery deleted successfully",
          delete_modal: false
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        
        this.UpdateDeletedRecovery()
      }
    })
  }

  handleListRecoveries = () => {
    list_recovery_shipment_live({}).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let recoveries = []
        let recovery_shipment = data.filter(
          recovery =>
            recovery.shipment_id === this.props.location.state.shipmentId
        );
        recovery_shipment.map(recovery =>{
         return recoveries.push(recovery.recovery)
        })
        let total;
        if (recoveries) {
          let array = [];
          recoveries.map(data => {
            data.map(recovery =>{
              console.log(recovery)
              return array.push(Number(recovery.total_weight));
            })  
          });
          total = array.reduce((a, b) => a + b, 0);
        }
        this.setState({ total_recovery_weight: total, recoveries: recoveries});
        console.log(this.state.recoveries)
      }
    });
  };

  checkForm = () => {
    if (!this.state.reason) {
      this.setState({ error: 'Reason is required' });
    }
    if (this.state.reason) {
      this.updateShipmentDisapprove();
    }
  };

  updateShipmentDisapprove = () => {
    const jwt = auth.isAuthenticated();
    const shipment = {
      
      updatedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      status: 'disapproved',
      reason: this.state.reason
    };
    update_shipment_live(shipment, {shipmentId: this.props.location.state.shipmentId}, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        return;
      } else {
        this.setState({disable_approved: true})
        this.state.recoveries.map(
          data => (data.shipment_status = 'disapproved')
        );

        // update_recovery_center_cooperative_shipment_status(
        //   this.state.recoveries,
        //   {
        //     t: jwt.token
        //   }
        // ).then(data => {
        //   if (data.error) {
        //     return;
        //   } else {
        //   }
        // });
        this.handleListShipment();
        this.setState({ disapprove_modal: false });
      }
    });
  };

  updateShipmentApprove = () => {
    const jwt = auth.isAuthenticated();
    const shipment = {
      
      updatedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      status: 'approved',
      reason: ''
    };
    update_shipment_live(shipment,{shipmentId: this.props.location.state.shipmentId}, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.state.recoveries.map(data => (data.shipment_status = 'approved'));
        this.setState({disable_disapproved: true})

        // update_recovery_center_cooperative_shipment_status(
        //   this.state.recoveries,
        //   {
        //     t: jwt.token
        //   }
        // ).then(data => {
        //   if (data.error) {
        //     this.setState({ error: data.error });
        //   } else {
        //     this.setState({disable_disapproved: true})
        //   }
        // });
        this.handleListShipment();
      }
    });
  };

  render() {
    if (this.props.location.state === undefined) {
      return;
    } else {
      // this.handleListRecoveries();
    }
    return (
      <div className='wrapper '>
        <SideBar />

        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='content'>
              <div className='container-fluid'>
                {this.props.location.state.direct === 'shipment' ? (
                  <Link className='btn btn-primary btn-round' to='/shipments'>
                    Go Back
                  </Link>
                ) : (
                  <Link
                    className='btn btn-primary btn-round'
                    to={{
                      pathname: '/cooperative_details',
                      state: {
                        cooperativeId: this.props.location.state.cooperativeId,
                        name: this.props.location.state.name,
                        type: this.props.location.state.type,
                        start_month: this.props.location.state.start_month,
                        end_month: this.props.location.state.end_month,
                        year: this.props.location.state.year
                      }
                    }}
                  >
                    Go Back
                  </Link>
                )}

                <div className='row'>
                  <div className='col-md-3'>
                    <div>
                      <input
                        type='hidden'
                        name='csrfmiddlewaretoken'
                        value='Ve6DAzlPoDRHe6cz7rYShGw1NnbnXycVc5ljdATbPlyrbzqV4J7qdRnVlRyi559w'
                      />
                      <input type='hidden' name='approve' value='1' />
                      {this.state.status !== 'nothing'  ? (
                        <div>
                          <button
                            type='submit'
                            className='btn btn-fill btn-primary'
                            onClick={this.updateShipmentApprove}
                            disabled
                          >
                            {' '}
                            Approve
                          </button>
                          <p>{this.state.shipment.updatedBy}</p>
                        </div>
                      ) : (
                        <button
                          type='submit'
                          className='btn btn-fill btn-primary'
                          onClick={this.updateShipmentApprove}
                        >
                          {' '}
                          Approve{' '}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='col-md-3'>
                    {this.state.status !== 'nothing'  ? (
                      <div>
                        <button
                          data-toggle='modal'
                          data-target='#disapproveModal'
                          className='btn btn-fill btn-primary'
                          onClick={() =>
                            this.setState({ disapprove_modal: true, error: '' })
                          }
                          disabled
                        >
                          Disapprove
                        </button>
                        {/* <p>
                          {this.state.shipment.reason +
                            ' ,' +
                            this.state.shipment.updatedBy}
                        </p> */}
                      </div>
                    ) : (
                      <button
                        data-toggle='modal'
                        data-target='#disapproveModal'
                        className='btn btn-fill btn-primary'
                        onClick={() =>
                          this.setState({ disapprove_modal: true, error: '' })
                        }
                      >
                        Disapprove
                      </button>
                    )}

                    <p />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Recoveries</h4>
                        <h5 className='card-title'>
                          Total Weight: {this.state.total_recovery_weight}Kg
                        </h5>
                        <h5 className='card-title'>Rejected Bags: 0 Bags</h5>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                          {this.props.location.state.direct === 'shipment' ? (
                            <Link
                              to={{
                                pathname: '/shipment_recovery',
                                state: {
                                  direct: 'shipment',
                                  cooperativeId: this.props.location.state
                                    .cooperativeId,
                                  shipmentId: this.props.location.state
                                    .shipmentId
                                }
                              }}
                              className='btn btn-primary btn-round'
                            >
                              Add Recoveries
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: '/shipment_recovery',
                                state: {
                                  direct: 'cooperative_shipment',
                                  cooperativeId: this.props.location.state
                                    .cooperativeId,
                                  shipmentId: this.props.location.state
                                    .shipmentId,
                                  name: this.props.location.state.name,
                                  type: this.props.location.state.type,
                                  start_month: this.props.location.state
                                    .start_month,
                                  end_month: this.props.location.state
                                    .end_month,
                                  year: this.props.location.state.year
                                }
                              }}
                              className='btn btn-primary btn-round'
                            >
                              Add Recoveries
                            </Link>
                          )}
                          <div>{this.state.status !== 'nothing'? `The recoveries have been ${this.state.status} on ${this.state.shipment.updated}` : ""}</div>
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          placeholder=''
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Rebby Oshiobughie Shipment Recoveries'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing='0'
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='sorting_asc'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-sort='ascending'
                                          aria-label='Farmer: activate to sort column descending'
                                          style={{ width: '61px' }}
                                        >
                                          Farmer
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Number of Bags: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Number of Bags
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Weight: activate to sort column ascending'
                                          style={{ width: '59px' }}
                                        >
                                          Weight
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '40px' }}
                                        >
                                          Cost
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Recovery Centre: activate to sort column ascending'
                                          style={{ width: '139px' }}
                                        >
                                          Recovery Centre
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '41px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '41px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan='1' colSpan='1'>
                                          Farmer
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Number of Bags
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Weight
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Cost
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Recovery Centre
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Date
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {this.state.recoveries.map((data) =>{
                                        return data.map((recoveries) =>{
                                          return <tr role='row' className='odd'>
                                          <td>{recoveries.farmer}</td>
                                          <td>{recoveries.num_of_bags}</td>
                                          <td>{recoveries.total_weight}Kg</td>
                                          <td>₦{recoveries.total_cost}</td>
                                          <td>{recoveries.recovery_center}</td>
                                          <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {recoveries.created}
                                        </Moment>
                                      </td>
                                          <td><div>
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            onClick={()=>{console.log(recoveries)
                                              this.setState({
                                                recoveryId: recoveries._id,
                                                delete_modal: true,
                                              })
                                            }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Recovery"
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div></td>
                                        </tr>
                                        })
                                      }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          
                                          aria-controls='aggregations'
                                          data-dt-idx='0'
                                          tabIndex='0'
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          
                                          aria-controls='aggregations'
                                          data-dt-idx='1'
                                          tabIndex='0'
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Recovery Aggregation?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Recovery Aggregation
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.deleteData()}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  Recovery Aggregation.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {!this.state.disapprove_modal ? (
                ''
              ) : (
                <div
                  className='modal fade show'
                  id='disapproveModal'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='myModalLabel'
                  style={{ paddingRight: '15px', display: 'block' }}
                >
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 className='modal-title'>Disapprove Shipment</h4>
                        <h4 className='modal-notice'>
                          Weighbridge Manager will be emailed with the details
                          of this disapproval.
                        </h4>
                        <button
                          type='button'
                          className='close'
                          onClick={() =>
                            this.setState({
                              disapprove_modal: false,
                              error: ''
                            })
                          }
                          data-dismiss='modal'
                          aria-hidden='true'
                        >
                          <i className='material-icons'>clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: 'red', alignSelf: 'center' }}>
                        {this.state.error}
                      </h4>
                      <div className='modal-body'>
                        <div className='card '>
                          <div className='card-body '>
                            <div>
                              <input
                                type='hidden'
                                name='csrfmiddlewaretoken'
                                value='VKuJeFbn6Jn2zh0HFWgh4Rvmm0TulzQA443hZ0YF1VUeeNhzEI7LSiF2GSLwsSqx'
                              />
                              <div
                                className={
                                  this.state.reason
                                    ? 'is-filled form-group bmd-form-group'
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for='inputName'
                                  className='bmd-label-floating'
                                >
                                  Why are you disapproving this Shipment?
                                </label>
                                <input
                                  onChange={event =>
                                    this.setState({
                                      reason: event.target.value,
                                      error: ''
                                    })
                                  }
                                  required='true'
                                  name='note'
                                  type='text'
                                  className='form-control'
                                  id='inputName'
                                />
                              </div>
                              <input
                                type='hidden'
                                name='disapprove'
                                value='1'
                              />
                              <button
                                type='submit'
                                onClick={this.checkForm}
                                className='btn btn-fill btn-danger'
                              >
                                Disapprove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShipmentOptions;
