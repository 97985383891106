import { fetchRequest } from "../utils";

export const create_crop_price = (cropPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_price/`,
    method: "POST",
    data: cropPrice,
  });

export const list_crop_price = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_price/`,
  });

export const read_crop_price = ({ cropPriceId }) => {
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_price/` + cropPriceId,
  });
};

export const update_crop_price = (cropPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_price/`,
    method: "PUT",
    data: cropPrice,
  });

export const create_update_crop_price = (cropPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/crop_price/`,
    method: "PUT",
    data: cropPrice,
  });

export const remove_all_crop = (cropPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/crop_price`,
    method: "DELETE",
    data: cropPrice,
  });

export const remove_crop_price = (cropPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_price/`,
    method: "DELETE",
    data: cropPrice,
  });

export const create_crop_price_live = (cropPrice) => {
  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop_price/`,
    method: "POST",
    data: cropPrice,
  });
};

export const list_crop_price_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop_price/`,
  });

export const remove_crop_price_live = ({ cropPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop_price/` + cropPriceId,
    method: "DELETE",
  });

export const update_crop_price_live = (cropPrice, { cropPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop_price/` + cropPriceId,
    method: "PUT",
    data: cropPrice,
  });

export const read_crop_price_live = ({ cropPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop_price/` + cropPriceId,
  });

export const remove_all_crop_live = ({ cropPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/update/crop_price` +
      cropPriceId,
    method: "DELETE",
  });
