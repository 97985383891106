import React, { Component } from "react";
import Loader from "react-loader-spinner";

import auth from "../../api/auth-helper";
import { signIn, signUp } from "../../api/api-auth";


import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import UserContext from "../UserContext";

class Login extends Component {
  state = {
    error: "",
    email: "",
    password: "",
    loading: false,
    activity: false,
  };

  componentDidMount() {
    if (this.context.user) {
      this.handleUser({ user: this.context.user });
    }
    document.title = "Login";
  }

  /* Setting activity to a boolean so it can be easily toggled
    between login and signup states */

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  reduceFields = (fields) =>
    Object.values(fields).reduce((acc, cur) => acc && !!cur);

  handleLogin = (user) => {
    if (this.reduceFields(user)) {
      this.setState({ loading: true });

      if (navigator.onLine) {
        signIn(user)
          .then((data) => {
            const { user, token, error, organization } = data;

            if (user && token) {
              this.context.setUser(user);
              localStorage.setItem("token", token);
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem(
                "organization",
                JSON.stringify(organization)
              );

              this.handleUser(data);
            } else if (error) {
              console.error(error.message);
            }
          })
          .catch(({ message }) => {
            this.setState({
              error: `Could not sign you in.\nPlease check your details`,
              loading: false,
            });
            console.log(message);
          });
      } else {
        this.setState({
          error: "Internet Connection is required to login",
        });
      }
    } else {
      this.setState({ error: "All fields must have correct values." });
    }
  };

  handleSignup = (user) => {
    if (this.reduceFields(user)) {
      this.setState({ loading: true });

      if (navigator.onLine) {
        signUp(user)
          .then(() => {
            this.handleLogin(user);
          })
          .catch((err) => {
            console.error(err);
            this.setState({ loading: false, error: err.message });
          });
      } else {
        this.setState({
          error: "Internet Connection is required to sign up",
        });
      }
    } else {
      this.setState({ error: "All fields must have correct values." });
    }
  };

  handleUser = (data) => {
    const { history } = this.props;

    if (data) {
      if (data.user && data.token) {
        auth.authenticate(data, () => {
          if (data.user.role === "admin") {
            history.push("/admin");
          }

          if (data.user.role === "extension_manager") {
            history.push("/extension");
          }

          if (data.user.role === "warehouse_manager") {
            history.push("/warehouse");
          }

          if (data.user.role === "accountant") {
            history.push("/accountant");
          }

          if (data.user.role === "weighbridge_manager") {
            history.push("/weighbridge");
          }
          
          if(data.user.role === "qc_manager"){
            history.push("/quality_control")
          }

          if(data.user.role === "ext_plus_manager"){
            history.push("/ext_plus")
          }

          if(data.user.role === "auditor"){
            history.push("/auditor")
          }
        });
      } else {
        this.setState({ error: data.mssg });
      }
    } else {
      this.setState({ error: "User not found" });
    }

    this.setState({ loading: false });
  };

  toggleActivity = () =>
    this.setState(({ activity }) => ({ activity: !activity }));

  render() {
    const { error, loading, activity } = this.state;

    return loading ? (
      <Loader
        style={{ marginTop: "50vh", textAlign: "center" }}
        type="Oval"
        color="green"
        height={50}
        width={50}
      />

    ) : (
      <div className="off-canvas-sidebar">
        <nav
          className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white"
          id="navigation-example"
        >
          <div className="container">
            <div className="navbar-wrapper">
              <a className="navbar-brand" href="/">
                SupplyBase
              </a>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              data-target="#navigation-example"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
            </button>

            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    <i className="material-icons">dashboard</i>
                    Go Home
                  </a>
                </li>
                <li className="nav-item" onClick={this.toggleActivity}>
                  <p className="nav-link" style={{ cursor: "pointer" }}>
                    {activity ? `Log In ` : ` `}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="wrapper wrapper-full-page">
          <div
            className="page-header login-page header-filter"
            filter-color="black"
            style={{
              backgroundSize: "cover",
              background: "url('/static/assets/img/farm.jpg') top center",
            }}
          >
            <div className="container">
              <div className="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
                {!activity ? (
                  <LoginForm error={error} handleSubmit={this.handleLogin} />
                ) : (
                  <SignupForm error={error} handleSubmit={this.handleSignup} />
                )}
              </div>
              <div
                id="offlinealert"
                className="alert alert-warning"
                style={{ visibility: "hidden" }}
              >
                <span>
                  {`You are not connected to the internet so you can't ${activity} `}
                </span>
              </div>
            </div>

            <footer className="footer">
              <div className="container">
                <nav className="float-left">
                  <ul>
                    <li>
                      <a href="/">SupplyBase</a>
                    </li>
                  </ul>
                </nav>
                <div className="copyright float-right">
                  &copy; {new Date().getFullYear()}, made with{" "}
                  <i className="material-icons">favorite</i> by {" "}
                  <a
                    href="http://www.crop2cash.com.ng/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Crop2Cash
                  </a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

Login.contextType = UserContext;

export default Login;
