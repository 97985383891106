import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { Link } from "react-router-dom";
class OpenMarketSuppliersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  componentDidMount() {
    document.title = "Market Supplier Details";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />
        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <Link
                className="btn btn-primary btn-round"
                to="/auditor_open_market_suppliers"
              >
                Go Back
              </Link>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">D-JAN-MAY-18</h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <div className="dropdown">
                            {/* <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Change Season
                            </button> */}
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownSeasonButton"
                            >
                              <h6 className="dropdown-header">Seasons</h6>
                              <a
                                className="dropdown-item"
                                href="/accounts/suppliers/51/?season=D-JAN-MAY-18"
                              >
                                D-JAN-MAY-18
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <p className="card-category">Amount Owed to Supplier</p>
                        <h3 className="card-title">Owed Nothing</h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <p>
                            This is the amount the company owes the supplier.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <p className="card-category">Amount Paid to Supplier</p>
                        <h3 className="card-title">₦1343342.00</h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <p>
                            This is the amount the company has paid to the
                            supplier.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">monetization_on</i>
                        </div>
                        <h4 className="card-title">Payment Vouchers</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-success btn-round"
                            data-toggle="modal"
                            data-target="#createVoucherModal"
                            onClick={this.handleModal}
                          >
                            Create Voucher
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="payment_vouchers_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="dt-buttons btn-group">
                                {" "}
                                <button
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  tabIndex={0}
                                  aria-controls="payment_vouchers"
                                  type="button"
                                >
                                  <span>Export PDF</span>
                                </button>{" "}
                                <button
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  tabIndex={0}
                                  aria-controls="payment_vouchers"
                                  type="button"
                                >
                                  <span>Export Excel</span>
                                </button>{" "}
                              </div>
                              <div
                                id="payment_vouchers_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      aria-controls="payment_vouchers"
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                id="payment_vouchers"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing={0}
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="payment_vouchers_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-sort="ascending"
                                      aria-label="Amount: activate to sort column descending"
                                      style={{ width: "120px" }}
                                    >
                                      Amount
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Created By: activate to sort column ascending"
                                      style={{ width: "143px" }}
                                    >
                                      Created By
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Date Created: activate to sort column ascending"
                                      style={{ width: "138px" }}
                                    >
                                      Date Created
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Paid By: activate to sort column ascending"
                                      style={{ width: "144px" }}
                                    >
                                      Paid By
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Date Paid: activate to sort column ascending"
                                      style={{ width: "120px" }}
                                    >
                                      Date Paid
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex={0}
                                      aria-controls="payment_vouchers"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "209px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan={1} colSpan={1}>
                                      Amount
                                    </th>
                                    <th rowSpan={1} colSpan={1}>
                                      Created By
                                    </th>
                                    <th rowSpan={1} colSpan={1}>
                                      Date Created
                                    </th>
                                    <th rowSpan={1} colSpan={1}>
                                      Paid By
                                    </th>
                                    <th rowSpan={1} colSpan={1}>
                                      Date Paid
                                    </th>
                                    <th
                                      className="text-right"
                                      rowSpan={1}
                                      colSpan={1}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  <tr role="row" className="odd">
                                    <td className="sorting_1">₦1343342.00</td>
                                    <td>Rasaq Abubakar</td>
                                    <td>Nov. 30, 2018</td>
                                    <td>Rasaq Abubakar</td>
                                    <td>Nov. 30, 2018</td>
                                    <td className="text-right inline-block">
                                      <form method="post" action=".">
                                        <input
                                          type="hidden"
                                          name="csrfmiddlewaretoken"
                                          defaultValue="bfEeBW4uve7WTsY3RWEfFbl0nRUzEECcTTUErvASjV8GFshFUHKnLxgJA3GelUX5"
                                        />
                                        <input
                                          type="hidden"
                                          name="paid_voucher"
                                          defaultValue={`1`}
                                        />
                                        <button
                                          type="submit"
                                          disabled
                                          className="btn btn-outline-primary btn-success"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Voucher Paid"
                                        >
                                          Voucher Paid
                                        </button>
                                      </form>
                                      <form method="post" action=".">
                                        <input
                                          type="hidden"
                                          name="csrfmiddlewaretoken"
                                          defaultValue="bfEeBW4uve7WTsY3RWEfFbl0nRUzEECcTTUErvASjV8GFshFUHKnLxgJA3GelUX5"
                                        />
                                        <input
                                          type="hidden"
                                          name="delete_voucher"
                                          defaultValue={`1`}
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Voucher"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </form>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                className="dataTables_info"
                                id="payment_vouchers_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 1 to 1 of 1 entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="payment_vouchers_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="payment_vouchers_previous"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="payment_vouchers"
                                      data-dt-idx={0}
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      href="#/"
                                      aria-controls="payment_vouchers"
                                      data-dt-idx={1}
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="payment_vouchers_next"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="payment_vouchers"
                                      data-dt-idx={2}
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Mill Deliveries</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          {/*        Here you can write extra buttons/actions for the toolbar              */}
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              title=" Shipments"
                              id="unaggregated"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing={0}
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Weighbridge No</th>
                                  <th>Vehicle No</th>
                                  <th>Gross Wt</th>
                                  <th>Tare Wt</th>
                                  <th>Net Wt</th>
                                  <th>Rejected Bags</th>
                                  <th>Price / Kg</th>
                                  <th>Cost</th>
                                  <th>Date Added</th>
                                  <th>Price Updated By</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Weighbridge No</th>
                                  <th>Vehicle No</th>
                                  <th>Gross Wt</th>
                                  <th>Tare Wt</th>
                                  <th>Net Wt</th>
                                  <th>Rejected Bags</th>
                                  <th>Price / Kg</th>
                                  <th>Cost</th>
                                  <th>Date Added</th>
                                  <th>Price Updated By</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                <tr>
                                  <td>022022</td>
                                  <td>Brk,man</td>
                                  <td>22022.0Kg</td>
                                  <td>11011.0Kg</td>
                                  <td>11011.0Kg</td>
                                  <td>2 Bags</td>
                                  <td>₦122.00/Kg</td>
                                  <td>₦1343342.0</td>
                                  <td>Nov. 28, 2018</td>
                                  <td>None</td>
                                  <td className="text-right inline-block">
                                    <Link
                                      to="/accountant_mill_deliveries_detail"
                                      className="btn btn-outline-primary btn-sm"
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card ">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">person</i>
                        </div>
                        <h4 className="card-title">Basic Information</h4>
                      </div>
                      <form method="post" action=".">
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          defaultValue="bfEeBW4uve7WTsY3RWEfFbl0nRUzEECcTTUErvASjV8GFshFUHKnLxgJA3GelUX5"
                        />
                        <div className="card-body ">
                          <div className="form-group">
                            <div className="dropdown bootstrap-select disabled">
                              <select
                                disabled
                                required
                                name="farmer_title"
                                className="selectpicker"
                                data-size={4}
                                data-style="btn btn-success"
                                title="Choose Title"
                                tabIndex={-98}
                                defaultValue={"Mr"}
                              >
                                <option className="bs-title-option" value />
                                <option disabled>Choose Title</option>
                                <option value="Mr.">Mr.</option>
                                <option value="Alh.">Alh.</option>
                                <option value="Mrs.">Mrs.</option>
                              </select>
                              <button
                                type="button"
                                className="dropdown-toggle disabled btn btn-success"
                                data-toggle="dropdown"
                                tabIndex={-1}
                                aria-disabled="true"
                                title="Mr."
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Mr.
                                    </div>
                                  </div>{" "}
                                </div>
                              </button>
                              <div className="dropdown-menu ">
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="false"
                                  tabIndex={-1}
                                >
                                  <ul className="dropdown-menu inner show" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="firstName"
                              className="bmd-label-floating"
                            >
                              First Name
                            </label>
                            <input
                              disabled
                              name="first_name"
                              type="text"
                              className="form-control"
                              id="firstName"
                              defaultValue="Buhari "
                            />
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="lastName"
                              className="bmd-label-floating"
                            >
                              Last Name
                            </label>
                            <input
                              disabled
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="lastName"
                              defaultValue="Atiku"
                            />
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="phoneNumber"
                              className="bmd-label-floating"
                            >
                              Phone Number
                            </label>
                            <input
                              disabled
                              name="phone_number"
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              defaultValue={`08022222222`}
                            />
                          </div>
                          <div className="form-group">
                            <div className="dropdown bootstrap-select disabled">
                              <select
                                disabled
                                required
                                name="gender"
                                className="selectpicker"
                                data-size={4}
                                data-style="btn btn-success"
                                title="Choose Gender"
                                tabIndex={-98}
                                defaultValue={"Male"}
                              >
                                <option className="bs-title-option" value />
                                <option disabled>Choose Gender</option>
                                <option value="Male">
                                  Male
                                </option>
                                <option value="Female">Female</option>
                              </select>
                              <button
                                type="button"
                                className="dropdown-toggle disabled btn btn-success"
                                data-toggle="dropdown"
                                tabIndex={-1}
                                aria-disabled="true"
                                title="Male"
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      Male
                                    </div>
                                  </div>{" "}
                                </div>
                              </button>
                              <div className="dropdown-menu">
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="false"
                                  tabIndex={-1}
                                >
                                  <ul className="dropdown-menu inner show" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="ward"
                              className="bmd-label-floating"
                            >
                              State
                            </label>
                            <input
                              disabled
                              name="ward"
                              type="text"
                              className="form-control"
                              id="ward"
                              defaultValue="Kebbi"
                            />
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label htmlFor="lga" className="bmd-label-floating">
                              Country
                            </label>
                            <input
                              disabled
                              name="lga"
                              type="text"
                              className="form-control"
                              id="lga"
                              defaultValue="Nigeria"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card ">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <h4 className="card-title">Account Information</h4>
                      </div>
                      <form method="post" action=".">
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          defaultValue="bfEeBW4uve7WTsY3RWEfFbl0nRUzEECcTTUErvASjV8GFshFUHKnLxgJA3GelUX5"
                        />
                        <div className="card-body ">
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="editaccount_name"
                              className="bmd-label-floating"
                            >
                              Bank Name
                            </label>
                            <input
                              disabled
                              name="account_name"
                              type="text"
                              className="form-control"
                              id="editaccount_name"
                              defaultValue="Wema Bank Plc"
                            />
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="editaccount_number"
                              className="bmd-label-floating"
                            >
                              Account Number
                            </label>
                            <input
                              disabled
                              name="account_number"
                              type="text"
                              className="form-control"
                              id="editaccount_number"
                              defaultValue={`022022022022`}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Create Manager Modal */}
                <div
                  className="modal fade"
                  id="createVoucherModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">
                          Create New Payment Voucher
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <form method="post" action=".">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                defaultValue="bfEeBW4uve7WTsY3RWEfFbl0nRUzEECcTTUErvASjV8GFshFUHKnLxgJA3GelUX5"
                              />
                              <div className="form-group bmd-form-group is-filled">
                                <label
                                  htmlFor="inputName"
                                  className="bmd-label-floating"
                                >
                                  Enter an amount
                                </label>
                                <input
                                  required
                                  name="amount"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="inputName"
                                  defaultValue={`0.0`}
                                />
                              </div>
                              <input
                                type="hidden"
                                name="create_voucher"
                                defaultValue={`1`}
                              />
                              <button
                                type="submit"
                                className="btn btn-fill btn-success"
                              >
                                Create
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  End Modal */}
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                © 2019 , made with <i className="material-icons">favorite</i> by
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default OpenMarketSuppliersDetail;
