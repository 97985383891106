import { fetchRequest } from "../utils";

export const create_payment_voucher = (payment_voucher) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_voucher/`,
    method: "POST",
    data: payment_voucher,
  });

export const list_payment_voucher = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_voucher/`,
  });

export const read_payment_voucher = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_voucher/` +
      params.payment_voucherId,
  });

export const update_payment_voucher = (payment_voucher) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_voucher/`,
    method: "PUT",
    data: payment_voucher,
  });

export const remove_payment_voucher = (payment_voucher) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_voucher/`,
    method: "DELETE",
    data: payment_voucher,
  });

export const create_payment_voucher_live = (payment_voucher) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_voucher/`,
    method: "POST",
    data: payment_voucher,
  });

export const read_payment_voucher_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_voucher/` +
      params.shipmentId,
  });

export const list_payment_voucher_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_voucher/`,
  });

export const update_payment_voucher_live = (payment_voucher, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_voucher/` +
      params.payment_voucherId,
    method: "PUT",
    data: payment_voucher,
  });

export const remove_payment_voucher_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_voucher/` +
      params.payment_voucherId,
    method: "DELETE",
  });

// export const create_deleted_payment_voucher_live = (payment_voucher) =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_payment_voucher/`,
//     method: "POST",
//     data: payment_voucher,
//   });

// export const list_deleted_payment_voucher_live = () =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_payment_voucher/`,
//   });
