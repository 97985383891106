import React, { Component } from 'react';
import SideBar from './SideBar';
import Nav from './Nav';
import { Link, Redirect } from 'react-router-dom';
import auth from '../../api/auth-helper';
import {
  list_shipment_live,
} from '../../api/api-shipment';
import {
  list_recovery_center_cooperative_live,
  update_recovery_center_cooperative_shipment
} from '../../api/api-recovery_center_cooperative';
import {create_recovery_shipment_live} from '../../api/api-recovery_shipment';
import Moment from "react-moment";
class ShipmentRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disapprove_modal: false,
      reason: '',
      error: '',
      status: '',
      recoveries: [],
      shipment: {},
      recovery_shipment: [],
      cooperativeId: '',
      redirectToShipment: false,
      recovery_modal: false,
      update_modal: false,
      recovery_ids: []
    };
    this.handleListShipment();
    this.handleListRecoveries();
  }

  componentDidMount(){
    document.title = "Shipment Recovery"
    console.log(this.props.location.state)
  }


  handleListRecoveries = () => {
    list_recovery_center_cooperative_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let recovery = data.filter(
          recovery =>
            recovery.status ===  "pending" || !recovery.status
        );
        console.log(recovery)
        this.setState({
          recoveries: recovery
        });
      }
    });
  };

  handleListShipment = () => {
    list_shipment_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let shipment = data.filter(
          shipment => shipment._id === this.props.location.state.shipmentId
        );
        this.setState({
          status: shipment[0].status,
          shipment: shipment[0],
          cooperativeId: shipment[0].cooperativeId
        });
      }
    });
  };

  submitModal = () => {
    this.setState(prevState => ({ update_modal: !prevState.update_modal }));
  };

  handleCheckBox = (event, Id) => {
    if (event.target.checked) {
      //append to array
      console.log(this.state.recovery_shipment)
      this.setState({
        recovery_ids: this.state.recovery_ids.concat([Id]),
      });
    } else {
      //remove from array
      this.setState({
        recovery_ids: this.state.recovery_ids.filter(function(val) {
          return val !== Id;
        })
      });
    }
  };

  updateRecoveryShipment = () => {
    const jwt = auth.isAuthenticated();
        const recovery_shipment = {
          shipment_id: this.props.location.state.shipmentId,
          recovery: this.state.recovery_shipment,
          createdBy:
            auth.isAuthenticated().user.first_name +
        ' ' +
          auth.isAuthenticated().user.last_name,
    }
    create_recovery_shipment_live(recovery_shipment, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.submitModal();
    this.setState({ redirectToShipment: true });
  }

  updateRecovery = () => {
    const jwt = auth.isAuthenticated();
    this.state.recovery_ids.map(id => {
      const recovery= {
      updatedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      status: 'recovered',
    };
    update_recovery_center_cooperative_shipment(recovery,{ recovery_id: id },{
      t: jwt.token,

    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.submitModal();
    })
    this.updateRecoveryShipment()
  };

  render() {
    if (this.state.redirectToShipment) {
      return (
        <Redirect
          to={{
            pathname: '/shipment_options',
            state: {
              direct: 'shipment',
              refresh: true,
              shipmentId: this.props.location.state.shipmentId,
              cooperativeId: this.props.location.state.cooperativeId
            }
          }}
        />
      );
    }
    return (
      <div className='wrapper '>
        <SideBar />

        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='container-fluid'>
              {this.props.location.state.direct === 'shipment' ? (
                <Link
                  className='btn btn-primary btn-round'
                  to={{
                    pathname: '/shipment_options',
                    state: {
                      direct: 'shipment',
                      shipmentId: this.props.location.state.shipmentId,
                      cooperativeId: this.props.location.state.cooperativeId
                    }
                  }}
                >
                  Go Back
                </Link>
              ) : this.props.location.state.direct === 'cooperative' ? (
                <Link
                  className='btn btn-primary btn-round'
                  to={{
                    pathname: '/cooperative_details',
                    state: {
                      direct: 'cooperative',
                      shipmentId: this.props.location.state.shipmentId,
                      cooperativeId: this.props.location.state.cooperativeId,
                      name: this.props.location.state.name,
                      type: this.props.location.state.type,
                      start_month: this.props.location.state.start_month,
                      end_month: this.props.location.state.end_month,
                      year: this.props.location.state.year
                    }
                  }}
                >
                  Go Back
                </Link>
              ) : (
                <Link
                  className='btn btn-primary btn-round'
                  to={{
                    pathname: '/shipment_options',
                    state: {
                      direct: 'cooperative',
                      shipmentId: this.props.location.state.shipmentId,
                      cooperativeId: this.props.location.state.cooperativeId,
                      name: this.props.location.state.name,
                      type: this.props.location.state.type,
                      start_month: this.props.location.state.start_month,
                      end_month: this.props.location.state.end_month,
                      year: this.props.location.state.year
                    }
                  }}
                >
                  Go Back
                </Link>
              )}

              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header card-header-primary card-header-icon'>
                      <div className='card-icon'>
                        <i className='material-icons'>ballot</i>
                      </div>
                      <h4 className='card-title'>Recovery</h4>
                      <h6 className='card-title'>
                        Select aggregations and click Apply below
                      </h6>
                    </div>
                    <div className='card-body'>
                      <div>
                        <input
                          type='hidden'
                          name='aggregate_shipment'
                          value='1'
                        />
                        <div className='toolbar'>
                          <button
                            onClick={this.submitModal}
                            className='btn btn-primary btn-round'
                            type='submit'
                          >
                            Apply
                          </button>
                        </div>
                        <h4 style={{ color: 'red', alignSelf: 'center' }}>
                          {this.state.error}
                        </h4>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='pending_aggregations'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Farmer</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>Cost</th>
                                  <th>Recovery Centre</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Action</th>
                                  <th>Farmer</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>Cost</th>
                                  <th>Recovery Centre</th>
                                  <th>Date</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.recoveries.map(data => (
                                  <tr role='row' className='odd'>
                                    <td className='sorting_1'>
                                      <div className='form-check'>
                                        <label className='form-check-label'>
                                          <input
                                            name={data._id}
                                            className='form-check-input'
                                            type='checkbox'
                                            value={data._id}
                                            onChange={(event) =>{
                                              this.handleCheckBox(
                                                event,
                                                data._id
                                              )
                                              this.state.recovery_shipment.push(event.target.value)
                                              console.log(this.state.recovery_shipment)
                                            }
                                            }
                                          />
                                          <span className='form-check-sign'>
                                            <span className='check'></span>
                                          </span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>{data.farmer}</td>
                                    <td>{data.num_of_bags}</td>
                                    <td>{data.total_weight}Kg</td>
                                    <td>₦{data.total_cost}</td>
                                    <td>{data.recovery_center}</td>
                                    
                                    <td> <Moment format="D MMM YYYY" withTitle>{data.created}</Moment></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.update_modal ? (
                ''
              ) : (
                <div
                  className='modal fade show'
                  id='addCooperativeModal'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='myModalLabel'
                  style={{
                    display: 'block',
                    paddingRight: '5px',
                    paddingTop: '100px'
                  }}
                >
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 className='modal-title'>Recover Recovery?</h4>
                        <button
                          onClick={this.submitModal}
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-hidden='true'
                        >
                          <i className='material-icons'>clear</i>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='card '>
                          {this.state.recovery_ids.length !== 0 ? (
                            <div className='card-body '>
                              <div>Are you sure you want to recover the selected aggregations?</div>

                              <button
                                type='submit'
                                onClick={() => this.updateRecovery()}
                                className='btn btn-fill btn-success'
                              >
                                Recover
                              </button>
                              <button
                                type='submit'
                                onClick={this.submitModal}
                                className='btn btn-fill btn-danger'
                              >
                                Cancel
                              </button>

                              <button
                                type='submit'
                                className='btn btn-fill btn-primary'
                                id='create_offline'
                                style={{ display: 'none' }}
                              >
                                Create Offline
                              </button>
                            </div>
                          ) : (
                            <div className='card-body '>
                              <div>No recovery to Recover.</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShipmentRecovery;
