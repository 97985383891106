import React, { useState } from "react";
import NaijaStates from "naija-state-local-government";

const SignupForm = ({ error, handleSubmit }) => {
  const initialState = {
    email: "",
    country: "Nigeria",
    password: "",
    last_name: "",
    first_name: "",
    subscription: 0,
    phone_number: "",
    state: "",
    organization_name: "",
  };

  const [state, setState] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state)
  };

  const handleStateModal = () => {
    if(modal=== false)
    setModal(true)
    else setModal(false)
  };


  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit(state);
    console.log(state)
  };

  return (
    <form onSubmit={submitForm}>
      <div className="card card-login">
        <div className="card-header card-header-success text-center">
          <h4 className="card-title">Sign Up</h4>
          <p style={{ color: "red" }}>{error}</p>
        </div>
        <div className="card-body ">
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">account_circle</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.first_name}
                name="first_name"
                type="text"
                className="form-control"
                placeholder="First Name"
              />
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">account_circle</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.last_name}
                name="last_name"
                type="text"
                className="form-control"
                placeholder="Last Name"
              />
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">email</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.email}
                name="email"
                type="email"
                className="form-control"
                placeholder="Email"
              />
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">business</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.organization_name}
                name="organization_name"
                type="text"
                className="form-control"
                placeholder="Name of Organization"
              />
            </div>
          </span>
          {/* <span className='bmd-form-group'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>
                  <i className='material-icons'>business</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.fcmb_account_number}
                name='fcmb_account_number'
                type='text'
                className='form-control'
                placeholder='Organization FCMB account number'
              />
            </div>
          </span> */}
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">local_phone</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.phone_number}
                name="phone_number"
                type="text"
                className="form-control"
                placeholder="Phone Number"
                required
              />
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">my_location</i>
                </span>
              </div>
              <input
                className="form-control"
                name="country"
                disabled
                defaultValue={state.country}
              >
              </input>
            </div>
          </span>
          <span className="bmd-form-group">
            <div
             className={
              !modal
               ? "dropdown bootstrap-select" 
               : "dropdown bootstrap-select show"
              }
            >
              <button                                        
              type="button"                                      
              className="dropdown-toggle btn  btn-success"                                      
              data-toggle="dropdown"                                    
              aria-expanded="true"
              style={{left: "18px",top:'14px', width: "287px"}}              
              onClick={() =>
                handleStateModal()}
              >
                <div className="filter-option">
                  <div className="filter-option-inner">
                    <div className="filter-option-inner-inner">
                      {!state.state ? "Choose State" : state.state}
                    </div>
                  </div>{" "}
                </div>
              </button>
              <div
                id="states"
                className={
                  !modal ? 
                  "dropdown-menu" : 
                  "dropdown-menu show"
                  }
                  role="combobox" 
                  aria-expanded="true"                    
                  x-placement="bottom-start"
                  style={{
                    maxHeight: "182px",
                    overflow: "hidden",
                    minWidth: "320px",
                    position: "absolute",
                    top: "46px",
                    left: "1px",
                    willChange: "top, left",
                    }}
               >
              <div
              className="inner show"
              role="listbox"
              aria-expanded="true"
              tabIndex="-1"
              style={{
                maxHeight: "172px",
                overflowY: "auto",
              }}
              >
                <ul className="dropdown-menu inner show">
                  <li className="disabled">
                    <a
                      role="option"
                      className="dropdown-item disabled"
                      aria-disabled="true"
                      tabIndex="-1"
                      aria-selected="false"
                    >
                      <span className=" bs-ok-default check-mark" />
                      <span className="text">
                        {state.state || "Choose State"}
                      </span>
                      </a>
                  </li>
                    {NaijaStates.states().map((State, id) => (
                      <li key={id}>
                        <a
                          role="option"
                          className={
                            state.state === "" ? "dropdown-item"
                            : "dropdown-item selected active"}
                          aria-disabled="false"
                          tabIndex="0"
                          aria-selected="false"
                          onClick={() => { handleStateModal();
                            console.log(State)
                          setState({...state, state: State});
                          }}
                        >
                          <span className=" bs-ok-default check-mark" />
                          <span className="text">{State}</span>
                        </a>
                      </li>
                      )
                    )}
                </ul>
              </div>
              </div>
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">group_work</i>
                </span>
              </div>
              <select
                className="form-control"
                name="subscription"
                onChange={handleChange}
                value={state.subscription}
              >
                <option disabled value={0}>
                  Subscription Plan
                </option>
                <option value={5}>Basic - 5 Users</option>
                <option value={10}>Standard - 10 Users</option>
                <option value={15}>Premium - 15 Users</option>
              </select>
            </div>
          </span>
          <span className="bmd-form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">lock_outline</i>
                </span>
              </div>
              <input
                onChange={handleChange}
                value={state.password}
                name="password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
              />
              <i
                className="material-icons"
                style={{
                  right: 0,
                  top: "1.5rem",
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "visibility_off" : "visibility"}
              </i>
            </div>
          </span>
        </div>
        <div className="card-footer justify-content-center">
          <button type="submit" className="btn btn-success btn-lg">
            Sign Up
          </button>
        </div>
      </div>
    </form>
  );
};

export default SignupForm;
