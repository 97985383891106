import React, { Component } from 'react';
import SideBar from './SideBar';
import Nav from './Nav';
import { Link} from 'react-router-dom';
import auth from '../../api/auth-helper';
import {update_payment_schedule_live, list_payment_schedule_live} from "../../api/api-payment_schedule"
import {list_payment_voucher_live, update_payment_voucher_live} from "../../api/api-payment_voucher"
import Moment from "react-moment";
class PaymentScheduleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disapprove_modal: false,
      delete_modal: false,
      reason: '',
      error: '',
      status: '',
      shipment: {},
      redirectToShipment: false,
      vouchers: [],
      voucherId: '',
      disable_disapproved: false,
      disable_approved: false,
      auditor_status: ""
    };
    this.handleListVouchers()
  }

  componentDidMount(){
    document.title = "Payment Schedule Details"
    console.log(this.props.location.state.payment_scheduleId)
  }

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };


  
  UpdateDeletedVoucher = () => {
     const jwt = auth.isAuthenticated();
      const payment_voucher= { 
      auditor_status: '',
      }
    update_payment_voucher_live(payment_voucher,{payment_voucherId: this.state.voucherId },{
      t: jwt.token,
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
      }
    });
    this.handleListVouchers()
  }

  deleteData = () => {
    const jwt = auth.isAuthenticated();
    let [vouchers] = this.state.vouchers
    vouchers = vouchers.filter((voucher)=>{
        return voucher._id !== this.state.voucherId
    })
    const payment_schedule = {
        voucher: vouchers
    }
    update_payment_schedule_live(payment_schedule,{
      payment_scheduleId: this.props.location.state.payment_scheduleId},{
        t: jwt.token,
      }
    ).then((data)=> {
      if(!data){
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Vouchers Could Not Delete",
          delete_modal: false
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
      else if (data){
        this.setState({
          loading: false,
          success_notification: true,
          mssg: "Voucher deleted successfully",
          delete_modal: false
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        
        this.UpdateDeletedVoucher()
      }
    })
  }

  handleListVouchers = () => {
    list_payment_schedule_live({}).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let vouchers = []
        let payment_schedule = data.filter(
          payment_schedule =>
            payment_schedule._id === this.props.location.state.payment_scheduleId
        );
        console.log(payment_schedule[0].accountant_status)
        if(payment_schedule[0].auditor_status){
          this.setState({
            auditor_status: payment_schedule[0].auditor_status
          })
        }else{
          this.setState({
            auditor_status: ""
          })
        }
        console.log(payment_schedule)
        payment_schedule.map(payment_schedule =>{
         return vouchers.push(payment_schedule.voucher)
        })
        console.log(vouchers)
        this.setState({ vouchers: vouchers});
        console.log(this.state.vouchers)
      }
    });
  };


  updateDisapprove = () => {
    const jwt = auth.isAuthenticated();
    const payment_schedule = {
      
      disapprovedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      auditor_status: 'disapproved',
      
    };
    update_payment_schedule_live(payment_schedule, {payment_scheduleId: this.props.location.state.payment_scheduleId}, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        return;
      } else {
        this.setState({disable_approved: true})
        this.setState({ disapprove_modal: false });
      }
    });
  };

  updateApprove = () => {
    const jwt = auth.isAuthenticated();
    const payment_schedule = {
      
      approvedBy:
        auth.isAuthenticated().user.first_name +
        ' ' +
        auth.isAuthenticated().user.last_name,
      auditor_status: 'approved',
    };
    update_payment_schedule_live(payment_schedule,{payment_scheduleId: this.props.location.state.payment_scheduleId}, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.setState({disable_disapproved: true})
      }
    });
  };

  render() {
    if (this.props.location.state === undefined) {
      return;
    } else {
      // this.handleListVouchers();
    }
    return (
      <div className='wrapper '>
        <SideBar />

        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='content'>
              <div className='container-fluid'>
                {this.props.location.state.direct === 'payment_schedule' ? (
                  <Link className='btn btn-primary btn-round' to='/payment_schedule'>
                    Go Back
                  </Link>
                ) : (
                  <Link
                    className='btn btn-primary btn-round'
                    to={{
                      pathname: '/payment_schedule',
                      state: {
                        cooperativeId: this.props.location.state.cooperativeId,
                        name: this.props.location.state.name,
                        type: this.props.location.state.type,
                        start_month: this.props.location.state.start_month,
                        end_month: this.props.location.state.end_month,
                        year: this.props.location.state.year
                      }
                    }}
                  >
                    Go Back
                  </Link>
                )}

                <div className='row'>
                  <div className='col-md-3'>
                    <div>
                      <input
                        type='hidden'
                        name='csrfmiddlewaretoken'
                        value='Ve6DAzlPoDRHe6cz7rYShGw1NnbnXycVc5ljdATbPlyrbzqV4J7qdRnVlRyi559w'
                      />
                      <input type='hidden' name='approve' value='1' />
                      {this.state.auditor_status === 'approved' || this.state.auditor_status === 'disapproved' ? (
                        <div>
                          <button
                            type='submit'
                            className='btn btn-fill btn-primary'
                            onClick={this.updateApprove}
                          >
                            {' '}
                            Approve
                          </button>
                          
                        </div>
                      ) : (
                        <button
                            type='submit'
                            className='btn btn-fill btn-primary'
                            
                            disabled
                          >
                            {' '}
                            Approve
                          </button>
                      )}
                    </div>
                  </div>
                  <div className='col-md-3'>
                    {this.state.auditor_status === 'approved' || this.state.auditor_status === 'disapproved'  ? (
                      <div>
                        <button
                        data-toggle='modal'
                        data-target='#disapproveModal'
                        className='btn btn-fill btn-primary'
                        onClick={this.updateDisapprove}
                      >
                        Disapprove
                      </button>
                      </div>
                    ) : (
                        <button
                        data-toggle='modal'
                        data-target='#disapproveModal'
                        className='btn btn-fill btn-primary'
                        
                        disabled
                      >
                        Disapprove
                      </button>
                    )}

                    <p />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Vouchers</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    {/* <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          placeholder=''
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label> */}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                <table
                              id='pending_aggregations'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                              <tr role='row'>                                 
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Farmer: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Farmer
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Number of Bags: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Amount
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Weight: activate to sort column ascending'
                                          style={{ width: '59px' }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Paid By
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Aggregation Centre: activate to sort column ascending'
                                          style={{ width: '163px' }}
                                        >
                                          Voucher Number
                                        </th>
                                        
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='sorting'
                                          tabIndex='0'
                                          aria-controls='aggregations'
                                          rowSpan='1'
                                          colSpan='1'
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '41px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                              </thead>
                              <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Farmer
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Amount
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Paid By
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Voucher Number
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                        <th rowSpan='1' colSpan='1'>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                              <tbody>
                              {this.state.vouchers.map((data) =>{
                                   return data.map((voucher) =>{
                                       return <tr role='row' className='odd'>
                                       <td>{voucher.recovery.farmer}</td>
                                       <td>₦{voucher.amount}</td>
                                       <td>{voucher.status}</td>
                                       <td>{voucher.updatedBy}</td>
                                       <td>{voucher.voucher_no}</td>
                                       <td>
                                     <Moment format="D MMM YYYY" withTitle>
                                       {voucher.created}
                                     </Moment>
                                    </td>
                                    <td>
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            onClick={()=>{
                                              this.setState({
                                                voucherId: voucher._id,
                                                delete_modal: true,
                                              })
                                            }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Voucher"
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div></td>
                                 </tr> 
                                   })                           
  })}
                              </tbody>
                            </table>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          
                                          aria-controls='aggregations'
                                          data-dt-idx='0'
                                          tabIndex='0'
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          
                                          aria-controls='aggregations'
                                          data-dt-idx='1'
                                          tabIndex='0'
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Voucher?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Voucher
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.deleteData()}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this Voucher.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              {!this.state.disapprove_modal ? (
                ''
              ) : (
                <div
                  className='modal fade show'
                  id='disapproveModal'
                  tabIndex='-1'
                  role='dialog'
                  aria-labelledby='myModalLabel'
                  style={{ paddingRight: '15px', display: 'block' }}
                >
                  <div className='modal-dialog'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h4 className='modal-title'>Disapprove Payment Schedule</h4>
                        <h4 className='modal-notice'>
                          Weighbridge Manager will be emailed with the details
                          of this disapproval.
                        </h4>
                        <button
                          type='button'
                          className='close'
                          onClick={() =>
                            this.setState({
                              disapprove_modal: false,
                              error: ''
                            })
                          }
                          data-dismiss='modal'
                          aria-hidden='true'
                        >
                          <i className='material-icons'>clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: 'red', alignSelf: 'center' }}>
                        {this.state.error}
                      </h4>
                      <div className='modal-body'>
                        <div className='card '>
                          <div className='card-body '>
                            <div>
                              <input
                                type='hidden'
                                name='csrfmiddlewaretoken'
                                value='VKuJeFbn6Jn2zh0HFWgh4Rvmm0TulzQA443hZ0YF1VUeeNhzEI7LSiF2GSLwsSqx'
                              />
                              <div
                                className={
                                  this.state.reason
                                    ? 'is-filled form-group bmd-form-group'
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  for='inputName'
                                  className='bmd-label-floating'
                                >
                                  Why are you disapproving this Payment Schedule?
                                </label>
                                <input
                                  onChange={event =>
                                    this.setState({
                                      reason: event.target.value,
                                      error: ''
                                    })
                                  }
                                  required='true'
                                  name='note'
                                  type='text'
                                  className='form-control'
                                  id='inputName'
                                />
                              </div>
                              <input
                                type='hidden'
                                name='disapprove'
                                value='1'
                              />
                              <button
                                type='submit'
                                onClick={this.updateDisapprove}
                                className='btn btn-fill btn-danger'
                              >
                                Disapprove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentScheduleDetails;
