import { fetchRequest } from "../utils";

export const create_farmer = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer/`,
    method: "POST",
    data: farmer,
  });

export const list_farmer = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer/` });

export const read_farmer = (params) => {
  console.log("bug");
  console.log(params);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer/` + params.farmerId,
  });
};

export const update_farmer = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer/`,
    method: "PUT",
    data: farmer,
  });

export const update_lead_farmer = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_lead/`,
    method: "PUT",
    data: farmer,
  });

export const update_season_deposit = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/season_deposit`,
    method: "PUT",
    data: farmer,
  });

export const remove_farmer = (farmer) => {
  console.log(farmer);
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer/`,
    method: "DELETE",
    data: farmer,
  });
};

export const create_farmer_live = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/`,
    method: "POST",
    data: farmer,
  });

export const list_farmer_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/`,
  });

export const read_farmer_live = (params) => {
  console.log(params);
  return fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/` + params.farmerId,
  });
};

export const update_farmer_live = (farmer, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/` + params.farmerId,
    method: "PUT",
    data: farmer,
  });

export const remove_farmer_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer/` + params.farmerId,
    method: "DELETE",
  });

export const create_deleted_farmer_live = (farmer) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer/`,
    method: "POST",
    data: farmer,
  });

export const list_deleted_farmer_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_farmer/`,
  });
