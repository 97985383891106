import React from "react";
import moment from "moment";
import { capitalize } from "../../../utils";

const SingleSeason = ({
  type,
  year,
  created,
  end_month,
  handleClick,
  start_month,
}) => (
  <tr>
    <td>{capitalize(type)}</td>
    <td>{start_month}</td>
    <td>{end_month}</td>
    <td>{year}</td>
    <td>{moment(created).format("Do MMMM YYYY")}</td>

    <td className="text-right inline-block">
      <div>
        <input type="hidden" name="delete_season" value="{{ season.pk }}" />
        <button
          type="submit"
          onClick={handleClick}
          className="btn btn-link btn-just-icon btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Season"
        >
          <i className="material-icons">clear</i>
        </button>
      </div>
    </td>
  </tr>
);

export default SingleSeason;
