import React from "react";
import GoogleMapReact from "google-map-react";
import { read_farm_live } from "../../api/api-farm";
import Loader from "react-loader-spinner";
import Marker from "./Marker";

class GMapReact extends React.Component {
  render() {
    const { center, zoom } = this.props;
    console.log(center);
    const lat = center[0].latitude;
    const lng = center[0].longitude;

    return (
      <div style={{ height: "25vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY }}
          center={{ lat: lat, lng: lng }}
          zoom={zoom}
        >
          {center.map((cen, id) => {
            console.log(cen);
            return (
              <Marker
                key={id}
                lat={cen.latitude}
                lng={cen.longitude}
                name="Famrer's Farm"
                color="red"
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

class Farm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [
        {
          lat: 7.416135017694768,
          lng: 3.933130614459515,
        },
      ],
      loading: false,
    };
    this.handleFarm = this.handleFarm.bind(this);
  }

  componentDidMount() {
    this.handleFarm();
  }

  handleFarm = () => {
    this.setState({
      loading: true,
    });
    read_farm_live({ farmerId: this.props.farmer_id }).then((data) => {
      if (!data) {
        this.setState({
          loading: false,
          center: false,
        });
      } else if (data.error) {
        this.setState({
          loading: false,
          center: false,
        });
      } else if (data) {
        const coordinates = data.farm.coordinates;
        let coordinatesArr = [];
        coordinates.forEach((coordinate) =>
          coordinatesArr.push(JSON.parse(coordinate))
        );
        console.log(coordinatesArr);
        this.setState({
          center: coordinatesArr,
          loading: false,
        });
      }
      console.log(this.state.center);
    });
  };

  render() {
    const center = this.state.center;
    console.log(center);
    return this.state.loading ? (
      <Loader
        style={{ marginTop: "5vh", textAlign: "center" }}
        type="Oval"
        color="green"
        height={50}
        width={50}
      />
    ) : this.state.center ? (
      <div style={{ height: "25vh", width: "100%" }}>
        <GMapReact center={center} zoom={11} />
      </div>
    ) : (
      "This Farmer doesn't have a farm yet"
    );
  }
}

export default Farm;
