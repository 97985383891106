import { fetchRequest } from '../utils';

export const create_farminputinventory = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_inventory/`,
    method: 'POST',
    data: farmInputInventory
  });

export const list_farminputinventory = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_inventory/`
  });

export const read_farminputinventory = ({ farmInputInventoryId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_inventory/` +
      farmInputInventoryId
  });

export const update_farminputinventory = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_inventory/`,
    method: 'PUT',
    data: farmInputInventory
  });

export const create_update_farminputinventory = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/farm_input_inventory/`,
    method: 'PUT',
    data: farmInputInventory
  });

export const remove_all_farminput = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/farm_input_inventory`,
    method: 'DELETE',
    data: farmInputInventory
  });

export const remove_farminputinventory = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_inventory/`,
    method: 'DELETE',
    data: farmInputInventory
  });

export const create_farminputinventory_live = farmInputInventory =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER}/api/farm_input_inventory/`,
    method: 'POST',
    data: farmInputInventory
  });

export const list_farminputinventory_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER}/api/farm_input_inventory/`
  });

export const remove_farminputinventory_live = ({ farmInputInventoryId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER}/api/farm_input_inventory/` +
      farmInputInventoryId,
    method: 'DELETE'
  });

export const update_farminput_inventory_live = (
  farmInputInventory,
  { farmInputInventoryId }
) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER}/api/farm_input_inventory/` +
      farmInputInventoryId,
    method: 'PUT',
    data: farmInputInventory
  });

export const read_farminputinventory_live = ({ farmInputInventoryId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER}/api/farm_input_inventory/` +
      farmInputInventoryId
  });

export const remove_all_farminput_live = ({ farmInputInventoryId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER}/api/update/farm_input_inventory` +
      farmInputInventoryId,
    method: 'DELETE'
  });
