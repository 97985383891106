import { fetchRequest } from "../utils";

export const create_farminputprice = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_price/`,
    method: "POST",
    data: farmInputPrice,
  });

export const list_farminputprice = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_price/`,
  });

export const read_farminputprice = ({ farmInputPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_price/` +
      farmInputPriceId,
  });

export const update_farminputprice = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_price/`,
    method: "PUT",
    data: farmInputPrice,
  });

export const create_update_farminputprice = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/farm_input_price/`,
    method: "PUT",
    data: farmInputPrice,
  });

export const remove_all_farminput = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/update/farm_input_price`,
    method: "DELETE",
    data: farmInputPrice,
  });

export const remove_farminputprice = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farm_input_price/`,
    method: "DELETE",
    data: farmInputPrice,
  });

export const create_farminputprice_live = (farmInputPrice) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_input_price/`,
    method: "POST",
    data: farmInputPrice,
  });

export const list_farminputprice_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_input_price/`,
  });

export const remove_farminputprice_live = ({ farmInputPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_input_price/` +
      farmInputPriceId,
    method: "DELETE",
  });

export const update_farminput_price_live = (
  farmInputPrice,
  { farmInputPriceId }
) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_input_price/` +
      farmInputPriceId,
    method: "PUT",
    data: farmInputPrice,
  });

export const read_farminputprice_live = ({ farmInputPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farm_input_price/` +
      farmInputPriceId,
  });

export const remove_all_farminput_live = ({ farmInputPriceId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/update/farm_input_price` +
      farmInputPriceId,
    method: "DELETE",
  });
