import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

import Nav from "./Nav";
import SideBar from "./SideBar";

import auth from "../../api/auth-helper";
import { create_farmer_live } from "../../api/api-farmer";
import { list_crop_live } from "../../api/api-crop";
import { create_farmer_guarantor_live } from "../../api/api-farmer_guarantor";
import {create_farmer_next_of_kin_live} from "../../api/api-farmer_next_of_kin"
import NaijaStates from "naija-state-local-government";

class AdminAddFarmer extends Component {
  state = {
    error_notification: false,
    mssg: "",
    farmerCreated: false,
    titlemodal: false,
    maritalstatusmodal: false,
    gendermodal: false,
    bankmodal: false,
    nexttitlemodal: false,
    tab1: true,
    tab2: false,
    tab3: false,
    gender_error: "",
    first_name_error: "",
    last_name_error: "",
    marital_error: "",
    Id_number_error: "",
    phone_number_error: "",
    community_error: "",
    state_error: "",
    LGA_error: "",
    farm_size_error: "",
    gender: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    Id_number: "",
    phone_number: "",
    marital_status: "",
    address: "",
    state: "",
    LGA: "",
    farm_size: "",
    bank: "",
    account_number: "",
    next_title_error: "",
    next_first_name_error: "",
    next_last_name_error: "",
    next_phone_number_error: "",
    next_address_error: "",
    guarantor_first_name_error: "",
    guarantor_last_name_error: "",
    guarantor_phone_number_error: "",
    guarantor_address_error: "",
    next_title: "",
    next_first_name: "",
    next_last_name: "",
    next_phone_number: "",
    next_address: "",
    nextPhoneNumber: "",
    guarantor_first_name: "",
    guarantor_last_name: "",
    guarantor_phone_number: "",
    guarantorPhone: "",
    guarantor_address: "",
    modals: {
      day: false,
      month: false,
      year: false,
      crop: false,
      LGA: false,
      state: false,
    },
    day: "",
    month: "",
    year: "",
    crops: [],
    crop: [],
    selected_crops: [],
    crop_id: "",
    cropmodal: false,
    crop_error: "",
    cooperative_name: "",
    farmer: []
  };

  componentDidMount() {
    this.handleList();
    document.title = "Add Farmer";
  }

  handleGenderModal = () => {
    this.setState((prevState) => ({
      gendermodal: !prevState.gendermodal,
    }));
  };

  handleCropModal = () => {
    this.setState((prevState) => ({
      cropmodal: !prevState.cropmodal,
    }));
  };

  handleBankModal = () => {
    this.setState((prevState) => ({
      bankmodal: !prevState.bankmodal,
    }));
  };

  handleNexttitleModal = () => {
    this.setState((prevState) => ({
      nexttitlemodal: !prevState.nexttitlemodal,
    }));
  };

  handleMaritalStatusModal = () => {
    this.setState((prevState) => ({
      maritalstatusmodal: !prevState.maritalstatusmodal,
    }));
  };

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleList = () => {
    if (navigator.onLine) {
      list_crop_live().then((data) => {
        if (!data) {
          this.setState({
            error_notification: true,
            mssg: "There seems to be a problem, reload this page or login out",
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length === 0) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
            crops: [],
          });
        } else if (data.error) {
          this.setState({
            mssg: data.error,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ crops: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  availableYears = () => {
    let years = [];
    const thisYear = new Date().getFullYear();
    for (let i = 80; i >= 10; i--) {
      let year = thisYear - i;
      years.push(year);
    }
    return years;
  };

  availableMonths = () => {
    let months = [
      "Choose Month",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months;
  };

  availableDays = () => {
    let days = [],
      m = this.state.month,
      d;
    if (m === 3 || m === 5 || m === 8 || m === 10) d = 30;
    else if (m === 1) this.isLeapYear(this.state.year) ? (d = 29) : (d = 28);
    else d = 31;
    for (let i = 1; i <= d; i++) days.push(i);
    return days;
  };

  isLeapYear = (year) => {
    return !((year % 4 && year % 100) || !(year % 400));
  };

  checkForm1 = () => {
    if (!this.state.gender) {
      this.setState({
        gender_error: "has-danger",
      });
    }

    if (!this.state.first_name) {
      this.setState({
        first_name_error: "has-danger",
      });
    }

    if (!this.state.last_name) {
      this.setState({
        last_name_error: "has-danger",
      });
    }

    if (!this.state.marital_status) {
      this.setState({
        marital_error: "has-danger",
      });
    }

    if (!this.state.phone_number) {
      this.setState({
        phone_number_error: "has-danger",
      });
    }

    if (!this.state.address) {
      this.setState({
        community_error: "has-danger",
      });
    }

    if (!this.state.LGA) {
      this.setState({
        LGA_error: "has-danger",
      });
    }

    if (!this.state.state) {
      this.setState({
        LGA_error: "has-danger",
      });
    }

    if (!this.state.crop) {
      this.setState({
        crop_error: "has-danger",
      });
    }

    if (
      this.state.gender &&
      this.state.first_name &&
      this.state.last_name &&
      this.state.marital_status &&
      this.state.phone_number &&
      this.state.address &&
      this.state.state &&
      this.state.LGA &&
      this.state.crop
    ) {
      this.setState({
        tab1: false,
        tab2: true,
        tab3: false,
      });
    }
  };

  checkForm3 = () => {
    if (
      this.state.next_title ||
      this.state.next_first_name ||
      this.state.next_last_name ||
      this.state.next_phone_number ||
      this.state.next_address ||
      this.state.guarantor_first_name ||
      this.state.guarantor_last_name ||
      this.state.guarantor_phone_number ||
      this.state.guarantor_address ||
      !this.state.next_title ||
      !this.state.next_first_name ||
      !this.state.next_last_name ||
      !this.state.next_phone_number ||
      !this.state.next_address ||
      !this.state.guarantor_first_name ||
      !this.state.guarantor_last_name ||
      !this.state.guarantor_phone_number ||
      !this.state.guarantor_address
    ) {
      this.submitForm();
    }
  };

  goToTab1 = () => {
    this.setState({
      tab1: true,
      tab2: false,
      tab3: false,
    });
  };

  goToTab2 = () => {
    this.setState({
      tab1: false,
      tab2: true,
      tab3: false,
    });
  };

  goToTab3 = () => {
    this.setState({
      tab1: false,
      tab2: false,
      tab3: true,
    });
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    let d = this.state.day;
    let m = this.state.month;
    let y = this.state.year;
    console.log(d, m);
    let date =
      (d <= 9 ? "0" + d : d) +
      "-" +
      (m <= 9 ? "0" + m : m) +
      "-" +
      (y === " " ? "0" : y);
    let phone_num = this.state.phone_number;
    let phoneNumber = "+234" + phone_num.substring(1, 11);
    console.log(date);

    if (this.state.next_phone_number !== "") {
      console.log("Running");
      let next_phone_num = this.state.next_phone_number;
      let nextPhoneNumber = "+234" + next_phone_num.substring(1, 11);
      this.setState({
        nextPhoneNumber: nextPhoneNumber,
      });
    } else {
      this.setState({
        nextPhoneNumber: "",
      });
    }

    if (this.state.guarantor_phone_number !== "") {
      let guarantor_phone_num = this.state.guarantor_phone_number;
      let guarantorPhone = "+234" + guarantor_phone_num.substring(1, 11);
      this.setState({
        guarantorPhone: guarantorPhone,
      });
    } else {
      this.setState({
        guarantorPhone: "",
      });
    }
    const farmer = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      cooperativeLiveId: this.props.location.state.cooperativeLiveId,
      season_deposit: 0,
      gender: this.state.gender,
      first_name: this.state.first_name,
      middle_name: this.state.middle_name,
      last_name: this.state.last_name,
      marital_status: this.state.marital_status,
      bvn: this.state.Id_number,
      phone_number: phoneNumber,
      address: this.state.address,
      LGA: this.state.LGA,
      farm_size: this.state.farm_size,
      bank: this.state.bank,
      account_number: this.state.account_number,
      lead_farmer: false,
      date_of_birth: date,
      state: this.state.state,
      crop: this.state.crop,
      cooperative_name: this.props.location.state.name,
    };
    create_farmer_live(farmer, {
      t: jwt.token,
    })
      .then((data) => {
        console.log(data);
        if (data.error) {
          this.setState({
            mssg: `Could not create Farmer`,
            error_notification: true,
          });
        } else if(this.state.next_first_name || this.state.next_last_name || this.state.next_phone_number || this.state.next_address || this.state.next_relationship){
            console.log(data)
            const farmer_next_of_kin ={
              createdBy: auth.isAuthenticated().user.first_name +
              " " +
              auth.isAuthenticated().user.last_name,
              relationship: this.state.next_title,
              first_name: this.state.next_first_name,
              last_name: this.state.next_last_name,
              phone_number: this.state.nextPhoneNumber,
              address: this.state.next_address,
              farmer_id: data.farmer._id
            }
            create_farmer_next_of_kin_live(farmer_next_of_kin,{
              t : jwt.token
            })
              .then((data) =>{
              if (data.error) {
                this.setState({
                  mssg: `Could not create Farmer`,
                  error_notification: true,
                });
              } 
            })
            if(this.state.guarantor_first_name || this.state.guarantor_last_name || this.state.guarantor_phone_number || this.state.guarantor_address || this.state.guarantor_relationship){
            const farmer_guarantor ={
              createdBy: auth.isAuthenticated().user.first_name +
              " " +
              auth.isAuthenticated().user.last_name,
              first_name: this.state.guarantor_first_name,
              last_name: this.state.guarantor_last_name,
              phone_number: this.state.guarantorPhone,
              address: this.state.guarantor_address,
              farmer_id: data.farmer._id
            }
            create_farmer_guarantor_live(farmer_guarantor,{t: jwt.token}).then((data) =>{
              if (data.error) {
                this.setState({
                  mssg: `Could not create Farmer`,
                  error_notification: true,
                });
              }
              else{
          this.setState({
            farmerCreated: true,
          });
          }
              
            })
          }
          else{
          this.setState({
            farmerCreated: true,
          });
          }
        }
        else{
          this.setState({
            farmerCreated: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.farmerCreated) {
      return (
        <Redirect
          to={{
            pathname: "/admin_cooperative_details",
            state: {
              cooperativeId: this.props.location.state.cooperativeLiveId,
              cooperativeLiveId: this.props.location.state.cooperativeLiveId,
              name: this.props.location.state.name,
              type: this.props.location.state.type,
              start_month: this.props.location.state.start_month,
              end_month: this.props.location.state.end_month,
              year: this.props.location.state.year,
            },
          }}
        />
      );
    }
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              {this.state.error_notification && (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              )}

              <Link
                className="btn btn-primary btn-round"
                to={{
                  pathname: "/admin_cooperative_details",
                  state: {
                    cooperativeId: this.props.location.state.cooperativeLiveId,
                    cooperativeLiveId: this.props.location.state
                      .cooperativeLiveId,
                    name: this.props.location.state.name,
                    type: this.props.location.state.type,
                    start_month: this.props.location.state.start_month,
                    end_month: this.props.location.state.end_month,
                    year: this.props.location.state.year,
                  },
                }}
              >
                Go Back
              </Link>
              <div className="container-fluid">
                <div className="col-md-8 col-12 mr-auto ml-auto">
                  <div className="wizard-container">
                    <div
                      className="card card-wizard active"
                      style={{ opacity: "1" }}
                      data-color="green"
                      id="wizardProfile"
                    >
                      <form>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="ZutObGpzskPOe80Rog3rICibC25QFgnZvRzsRhZjLGRjl8Kafst4jN7ZRruom3kx"
                        />
                        <div className="card-header text-center">
                          <h3 className="card-title">Add a New Farmer</h3>
                          <h4 className="card-description">Name</h4>
                          <h5 className="card-description">
                            Please fill in the information of the farmer.
                          </h5>
                        </div>
                        <div className="wizard-navigation">
                          <ul className="nav nav-pills">
                            <li
                              onClick={() =>
                                this.setState({
                                  tab1: true,
                                  tab2: false,
                                  tab3: false,
                                })
                              }
                              className="nav-item"
                              style={{ width: "33.3333%" }}
                            >
                              <p className="nav-link ">About Farmer</p>
                            </li>
                            <li
                              onClick={() =>
                                this.setState({
                                  tab1: false,
                                  tab2: true,
                                  tab3: false,
                                })
                              }
                              className="nav-item"
                              style={{ width: "33.3333%" }}
                            >
                              <p className="nav-link ">Account Info</p>
                            </li>
                            <li
                              onClick={() =>
                                this.setState({
                                  tab1: false,
                                  tab2: false,
                                  tab3: true,
                                })
                              }
                              className="nav-item"
                              style={{ width: "33.3333%" }}
                            >
                              <p className="nav-link">Next of Kin/Guarantor</p>
                            </li>
                          </ul>
                          {this.state.tab1 ? (
                            <div
                              className="moving-tab"
                              style={{
                                width: "223.219px",
                                transform: "translate3d(-8px, 0px, 0px)",
                                transition: "transform 0s ease 0s",
                              }}
                            >
                              About Farmer
                            </div>
                          ) : this.state.tab2 ? (
                            <div
                              className="moving-tab"
                              style={{
                                width: "228.219px",
                                transform: "translate3d(205px, 0px, 0px)",
                                transition: "transform 0s ease 0s",
                              }}
                            >
                              Account Info
                            </div>
                          ) : this.state.tab3 ? (
                            <div
                              className="moving-tab"
                              style={{
                                width: "228.219px",
                                transform: "translate3d(445px, 0px, 0px)",
                                transition: "transform 0s ease 0s",
                              }}
                            >
                              Next of Kin/Guarantor
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="card-body">
                          <div className="tab-content">
                            <div
                              className={
                                !this.state.tab1
                                  ? "tab-pane"
                                  : "tab-pane active"
                              }
                              id="about"
                            >
                              <h5 className="info-text">
                                {" "}
                                Let us start with the basic information about
                                the farmer.
                                {this.state.gender_error ? (
                                  <p style={{ color: "red" }}>
                                    Error: Gender field is required
                                  </p>
                                ) : (
                                  ""
                                )}
                              </h5>
                              <div className="row justify-content-center">
                                <div className="col-sm-6">
                                  <div
                                    className={
                                      this.state.first_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.first_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editfirst_name"
                                      className="bmd-label-floating"
                                    >
                                      Enter First Name
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          first_name: event.target.value,
                                          first_name_error: "",
                                        })
                                      }
                                      required=""
                                      name="first_name"
                                      type="text"
                                      className="form-control"
                                      value={this.state.first_name}
                                    />
                                  </div>

                                  <div
                                    className={
                                      this.state.middle_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.middle_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editmiddle_name"
                                      className="bmd-label-floating"
                                    >
                                      Enter Middle Name
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          middle_name: event.target.value,
                                        })
                                      }
                                      required=""
                                      name="middle_name"
                                      type="text"
                                      className="form-control"
                                      value={this.state.middle_name}
                                    />
                                  </div>

                                  <div
                                    className={
                                      this.state.last_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.last_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editlast_name"
                                      className="bmd-label-floating"
                                    >
                                      Enter Last Name
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          last_name: event.target.value,
                                        })
                                      }
                                      required=""
                                      name="last_name"
                                      type="text"
                                      className="form-control"
                                      value={this.state.last_name}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div
                                      className={
                                        !this.state.maritalstatusmodal
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        title="Married"
                                        aria-expanded="true"
                                        onClick={this.handleMaritalStatusModal}
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.marital_status
                                                ? "Select Marital Status"
                                                : this.state.marital_status}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="marital"
                                        className={
                                          !this.state.maritalstatusmodal
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-controls="marital"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          overflow: "hidden",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{ overflowY: "auto" }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Select Marital Status
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.marital_status !==
                                                  "Married"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    marital_status: "Married",
                                                    maritalstatusmodal: false,
                                                    marital_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Married
                                                </span>
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.marital_status !==
                                                  "Single"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    marital_status: "Single",
                                                    maritalstatusmodal: false,
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Single
                                                </span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      this.state.Id_number
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.Id_number_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editlast_name"
                                      className="bmd-label-floating"
                                    >
                                      Enter BVN
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          Id_number: event.target.value,
                                        })
                                      }
                                      name="id_no"
                                      type="text"
                                      className="form-control"
                                      value={this.state.Id_number}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.phone_number
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.phone_number_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editphone_number"
                                      className="bmd-label-floating"
                                    >
                                      Enter Phone Number
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          phone_number: event.target.value,
                                        })
                                      }
                                      required=""
                                      name="phone_number"
                                      type="number"
                                      className="form-control"
                                      value={this.state.phone_number}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div
                                      id="gender"
                                      className={
                                        !this.state.gendermodal
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        title="Gender"
                                        aria-expanded="true"
                                        onClick={this.handleGenderModal}
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.gender
                                                ? "Choose Gender"
                                                : this.state.gender}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="gender"
                                        className={
                                          !this.state.gendermodal
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-controls="gender"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          overflow: "hidden",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{ overflowY: "auto" }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Choose Gender
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.gender !== "Male"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    gender: "Male",
                                                    gendermodal: false,
                                                    gender_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Male
                                                </span>
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.gender !== "Female"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    gender: "Female",
                                                    gendermodal: false,
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Female
                                                </span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div
                                    className={
                                      this.state.address
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.community_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editcommunity"
                                      className="bmd-label-floating"
                                    >
                                      Enter Address
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          address: event.target.value,
                                        })
                                      }
                                      name="address"
                                      type="text"
                                      className="form-control"
                                      required=""
                                      value={this.state.address}
                                    />
                                  </div>
                                  <div
                                    className={
                                      !this.state.modals.state
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() => this.handleModal("state")}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.state
                                              ? "Choose State"
                                              : this.state.state}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="states"
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="states"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.state ||
                                                  "Choose State"}
                                              </span>
                                            </a>
                                          </li>
                                          {NaijaStates.states().map(
                                            (state, id) => (
                                              <li key={id}>
                                                <a
                                                  role="option"
                                                  className={
                                                    this.state.state !== state
                                                      ? "dropdown-item"
                                                      : "dropdown-item selected active"
                                                  }
                                                  aria-disabled="false"
                                                  tabIndex="0"
                                                  aria-selected="false"
                                                  onClick={() => {
                                                    this.handleModal("state");
                                                    this.setState({
                                                      state: state,
                                                    });
                                                  }}
                                                >
                                                  <span className=" bs-ok-default check-mark" />
                                                  <span className="text">
                                                    {state}
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      !this.state.modals.LGA
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() => this.handleModal("LGA")}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.LGA
                                              ? "Choose LGA"
                                              : this.state.LGA}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="LGAs"
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="LGAs"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.LGA || "Choose LGA"}
                                              </span>
                                            </a>
                                          </li>
                                          {!this.state.state
                                            ? " "
                                            : NaijaStates.lgas(
                                                this.state.state
                                              ).lgas.map((LGA, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.LGA !== LGA
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleModal("LGA");
                                                      this.setState({
                                                        LGA: LGA,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {LGA}
                                                    </span>
                                                  </a>
                                                </li>
                                              ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-check">
                                    <p>Select Crops</p>
                                    {this.state.crops.map((data) => {
                                      return (
                                        <label className="form-check-label">
                                          <input
                                            name="crop"
                                            onChange={(event) => {
                                              // console.log(this.state.crop)
                                              // console.log(event.target
                                              this.state.selected_crops.push(
                                                event.target.value
                                              );
                                              console.log(
                                                this.state.selected_crops
                                              );
                                              return this.setState({
                                                crop: this.state.selected_crops,
                                              });
                                            }}
                                            className="form-check-input"
                                            type="checkbox"
                                            value={data._id}
                                            id="add_to_season"
                                          />
                                          {data.name}
                                          <span className="form-check-sign">
                                            <span className="check" />
                                          </span>
                                        </label>
                                      );
                                    })}
                                  </div>

                                  <div
                                    className={
                                      this.state.day &&
                                      this.state.month &&
                                      this.state.year
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group`
                                    }
                                  >
                                    <div><label htmlFor="day" className="">
                                      Date Of Birth
                                    </label></div>
                                    <div
                                      className={
                                        !this.state.modals.year
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() => this.handleModal("year")}
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.year
                                                ? "Choose Year"
                                                : this.state.year}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        className={
                                          !this.state.modals.year
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "162px",
                                          minWidth: "100px",
                                          overflow: "hidden",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "152px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.year ||
                                                    "Choose Year"}
                                                </span>
                                              </a>
                                            </li>
                                            {this.availableYears().map(
                                              (year, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.year !== year
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleModal("year");
                                                      this.setState({
                                                        year: year,
                                                      });
                                                      console.log(
                                                        this.state.year
                                                      );
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {year}
                                                    </span>
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        !this.state.modals.month
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleModal("month")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.month
                                                ? "Choose Month"
                                                : this.availableMonths()[
                                                    this.state.month
                                                  ]}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        className={
                                          !this.state.modals.month
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "162px",
                                          minWidth: "100px",
                                          overflow: "hidden",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "152px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.availableMonths()[
                                                    this.state.month
                                                  ] || "Choose Month"}
                                                </span>
                                              </a>
                                            </li>
                                            {this.availableMonths().map(
                                              (month, id) => {
                                                return (
                                                  <li key={id}>
                                                    <a
                                                      role="option"
                                                      className={
                                                        this.state.month !== month
                                                          ? "dropdown-item "
                                                          : "dropdown-item selected active"
                                                      }
                                                      aria-disabled="false"
                                                      tabIndex="0"
                                                      aria-selected="false"
                                                      onClick={() => {
                                                        this.handleModal(
                                                          "month"
                                                        );
                                                        this.setState({
                                                          month: this.availableMonths().indexOf(
                                                            month
                                                          ),
                                                        });
                                                      }}
                                                    >
                                                      <span className=" bs-ok-default check-mark" />
                                                      <span className="text">
                                                        {month}
                                                      </span>
                                                    </a>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        !this.state.modals.day
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() => this.handleModal("day")}
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.day
                                                ? "Choose Day"
                                                : this.state.day}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        className={
                                          !this.state.modals.day
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "162px",
                                          overflow: "hidden",
                                          minWidth: "152px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "152px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.day ||
                                                    "Choose Day"}
                                                </span>
                                              </a>
                                            </li>
                                            {this.availableDays().map(
                                              (day, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.day !== day
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleModal("day");
                                                      this.setState({
                                                        day: day,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {day}
                                                    </span>
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                !this.state.tab2
                                  ? "tab-pane"
                                  : "tab-pane active"
                              }
                              id="account"
                            >
                              <h5 className="info-text">
                                {" "}
                                Then to the account information of the farmer.
                                (Optional){" "}
                              </h5>
                              <div className="row justify-content-center">
                                <div className="col-lg-10">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="form-group">
                                        <div className="dropdown bootstrap-select show">
                                          <button
                                            type="button"
                                            className="dropdown-toggle bs-placeholder btn btn-success"
                                            data-toggle="dropdown"
                                            role="button"
                                            title="Choose Bank"
                                            onClick={() =>
                                              this.handleBankModal()
                                            }
                                          >
                                            <div className="filter-option">
                                              <div className="filter-option-inner">
                                                <div className="filter-option-inner-inner">
                                                  {!this.state.bank
                                                    ? "Choose Bank"
                                                    : this.state.bank}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </button>
                                          <div
                                            className={
                                              !this.state.bankmodal
                                                ? "dropdown-menu"
                                                : "dropdown-menu show"
                                            }
                                            role="combobox"
                                            x-placement="bottom-start"
                                            style={{
                                              maxHeight: "162px",
                                              overflow: "hidden",
                                              minWidth: "452px",
                                              position: "absolute",
                                              top: "46px",
                                              left: "1px",
                                              willChange: "top, left",
                                            }}
                                          >
                                            <div
                                              className="inner show"
                                              role="listbox"
                                              aria-expanded="true"
                                              tabIndex="-1"
                                              style={{
                                                maxHeight: "152px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              <ul className="dropdown-menu inner show">
                                                <li className="disabled">
                                                  <a
                                                    role="option"
                                                    className="dropdown-item disabled"
                                                    aria-disabled="true"
                                                    tabIndex="-1"
                                                    aria-selected="false"
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Choose Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Access Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Access Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Access Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Citibank Nigeria Limited"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Citibank Nigeria Limited",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Citibank Nigeria Limited
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Ecobank Nigeria Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Ecobank Nigeria Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Ecobank Nigeria Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Fidelity Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Fidelity Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Fidelity Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "First Bank Nigeria Limited"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "First Bank Nigeria Limited",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      First Bank Nigeria Limited
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "First City momnument Bank"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "First City momnument Bank",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      FCMB
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Globus Bank Limited"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Globus Bank Limited",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Globus Bank Limited
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Guaranty Trust Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Guaranty Trust Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Guaranty Trust Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Heritage Banking Company Ltd"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Heritage Banking Company Ltd",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Heritage Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Keystone Bank"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Keystone Bank",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Keystone Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Polaris Bank"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Polaris Bank",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Polaris Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Providus Bank"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Providus Bank",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Providus Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Stanbic IBTC Bank Ltd"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Stanbic IBTC Bank Ltd",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Stanbic IBTC Bank Ltd
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Standard Chartered Bank Nigeria Ltd"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Standard Chartered Bank Nigeria Ltd",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Standard Chartered Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Sterling Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Sterling Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Sterling Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "SunTrust Bank Nigeria Limited"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "SunTrust Bank Nigeria Limited",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      SunTrust Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Titan Trust Bank Ltd"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Titan Trust Bank Ltd",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Titan Trust Bank
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Union Bank of Nigeria Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "Union Bank of Nigeria Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Union Bank of Nigeria Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "United Bank For Africa Plc "
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank:
                                                          "United Bank For Africa Plc ",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      United Bank for Africa Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Unity Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Unity Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Unity Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Wema Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Wema Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Wema Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.bank !==
                                                      "Zenith Bank Plc"
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() =>
                                                      this.setState({
                                                        bank: "Zenith Bank Plc",
                                                        bankmodal: false,
                                                      })
                                                    }
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      Zenith Bank Plc
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div
                                        className={
                                          this.state.account_number
                                            ? "is-filled form-group bmd-form-group"
                                            : `form-group bmd-form-group ${this.state.account_number_error}`
                                        }
                                      >
                                        <label
                                          htmlFor="editaccount_number"
                                          className="bmd-label-floating"
                                        >
                                          Enter Account Number
                                        </label>
                                        <input
                                          onChange={(event) =>
                                            this.setState({
                                              account_number:
                                                event.target.value,
                                            })
                                          }
                                          name="account_number"
                                          type="number"
                                          className="form-control"
                                          id="editaccount_number"
                                          value={this.state.account_number}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                !this.state.tab3
                                  ? "tab-pane"
                                  : "tab-pane active"
                              }
                              id="nextkin"
                            >
                              <h5 className="info-text">
                                {" "}
                                Now to the Next of Kin and Guarantor
                                {this.state.next_title_error ? (
                                  <p style={{ color: "red" }}>
                                    Error: Next Of Kin Title field is required
                                  </p>
                                ) : (
                                  ""
                                )}
                              </h5>
                              <div className="row justify-content-center">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <div className="dropdown bootstrap-select">
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn btn-success"
                                        data-toggle="dropdown"
                                        role="button"
                                        title="Father"
                                        onClick={() =>
                                          this.handleNexttitleModal()
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.next_title
                                                ? "Select Relationship"
                                                : this.state.next_title}
                                            </div>
                                          </div>{" "}
                                        </div>
                                      </button>
                                      <div
                                        className={
                                          !this.state.nexttitlemodal
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "162px",
                                          overflow: "hidden",
                                          minWidth: "220px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "152px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Select Relationship
                                                </span>
                                              </a>
                                            </li>
                                            <li className="selected active">
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Father"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="true"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Father",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Father
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Mother"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Mother",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Mother
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Son"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Son",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Son
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Daughter"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Daughter",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Daughter
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Wife"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Wife",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Wife
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Husband"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Husband",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Husband
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.next_title !==
                                                  "Family Member"
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() =>
                                                  this.setState({
                                                    next_title: "Family Member",
                                                    nexttitlemodal: false,
                                                    next_title_error: "",
                                                  })
                                                }
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  Family Member
                                                </span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      this.state.next_first_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.next_first_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editnext_first_name"
                                      className="bmd-label-floating"
                                    >
                                      Next of Kin First Name
                                    </label>
                                    <input
                                      required=""
                                      name="next_first_name"
                                      type="text"
                                      className="form-control"
                                      id="editnext_first_name"
                                      onChange={(event) =>
                                        this.setState({
                                          next_first_name: event.target.value,
                                          next_first_name_error: "",
                                        })
                                      }
                                      value={this.state.next_first_name}
                                      aria-required="true"
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.next_last_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.next_last_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editnext_last_name"
                                      className="bmd-label-floating"
                                    >
                                      Next of Kin Last Name
                                    </label>
                                    <input
                                      required=""
                                      name="next_last_name"
                                      type="text"
                                      className="form-control"
                                      id="editnext_last_name"
                                      onChange={(event) =>
                                        this.setState({
                                          next_last_name: event.target.value,
                                          next_last_name_error: "",
                                        })
                                      }
                                      value={this.state.next_last_name}
                                      aria-required="true"
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.next_phone_number
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.next_phone_number_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editnext_phone_number"
                                      className="bmd-label-floating"
                                    >
                                      Next of Kin Phone Number
                                    </label>
                                    <input
                                      required=""
                                      name="next_phone_number"
                                      type="number"
                                      className="form-control"
                                      id="editnext_phone_number"
                                      onChange={(event) =>
                                        this.setState({
                                          next_phone_number: event.target.value,
                                          next_phone_number_error: "",
                                        })
                                      }
                                      value={this.state.next_phone_number}
                                      aria-required="true"
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.next_address
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.next_address_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editnext_address"
                                      className="bmd-label-floating"
                                    >
                                      Next of Kin Address
                                    </label>
                                    <input
                                      required=""
                                      name="next_address"
                                      type="text"
                                      className="form-control"
                                      id="editnext_address"
                                      onChange={(event) =>
                                        this.setState({
                                          next_address: event.target.value,
                                          next_address_error: "",
                                        })
                                      }
                                      value={this.state.next_address}
                                      aria-required="true"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div
                                    className={
                                      this.state.guarantor_first_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.guarantor_first_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editguarantor_first_name"
                                      className="bmd-label-floating"
                                    >
                                      Guarantor First Name (Optional)
                                    </label>
                                    <input
                                      name="guarantor_first_name"
                                      type="text"
                                      className="form-control"
                                      id="editguarantor_first_name"
                                      onChange={(event) =>
                                        this.setState({
                                          guarantor_first_name:
                                            event.target.value,
                                          guarantor_first_name_error: "",
                                        })
                                      }
                                      value={this.state.guarantor_first_name}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.guarantor_last_name
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.guarantor_last_name_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editguarantor_last_name"
                                      className="bmd-label-floating"
                                    >
                                      Guarantor Last Name (Optional)
                                    </label>
                                    <input
                                      name="guarantor_last_name"
                                      type="text"
                                      className="form-control"
                                      id="editguarantor_last_name"
                                      onChange={(event) =>
                                        this.setState({
                                          guarantor_last_name:
                                            event.target.value,
                                          guarantor_last_name_error: "",
                                        })
                                      }
                                      value={this.state.guarantor_last_name}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.guarantor_phone_number
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.guarantor_phone_number_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editguarantor_phone_number"
                                      className="bmd-label-floating"
                                    >
                                      Guarantor Phone Number (Optional)
                                    </label>
                                    <input
                                      name="guarantor_phone_number"
                                      type="number"
                                      className="form-control"
                                      id="editguarantor_phone_number"
                                      onChange={(event) =>
                                        this.setState({
                                          guarantor_phone_number:
                                            event.target.value,
                                          guarantor_phone_number_error: "",
                                        })
                                      }
                                      value={this.state.guarantor_phone_number}
                                    />
                                  </div>
                                  <div
                                    className={
                                      this.state.guarantor_address
                                        ? "is-filled form-group bmd-form-group"
                                        : `form-group bmd-form-group ${this.state.guarantor_address_error}`
                                    }
                                  >
                                    <label
                                      htmlFor="editguarantor_address"
                                      className="bmd-label-floating"
                                    >
                                      Guarantor Address (Optional)
                                    </label>
                                    <input
                                      name="guarantor_address"
                                      type="text"
                                      className="form-control"
                                      id="editguarantor_address"
                                      onChange={(event) =>
                                        this.setState({
                                          guarantor_address: event.target.value,
                                          guarantor_address_error: "",
                                        })
                                      }
                                      value={this.state.guarantor_address}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="mr-auto">
                            {this.state.tab1 ? (
                              <input
                                type="button"
                                className="btn btn-previous btn-fill btn-default btn-wd disabled"
                                name="previous"
                                value="Previous"
                              />
                            ) : this.state.tab2 ? (
                              <input
                                onClick={() => this.goToTab1()}
                                type="button"
                                className="btn btn-previous btn-fill btn-default btn-wd "
                                name="previous"
                                value="Previous"
                              />
                            ) : this.state.tab3 ? (
                              <input
                                onClick={() => this.goToTab2()}
                                type="button"
                                className="btn btn-previous btn-fill btn-default btn-wd "
                                name="previous"
                                value="Previous"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ml-auto">
                            <input
                              type="hidden"
                              name="create_farmer"
                              value="1"
                            />
                            {this.state.tab1 ? (
                              <input
                                onClick={() => this.checkForm1()}
                                type="button"
                                className="btn btn-next btn-fill btn-success btn-wd"
                                name="next"
                                value="Next"
                              />
                            ) : this.state.tab2 ? (
                              <input
                                onClick={() => this.goToTab3()}
                                type="button"
                                className="btn btn-next btn-fill btn-success btn-wd"
                                name="next"
                                value="Next"
                              />
                            ) : this.state.tab3 ? (
                              <input
                                onClick={() => this.checkForm3()}
                                type="button"
                                className="btn btn-next btn-fill btn-success btn-wd"
                                name="next"
                                value="Submit"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="clearfix" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by{" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AdminAddFarmer;
