import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Pdf from "react-to-pdf";
import { CSVLink } from "react-csv";
import { remove_farmer_live, list_farmer_live } from "../../api/api-farmer";
import { read_crop_live, list_crop_live } from "../../api/api-crop";
import { read_farmer_crop_live } from "../../api/api-farmer_crop";
import { read_farm_live } from "../../api/api-farm";
import {list_farmer_next_of_kin, list_farmer_next_of_kin_live} from "../../api/api-farmer_next_of_kin";
import CSVExport from "../../services/CSVExport"

const ref = React.createRef();
class AdminFarmers extends Component {
  constructor() {
    super();
    this.state = {
      season_drop_down: false,
      loan: "21000",
      set_lead_famer_drop_down: false,
      farmers: [],
      Id: "",
      liveId: "",
      search: "",
      deletemodal: false,
      mssg: "",
      error_notification: false,
      csv_header: [],
      csv_data: [],
      crops: [],
      farmer_next_of_kin: [],
      farmer_guarantor: []
    };
    this.handleListFarmers();
    this.handleCSV();
    this.getCrop();
  }

  componentDidMount() {
    document.title = "Farmers";
  }

  handleListFarmers = () => {
    list_farmer_live().then((data) => {
      console.log(data);
      if (!data || data.message) {
        this.setState({
          mssg: `Could not fetch farmers, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else if (data !== 0) {
        this.setState({ farmers: data });
      } else {
        this.setState({ farmers: [] });
      }
    });
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      deletemodal: !prevState.deletemodal,
    }));
  };

  getCrop = () => {
    list_crop_live().then((data) => {
      if (!data) {
        return;
      } else {
        console.log(data);
        this.setState({ crops: data });
      }
    });
  };

  handleFarmerCrop = async (farmerId) => {
    let farmer_crops = await read_farmer_crop_live({
      farmerId: farmerId,
    });
    let crops = [];
    farmer_crops.farmer_crop.crops.map((fcrop) => {
      this.state.crops.map((crop) => {
        if (crop._id === fcrop) {
          crops.push(crop.name);
        }
      });
    });
    return crops;
  };

  handleNextOfKin = async (Id) => {
    let data2 = await list_farmer_next_of_kin_live({ farmer_id: Id });
    console.log(data2);
    if (data2.error) {
      return `No Next Of Kin`;
    } else {
      return `${data2.farmer_next_of_kin.relationship}`
    }
  };

  handleFarm = async (Id) => {
    let data2 = await read_farm_live({ farmerId: Id });
    console.log(data2);
    if (data2.error) {
      return `No Farm yet`;
    } else {
      return `${data2.farm.size} ${data2.farm.unit}`;
    }
  };

  getLGACode = (LGAName) => {
    const LGA = {
      "Akoko Edo": "225",
      Egor: "226",
      "Esan Central": "227",
      "Esan North-East": "228",
      "Esan South-East": "229",
      "Esan West": "230",
      "Etsako Central": "231",
      "Etsako East": "232",
      "Etsako West": "233",
      Igueben: "234",
      "Ikpoba Okha": "235",
      Orhionmwon: "236",
      Oredo: "237",
      "Ovia North-East": "238",
      "Ovia South-West": "239",
      "Owan East": "240",
      "Owan West": "241",
      Uhunmwonde: "242",
    };
    return LGA[LGAName];
  };

  handleCSV = async () => {
    await list_farmer_live().then(async (data) => {
      // console.log(data);
      if (!data || data.message) {
        this.setState({
          mssg: `Could not fetch farmers, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else if (data !== 0) {
        const headers = [
          { label: "Applicant_BVN", key: "bvn" },
          { label: "Applicant_ACC_NO", key: "acct_no" },
          { label: "Applicant_Bank", key: "bank" },
          { label: "Applicant_Firstname", key: "first_name" },
          { label: "Applicant_Middlename", key: "middle_name" },
          { label: "Applicant_Lastname", key: "last_name" },
          { label: "Applicant_Gender", key: "gender" },
          { label: "Farmers_Crop", key: "crop" },
          { label: "Farmer_Size", key: "farm_size" },
          { label: "Marital_Status", key: "marital_status" },
          { label: "Address", key: "address" },
          { label: "State", key: "state" },
          { label: "LGA", key: "LGA" },
          { label: "NOK_Relationship", key: "next_relationship" },
          { label: "Occupation", key: "occupation" },

        ];

        const all_data = data;
        let cvsdata = [];
        await all_data.map(async (data) => {
          let farmer_crop = await (
            await this.handleFarmerCrop(data._id)
          ).toString();
          console.log(farmer_crop);

          let cvsData = {
            bvn: data.bvn,
            acct_no: data.account_number,
            bank: data.bank,
            first_name: data.first_name.toUpperCase(),
            middle_name: data.middle_name.toUpperCase(),
            last_name: data.last_name.toUpperCase(),
            gender: data.gender.charAt(0),
            crop: farmer_crop,
            occupation: "Farmer",
            farm_size: await this.handleFarm(data._id),
            marital_status: data.marital_status.charAt(0),
            address: data.address.toUpperCase(),
            state: data.state.toUpperCase(),
            LGA: data.LGA.toUpperCase(),
            next_relationship: await this.handleNextOfKin(data._id),

          };
          cvsdata.push(cvsData);
          console.log(cvsdata);
          this.setState({
            csv_header: headers,
            csv_data: cvsdata,
          });
        });
        console.log(cvsdata);
      } else {
        this.setState({ farmers: [] });
      }
    });
  };

  deleteFarmer = () => {
    remove_farmer_live({
      farmerId: this.state.Id,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) this.handleListFarmers();
    });

    this.handleDeleteModal();
  };

  handleGetDeleteFarmerId = (farmerId, liveId) => {
    this.setState({
      Id: farmerId,
      liveId: liveId,
    });

    this.handleDeleteModal();
  };

  handleSeasonDropDown = () => {
    this.setState((prevState) => ({
      season_drop_down: !prevState.season_drop_down,
    }));
  };

  handleSetFarmerDropDown = () => {
    this.setState((prevState) => ({
      set_lead_famer_drop_down: !prevState.set_lead_famer_drop_down,
    }));
  };

  render() {
    const { search } = this.state;

    let { farmers } = this.state;

    if (search) {
      farmers = farmers.filter((farmer) => {
        return farmer.first_name.toLowerCase().includes(search.toLowerCase());
      });
    }

    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title">Farmers</h4>
                        <h6>Manage Cooperative Farmers</h6>
                      </div>
                      {this.state.error_notification && (
                        <div className="alert alert-danger">
                          <button
                            onClick={() =>
                              this.setState({ error_notification: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <i className="material-icons">close</i>
                          </button>
                          <span>{this.state.mssg} </span>
                        </div>
                      )}
                      <div className="card-body">
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="farmers_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Farmers.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                              </div>
                              <div
                                id="farmers_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="farmers"
                                      onChange={(event) =>
                                        this.setState({
                                          search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                ref={ref}
                                id="farmers"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="farmers_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Name: activate to sort column ascending"
                                      style={{ width: "77px" }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Phone Number: activate to sort column ascending"
                                      style={{ width: "109px" }}
                                    >
                                      Phone Number
                                    </th>
                                    {/* <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='farmers'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='ID Card: activate to sort column ascending'
                                      style={{ width: '36px' }}
                                    >
                                      ID Card
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Community: activate to sort column ascending"
                                      style={{ width: "87px" }}
                                    >
                                      Address
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="State: activate to sort column ascending"
                                      style={{ width: "38px" }}
                                    >
                                      Cooperative
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="LGA: activate to sort column ascending"
                                      style={{ width: "53px" }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="State: activate to sort column ascending"
                                      style={{ width: "53px" }}
                                    >
                                      State
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Added: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "57px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Name
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Phone Number
                                    </th>
                                    {/* <th rowSpan='1' colSpan='1'>
                                      ID Card
                                    </th> */}
                                    <th rowSpan="1" colSpan="1">
                                      Address
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Cooperative
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      LGA
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      State
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody id="all-farmers">
                                  {farmers.map((data) => (
                                    <tr
                                      role="row"
                                      className="odd"
                                      key={data._id}
                                    >
                                      <td>{`${data.first_name} ${data.last_name}`}</td>
                                      <td>{data.phone_number}</td>
                                      {/* <td>{data.Id_number}</td> */}
                                      <td>{data.address}</td>
                                      <td>{data.cooperative_name}</td>
                                      <td>{data.LGA}</td>
                                      <td>{data.state}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <Link
                                          to={{
                                            pathname: "/admin_farmer_detail",
                                            state: {
                                              farmerId: data._id,
                                              cooperativeId:
                                                data.cooperativeLiveId,
                                              liveId: data._id,
                                              direct: "farmer",
                                              crop: data.crop,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                        <div>
                                          <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value="MkOSHc7Cu2hIpeEzhyaeLxRVDMKBiKEJ20vVZ9YKX8mFCgHX8GQea3CeazM7DkH0"
                                          />
                                          <input
                                            type="hidden"
                                            name="delete_farmer"
                                            value="1"
                                          />
                                          <button
                                            onClick={() =>
                                              this.handleGetDeleteFarmerId(
                                                data._id,
                                                data._id
                                              )
                                            }
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Farmer"
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className="dataTables_info"
                                id="farmers_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 1 to 2 of 2 entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="farmers_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="farmers_previous"
                                  >
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="farmers_next"
                                  >
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="2"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Farmer?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this farmer
                                </div>

                                <button
                                  type="submit"
                                  onClick={this.deleteFarmer}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  farmer.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by{" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AdminFarmers;
