import { fetchRequest } from "../utils";

export const create_shipment_quality_metric = (shipment_quality_metric) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_quality_metric/`,
    method: "POST",
    data: shipment_quality_metric,
  });

export const list_shipment_quality_metric = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_quality_metric/`,
  });

export const read_shipment_quality_metric = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_quality_metric/` +
      params.shipment_quality_metricId,
  });

export const update_shipment_quality_metric = (shipment_quality_metric) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_quality_metric/`,
    method: "PUT",
    data: shipment_quality_metric,
  });

export const remove_shipment_quality_metric = (shipment_quality_metric) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipment_quality_metric/`,
    method: "DELETE",
    data: shipment_quality_metric,
  });

export const create_shipment_quality_metric_live = (shipment_quality_metric) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_quality_metric/`,
    method: "POST",
    data: shipment_quality_metric,
  });

export const list_shipment_quality_metric_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_quality_metric/`,
  });

export const update_shipment_quality_metric_live = (shipment_quality_metric, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_quality_metric/` +
      params.shipment_quality_metricId,
    method: "PUT",
    data: shipment_quality_metric,
  });

export const remove_shipment_quality_metric_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipment_quality_metric/` +
      params.shipment_quality_metricId,
    method: "DELETE",
  });

// export const create_deleted_shipment_quality_metric_live = (shipment_quality_metric) =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_shipment_quality_metric/`,
//     method: "POST",
//     data: shipment_quality_metric,
//   });

// export const list_deleted_shipment_quality_metric_live = () =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_shipment_quality_metric/`,
//   });
