import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import Moment from "react-moment";
import auth from "../../api/auth-helper";
import {
  create_recovery_center_live,
  list_recovery_center_live,
  remove_recovery_center_live,
  update_recovery_center_live,
} from "../../api/api-recovery_center";
import NaijaStates from "naija-state-local-government";
class RecoveryCenter extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      deletemodal: false,
      editmodal: false,
      id: "",
      name: "",
      LGA: "",
      state: "",
      error: "",
      recovery_centers: [],
      liveId: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      modals: {
        LGA: false,
        state: false,
      },
    };
    this.handleList();
  }

  componentDidMount() {
    document.title = "Recovery Center";
  }

  handleList = () => {
    if (navigator.onLine) {
      list_recovery_center_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ recovery_centers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleOpenDeleteModal = (id, liveId) => {
    this.setState({
      deletemodal: true,
      id: id,
      liveId: liveId,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
    });
  };

  deleteData = (id, liveId) => {
    remove_recovery_center_live({
      recoveryCenterId: liveId,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleList();
        this.setState({
          loading: false,
          success_notification: true,
          mssg: "Recovery Center deleted successfully",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        this.handleCloseDeleteModal();
      }
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleLGAModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  checkForm2 = () => {
    if (!this.state.name) {
      this.setState({
        error: "Recovery Name is required",
      });
    }

    if (!this.state.LGA) {
      this.setState({
        error: "Recovery LGA is required",
      });
    }

    if (!this.state.state) {
      this.setState({
        error: "Recovery State is required",
      });
    }

    if (this.state.name && this.state.LGA && this.state.state) {
      this.editForm();
    }

    if (!this.state.name && !this.state.LGA && !this.state.state) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  editForm = () => {
    console.log(this.state.liveId);
    const jwt = auth.isAuthenticated();

    const recovery = {
      name: this.state.name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      LGA: this.state.LGA,
      state: this.state.state,
    };
    update_recovery_center_live(
      recovery,
      {
        recoveryCenterId: this.state.liveId,
      },
      {
        t: jwt.token,
      }
    ).then((data) => {
      this.handleList();

      this.setState({
        loading: false,
        update_notification: true,
        mssg: "Recovery Center updated successfully",
      });
      setTimeout(() => this.setState({ success_notification: false }), 3000);
      this.handleCloseEditModal();
    });
  };

  checkForm = () => {
    if (!this.state.name) {
      this.setState({
        error: "Recovery Name is required",
      });
    }

    if (!this.state.LGA) {
      this.setState({
        error: "Recovery State is required",
      });
    }

    if (this.state.name && this.state.LGA) {
      this.submitForm();
    }

    if (!this.state.name && !this.state.LGA) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();

    const recovery = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      name: this.state.name,
      LGA: this.state.LGA,
      state: this.state.state,
    };
    create_recovery_center_live(recovery, {
      t: jwt.token,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleList();
        this.setState({
          loading: false,
          success_notification: true,
          mssg: "Recovery Center created successfully",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        this.handleModal();
      }
    });
  };
  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.update_notification ? (
                  <div className="alert alert-warning">
                    <button
                      onClick={() =>
                        this.setState({ update_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Recovery Centres</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Recovery Centres
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>LGA</th>
                                  <th>State</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>LGA</th>
                                  <th>State</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.recovery_centers.map((data) => (
                                  <tr key={data._id}>
                                    <td>{data.name}</td>
                                    <td>{data.LGA}</td>
                                    <td>{data.state}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <button
                                        className="btn btn-link btn-just-icon btn-info edit"
                                        onClick={() =>
                                          this.setState({
                                            editmodal: true,
                                            id: data._id,
                                            name: data.name,
                                            LGA: data.LGA,
                                            state: data.state,
                                            liveId: data._id,
                                          })
                                        }
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit Center"
                                      >
                                        <i className="material-icons">edit</i>
                                      </button>
                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.handleOpenDeleteModal(
                                              data._id,
                                              data._id
                                            )
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Center"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Delete Recovery Center?
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Recovery
                                  Center
                                </div>

                                <button
                                  type="submit"
                                  onClick={() =>
                                    this.deleteData(
                                      this.state.id,
                                      this.state.liveId
                                    )
                                  }
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleCloseDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  recovery center.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createAggregationCentreModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Create Recovery Centre
                            </h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="centreName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Recovery Centre
                                      </label>
                                      <input
                                        required
                                        type="text"
                                        className="form-control"
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                            error: "",
                                          })
                                        }
                                      />
                                    </div>
                                    <div
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("state")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.state
                                                ? "Choose State"
                                                : this.state.state}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="states"
                                        className={
                                          !this.state.modals.state
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="states"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.state ||
                                                    "Choose State"}
                                                </span>
                                              </a>
                                            </li>
                                            {NaijaStates.states().map(
                                              (state, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.state !== state
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleLGAModal(
                                                        "state"
                                                      );
                                                      this.setState({
                                                        state: state,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {state}
                                                    </span>
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("LGA")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.LGA
                                                ? "Choose LGA"
                                                : this.state.LGA}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="LGAs"
                                        className={
                                          !this.state.modals.LGA
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="LGAs"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.LGA ||
                                                    "Choose LGA"}
                                                </span>
                                              </a>
                                            </li>
                                            {!this.state.state
                                              ? " "
                                              : NaijaStates.lgas(
                                                  this.state.state
                                                ).lgas.map((LGA, id) => (
                                                  <li key={id}>
                                                    <a
                                                      role="option"
                                                      className={
                                                        this.state.LGA !== LGA
                                                          ? "dropdown-item"
                                                          : "dropdown-item selected active"
                                                      }
                                                      aria-disabled="false"
                                                      tabIndex="0"
                                                      aria-selected="false"
                                                      onClick={() => {
                                                        this.handleLGAModal(
                                                          "LGA"
                                                        );
                                                        this.setState({
                                                          LGA: LGA,
                                                        });
                                                      }}
                                                    >
                                                      <span className=" bs-ok-default check-mark" />
                                                      <span className="text">
                                                        {LGA}
                                                      </span>
                                                    </a>
                                                  </li>
                                                ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_aggregation_centre"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    onClick={this.checkForm}
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to Create a
                                  recovery center.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.editmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Edit Recovery Center
                            </h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleCloseEditModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="centreName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Name of Recovery Centre
                                      </label>
                                      <input
                                        required
                                        type="text"
                                        className="form-control"
                                        value={this.state.name}
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                            error: "",
                                          })
                                        }
                                      />
                                    </div>
                                    <div
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("state")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.state
                                                ? "Choose state"
                                                : this.state.state}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="states"
                                        className={
                                          !this.state.modals.state
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="states"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.state ||
                                                    "Choose State"}
                                                </span>
                                              </a>
                                            </li>
                                            {NaijaStates.states().map((state, id) => (
                                                  <li key={id}>
                                                    <a
                                                      role="option"
                                                      className={
                                                        this.state.state !== state
                                                          ? "dropdown-item"
                                                          : "dropdown-item selected active"
                                                      }
                                                      aria-disabled="false"
                                                      tabIndex="0"
                                                      aria-selected="false"
                                                      onClick={() => {
                                                        this.handleLGAModal(
                                                          "state"
                                                        );
                                                        this.setState({
                                                          state: state,
                                                        });
                                                      }}
                                                    >
                                                      <span className=" bs-ok-default check-mark" />
                                                      <span className="text">
                                                        {state}
                                                      </span>
                                                    </a>
                                                  </li>
                                                ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("LGA")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.LGA
                                                ? "Choose LGA"
                                                : this.state.LGA}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="LGAs"
                                        className={
                                          !this.state.modals.LGA
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="LGAs"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.LGA ||
                                                    "Choose LGA"}
                                                </span>
                                              </a>
                                            </li>
                                            {!this.state.state
                                              ? " "
                                              : NaijaStates.lgas(
                                                  this.state.state
                                                ).lgas.map((LGA, id) => (
                                                  <li key={id}>
                                                    <a
                                                      role="option"
                                                      className={
                                                        this.state.LGA !== LGA
                                                          ? "dropdown-item"
                                                          : "dropdown-item selected active"
                                                      }
                                                      aria-disabled="false"
                                                      tabIndex="0"
                                                      aria-selected="false"
                                                      onClick={() => {
                                                        this.handleLGAModal(
                                                          "LGA"
                                                        );
                                                        this.setState({
                                                          LGA: LGA,
                                                        });
                                                      }}
                                                    >
                                                      <span className=" bs-ok-default check-mark" />
                                                      <span className="text">
                                                        {LGA}
                                                      </span>
                                                    </a>
                                                  </li>
                                                ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    id="editinputID"
                                    type="hidden"
                                    name="edit_farm_input"
                                    value=""
                                  />
                                  <button
                                    onClick={this.checkForm2}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseEditModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  supplybase.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default RecoveryCenter;
