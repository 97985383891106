import { fetchRequest } from "../utils";

export const create_cashloan = (cashloan) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cash_loans/`,
    method: "POST",
    data: cashloan,
  });

export const list_cashloan = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cash_loans/`,
  });

export const read_cashloan = ({ cashloanId }) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cash_loans/` + cashloanId,
  });

export const update_cashloan = (cashloan) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cash_loans/`,
    method: "PUT",
    data: cashloan,
  });

export const remove_cashloan = (cashloan) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cash_loans/`,
    method: "DELETE",
    data: cashloan,
  });
//Live Api

export const create_cashloan_live = (cashloan) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cash_loans/`,
    method: "POST",
    data: cashloan,
  });

export const list_cashloan_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cash_loans/`,
  });

export const update_cashloan_live = (cashloan, { cashLoanId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cash_loans/` + cashLoanId,
    method: "PUT",
    data: cashloan,
  });

export const remove_cashloan_live = ({ cashLoanId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cash_loans/` + cashLoanId,
    method: "DELETE",
  });

export const create_deleted_cashloan_live = (cashloan) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_cash_loan/`,
    method: "POST",
    data: cashloan,
  });

export const list_deleted_cashloan_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_cash_loan/`,
  });
