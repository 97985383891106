import { fetchRequest } from "../utils";

export const create_inputsupply = (inputsupply) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/input_supply/`,
    method: "POST",
    data: inputsupply,
  });

export const list_inputsupply = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/input_supply/`,
  });

export const read_inputsupply = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/input_supply/` +
      params.inputsupplyId,
  });

export const update_inputsupply = (inputsupply) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/input_supply/`,
    method: "PUT",
    data: inputsupply,
  });

export const remove_inputsupply = (inputsupply) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/input_supply/`,
    method: "DELETE",
    data: inputsupply,
  });

export const create_inputsupply_live = (inputsupply) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/input_supply/`,
    method: "POST",
    data: inputsupply,
  });

export const list_inputsupply_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/input_supply/`,
  });

export const update_inputsupply_live = (inputsupply, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/input_supply/` +
      params.inputSupplyId,
    method: "PUT",
    data: inputsupply,
  });

export const remove_inputsupply_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/input_supply/` +
      params.inputSupplyId,
    method: "DELETE",
  });

export const create_deleted_inputsupply_live = (inputsupply) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_input_supply/`,
    method: "POST",
    data: inputsupply,
  });

export const list_deleted_inputsupply_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_input_supply/`,
  });
