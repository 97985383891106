import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
  create_cooperative_live,
  list_cooperative_live,
  read_cooperative,
  remove_cooperative,
  update_cooperative
} from '../../api/api-cooperative';
import { listseasons_live } from '../../api/api-seasons';
import { update_season_cooperative } from '../../api/api-season_cooperative';
import auth from '../../api/auth-helper';
import Nav from './Nav';
import SideBar from './SideBar';

class Cooperative extends Component {
  constructor() {
    super();
    this.state = {
      cooperatives: [],
      search: '',
      modal: false,
      editmodal: false,
      deletemodal: false,
      update_the_season_cooperative: false,
      Id: '',
      id: '',
      name: '',
      community: '',
      district: '',
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      ward: '',
      add_to_table: false,
      current_season_id: '',
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: ''
    };
    this.handleGetCurrentSeason();
    this.handleListSeason()
    this.handleList();
  }

  componentDidMount(){
    document.title = "Cooperatives"
  }

  handleGetCurrentSeason = () => {
    listseasons_live().then(data => {
      console.log('getting season');
      console.log(data);
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({ current_season_id: data[0]._id });
      }
    });
  };

   handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasonLiveId: data[0].liveId,
            seasonId: data[0]._id,
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
          });
        }
      }
    });
  };

  handleList = () => {
    list_cooperative_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        this.setState({ cooperatives: data });
      }
    });
  };

  updateForm = () => {
    const jwt = auth.isAuthenticated();
    const cooperative = {
      Id: '1',
      _id: this.state.id,
      name: this.state.name,
      community: this.state.community,
      district: this.state.district,
      ward: this.state.ward
    };
    update_cooperative(cooperative, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.handleList();
      }
      this.handleEditModal();
    });
  };

  handleGetDetails = Id => {
    const jwt = auth.isAuthenticated();
    read_cooperative(
      { cooperativeId: Id },
      {
        t: jwt.token
      }
    ).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        this.setState({
          dataAvailable: true,
          Id: '1',
          name: data.name,
          community: data.community,
          ward: data.ward,
          district: data.district
        });
      }
    });
  };

  handleName = event => {
    this.setState({
      name: event.target.value
    });
  };

  handleCommunity = event => {
    this.setState({
      community: event.target.value
    });
  };

  handleDistrict = event => {
    this.setState({
      district: event.target.value
    });
  };

  handleWard = event => {
    this.setState({
      ward: event.target.value
    });
  };

  handleTick = () => {
    this.setState({
      add_to_table: true
    });
  };

  deleteSeason = () => {
    console.log('delete here');
    console.log(this.state.Id);

    remove_cooperative({
      cooperativeId: this.state.Id
    }).then(data => {
      if (!data) {
        console.log(data.error);
      } else {
        /*
				update_season_cooperative
				update_season_remove_cooperative(
					{ seasonId: this.state.current_season_id },
					,
					{ cooperative_id: this.state.Id, season_id: this.state.current_season_id }
				).then(data => {
					if (data.error) {
						console.log(data.error);
					} else {
						console.log('Cooperative removed from season');
					}
				});
				*/
        this.handleList();
      }
    });
    this.handleDeleteModal();
  };

  submitForm = () => {
    console.log(this.state);
    const jwt = auth.isAuthenticated();
    const cooperative = {
      Id: '1',
      name: this.state.name,
      community: this.state.community,
      ward: this.state.ward,
      district: this.state.district
    };
    create_cooperative_live(cooperative, {
      t: jwt.token
    }).then(data => {
      if (data.error) {
        this.setState({ error: data.error });
      } else {
        console.log(data.cooperative);
        console.log(this.state.current_season_id);
        console.log('e don happen');

        if (this.state.update_the_season_cooperative) {
          update_season_cooperative(
            {
              season_id: this.state.current_season_id,
              cooperative: data.cooperative
            },
            {
              t: jwt.token
            }
          ).then(data => {
            if (data.error) {
              this.setState({ error: data.error });
            } else {
              console.log('season cooperative updated');
            }
          });
        }
        this.handleList();
      }
    });
    this.handleModal();
  };
  handleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  handleGetDeleteSeasonId = id => {
    this.setState({
      Id: id
    });
    this.handleDeleteModal();
  };

  handleDeleteModal = () => {
    this.setState(prevState => ({
      deletemodal: !prevState.deletemodal
    }));
  };

  handleGetEditDetails = (id, name, community, ward, district) => {
    this.setState({
      id: id,
      name: name,
      community: community,
      ward: ward,
      district: district
    });
    this.handleEditModal();
  };

  handleEditModal = () => {
    this.setState(prevState => ({
      editmodal: !prevState.editmodal
    }));
  };
  render() {
    // const { search } = this.state;
    // const cooperatives = this.state.cooperatives.filter(cooperative => {
    //   return cooperative.name.toLowerCase().includes(search.toLowerCase())
    // });
  
    return (
      <div className='wrapper'>
        <SideBar />
        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='content'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>people</i>
                        </div>
                        <h4 className='card-title'>Cooperatives</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar' />
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='cooperatives_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='dt-buttons btn-group'>
                                {' '}
                                <button
                                  className='btn btn-secondary buttons-pdf buttons-html5'
                                  tabIndex='0'
                                  aria-controls='cooperatives'
                                  type='button'
                                >
                                  <span>Export PDF</span>
                                </button>{' '}
                                <button
                                  className='btn btn-secondary buttons-excel buttons-html5'
                                  tabIndex='0'
                                  aria-controls='cooperatives'
                                  type='button'
                                >
                                  <span>Export Excel</span>
                                </button>{' '}
                              </div>
                              <div
                                id='cooperatives_filter'
                                className='dataTables_filter'
                              >
                                <label>
                                  Search:
                                  <span className='bmd-form-group bmd-form-group-sm'>
                                    <input
                                      type='search'
                                      className='form-control form-control-sm'
                                      placeholder=''
                                      aria-controls='cooperatives'
                                      onChange={(event) =>
                                        this.setState({
                                          search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                title='Tamanaa Cooperatives'
                                id='cooperatives'
                                className='table table-striped table-no-bordered table-hover dataTable'
                                cellSpacing='0'
                                width='100%'
                                style={{ width: '100%' }}
                                role='grid'
                                aria-describedby='cooperatives_info'
                              >
                                <thead>
                                  <tr role='row'>
                                    {/* <th
                                      className='sorting_asc'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-sort='ascending'
                                      aria-label='ID: activate to sort column descending'
                                      style={{ width: '53px' }}
                                    >
                                      ID
                                    </th> */}
                                    <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='Name: activate to sort column ascending'
                                      style={{ width: '48px' }}
                                    >
                                      Name
                                    </th>

                                    <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='Zone: activate to sort column ascending'
                                      style={{ width: '40px' }}
                                    >
                                      State
                                    </th>
                                    <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='District: activate to sort column ascending'
                                      style={{ width: '56px' }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='Date Added: activate to sort column ascending'
                                      style={{ width: '91px' }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className='disabled-sorting text-right sorting'
                                      tabIndex='0'
                                      aria-controls='cooperatives'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='Actions: activate to sort column ascending'
                                      style={{ width: '99px' }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    {/* <th rowSpan='1' colSpan='1'>
                                      ID
                                    </th> */}
                                    <th rowSpan='1' colSpan='1'>
                                      Name
                                    </th>

                                    <th rowSpan='1' colSpan='1'>
                                      State
                                    </th>
                                    <th rowSpan='1' colSpan='1'>
                                      LGA
                                    </th>
                                    <th rowSpan='1' colSpan='1'>
                                      Date Added
                                    </th>
                                    <th
                                      className='text-right'
                                      rowSpan='1'
                                      colSpan='1'
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {this.state.cooperatives.map((data,id) => (
                                    <tr key={id}>
                                      {/* <td>{id}</td> */}
                                      <td>{data.name}</td>

                                      <td>{data.LGA}</td>
                                      <td>{data.state}</td>
                                      <td>
                                        <Moment format='D MMM YYYY' withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className='text-right inline-block'>
                                        <Link
                                          className='btn btn-outline-primary btn-md edit'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='View Cooperative'
                                          to={{
                                            pathname:
                                            '/accountant_cooperative_detail',
                                            state: {
                                              cooperativeId: data._id,
                                              cooperativeLiveId: data._id,
                                              name: data.name,
                                              type: this.state.type,
                                              start_month: this.state
                                                .start_month,
                                              end_month: this.state.end_month,
                                              year: this.state.year,
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className='dataTables_info'
                                id='cooperatives_info'
                                role='status'
                                aria-live='polite'
                              >
                                Showing 1 to 1 of 1 entries
                              </div>
                              <div
                                className='dataTables_paginate paging_simple_numbers'
                                id='cooperatives_paginate'
                              >
                                <ul className='pagination'>
                                  <li
                                    className='paginate_button page-item previous disabled'
                                    id='cooperatives_previous'
                                  >
                                    <a
                                      href='#/'
                                      aria-controls='cooperatives'
                                      data-dt-idx='0'
                                      tabIndex='0'
                                      className='page-link'
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className='paginate_button page-item active'>
                                    <a
                                      href='#/'
                                      aria-controls='cooperatives'
                                      data-dt-idx='1'
                                      tabIndex='0'
                                      className='page-link'
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className='paginate_button page-item next disabled'
                                    id='cooperatives_next'
                                  >
                                    <a
                                      href='#/'
                                      aria-controls='cooperatives'
                                      data-dt-idx='2'
                                      tabIndex='0'
                                      className='page-link'
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Cooperative;
