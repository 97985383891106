import { fetchRequest } from "../utils";

export const create_incoming_processed_crop = (incoming_processed_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/incoming_processed_crop/`,
    method: "POST",
    data: incoming_processed_crop,
  });

export const list_incoming_processed_crop = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/incoming_processed_crop/`,
  });

export const read_incoming_processed_crop = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/incoming_processed_crop/` +
      params.incoming_processed_cropId,
  });

export const update_incoming_processed_crop = (incoming_processed_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/incoming_processed_crop/`,
    method: "PUT",
    data: incoming_processed_crop,
  });

export const remove_incoming_processed_crop = (incoming_processed_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/incoming_processed_crop/`,
    method: "DELETE",
    data: incoming_processed_crop,
  });

export const create_incoming_processed_crop_live = (incoming_processed_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/incoming_processed_crop/`,
    method: "POST",
    data: incoming_processed_crop,
  });

export const list_incoming_processed_crop_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/incoming_processed_crop/`,
  });

export const remove_incoming_processed_crop_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/incoming_processed_crop/` +
      params.incoming_processed_cropId,
    method: "DELETE",
  });
