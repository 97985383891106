import { fetchRequest } from "../utils";

export const create_warehouse_shipment = (warehouse_shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse_shipment/`,
    method: "POST",
    data: warehouse_shipment,
  });

export const list_warehouse_shipment = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse_shipment/`,
  });

export const read_warehouse_shipment = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse_shipment/` +
      params.warehouse_shipmentId,
  });

export const update_warehouse_shipment = (warehouse_shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse_shipment/`,
    method: "PUT",
    data: warehouse_shipment,
  });

export const remove_warehouse_shipment = (warehouse_shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse_shipment/`,
    method: "DELETE",
    data: warehouse_shipment,
  });

export const create_warehouse_shipment_live = (warehouse_shipment) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse_shipment/`,
    method: "POST",
    data: warehouse_shipment,
  });

export const list_warehouse_shipment_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse_shipment/`,
  });

export const remove_warehouse_shipment_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse_shipment/` +
      params.warehouse_shipmentId,
    method: "DELETE",
  });
