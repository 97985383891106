import { fetchRequest } from "../utils";

//Local SERVER Api endpoints process.env.REACT_APP_LOCAL_SERVER
export const create_crop = (crop) => {
  console.log("api create crop");
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop/`,
    method: "POST",
    data: crop,
  });
};

export const list_crop = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop/` });

export const read_crop = (params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop/` + params.cropId,
  });

export const update_crop = (crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop/`,
    method: "PUT",
    data: crop,
  });

export const update_cropprice = (params, crop) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/update/crop_price` +
      params.cropId,
    method: "PUT",
    data: crop,
  });

export const remove_crop = (crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop/`,
    method: "DELETE",
    data: crop,
  });

export const remove_crop_deleted_live = (crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/crop_deleted_live`,
    method: "DELETE",
    data: crop,
  });

// Live Server Api endpoints process.env.REACT_APP_LIVE_SERVER_PROD

export const create_crop_live = (crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop/`,
    method: "POST",
    data: crop,
  });

export const list_crop_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop/`,
  });

export const read_crop_live = (params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop/` + params.cropId,
  });

export const update_crop_live = (crop, params) => {
  console.log(params.cropId);
  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop/` + params.cropId,
    method: "PUT",
    data: crop,
  });
};

export const remove_crop_live = (cropId) => {
  console.log(cropId);
  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/crop/` + cropId.cropId,
    method: "DELETE",
  });
};
