import { fetchRequest } from "../utils";

export const read_farmer_crop = ({ farmerId }) => {
  return fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_crop/` + farmerId,
  });
};

export const update_farmer_crop = (farmerCrop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_crop/`,
    method: "PUT",
    data: farmerCrop,
  });

export const remove_farmer_crop = (farmerCrop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/farmer_crop/`,
    method: "DELETE",
    data: farmerCrop,
  });

export const remove_farmer_crop_live = ({ farmerId }) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_crop/` + farmerId,
    method: "DELETE",
  });

export const update_farmer_crop_live = (farmerCrop, { farmerId }) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_crop/` + farmerId,
    method: "PUT",
    data: farmerCrop,
  });

export const list_farmer_crop_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_crop/`,
  });

export const read_farmer_crop_live = ({ farmerId }) =>{
return  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/farmer_crop/` + farmerId, 
})
}
