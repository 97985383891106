import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import {
  list_shipment_live,
  update_shipment_live
} from '../../api/api-shipment';
import {list_quality_metric_live, create_quality_metric_live, read_quality_metric_live} from "../../api/api-quality_metric";
import Nav from "./Nav";
import {create_shipment_quality_metric_live, list_shipment_quality_metric_live} from "../../api/api-shipment_quality_metric"
import {list_quality_metric_type_live, remove_quality_metric_type_live} from "../../api/api-quality_metric_type";
import {list_warehouse_shipment_live} from "../../api/api-warehouse_shipment"
import {list_recovery_shipment_live, remove_recovery_shipment_live} from "../../api/api-recovery_shipment";
import {create_payment_voucher_live, list_payment_voucher_live, update_payment_voucher_live} from "../../api/api-payment_voucher";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_modal: false,
      error: "",
      voucher_no: "",
      voucher_id: "",
      status:"",
      recovery_id:"",
      updatedBy: "",
      amount: "",
      shipment: {},
      redirectToShipment: false,
      status: "",
      vouchers: [],
      recoveries: [],
      shipment_quality_metric: [],
      quality_metric: [],
      warehouse_shipment: []
    };

    this.handleListShipment();
    this.handleListVoucher()
    this.handleListRecoveries();
    this.handleListQM();
    this.handleListWarehouseShipment()
  }

  componentDidMount(){
    document.title = "Shipment Detail"
  }

   handleEditModal = () => {
    this.setState((prevState) => ({
      edit_modal: !prevState.modal,
      error: "",
    }));
  };

  handleListShipment = () => {
    list_shipment_live().then(data => {
      if (data.error) {
        return;
      } else {
        let shipment = data.filter(
          shipment => shipment._id === this.props.location.state.shipment_id
        );
        
        this.setState({
          status: shipment[0].status,
          shipment: shipment[0]
        });
      }
    });
  };

  handleShipmentQualityMetric () {
    list_shipment_quality_metric_live().then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.setState({
          shipment_quality_metric: data
        })
        this.handleListShipment();
      }
    })
  }

  checkForm = () => {
    if(!this.state.amount) {
      this.setState({
        error: "Amount is required"
      })
    }

    if(!this.state.voucher_no) {
      this.setState({
        error: "Voucher Number is required"
      })
    }

    if(!this.state.amount && ! this.state.voucher_no){
      this.setState({
        error: "All Fields are required"
      })
    }

    if(this.state.amount && this.state.voucher_no){
      this.handleVoucherSubmit()
        this.handleEditModal()
    }
  }

   handleListVoucher = () => {
    list_payment_voucher_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        let vouchers = data.filter(
          vouchers => vouchers.shipment_id === this.props.location.state.shipment_id
        );
        this.setState({
          vouchers: vouchers
        });
      }
    });
   };

    handleVoucherSubmit = () => {
    const jwt = auth.isAuthenticated();
    const payment_voucher ={
      createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
      status: "Unpaid",
      shipment_id: this.props.location.state.shipment_id,
      recovery: this.state.recovery_id,
      amount: this.state.amount,
      voucher_no: this.state.voucher_no
    }
    create_payment_voucher_live(payment_voucher,
    {
      t: jwt.token
    }
    ).then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.handleListVoucher()
      }
    })
  }

  UpdateVoucher (){
    const jwt = auth.isAuthenticated();

    const payment_voucher = {
      updatedBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      status: 'Paid',
    };
    update_payment_voucher_live(payment_voucher,{payment_voucherId: this.state.voucher_id}, {t: jwt.token})
    .then((data)=>{
      if(!data){
        return;
      }
      if(data){
        this.handleListVoucher()
      }
    })
  }

  handleListWarehouseShipment = () => {
    list_warehouse_shipment_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let warehouse_shipment = data.filter(
          warehouse_shipment => warehouse_shipment.shipment_id._id === this.props.location.state.shipment_id
        );
        this.setState({
          warehouse_shipment: warehouse_shipment
        });
      }
    });
  };

     handleListQM = () => {
    list_quality_metric_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let quality_metric = data.filter(
          quality_metric => quality_metric.shipment_id === this.props.location.state.shipment_id
        );
        this.setState({
          quality_metric: quality_metric
        });
      }
    });
  };


    handleListRecoveries = () => {
    list_recovery_shipment_live({}).then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let recoveries = []
        let recovery_shipment = data.filter(
          recovery =>
            recovery.shipment_id === this.props.location.state.shipment_id
        );
        recovery_shipment.map(recovery =>{
         return recoveries.push(recovery.recovery)
        })
        let total;
        if (recoveries) {
          let array = [];
          recoveries.map(data => {
            return array.push(Number(data.total_weight));
          });
          total = array.reduce((a, b) => a + b, 0);
        }
        this.setState({ total_recovery_weight: total, recoveries: recoveries});
        console.log(this.state.recoveries)
      }
    });
  };

  render() {
    return (
      <div className='wrapper '>
        <SideBar />
        <div className='main-panel'>
          <Nav />

          <div className='content'>
            <div className='content'>
              <Link
                className='btn btn-primary btn-round'
                to='/accountant_shipment'
              >
                Go Back
              </Link>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>people</i>
                        </div>
                        <p className='card-category'>Party Name</p>
                        <h3 className='card-title'>{this.state.shipment.partyName}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_strong</i>
                        </div>
                        <p className='card-category'>Gross Weight</p>
                        <h3 className='card-title'>{this.state.shipment .gross_weight|| ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_weak</i>
                        </div>
                        <p className='card-category'>Tare Weight</p>
                        <h3 className='card-title'>{this.state.shipment.tare_weight || "No Tare Weight"}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>crop_free</i>
                        </div>
                        <p className='card-category'>Net Weight</p>
                        <h3 className='card-title'>{this.state.shipment.net_weight || "No Net Weight"}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>format_color_fill</i>
                        </div>
                        <p className='card-category'>Number of Bags</p>
                        <h3 className='card-title'>{this.state.shipment.num_of_bags || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>grain</i>
                        </div>
                        <p className='card-category'>Driver's Name</p>
                        <h3 className='card-title'>{this.state.shipment.driver_name || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>sms</i>
                        </div>
                        <p className='card-category'>Type</p>
                        <h3 className='card-title'>{this.state.shipment.type}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>spa</i>
                        </div>
                        <p className='card-category'>WeighBridge Number</p>
                        <h3 className='card-title'>{this.state.shipment.weighbridge_number || " "}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='row'>
                  <form method='post' action='.'>
                    <input
                      type='hidden'
                      name='csrfmiddlewaretoken'
                      defaultValue='5HSfKpU6D5Ox36criLSIl59BwpznURVGrRjbuZKnFeVZLnW5OG7xHtBM8xlvlTTV'
                    />
                    <div className='form-group'>
                      <div className='dropdown bootstrap-select'>
                        <select
                          required
                          name='price'
                          className='selectpicker'
                          data-size={4}
                          data-style='btn btn-round btn-info'
                          title='Choose Price'
                          tabIndex={-98}
                        >
                          <option className='bs-title-option' value />
                          <option value={1}>
                            ₦122.00 - Nov. 28, 2018, 10:17 a.m.
                          </option>
                        </select>
                        <button
                          type='button'
                          className='dropdown-toggle bs-placeholder btn btn-round btn-info'
                          data-toggle='dropdown'
                          title='Choose Price'
                        >
                          <div className='filter-option'>
                            <div className='filter-option-inner'>
                              <div className='filter-option-inner-inner'>
                                Choose Price
                              </div>
                            </div>{' '}
                          </div>
                        </button>
                        <div className='dropdown-menu ' role='combobox'>
                          <div
                            className='inner show'
                            role='listbox'
                            aria-expanded='false'
                            tabIndex={-1}
                          >
                            <ul className='dropdown-menu inner show' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      id="update_price"
                      type='hidden'
                      name='update_price'
                      defaultValue={1}
                    />
                    <button type='submit' className='btn btn-fill btn-primary'>
                      Update Price
                    </button>
                  </form>
                  <p />
                </div> */}

              <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Vouchers</h4>
                        {/* <h5 className='card-title'>Total Weight: 652.0Kg</h5>
                        <h5 className='card-title'>Rejected Bags: 0 Bags</h5> */}
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                    {/* <label>
                                      Show{' '}
                                      <select
                                        name='aggregations_length'
                                        aria-controls='aggregations'
                                        className='custom-select custom-select-sm form-control form-control-sm'
                                      >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>{' '}
                                      entries
                                    </label> */}
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Farmer: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Farmer
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Number of Bags: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Amount
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Weight: activate to sort column ascending'
                                          style={{ width: '59px' }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Paid By
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Aggregation Centre: activate to sort column ascending'
                                          style={{ width: '163px' }}
                                        >
                                          Voucher Number
                                        </th>
                                        {/* <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Rejected Bags: activate to sort column ascending'
                                          style={{ width: '122px' }}
                                        >
                                          Rejected Bags
                                        </th> */}
                                        {/* <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Rejected Status: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Rejected Status
                                        </th> */}
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Farmer
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Amount
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Paid By
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Voucher Number
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                         <th className="text-center" rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     {this.state.vouchers.map((data) =>(
                                        
                                           <tr role='row' className='odd'>
                                          <td>{data.recovery.farmer}</td>
                                          <td>₦{data.amount}</td>
                                          <td>{data.status}</td>
                                          <td>{data.updatedBy}</td>
                                          <td>{data.voucher_no}</td>
                                          {/* <td>{this.state.status === "disapproved" ? `${data.num_of_bags}`: `No Rejected Bags`}</td>
                                          <td>{this.state.status === "disapproved" ? `${data.total_weight}`: `No Rejected Bags`}</td> */}
                                          <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                        <td className="text-center"><div
                                        onClick={() =>
                                          {
                                            
                                            this.setState({voucher_id: data._id})
                                            this.UpdateVoucher()
                                          }
                                        }
                                      >
                                        <button
                                          type="submit"
                                          className="btn text-center btn-link btn-just-icon btn-success"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Mark as Collected"
                                        >
                                          <i className="material-icons">
                                            {data.status !== "Paid"
                                              ? "done"
                                              : "done_all"}
                                          </i>
                                        </button>
                                      </div></td>
                                        </tr>
                                       
                                      
                                       )  )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>


              <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>WeighBridge Details</h4>
                        {/* <h5 className='card-title'>Total Weight: 652.0Kg</h5>
                        <h5 className='card-title'>Rejected Bags: 0 Bags</h5> */}
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                    {/* <label>
                                      Show{' '}
                                      <select
                                        name='aggregations_length'
                                        aria-controls='aggregations'
                                        className='custom-select custom-select-sm form-control form-control-sm'
                                      >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>{' '}
                                      entries
                                    </label> */}
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Vehicle No: activate to sort column ascending"
                                          style={{ width: "68px" }}
                                        >
                                          Vehicle No
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Party Name: activate to sort column ascending"
                                          style={{ width: "192px" }}
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "77px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Gross Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Gross Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Tare Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Tare Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Net Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Net Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Created By: activate to sort column ascending"
                                          style={{ width: "76px" }}
                                        >
                                          Created By
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Date Added: activate to sort column ascending"
                                          style={{ width: "62px" }}
                                        >
                                          Date Added
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Vehicle No
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Party Name
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Type
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Gross Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Tare Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Net Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Created By
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Date Added
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     
                                        <tr role='row' className='odd'>
                                          <td>{this.state.shipment.vehicleNumber}</td>
                                          <td>{this.state.shipment.partyName}</td>
                                          <td>{this.state.shipment.type}</td>
                                          <td>{this.state.shipment.gross_weight}</td>
                                          <td>{this.state.shipment.tare_weight}</td>
                                          <td>{this.state.shipment.net_weight}</td>
                                          <td>{this.state.shipment.createdBy}</td>
                                          <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {this.state.shipment.created}
                                        </Moment>
                                        </td>
                                          
                                        </tr>
                                       
                                      
                                        
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>



                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Aggregations</h4>
                        {/* <h5 className='card-title'>Total Weight: 652.0Kg</h5>
                        <h5 className='card-title'>Rejected Bags: 0 Bags</h5> */}
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                    {/* <label>
                                      Show{' '}
                                      <select
                                        name='aggregations_length'
                                        aria-controls='aggregations'
                                        className='custom-select custom-select-sm form-control form-control-sm'
                                      >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>{' '}
                                      entries
                                    </label> */}
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Farmer: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Farmer
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Number of Bags: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Number of Bags
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Weight: activate to sort column ascending'
                                          style={{ width: '59px' }}
                                        >
                                          Weight
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Cost
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Aggregation Centre: activate to sort column ascending'
                                          style={{ width: '163px' }}
                                        >
                                          Aggregation Centre
                                        </th>
                                        {/* <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Rejected Bags: activate to sort column ascending'
                                          style={{ width: '122px' }}
                                        >
                                          Rejected Bags
                                        </th> */}
                                        {/* <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Rejected Weight: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Rejected Weight
                                        </th> */}
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                         <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Created By
                                        </th>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Farmer
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Number of Bags
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Weight
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Cost
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Aggregation Centre
                                        </th>
                                        {/* <th rowSpan={1} colSpan={1}>
                                          Rejected Bags
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Rejected Weight
                                        </th> */}
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Created By
                                        </th>
                                         <th className="text-center" rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     {this.state.recoveries.map((data) =>{
                                        return data.map((recoveries) =>{
                                          return <tr role='row' className='odd'>
                                          <td>{recoveries.farmer}</td>
                                          <td>{recoveries.num_of_bags}</td>
                                          <td>{recoveries.total_weight}Kg</td>
                                          <td>₦{recoveries.total_cost}</td>
                                          <td>{recoveries.recovery_center}</td>
                                          
                                          {/* <td>{this.state.status === "disapproved" ? `${recoveries.num_of_bags}`: `No Rejected Bags`}</td>
                                          <td>{this.state.status === "disapproved" ? `${recoveries.total_weight}`: `No Rejected Bags`}</td> */}
                                          <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {recoveries.created}
                                        </Moment>
                                      </td>
                                      <td>{recoveries.createdBy}</td>
                                          <td className="text-center inline-block">
                                            <div>
                                              <input
                                                type="hidden"
                                                name="csrfmiddlewaretoken"
                                                value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                              />
                                              <input
                                                type="hidden"
                                                name="enter_voucher"
                                                value="1"
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-round btn-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Create Payment Voucher"
                                                onClick={() =>
                                                  this.setState({
                                                   
                                                    recovery_id: recoveries._id,
                                                    edit_modal: true,
                                                    
                                                  })
                                                }
                                              >
                                                Create Voucher
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                        })
                                      }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Warehouse Details</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                   <h4>The Warehouse Shipment was created and approved by {this.state.warehouse_shipment.createdBy}</h4> 
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th>GRN</th>
                                  <th>Total Weight</th>
                                  <th> Number of Bags</th>
                                  <th>WareHouse</th>
                                  <th>Created By</th>
                                  <th>Date Added</th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th>GRN</th>
                                      <th>Total Weight</th>
                                      <th>Number of Bags</th>
                                      <th>WareHouse</th>
                                      <th>Created By</th>
                                      <th>Date Added</th>
                                        
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     {this.state.warehouse_shipment.map((data) => {
                                  return data.shipment_incoming_crop.map(function(shipment_incoming_crop){
                                   return <tr key={data._id}>
                                    <td>{shipment_incoming_crop.grn}</td>
                                    <td>{shipment_incoming_crop.total_weight}</td>
                                    <td>{shipment_incoming_crop.number_of_bags}</td>
                                    <td>{shipment_incoming_crop.warehouse_id.name}</td>
                                    <td>{data.createdBy}</td>
                                        <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                          
                                        </tr>
                                        })
                                      }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Quality Metrics</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                  <h5 className='card-title'>Recommendation :{this.state.quality_metric.recommendation || ""}</h5>
                                  <h5 className='card-title'>Quality Control Suggested Crop Price: {this.state.quality_metric.suggested_price || ""} </h5>
                                    <h4>The Quality Metric was created and approved by {this.state.quality_metric.updatedBy}</h4>
                                  
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th>Metric</th>
                                  <th>Value</th>
                                  <th> Unit</th>
                                  <th>Created By</th>
                                  <th>Date Added</th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th>Metric</th>
                                      <th>Value</th>
                                      <th>Unit</th>
                                      <th>Created By</th>
                                  <th>Date Added</th>
                                        
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     {this.state.quality_metric.map((data) => {
                                  return data.quality_metric_type.map(function(quality_metric_type){
                                   return <tr key={data._id}>
                                    <td>{quality_metric_type.metric}</td>
                                    <td>{data.value}</td>
                                    <td>{quality_metric_type.unit}</td>
                                    <td>{data.createdBy}</td>
                                        <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                          
                                        </tr>
                                        })
                                      }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>

  {!this.state.edit_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createFarmInputModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Create Payment Voucher
                          </h4>
                          <button
                            type="button"
                            className="close"
                            onClick={()=>{
                              this.setState({
                                edit_modal: false
                              })
                            }}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      for="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Voucher Number
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          voucher_no: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="price"
                                      type="number"
                                      className="form-control"
                                      id="inputPrice"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      for="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Amount
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          amount: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="amount"
                                      type="number"
                                      className="form-control"
                                      id="inputamount"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  type="hidden"
                                  name="create_farm_input"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_online"
                                  onClick={this.checkForm}
                                  style={{ display: "block" }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                © 2021 , made with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ShipmentDetail;
