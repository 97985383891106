import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  create_cashloan_live,
  list_cashloan_live,
  remove_cashloan_live,
  update_cashloan_live,
} from "../../api/api-cash_loan";
import { read_farmer_live, update_farmer_live } from "../../api/api-farmer";
import { list_farminput_live } from "../../api/api-farm_inputs";
import { read_crop_live, list_crop_live } from "../../api/api-crop";
import {
  create_inputsupply_live,
  list_inputsupply_live,
  remove_inputsupply_live,
  update_inputsupply_live,
} from "../../api/api-input_supply";
import {
  update_farmer_crop_live,
  read_farmer_crop_live,
} from "../../api/api-farmer_crop";
import { read_farm_live } from "../../api/api-farm";
import { list_recovery_center_cooperative_live } from "../../api/api-recovery_center_cooperative";
import { listseasons_live } from "../../api/api-seasons";
import { read_deposit, update_deposit } from "../../api/api-deposit";
import {update_farmer_next_of_kin_live, list_farmer_next_of_kin_live} from "../../api/api-farmer_next_of_kin";
import { update_farmer_guarantor_live, list_farmer_guarantor_live, create_farmer_guarantor_live } from "../../api/api-farmer_guarantor";
import {read_farmer_season_live} from "../../api/api-farmer_season"
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";
import Farm from "../Shared/Farm";

class OpenMarketSuppliersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletemodal: false,
      deleteinputmodal: false,
      inputsupplymodal: false,
      cashloanmodal: false,
      input_supply: [],
      inputSuppyId: "",
      installment: "",
      installment_error: "",
      installmentmodal: false,
      farm_input_error: "",
      farminputmodal: false,
      cash_loan_amount: "",
      cash_loan_amount_error: "",
      cash_loans: [],
      cashLoanId: "",
      titlemodal: false,
      gendermodal: false,
      bankmodal: false,
      nexttitlemodal: false,
      season_drop_down: false,
      deposit: 0,
      loan: "21000",
      set_lead_famer_drop_down: false,
      title: "",
      first_name: "",
      last_name: "",
      gender: "",
      Id_number: "",
      phone_number: "",
      address: "",
      state: "",
      LGA: "",
      farmers: [],
      farm_size: 1,
      unit: "",
      bank: "",
      account_number: "",
      next_title: "",
      next_first_name: "",
      next_last_name: "",
      next_phone_number: "",
      next_address: "",
      next_relationship: "",
      next_of_kin_id: "",
      nextPhone: "",
      guarantor_id: "",
      guarantor_first_name: "",
      guarantor_last_name: "",
      guarantor_phone_number: "",
      guarantorPhone: "",
      guarantor_address: "",
      farmer_season_id: "",
      first_name_error: "",
      last_name_error: "",
      Id_number_error: "",
      phone_number_error: "",
      address_error: "",
      zone_error: "",
      LGA_error: "",
      farm_size_error: "",
      next_title_error: "",
      next_first_name_error: "",
      next_last_name_error: "",
      next_phone_number_error: "",
      next_address_error: "",
      guarantor_first_name_error: "",
      guarantor_last_name_error: "",
      guarantor_phone_number_error: "",
      guarantor_address_error: "",
      farm_inputs: [],
      farm_input_name: "",
      farm_input_type: "",
      farm_input_price: "",
      farm_input_quantity: "",
      farm_input_id: "",
      farm_input_inventory: "",
      error: "",
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      input_supply_total: 0,
      cash_loan_total: 0,
      recoveries: [],
      season_deposit: 0,
      loan_recovered: 0,
      cooperativeId: "",
      offline_modal: false,
      liveId: "",
      allocated_loan_per_hectare: 0,
      loan_per_acre: 0,
      crop: "",
      crop_name: "",
      selected_crops: [],
      crops: [],
      crop_id: "",
      crop_error: "",
      crop_modal: "",
      organization_id: "",
      modals: {
        day: false,
        month: false,
        year: false,
        crop: false,
      },
    };
    console.log(this.props.location);
    this.handleGetFarmerDetails();

    this.handleListCrop();
    this.handleFarm();
    this.handleListSeason();
    this.handleListRecoveries();
    this.handleFarmerCrop();
    this.handleNextOfKin();
    this.handleGuarantor();
    this.handleFarmerSeason();
  }

  componentDidMount() {
    document.title = "Farmer Detail";
    console.log(this.props.location.state);
  }

  handleCropModal = () => {
    this.setState((prevState) => ({
      cropmodal: !prevState.cropmodal,
    }));
  };

   handleFarmerSeason = () => {
    read_farmer_season_live({farmer_id: this.props.location.state.farmerId}).then((data) => {
      if(!data) {
       this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if(data){
          console.log(data.farmer_season)
          this.setState({
          farmer_season_id: data.farmer_season[0]._id
           });
           console.log(this.state.farmer_season_id)  
        }
            
    })
  }

  handleFarmerCrop = () => {
    read_farmer_crop_live({
      farmerId: this.props.location.state.farmerId,
    }).then((data) => {
      if (!data) {
        this.setState({
          selected_crops: [],
        });
      } else if (data.error) {
        this.setState({
          selected_crops: [],
        });
      } else if (data) {
        this.setState({
          selected_crops: data.farmer_crop.crops,
        });
      }
      console.log(this.state.selected_crops);
    });
  };

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        return;
      } else {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
          });
        }
      }
    });
  };

  handleNextOfKin =  () => {
     list_farmer_next_of_kin_live({ farmer_id: this.props.location.state.farmerId }).then(
       (data) => {
         console.log(data)
         if(!data) {
           this.setState({
             next_of_kin_id: "",
             next_address: "",
             next_first_name:"",
             next_phone_number: "",
             next_last_name: "",
             next_relationship: ""
           })
         }
         else if (data.error) {
          this.setState({
            next_of_kin_id: "",
            next_address: "",
            next_first_name:"",
            next_phone_number: "",
            next_last_name: "",
            next_relationship: ""
          })
         }
         else if(data){
          this.setState({
            next_of_kin_id: data.farmer_next_of_kin._id,
            next_address: data.farmer_next_of_kin.address,
            next_first_name: data.farmer_next_of_kin.first_name,
            next_phone_number: data.farmer_next_of_kin.phone_number,
            next_last_name: data.farmer_next_of_kin.last_name,
            next_relationship: data.farmer_next_of_kin.next_relationship
          })
         }
       }
     );
   
  };

  handleGuarantor =  () => {
    list_farmer_guarantor_live({ farmer_id: this.props.location.state.farmerId }).then(
      (data) => {
        console.log(data)
        if(!data) {
          this.setState({
            guarantor_id: "",
            guarantor_address: "",
            guarantor_first_name:"",
            guarantor_phone_number: "",
            guarantor_last_name: "",
          })
        }
        else if (data.error) {
         this.setState({
           guarantor_id: "",
           guarantor_address: "",
           guarantor_first_name:"",
           guarantor_phone_number: "",
           guarantor_last_name: "",
         })
        }
        else if(data){
         this.setState({
           guarantor_id : data.farmer_guarantor._id,
           guarantor_address: data.farmer_guarantor.address,
           guarantor_first_name: data.farmer_guarantor.first_name,
           guarantor_phone_number: data.farmer_guarantor.phone_number,
           guarantor_last_name: data.farmer_guarantor.last_name,
           guarantorPhone: data.farmer_guarantor.phone_number
         })
        }
      }
    );
  
 };

  handleFarm = () => {
    read_farm_live({ farmerId: this.props.location.state.farmerId }).then(
      (data) => {
        if (!data) {
          this.setState({
            unit: "Hectares",
            farm_size: 1,
          });
        } else if (data.error) {
          this.setState({
            unit: "Hectares",
            farm_size: 1,
          });
        } else if (data) {
          this.setState({
            farm_size: data.farm.size,
            unit: data.farm.unit,
          });
        }
        console.log(this.state.unit);
      }
    );
  };

  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleCropModal = () => {
    this.setState((prevState) => ({
      cropmodal: !prevState.cropmodal,
    }));
  };

  handleOffline = () => {
    this.setState((prevState) => ({
      offline_modal: !prevState.offline_modal,
    }));
  };


  handleFarmInputModal = () => {
    this.setState((prevState) => ({
      farminputmodal: !prevState.farminputmodal,
      error: "",
    }));
  };

  handleTitleModal = () => {
    this.setState((prevState) => ({
      titlemodal: !prevState.titlemodal,
    }));
  };

  handleGenderModal = () => {
    this.setState((prevState) => ({
      gendermodal: !prevState.gendermodal,
    }));
  };

  handleInstallmentModal = () => {
    this.setState((prevState) => ({
      installmentmodal: !prevState.installmentmodal,
      error: "",
    }));
  };

  handleBankModal = () => {
    this.setState((prevState) => ({
      bankmodal: !prevState.bankmodal,
    }));
  };

  handleNexttitleModal = () => {
    this.setState((prevState) => ({
      nexttitlemodal: !prevState.nexttitlemodal,
    }));
  };

  handleCashLoanModal = () => {
    this.setState((prevState) => ({
      cashloanmodal: !prevState.cashloanmodal,
      error: "",
    }));
  };

  handleInputSuppyModal = () => {
    this.setState((prevState) => ({
      inputsupplymodal: !prevState.inputsupplymodal,
      error: "",
    }));
  };

  handleDeleteInputModal = () => {
    this.setState((prevState) => ({
      deleteinputmodal: !prevState.deleteinputmodal,
    }));
  };

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  checkSeasonDeposit = () => {
    if (!this.state.season_deposit) {
      this.setState({
        season_deposit_error: "has-danger",
      });
    }

    if (this.state.season_deposit > 0) {
      this.seasonDepositForm();
    }
  };

  

  handleListRecoveries = () => {
    list_recovery_center_cooperative_live().then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        let total_cost_array = [];
        let total_cost;
        let recovery = data.filter(
          (recovery) => recovery.farmer === this.props.location.state.first_name + "  "+ this.props.location.state.last_name
        );
        console.log(recovery)
        if (recovery.length !== 0) {
          console.log(recovery);
          recovery.map((data) => {
            return total_cost_array.push(Number(data.total_cost));
          });

          total_cost = total_cost_array.reduce((a, b) => a + b, 0);

          this.setState({
            recoveries: recovery,
            loan_recovered: total_cost,
          });
          console.log(this.state.loan_recovered);
        } else if (recovery.length === 0) {
          this.setState({
            recoveries: [],
            loan_recovered: "0",
          });
        }
      }
    });
  };

  submitInputSupplyForm = () => {
    const jwt = auth.isAuthenticated();
    console.log(this.props.location);
    const inputsupply = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      farmerLiveId: this.props.location.state.farmerId,
      cooperativeLiveId: this.state.cooperativeId,
      name: this.state.farm_input_name,
      type: this.state.farm_input_type,
      quantity: this.state.farm_input_quantity,
      price: this.state.farm_input_price,
      cost: this.state.farm_input_quantity * this.state.farm_input_price,
      farmInputId: this.state.farm_input_id,
      collected: false,
      organization_id: auth.isAuthenticated().user.organization_id,
      crop: this.state.crop_id,
      farmer_season_id: this.state.farmer_season_id
    };
    create_inputsupply_live(inputsupply, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListInputSupply();
        this.handleInputSuppyModal();

        this.setState({
          farm_input_name: "",
          farm_input_type: "",
          farm_input_quantity: "",
          farm_input_price: "",
        });
        /*
				const inputsupply = {
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					liveId: data.data._id,
					cooperativeId: this.state.cooperativeId,
					farmerId: this.props.location.state.farmerId,
					name: this.state.farm_input_name,
					type: this.state.farm_input_type,
					quantity: this.state.farm_input_quantity,
					price: this.state.farm_input_price,
					cost: this.state.farm_input_quantity * this.state.farm_input_price,
					collected: false,
				};
				create_inputsupply(inputsupply, {
					t: jwt.token,
				}).then(data => {
					if (!data) {
						return;
					}
					if (data) {
						this.handleListInputSupply();
					}
				});
				this.handleInputSuppyModal();

				this.setState({
					farm_input_name: '',
					farm_input_type: '',
					farm_input_quantity: '',
					farm_input_price: '',
				});
				*/
      }
    });
  };

  

  editFarmerCrop = () => {
    const jwt = auth.isAuthenticated();
    const farmerCrop = {
      crops: this.state.selected_crops,
    };
    update_farmer_crop_live(
      farmerCrop,
      { farmerId: this.props.location.state.farmerId },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleFarmerCrop();
      }
    });
  };

  editNextOfKin = () =>{
    const jwt = auth.isAuthenticated();
    if (this.state.next_phone_number !== "") {
      console.log("Running");
      let next_phone_num = this.state.next_phone_number;
      let nextPhoneNumber = "+234" + next_phone_num.substring(1, 11);
      this.setState({
        nextPhone: nextPhoneNumber,
      });
    } else {
      this.setState({
        nextPhoneNumber: "",
      });
    }
    const farmer_next_of_kin ={
      createdBy: auth.isAuthenticated().user.first_name + " " +
      auth.isAuthenticated().user.last_name,
      relationship: this.state.next_title,
      first_name: this.state.next_first_name,
      last_name: this.state.next_last_name,
      phone_number: this.state.nextPhone,
      address: this.state.next_address,
      farmer_id: this.props.location.state.farmerId
    }
    update_farmer_next_of_kin_live(
      farmer_next_of_kin,
      {farmer_next_of_kinId: this.state.next_of_kin_id},
      {
        t:  jwt.token,
      }
    ).then((data) => {
      if (!data){
        return;
      }
      if (data) {
        this.handleNextOfKin();
      }
    })
  }

  editGuarantor = () =>{
    const jwt = auth.isAuthenticated();
    console.log(this.state.guarantor_phone_number)
    if (this.state.guarantor_phone_number !== "" ) {
      console.log("hi")
      if(this.state.guarantor_phone_number.substring(0,3) !== "+234"){
        console.log("hi again")
        let guarantor_phone_num = this.state.guarantor_phone_number;
        let guarantorPhone = "+234" + guarantor_phone_num.substring(1, 11);
        this.setState({guarantorPhone: guarantorPhone})
      }
      else {
      this.setState({
        guarantorPhone: this.state.guarantor_phone_number,
      });
      } 
    } else {
      this.setState({
        guarantorPhone: "",
      });
    }
    const farmer_guarantor ={
      createdBy: auth.isAuthenticated().user.first_name +
              " " +
      auth.isAuthenticated().user.last_name,
      relationship: this.state.guarantor_title,
      first_name: this.state.guarantor_first_name,
      last_name: this.state.guarantor_last_name,
      phone_number: this.state.guarantorPhone,
      address: this.state.guarantor_address,
      farmer_id: this.props.location.state.farmerId
    }
    if(this.state.guarantor_id){
    update_farmer_guarantor_live(
      farmer_guarantor,
      {farmer_guarantorId: this.state.guarantor_id},
      {
        t:  jwt.token,
      }
    ).then((data) => {
      if (!data){
        return;
      }
      if (data) {
        this.handleGuarantor();
      }
    })
  }
  else {
    create_farmer_guarantor_live(farmer_guarantor,{t: jwt.token}).then((data) =>{
      if (data.error) {
        this.setState({
          mssg: `Could not create Farmer`,
          error_notification: true,
        });
      } 
    })
  }
  }

  editFarmerForm = () => {
    this.editFarmerCrop();
    const jwt = auth.isAuthenticated();
    const farmer = {
      title: this.state.title,
      gender: this.state.gender,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      Id_number: this.state.Id_number,
      phone_number: this.state.phone_number,
      address: this.state.address,
      state: this.state.state,
      LGA: this.state.LGA,
      farm_size: this.state.farm_size,
      bank: this.state.bank,
      account_number: this.state.account_number,
      lead_farmer: this.state.lead_farmer,
      allocated_loan_per_hectare: this.state.loan_per_acre,
    };
    update_farmer_live(
      farmer,
      { farmerId: this.props.location.state.farmerId },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleGetFarmerDetails();
      }
    });
  };

  deleteCashLoan = (id) => {
    remove_cashloan_live({
      cashLoanId: id,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListCashLoans();
        this.setState({ deletemodal: false });
      }
    });
  };

  handleListCashLoans = () => {
    list_cashloan_live().then((data) => {
      if (!data) {
        //console.log(data.error);
        return;
      } else if (data) {
        if (data.length === 0) {
          this.setState({ cash_loans: [], cash_loan_total: 0 });
        }

        if (data.length !== 0) {
          let cash_loan_array = [];
          let total;
          let cash_loans = data.filter(
            (cash_loan) =>
              cash_loan.farmerLiveId === this.props.location.state.farmerId
          );
          if (cash_loans.length !== 0) {
            let collected_cash_loan = cash_loans.filter(
              (cash_loan) => cash_loan.collected === true
            );
            collected_cash_loan.map((data) => {
              return cash_loan_array.push(Number(data.amount));
            });

            total = cash_loan_array.reduce((a, b) => a + b, 0);
            console.log(total)
          }
          this.setState({ cash_loans: cash_loans, cash_loan_total: total });
          if (cash_loans.length === 0) {
            this.setState({ cash_loans: [], cash_loan_total: 0 });
          }
        }
      }
    });
  };

  handleGetInputSupplyId = (Id, liveId, collected) => {
    if (collected === false) {
      this.editInputSupplyForm(Id, liveId);
    }

    if (collected === true) {
      return;
    }
  };

  editInputSupplyForm = (id, liveId) => {
    const jwt = auth.isAuthenticated();
    const inputsupply = {
      collected: true,
    };
    update_inputsupply_live(
      inputsupply,
      { inputSupplyId: id },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListInputSupply();
        this.setState({
          inputSuppyId: "",
        });
      }
    });
  };

  deleteInputSupply = (id) => {
    remove_inputsupply_live({
      inputSupplyId: id,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListInputSupply();
      }
      this.setState({ deleteinputmodal: false });
    });
  };

  handleGetFarmerDetails = () => {
    read_farmer_live({ farmerId: this.props.location.state.farmerId }).then(
      (data) => {
        if (!data) {
          return;
        }
        if (data) {
          if (data.length === 0) {
            return;
          }
          if (data.length !== 0) {
            console.log(data);
            this.setState({
              cooperativeId: data.cooperativeLiveId,
              title: data.title,
              first_name: data.first_name,
              last_name: data.last_name,
              gender: data.gender,
              Id_number: data.Id_number,
              phone_number: data.phone_number,
              address: data.address,
              state: data.state,
              LGA: data.LGA,
              bank: data.bank,
              account_number: data.account_number,
              allocated_loan_per_hectare: data.allocated_loan_per_hectare,
              loan_per_acre: data.allocated_loan_per_hectare,
            });
          }
        }
      }
    );
  };

  handleSeasonDropDown = () => {
    this.setState((prevState) => ({
      season_drop_down: !prevState.season_drop_down,
    }));
  };

  handleSetFarmerDropDown = () => {
    this.setState((prevState) => ({
      set_lead_famer_drop_down: !prevState.set_lead_famer_drop_down,
    }));
  };
  render() {
    const farmer_id = this.props.location.state.farmerId;
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              {this.props.location.state.direct === "farmer" ? (
                <Link className="btn btn-primary btn-round" to="/admin_farmers">
                  Go Back
                </Link>
              ) : this.props.location.state.direct === "cooperative" ? (
                <Link
                  className="btn btn-primary btn-round"
                  to={{
                    pathname: "/admin_cooperative_details",
                    state: {
                      cooperativeLiveId: this.props.location.state
                        .cooperativeLiveId,
                      cooperativeId: this.props.location.state
                        .cooperativeLiveId,
                      name: this.props.location.state.name,
                      type: this.props.location.state.type,
                      start_month: this.props.location.state.start_month,
                      end_month: this.props.location.state.end_month,
                      year: this.props.location.state.year,
                    },
                  }}
                >
                  Go Back
                </Link>
              ) : (
                ""
              )}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-6">
                    <h2>
                      {" "}
                      {`${this.props.location.state.first_name} ${this.props.location.state.last_name}`}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          {/* <div className="dropdown">
                            <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Change Season
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownSeasonButton"
                            >
                              <h6 className="dropdown-header">Seasons</h6>

                              {/* <a
                                 className="dropdown-item"></a>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <p className="card-category">Amount Owed to Farmer</p>
                        <h3 className="card-title">Owed Nothing</h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <b></b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <p className="card-category">Amount Paid to Farmer</p>
                        <h3 className="card-title"></h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <p>
                            This is the amount the company has paid to the
                            farmer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="card ">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">person</i>
                        </div>
                        <h4 className="card-title">Basic Information</h4>
                      </div>
                      <div>
                        <div className="card-body ">
                          <div
                            className={
                              this.state.first_name
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group `
                            }
                          >
                            <label
                              htmlFor="firstName"
                              className="bmd-label-floating"
                            >
                              First Name
                            </label>
                            <input
                              name="first_name"
                              type="text"
                              className="form-control"
                              id="firstName"
                              onChange={(event) =>
                                this.setState({
                                  first_name: event.target.value,
                                })
                              }
                              value={this.state.first_name}
                            />
                          </div>
                          <div
                            className={
                              this.state.last_name
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group `
                            }
                          >
                            <label
                              htmlFor="lastName"
                              className="bmd-label-floating"
                            >
                              Last Name
                            </label>
                            <input
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="lastName"
                              onChange={(event) =>
                                this.setState({ last_name: event.target.value })
                              }
                              value={this.state.last_name}
                            />
                          </div>
                          <div
                            className={
                              this.state.phone_number
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group `
                            }
                          >
                            <label
                              htmlFor="phoneNumber"
                              className="bmd-label-floating"
                            >
                              Phone Number
                            </label>
                            <input
                              name="phone_number"
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              onChange={(event) =>
                                this.setState({
                                  phone_number: event.target.value,
                                })
                              }
                              value={this.state.phone_number}
                            />
                          </div>
                          <div className="form-group">
                            <div
                              className={
                                !this.state.gendermodal
                                  ? "dropdown bootstrap-select"
                                  : "dropdown bootstrap-select show"
                              }
                            >
                              <button
                                type="button"
                                className="dropdown-toggle btn btn-success"
                                data-toggle="dropdown"
                                role="button"
                                title="Alh."
                                aria-expanded="true"
                                onClick={this.handleGenderModal}
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      {!this.state.gender
                                        ? "Choose Gender"
                                        : this.state.gender}
                                    </div>
                                  </div>{" "}
                                </div>
                                <div className="ripple-container" />
                              </button>
                              <div
                                className={
                                  !this.state.gendermodal
                                    ? "dropdown-menu"
                                    : "dropdown-menu show"
                                }
                                role="combobox"
                                x-placement="bottom-start"
                                style={{
                                  overflow: "hidden",
                                  position: "absolute",
                                  top: "46px",
                                  left: "1px",
                                  willChange: "top, left",
                                }}
                              >
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="true"
                                  tabIndex="-1"
                                  style={{ overflowY: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="disabled">
                                      <a
                                        role="option"
                                        className="dropdown-item disabled"
                                        aria-disabled="true"
                                        tabIndex="-1"
                                        aria-selected="false"
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Choose Gender
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.gender !== "Male"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            gender: "Male",
                                            gendermodal: false,
                                            gender_error: "",
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">Male</span>
                                      </a>
                                    </li>

                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.gender !== "Female"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            gender: "Female",
                                            gendermodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">Female</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.address
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group `
                            }
                          >
                            <label
                              htmlFor="address"
                              className="bmd-label-floating"
                            >
                              Address
                            </label>
                            <input
                              name="address"
                              type="text"
                              className="form-control"
                              id="address"
                              onChange={(event) =>
                                this.setState({ address: event.target.value })
                              }
                              value={this.state.address}
                            />
                          </div>
                          {/* <div
                            className={
                              this.state.state
                                ? 'is-filled form-group bmd-form-group'
                                : `form-group bmd-form-group `
                            }
                          >
                            <label htmlFor='ward' className='bmd-label-floating'>
                              Zone
                            </label>
                            <input
                              name='state'
                              type='text'
                              className='form-control'
                              id='state'
                              onChange={event =>
                                this.setState({ state: event.target.value })
                              }
                              value={this.state.state}
                            />
                          </div> */}
                          <div
                            className={
                              this.state.LGA
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group `
                            }
                          >
                            <label htmlFor="lga" className="bmd-label-floating">
                              LGA
                            </label>
                            <input
                              name="LGA"
                              type="text"
                              className="form-control"
                              id="LGA"
                              onChange={(event) =>
                                this.setState({ LGA: event.target.value })
                              }
                              value={this.state.LGA}
                            />
                          </div>

                          <div className="form-check">
                            <p>Farmers Crops</p>
                            {this.state.crops.map((data) => {
                              let check = false;
                              this.state.selected_crops.map((data2) => {
                                if (data2._id === data._id) {
                                  check = true;
                                }
                              });
                              return (
                                <label className="form-check-label">
                                  <input
                                    name="crop"
                                    onChange={(event) => {
                                      console.log(event.target.checked);
                                      console.log(check);
                                      if (event.target.checked === true) {
                                        check = false;
                                        this.state.selected_crops.push(
                                          event.target.value
                                        );
                                      } else if (
                                        event.target.checked === false
                                      ) {
                                        check = true;
                                        this.state.selected_crops.pop(
                                          event.target.value
                                        );
                                      }
                                      console.log(this.state.selected_crops);
                                      return this.setState({
                                        crop: this.state.selected_crops,
                                      });
                                    }}
                                    className="form-check-input"
                                    checked={check}
                                    type="checkbox"
                                    value={data._id}
                                    id="add_to_season"
                                  />
                                  {data.name}
                                  <span className="form-check-sign">
                                    <span className="check" />
                                  </span>
                                </label>
                              );
                            })}
                          </div>

                          <div className="form-group">
                            <select
                              required
                              name="cooperative"
                              className="selectpicker"
                              data-size="4"
                              data-style="btn btn-success"
                              title="Choose Cooperative"
                            >
                              <option disabled>Choose Cooperative</option>
                            </select>
                          </div>
                        </div>
                        <div className="card-footer ">
                          <input type="hidden" name="basic" value="1" />
                          <button
                            onClick={
                              navigator.onLine
                                ? this.editFarmerForm
                                : this.handleOffline
                            }
                            type="submit"
                            className="btn btn-fill btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card ">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">attach_money</i>
                        </div>
                        <h4 className="card-title">Account Information</h4>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="2jAKhdGK8PeeSmPAxVOgJPevfdKpWaluIcn3GfhCItt7JGIspxDsDfxCzmpoeCaZ"
                        />
                        <div className="card-body ">
                          <div className="form-group">
                            <div className="dropdown bootstrap-select show">
                              <button
                                type="button"
                                className="dropdown-toggle bs-placeholder btn btn-success"
                                data-toggle="dropdown"
                                title="Choose Bank"
                                onClick={() => this.handleBankModal()}
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      {!this.state.bank
                                        ? "Choose Bank"
                                        : this.state.bank}
                                    </div>
                                  </div>{" "}
                                </div>
                              </button>
                              <div
                                className={
                                  !this.state.bankmodal
                                    ? "dropdown-menu"
                                    : "dropdown-menu show"
                                }
                                role="combobox"
                                x-placement="bottom-start"
                                style={{
                                  maxHeight: "162px",
                                  overflow: "hidden",
                                  minWidth: "452px",
                                  position: "absolute",
                                  top: "46px",
                                  left: "1px",
                                  willChange: "top, left",
                                }}
                              >
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="true"
                                  tabIndex="-1"
                                  style={{
                                    maxHeight: "152px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="disabled">
                                      <a
                                        role="option"
                                        className="dropdown-item disabled"
                                        aria-disabled="true"
                                        tabIndex="-1"
                                        aria-selected="false"
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Choose Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Access Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Access Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Access Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Citibank Nigeria Limited"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Citibank Nigeria Limited",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Citibank Nigeria Limited
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Ecobank Nigeria Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Ecobank Nigeria Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Ecobank Nigeria Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Fidelity Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Fidelity Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Fidelity Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "First Bank Nigeria Limited"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "First Bank Nigeria Limited",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          First Bank Nigeria Limited
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "First City momnument Bank"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "First City momnument Bank",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">FCMB</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Globus Bank Limited"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Globus Bank Limited",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Globus Bank Limited
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Guaranty Trust Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Guaranty Trust Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Guaranty Trust Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Heritage Banking Company Ltd"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank:
                                              "Heritage Banking Company Ltd",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Heritage Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Keystone Bank"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Keystone Bank",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Keystone Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Polaris Bank"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Polaris Bank",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Polaris Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Providus Bank"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Providus Bank",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Providus Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Stanbic IBTC Bank Ltd"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Stanbic IBTC Bank Ltd",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Stanbic IBTC Bank Ltd
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Standard Chartered Bank Nigeria Ltd"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank:
                                              "Standard Chartered Bank Nigeria Ltd",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Standard Chartered Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Sterling Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Sterling Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Sterling Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "SunTrust Bank Nigeria Limited"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank:
                                              "SunTrust Bank Nigeria Limited",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          SunTrust Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Titan Trust Bank Ltd"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Titan Trust Bank Ltd",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Titan Trust Bank
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "Union Bank of Nigeria Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Union Bank of Nigeria Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Union Bank of Nigeria Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !==
                                          "United Bank For Africa Plc "
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "United Bank For Africa Plc ",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          United Bank for Africa Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Unity Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Unity Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Unity Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Wema Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Wema Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Wema Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.bank !== "Zenith Bank Plc"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            bank: "Zenith Bank Plc",
                                            bankmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Zenith Bank Plc
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="editaccount_number"
                              className="bmd-label-floating"
                            >
                              Enter Account Number
                            </label>
                            <input
                              name="account_number"
                              type="text"
                              className="form-control"
                              id="editaccount_number"
                              onChange={(event) =>
                                this.setState({
                                  account_number: event.target.value,
                                })
                              }
                              value={this.state.account_number}
                            />
                          </div>
                        </div>
                        <div className="card-footer ">
                          <input type="hidden" name="account_info" value="1" />
                          <button
                            onClick={
                              navigator.onLine
                                ? this.editFarmerForm
                                : this.handleOffline
                            }
                            type="submit"
                            className="btn btn-fill btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>               
              </div>
            </div>
          </div>

          {!this.state.inputsupplymodal ? (
            ""
          ) : (
            <div
              className="modal fade show"
              id="addCooperativeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style={{
                display: "block",
                paddingRight: "5px",
                paddingTop: "100px",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      Add Farm Input for Farmer's Season
                    </h4>

                    <button
                      onClick={this.handleInputSuppyModal}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <h4 style={{ color: "red", alignSelf: "center" }}>
                    {this.state.error}
                  </h4>
                  <div className="modal-body">
                    <div className="card ">
                      <div className="card-body ">
                        <div>
                          <div className="form-group">
                            <div
                              className={
                                !this.state.farminputmodal
                                  ? "dropdown bootstrap-select"
                                  : "dropdown bootstrap-select show"
                              }
                            >
                              <button
                                type="button"
                                className="dropdown-toggle btn btn-success"
                                onClick={this.handleFarmInputModal}
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      {!this.state.farm_input_name &&
                                      !this.state.farm_input_type
                                        ? "Choose Farm Input"
                                        : `${this.state.farm_input_name} - ${this.state.farm_input_type}`}
                                    </div>
                                  </div>{" "}
                                </div>
                                <div className="ripple-container" />
                              </button>
                              <div
                                className={
                                  !this.state.farminputmodal
                                    ? "dropdown-menu"
                                    : "dropdown-menu show"
                                }
                                role="combobox"
                                x-placement="bottom-start"
                                style={{
                                  overflow: "hidden",
                                  position: "absolute",
                                  top: "46px",
                                  left: "1px",
                                  willChange: "top, left",
                                }}
                              >
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="true"
                                  tabIndex="-1"
                                  style={{ overflowY: "auto", height: "156px" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="disabled">
                                      <a
                                        role="option"
                                        className="dropdown-item disabled"
                                        aria-disabled="true"
                                        tabIndex="-1"
                                        aria-selected="false"
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Choose Farm Input
                                        </span>
                                      </a>
                                    </li>
                                    {this.state.farm_inputs.map((data) => (
                                      <li>
                                        <a
                                          role="option"
                                          className={
                                            this.state.farm_input_name !==
                                              data.name &&
                                            this.state.farm_input_type !==
                                              data.type
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            console.log(data);
                                            console.log(data.quantity);
                                            this.setState({
                                              farm_input_name: data.name,
                                              farm_input_type: data.type,
                                              farm_input_price: data.price,
                                              farm_input_error: "",
                                              farm_input_inventory:
                                                data.quantity,
                                              farm_input_id: data._id,
                                              farminputmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {`${data.name} - ${data.type}`}
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.selected_crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="form-group bmd-form-group is-filled">
                            <label
                              htmlFor="quantityField"
                              className="bmd-label-floating"
                            >
                              Enter Amount
                            </label>
                            <input
                              onChange={(event) =>
                                this.setState({
                                  farm_input_quantity: event.target.value,
                                  error: "",
                                })
                              }
                              required=""
                              name="amount"
                              type="number"
                              className="form-control"
                              step="0.01"
                              id="amountField"
                              min="0"
                            />
                          </div>
                          <input type="hidden" name="add_cash_loan" value="1" />
                          <button
                            onClick={this.checkForm}
                            type="submit"
                            className="btn btn-fill btn-primary"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!this.state.cashloanmodal ? (
            ""
          ) : (
            <div
              className="modal fade show"
              id="addCooperativeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style={{
                display: "block",
                paddingRight: "5px",
                paddingTop: "100px",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      Add Cash loan for Farmer's Season
                    </h4>
                    <h4 className="modal-title" style={{ color: "red" }}>
                      Please confirm the value below before creating.
                    </h4>
                    <button
                      onClick={this.handleCashLoanModal}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="card ">
                      {this.state.installment_error ? (
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          Installment is required
                        </h4>
                      ) : (
                        ""
                      )}
                      <div className="card-body ">
                        <div>
                          <div className="form-group">
                            <div
                              className={
                                !this.state.installmentmodal
                                  ? "dropdown bootstrap-select"
                                  : "dropdown bootstrap-select show"
                              }
                            >
                              <button
                                type="button"
                                className="dropdown-toggle btn btn-success"
                                onClick={this.handleInstallmentModal}
                              >
                                <div className="filter-option">
                                  <div className="filter-option-inner">
                                    <div className="filter-option-inner-inner">
                                      {!this.state.installment
                                        ? "Choose Installment"
                                        : this.state.installment}
                                    </div>
                                  </div>{" "}
                                </div>
                                <div className="ripple-container" />
                              </button>
                              <div
                                className={
                                  !this.state.installmentmodal
                                    ? "dropdown-menu"
                                    : "dropdown-menu show"
                                }
                                role="combobox"
                                x-placement="bottom-start"
                                style={{
                                  overflow: "hidden",
                                  position: "absolute",
                                  top: "46px",
                                  left: "1px",
                                  willChange: "top, left",
                                }}
                              >
                                <div
                                  className="inner show"
                                  role="listbox"
                                  aria-expanded="true"
                                  tabIndex="-1"
                                  style={{ overflowY: "auto" }}
                                >
                                  <ul className="dropdown-menu inner show">
                                    <li className="disabled">
                                      <a
                                        role="option"
                                        className="dropdown-item disabled"
                                        aria-disabled="true"
                                        tabIndex="-1"
                                        aria-selected="false"
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          Choose Installment
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="option"
                                        className={
                                          this.state.installment !==
                                          "1st Installment"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() =>
                                          this.setState({
                                            installment: "1st Installment",
                                            installment_error: "",
                                            installmentmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          1st Installment
                                        </span>
                                      </a>
                                    </li>
                                    <li className="selected active">
                                      <a
                                        role="option"
                                        className={
                                          this.state.installment !==
                                          "2nd Installment"
                                            ? "dropdown-item"
                                            : "dropdown-item selected active"
                                        }
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="true"
                                        onClick={() =>
                                          this.setState({
                                            installment: "2nd Installment",
                                            installment_error: "",
                                            installmentmodal: false,
                                          })
                                        }
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          2nd Installment
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              this.state.cash_loan_amount
                                ? "is-filled form-group bmd-form-group"
                                : `form-group bmd-form-group ${this.state.cash_loan_amount_error}`
                            }
                          >
                            <label
                              htmlFor="quantityField"
                              className="bmd-label-floating"
                            >
                              Enter Amount
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              step="0.01"
                              min="0"
                              onChange={(event) =>
                                this.setState({
                                  cash_loan_amount: event.target.value,
                                  error: "",
                                })
                              }
                            />
                          </div>

                          <button
                            onClick={this.checkCashLoan}
                            type="submit"
                            className="btn btn-fill btn-primary"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!this.state.deletemodal ? (
            ""
          ) : (
            <div
              className="modal fade show"
              id="addCooperativeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style={{
                display: "block",
                paddingRight: "5px",
                paddingTop: "100px",
              }}
            >
              <div className="modal-dialog">
                {navigator.onLine ? (
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete CashLoan?</h4>
                      <button
                        onClick={() => this.setState({ deletemodal: false })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Cash Loan
                          </div>

                          <button
                            type="submit"
                            onClick={() =>
                              this.deleteCashLoan(
                                this.state.cashLoanId,
                                this.state.liveId
                              )
                            }
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            onClick={() =>
                              this.setState({ deletemodal: false })
                            }
                            className="btn btn-fill btn-danger"
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            No Internet Connection
                          </h4>
                          <button
                            onClick={this.handleOffline}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <h5>
                                Internet connection is needed to delete this
                                input supply .
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!this.state.deleteinputmodal ? (
            ""
          ) : (
            <div
              className="modal fade show"
              id="addCooperativeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style={{
                display: "block",
                paddingRight: "5px",
                paddingTop: "100px",
              }}
            >
              <div className="modal-dialog">
                {navigator.onLine ? (
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete Input Supply?</h4>
                      <button
                        onClick={this.handleDeleteInputModal}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Input Supply
                          </div>

                          <button
                            type="submit"
                            onClick={() =>
                              this.deleteInputSupply(
                                this.state.inputSupplyId,
                                this.state.liveId
                              )
                            }
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            onClick={this.handleDeleteInputModal}
                            className="btn btn-fill btn-danger"
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            No Internet Connection
                          </h4>
                          <button
                            onClick={this.handleOffline}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <h5>
                                Internet connection is needed to delete this
                                input supply .
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!this.state.offline_modal ? (
            ""
          ) : (
            <div
              className="modal fade show"
              id="addCooperativeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              style={{
                display: "block",
                paddingRight: "5px",
                paddingTop: "100px",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">No Internet Connection</h4>
                    <button
                      onClick={this.handleOffline}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="card ">
                      <div className="card-body ">
                        <h5>
                          Internet connection is needed to edit this form.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by{" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default OpenMarketSuppliersDetails;
