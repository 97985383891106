import { fetchRequest } from "../utils";

export const create_project = (project) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/project/`,
    method: "POST",
    data: project,
  });

export const list_project = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/project/`,
  });

export const read_project = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/project/` +
      params.projectId,
  });

export const update_project = (project) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/project/`,
    method: "PUT",
    data: project,
  });

export const remove_project = (project) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/project/`,
    method: "DELETE",
    data: project,
  });

export const create_project_live = (project) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/project/`,
    method: "POST",
    data: project,
  });

export const read_project_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/project/` +
      params.shipmentId,
  });

export const list_project_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/project/`,
  });

export const update_project_live = (project, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/project/` +
      params.projectId,
    method: "PUT",
    data: project,
  });

export const remove_project_live = (projectId) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/project/` +
      projectId,
    method: "DELETE",
  });

// export const create_deleted_project_live = (project) =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_project/`,
//     method: "POST",
//     data: project,
//   });

// export const list_deleted_project_live = () =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_project/`,
//   });
