import React, { Component } from "react";
import auth from "../../api/auth-helper";
import { Link } from "react-router-dom";

class SideBar extends Component {
  state = {
    extension: false,
    weighbridge: false,
    quality_control: false,
    // warehouse: false,
    cooperative: false,
    farmer: false,
    accounting: false,
  };

  handleExtension = () => {
    this.setState((prevState) => ({
      extension: !prevState.extension,
    }));
  };

  handleWeighBridge = () => {
    this.setState((prevState) => ({
      weighbridge: !prevState.weighbridge,
    }));
  };

  handleQualityControl = () => {
    this.setState((prevState) => ({
      quality_control: !prevState.quality_control,
    }));
  };

  handleCooperative = () => {
    this.setState((prevState) => ({
      cooperative: !prevState.cooperative,
    }));
  };

  handleFarmer = () => {
    this.setState((prevState) => ({
      farmer: !prevState.farmer,
    }));
  };

  handleAccounting = () => {
    this.setState((prevState) => ({
      accounting: !prevState.accounting,
    }));
  };

  render() {
    /*
        setInterval(function syncServer() {
        if(navigator.onLine) {
            //let last_time = localStorage.getItem('lastUpdate');
            list_live().then(data => {
                if(data) {
                    data.filter(data => {
                        if(localStorage.getItem('lastUpdate') < data.created ) {
                        console.log(data)
                        }
                       
                    })
                }
            })
            
           //console.log(localStorage.getItem('lastUpdate'))
            
    }
        }, 500000000);
        */
    return (
      <div
        className="sidebar"
        data-color="green"
        data-background-color="black"
        data-image="/static/assets/img/sidebar-6.jpg"
      >
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            SB
          </a>

          <a href="/admin" className="simple-text logo-normal">
            Administrator
          </a>
        </div>

        <div className="sidebar-wrapper">
          <div className="user">
            <div className="user-info">
              <a href="/admin" className="username">
                <span>
                  {" "}
                  <strong>
                    {auth.isAuthenticated().user.first_name}{" "}
                    {auth.isAuthenticated().user.last_name}
                  </strong>
                </span>
              </a>
            </div>
          </div>
          <ul className="nav">
            <li className="nav-item {{ dashboard_page }} ">
              <Link className="nav-link" to="/admin_season">
                <i className="material-icons">grid_on</i>
                <p>
                  <strong>Season</strong>{" "}
                </p>
              </Link>
            </li>

            <li className="nav-item {{ dashboard_page }} ">
              <Link className="nav-link" to="/admin_project">
                <i className="material-icons">account_tree</i>
                <p>
                  <strong>Project</strong>{" "}
                </p>
              </Link>
            </li>

            <li className="nav-item {{ extension_managers_page }}">
              <Link className="nav-link" to="/extension_managers">
                <i className="material-icons">person_outline</i>
                <p>
                  <strong>Managers</strong>{" "}
                </p>
              </Link>
            </li>
            <li className="nav-item {{ crop_page }}">
              <Link className="nav-link" to="/crop">
                <i className="material-icons">grass</i>
                <p>
                  {" "}
                  <strong>Crops</strong>
                </p>
              </Link>
            </li>
            <li className="nav-item {{ extension_farm_inputs_page }}">
              <Link className="nav-link" to="/farm_inputs">
                <i className="material-icons">input</i>
                <p>
                  <strong>Farm Inputs</strong>{" "}
                </p>
              </Link>
            </li>
            <li className="nav-item {{ extension_aggregations_page }}">
              <Link className="nav-link" to="/recovery_center">
                <i className="material-icons">location_city</i>
                <p>
                  <strong>Recovery Centers</strong>{" "}
                </p>
              </Link>
            </li>

            {/* <li className="nav-item {{ accounting_page }}">
              <a className="nav-link" onClick={this.handleAccounting}>
                <i className="material-icons">grid_on</i>
                <p>
                  {" "}
                  Accounting
                  <b className="caret"></b>
                </p>
              </a>

              <div className={this.state.accounting ? "" : "collapse"}>
                <ul className="nav">
                  <li className="nav-item {{ accounting_managers_page }}">
                    <Link className="nav-link" to="/accounting">
                      <span className="sidebar-mini"> AM </span>
                      <span className="sidebar-normal">
                        {" "}
                        Accounting Managers{" "}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            {/* <li className="nav-item {{ warehouse_page }}">
              <a className="nav-link" onClick={this.handleWareHouse}>
                <i className="material-icons">grid_on</i>
                <p>
                  {" "}
                  Warehouse
                  <b className="caret"></b>
                </p>
              </a>

              <div className={this.state.warehouse ? "" : "collapse"}>
                <ul className="nav">
                  <li className="nav-item {{ warehouse_managers_page }}">
                    <Link className="nav-link" to="/warehouse_managers">
                      <span className="sidebar-mini"> WM </span>
                      <span className="sidebar-normal">
                        {" "}
                        Warehouse Managers{" "}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li className="nav-item {{ coop_page }}">
              <Link className="nav-link" to="/admin_cooperative">
                <i className="material-icons">people</i>
                <p>
                  {" "}
                  <strong>Cooperatives</strong>
                </p>
              </Link>
            </li>

            <li
              className="nav-item {{ farmers_page }}"
              onClick={this.handleFarmer}
            >
              <Link className="nav-link" to="/admin_farmers">
                <i className="material-icons">wc</i>
                <p>
                  {" "}
                  <strong>Farmers</strong>
                </p>
              </Link>
            </li>

            <li className="nav-item {{ summary_page }}">
              <Link className="nav-link" to="/summary">
                <i className="material-icons">content_paste</i>
                <p>
                  {" "}
                  <strong>Executive Summary</strong>
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
