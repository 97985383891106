import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import Pdf from "react-to-pdf";
import { listseasons_live } from "../../api/api-seasons";
import {
  list_shipment_live,
  remove_shipment_live,
} from "../../api/api-shipment";
import {list_quality_metric_live, create_quality_metric_live} from "../../api/api-quality_metric";
import Nav from "./Nav";
import {read_quality_metric_live} from "../../api/api-quality_metric";
import {create_shipment_quality_metric_live, list_shipment_quality_metric_live} from "../../api/api-shipment_quality_metric"
import SideBar from "./SideBar";
import {list_quality_metric_type_live, remove_quality_metric_type_live} from "../../api/api-quality_metric_type";
import auth from "../../api/auth-helper";

const ref = React.createRef();
class Shipments extends Component {
  constructor() {
    super();
    this.state = {
      shipments: [],
      shipment_id: "",
      shipment_live_id: "",
      delete_modal: false,
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      search: "",
      loan_per_acre: "",
      modals: {
        seasons: false,
        quality_metric_type: false
      },
      quality_metric_types: [],
      quality_metric: [],
      quality_metric_type_id: "",
      quality_metric_type: "",
      success_notification: false,
      error_notification: false,
      update_notification: false,
      shipment_quality_metric: [],
      mssg: "",
      modal: false
    };
    this.handleListShipment();
    this.handleListSeason();
    this.handleShipmentQualityMetric();
    this.handleQualityMetricType()
  }

  componentDidMount() {
    document.title = "Shipments";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleModals = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

    handleListShipment = () => {
    let shipment_quality_metric = this.state.shipment_quality_metric
    list_shipment_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        if (shipment_quality_metric){
          let shipments =[]
         console.log(shipment_quality_metric)
         let shipment_quality_metricIDS = []
          shipment_quality_metric.map(shipment_quality_metric => {
              shipment_quality_metricIDS.push(shipment_quality_metric.shipment_id._id)
          })
         
          data.map((data)=>{
           if(shipment_quality_metricIDS.includes(data._id) === false){
             if(shipments.includes(data)){
                   return
                 }else{
                   shipments.push(data)
                 }
           }        
          })   
          console.log(shipments)
          if (shipments.length !== 0){
          this.setState({
            shipments: shipments
          })
        }
           else {
             return;
           }
        }
         if (!shipment_quality_metric) {
           this.setState({ shipments : data})
         }  
      }
    });
  };

    handleQualityMetric () {
    list_quality_metric_live().then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.setState({
          quality_metric: data
        })
        this.handleListShipment();
      }
    })
  }

  handleQualityMetricType = () =>{
      if (navigator.onLine) {
      list_quality_metric_type_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ quality_metric_types: data });
          console.log(this.state.quality_metric_types)
        }
      });
    } else if (!navigator.onLine) {
    }
  }

  GetQualityMetric = () => {
    list_quality_metric_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let quality_metric = data.filter(
          quality_metric => quality_metric.shipment_id === this.state.shipment_id)
        console.log(quality_metric)
        this.setState({
          quality_metric: quality_metric
        });

        this.submitQualityMetricShipment()
      }
    });
  }

   submitQualityMetricShipment (){
    const jwt = auth.isAuthenticated();

    const shipment_quality_metric = {
      createdBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      shipment_id: this.state.shipment_id,
      quality_metric: this.state.quality_metric,
      status: 'Approved'
    };
    create_shipment_quality_metric_live(shipment_quality_metric, {t: jwt.token})
    .then((data)=>{
      if(!data){
        return;
      }
      if(data){
        this.handleShipmentQualityMetric()
      }
    })
  }

  handleShipmentQualityMetric () {
    list_shipment_quality_metric_live().then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.setState({
          shipment_quality_metric: data
        })
        this.handleListShipment();
      }
    })
  }

  checkForm = () => {
    if (!this.state.value) {
      this.setState({
        error: "Value is required",
      });
    }

    if (!this.state.quality_metric_type) {
      this.setState({
        error: " Quality Metric Type is required",
      });
    }

    if (this.state.value && this.state.quality_metric_type) {
      this.submitForm();
    }

    if (!this.state.value && !this.state.quality_metric_type) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

    submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const quality_metric_type = {
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        value:this.state.value,
        quality_metric_type: this.state.quality_metric_type_id,
        shipment_id: this.state.shipment_id
      };

      create_quality_metric_live(quality_metric_type, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleListShipment()
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Quality Metric created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  };


  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
            loan_per_acre: "",
          });
        }
      }
    });
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleDeleteShipment = () => {
    remove_shipment_live({
      shipmentId: this.state.shipment_id,
    }).then((data) => {
      if (!data) {
        return;
      } else {
        this.handleListShipment();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Shipment successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });
  };
  render() {
    const { search } = this.state;
    const shipments = this.state.shipments.filter((shipment) => {
      return shipment.partyName.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          {/* <div className="dropdown">
                            <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={() => this.handleModal("seasons")}
                            >
                              Change Season
                            </button>
                            <div
                              className={
                                !this.state.modals.seasons
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "452px",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      href="#/"
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        Select Season
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.seasons.map((season, id) => (
                                    <li key={id}>
                                      <a
                                        href = "#/"
                                        role="option"
                                        className="dropdown-item"
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() => {
                                          let {
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          } = season;
                                          this.setState({
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          });
                                          this.handleModal("seasons");
                                        }}
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          {season.type}
                                          {"  "}
                                          {season.year}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <p className="card-category">Total Shipments</p>
                        <h3 className="card-title">
                          {this.state.shipments.length}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            {/*<h3>Nothing to see here yet.</h3>*/}
                            <div
                              id="updated_shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <Pdf targetRef={ref} filename="shipments.pdf">
                                {({ toPdf }) => (
                                  <button
                                    className="btn btn-secondary buttons-pdf buttons-html5"
                                    tabIndex="0"
                                    aria-controls="shipments"
                                    type="button"
                                    onClick={toPdf}
                                  >
                                    <span>Export PDF</span>
                                  </button>
                                )}
                              </Pdf>{" "}
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-secondary buttons-excel buttons-html5"
                                table="shipments"
                                filename="shipmentsxls"
                                sheet="shipmentsxls"
                                buttonText="Export Excel"
                              />
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="updated_shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="updated_shipments_length"
                                        aria-controls="updated_shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="updated_shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="updated_shipments"
                                          onChange={(event) =>
                                            this.setState({
                                              search: event.target.value,
                                            })
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="updated_shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Vehicle No: activate to sort column descending"
                                          
                                        >
                                          Vehicle No
                                        </th>
                                        
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop: activate to sort column ascending"
                                          
                                        >
                                          Crop
                                        </th>

                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop Price: activate to sort column ascending"
                                          
                                        >
                                          Crop Price
                                        </th>


                                         <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Gross Weight: activate to sort column ascending"
                                          
                                        >
                                          Gross Weight
                                        </th>

                                         <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Tare Weight: activate to sort column ascending"
                                          
                                        >
                                          Tare Weight
                                        </th>

                                         <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Net Weight: activate to sort column ascending"
                                          
                                        >
                                          Net Weight
                                        </th>

                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Party Name: activate to sort column ascending"
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "47px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          style={{ width: "128px" }}
                                        >
                                          Date Created
                                        </th>
                                        <th
                                          className="disabled- text-right "
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Actions: activate to sort column ascending"                                        >
                                          Actions
                                        </th>                           
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan="1" colSpan="1">
                                          Vehicle No
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop Price
                                        </th>
                                         <th rowSpan="1" colSpan="1">
                                          Gross Weight
                                        </th>
                                         <th rowSpan="1" colSpan="1">
                                          Tare Weight
                                        </th>
                                         <th rowSpan="1" colSpan="1">
                                          Net Weight
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Party Name
                                        </th>
                                        
                                        <th rowSpan="1" colSpan="1">
                                          Type
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Date Created
                                        </th>
                                        <th
                                          className="disabled- text-center"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {shipments.map((data, id) => (
                                        <tr key={id}>
                                          <td className="_1">
                                            {data.vehicleNumber}
                                          </td>
                                          <td>{data.crop.name}</td>
                                          <td>₦{data.crop_price}</td>
                                          <td>{data.gross_weight}kg</td>
                                          <td>{data.tare_weight ? `${data.tare_weight}kg` : `No Tare Weight`}</td>
                                           <td>{data.net_weight ? `${data.net_weight}kg` : `No Net Weight`}</td>
                                          <td>{data.partyName}</td>
                                          <td>{data.type}</td>

                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                          <td className='text-right inline-block'>
                                      <Link
                                        to={{
                                          pathname: '/quality_control_shipment_details',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                       <button
                                          type="submit"
                                          data-toggle="Approve"
                                          className="btn btn-link btn-just-icon btn-success"
                                          onClick={()=>{
                                            this.GetQualityMetric()
                                            this.setState({shipment_id:data._id, crop: data.crop})
                                          }}
                                        >
                                          <i className="material-icons">
                                            {!data.collected
                                              ? "done"
                                              : "done_all"}
                                          </i>
                                        </button>
                                    </td>
                                          
                                     
                                        
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="updated_shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="updated_shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="updated_shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="0"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next disabled"
                                        id="updated_shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="1"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Completed Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            {/*<h3>Nothing to see here yet.</h3>*/}
                            <div
                              id="updated_shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <Pdf targetRef={ref} filename="shipments.pdf">
                                {({ toPdf }) => (
                                  <button
                                    className="btn btn-secondary buttons-pdf buttons-html5"
                                    tabIndex="0"
                                    aria-controls="shipments"
                                    type="button"
                                    onClick={toPdf}
                                  >
                                    <span>Export PDF</span>
                                  </button>
                                )}
                              </Pdf>{" "}
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-secondary buttons-excel buttons-html5"
                                table="shipments"
                                filename="shipmentsxls"
                                sheet="shipmentsxls"
                                buttonText="Export Excel"
                              />
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="updated_shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="updated_shipments_length"
                                        aria-controls="updated_shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="updated_shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="updated_shipments"
                                          onChange={(event) =>
                                            this.setState({
                                              search: event.target.value,
                                            })
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="W-APR-NOV-19 Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="updated_shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Vehicle No: activate to sort column descending"
                                          
                                        >
                                          Vehicle No
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop: activate to sort column ascending"
                                          
                                        >
                                          Crop
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop Price: activate to sort column ascending"
                                          
                                        >
                                          Crop Price
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Gross Weight: activate to sort column ascending"
                                          
                                        >
                                          Gross Weight
                                        </th>

                                         <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Tare Weight: activate to sort column ascending"
                                          
                                        >
                                          Tare Weight
                                        </th>

                                         <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Net Weight: activate to sort column ascending"
                                          
                                        >
                                          Net Weight
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Party Name: activate to sort column ascending"
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "47px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className=""
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          
                                        >
                                          Date Updated
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan="1" colSpan="1">
                                          Vehicle No
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop Price
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Gross Weight
                                        </th>
                                         <th rowSpan="1" colSpan="1">
                                          Tare Weight
                                        </th>
                                         <th rowSpan="1" colSpan="1">
                                          Net Weight
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Party Name
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Type
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Date Updated
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {this.state.shipment_quality_metric.map((data, id) => (
                                        <tr key={id}>
                                          <td className="_1">
                                            {data.shipment_id.vehicleNumber}
                                          </td>
                                          <td>{data.shipment_id.crop.name}</td>
                                          <td>₦{data.shipment_id.crop_price}</td>
                                           <td>{data.shipment_id.gross_weight}kg</td>
                                          <td>{data.shipment_id.tare_weight ? `${data.shipment_id.tare_weight}kg` : `No Tare Weight`}</td>
                                           <td>{data.shipment_id.net_weight ? `${data.shipment_id.net_weight}kg` : `No Net Weight`}</td>
                                          <td>{data.shipment_id.partyName}</td>
                                          <td>{data.shipment_id.type}</td>

                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="updated_shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="updated_shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="updated_shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="0"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next disabled"
                                        id="updated_shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="1"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                             

          
            {!this.state.modal ? (
                ""
              ) : (
                <div
                    className="modal fade show"
                    id="createQualityMetricTypeModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Create Quality Metric</h4>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div>
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Value of Quality Metric
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            value: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="metric"
                                        type="text"
                                        className="form-control"
                                        id="inputMetric"
                                      />
                                    </div>

                                <div
                                  className={
                                    !this.state.modals.quality_metric_type
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => this.handleModals("quality_metric_type")}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.quality_metric_type
                                            ? "Choose Quality Metric Type"
                                            : this.state.quality_metric_type}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.modals.quality_metric_type
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      minWidth: "250px",
                                      overflow: "hidden",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled">
                                          <a
                                            role="option"
                                            className="dropdown-item disabled"
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="false"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              {this.state.quality_metric_type || "Choose Quality Metric Type"}
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.quality_metric_types.map((data) => {
                                          return (
                                            <li key={data._id}>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.quality_metric_type === ""
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {

                                                  console.log(this.state.quality_metric_type_id)
                                                  this.handleModals("quality_metric_type");
                                                  this.setState({
                                                    quality_metric_type: data.metric,
                                                    quality_metric_type_id: data._id,
                                                  });
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.metric}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                    
                                  </div>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_quality_metric"
                                    value="1"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                    id="create_online"
                                    onClick={this.checkForm}
                                    style={{ display: "block" }}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  Quality Metric.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
              )}
            </div>
          </div>
          )}  
        </div>
        </div>
    );
  }
}

export default Shipments;
