import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import {
  create_crop_price_live,
  remove_crop_price_live,
  list_crop_price_live,
} from "../../api/api-crop_price";
import { update_crop_live } from "../../api/api-crop";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
class CropPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deletemodal: false,
      editmodal: false,
      id: "",
      name: "",
      price: "",
      error: "",
      return_to_crop: false,
      crop_price: [],
      allocated_loan_per_hectare: "",
      liveId: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
    };
    this.handleList();
  }

  componentDidMount() {
    document.title = "Crop Price";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleOpenDeleteModal = (id, liveId) => {
    this.setState({
      deletemodal: true,
      id: id,
      liveId: id,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

  handleOpenEditModal = (id, name, price, allocated_loan_per_hectare) => {
    console.log(this.props.location);
    this.setState({
      editmodal: true,
      id: this.props.location.state.id,
      name: this.props.location.state.name,
      allocated_loan_per_hectare: this.props.location.state
        .allocated_loan_per_hectare,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
      name: "",
      allocated_loan_per_hectare: "",
    });
  };

  handleList = () => {
    if (navigator.onLine) {
      list_crop_price_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          let prices = data.filter((data2) => {
            return data2.crop === this.props.location.state.id;
          });
          this.setState({ crop_price: prices });
        }
      });
    } else if (!navigator.onLine) {
      /*
			read_cropprice({ croppriceId: this.props.location.state.id }).then(data => {
				console.log(data);
				if (data.error) {
					console.log(data.error);
				} else {
					this.setState({ crop_price: data });
					console.log(data);
				}
			});
			*/
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  editForm = () => {
    const jwt = auth.isAuthenticated();

    const crop = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      name: this.state.name,
      allocated_loan_per_hectare: this.state.allocated_loan_per_hectare,
    };
    console.log(this.props.location.state.liveId);
    update_crop_live(
      crop,
      { cropId: this.props.location.state.liveId },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (data.error) {
        this.handleCloseEditModal();
        this.setState({
          error_notification: true,
          mssg: "Crop Price could not be updated",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else {
        this.handleCloseEditModal();
        this.setState({
          return_to_crop: true,
        });
      }
      /*
				const crop = {
					_id: this.state.id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					name: this.state.name,
				};
				update_crop(crop, {
					t: jwt.token,
				}).then(data => {
					if (!data) {
						return;
					} if(data) {
						this.setState({
							return_to_crop: true,
						});
					}
				});
				this.handleCloseEditModal();
				*/
    });
  };

  checkForm = () => {
    if (!this.state.price) {
      this.setState({
        error: "Crop Price is required",
      });
    }

    if (this.state.price < 0) {
      this.setState({
        error: "Please enter a valid amount",
      });
    }

    if (this.state.price && this.state.price > 0) {
      this.submitForm();
    }
  };

  checkForm2 = () => {
    if (!this.state.name) {
      this.setState({
        error: "Crop Name is required",
      });
    }

    if (!this.state.allocated_loan_per_hectare) {
      this.setState({
        error: "Allocated Laon is required",
      });
    }

    if (this.state.allocated_loan_per_hectare < 0) {
      this.setState({
        error: "Please enter a valid amount",
      });
    }

    if (!this.state.type && !this.state.name) {
      this.setState({
        error: "All Fields are required",
      });
    }

    if (this.state.name && this.state.allocated_loan_per_hectare > 0) {
      this.editForm();
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();

    const cropprice = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      crop: this.props.location.state.liveId,
      price: this.state.price,
    };
    create_crop_price_live(cropprice, {
      t: jwt.token,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleList();
        update_crop_live(
          cropprice,
          { cropId: this.props.location.state.liveId },
          {
            t: jwt.token,
          }
        ).then((data) => {
          if (data.error) {
            this.handleList();
            this.setState({
              loading: false,
              error_notification: true,
              mssg: "Could not create Crop Price",
            });
            setTimeout(
              () => this.setState({ error_notification: false }),
              3000
            );
            this.handleModal();
          } else {
            this.handleList();
            this.setState({
              loading: false,
              success_notification: true,
              mssg: "Crop Price created successfully",
            });
            setTimeout(
              () => this.setState({ success_notification: false }),
              3000
            );
            this.handleModal();
          }
        });
        /*
				const crop = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					cropId: this.props.location.state.id,
					cropLiveId: this.props.location.state.liveId,
					price: this.state.price,
				};
				create_cropprice(crop, {
					t: jwt.token,
				}).then(data => {
					if (data.error) {
						this.setState({ error: data.error });
					} 
					if(data) {
						this.handleList();
						this.handleModal();
						const crop = {
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
							price: this.state.price,
						};
						update_crop_live(
							crop,
							{ cropId: this.props.location.state.liveId },
							{
								t: jwt.token,
							}
						).then(data => {
							if (!data) {
								return;
							} if(data) {
								const crop = {
									deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
									_id: this.props.location.state.id,
									price: this.state.price,
								};
								update_crop_price(crop, {
									t: jwt.token,
								}).then(data => {
									if (!data) {
										return;
									} if(data) {
										
									}
								});
							}
						});

					}
					
				});
				*/
      }
    });
  };

  deleteData = () => {
    remove_crop_price_live({
      cropPriceId: this.state.id,
    }).then((data) => {
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not delete Crop Price",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleCloseDeleteModal();
      } else {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Crop Price deleted successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleCloseDeleteModal();
      }
    });
  };
  render() {
    if (this.state.return_to_crop) {
      return (
        <Redirect
          to={{
            pathname: "/extension_crop",
            state: { update_notification: true },
          }}
        />
      );
    }
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <Link className="btn btn-primary btn-round" to="/extension_crop">
                  Go back
                </Link>
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.update_notification ? (
                  <div className="alert alert-warning">
                    <button
                      onClick={() =>
                        this.setState({ update_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">
                          {`${this.props.location.state.name} Price`}{" "}
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            {/* <button
                              className="btn btn-primary btn-round"
                              onClick={this.handleModal}
                            >
                              Add Crop Price
                            </button> */}
                          </div>
                          <div className="col-md-3">
                            {/* <button
                              className="btn btn-primary btn-round"
                              onClick={this.handleOpenEditModal}
                            >
                              Edit Crop
                            </button> */}
                          </div>
                        </div>

                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Unit Price</th>
                                  <th>Created By</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Unit Price</th>
                                  <th>Created By</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.crop_price.map((data) => (
                                  <tr key={data._id}>
                                    <td>₦{data.price}</td>
                                    <td>{data.createdBy}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <input
                                          type="hidden"
                                          name="delete_crop"
                                          value="{{ crop.pk }}"
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.handleOpenDeleteModal(
                                              data._id,
                                              data._id
                                            )
                                          }
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Delete Crop?</h4>
                          <button
                            onClick={this.handleCloseDeleteModal}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div>
                                Are you sure you want to delete this Crop ?
                              </div>

                              <button
                                type="submit"
                                onClick={() => this.deleteData()}
                                className="btn btn-fill btn-success"
                              >
                                Delete
                              </button>
                              <button
                                type="submit"
                                onClick={this.handleCloseDeleteModal}
                                className="btn btn-fill btn-danger"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                id="create_offline"
                                style={{ display: "none" }}
                              >
                                Create Offline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createcropModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Create Crop Price</h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Unit Price of Crop (Per Kg)
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          price: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="price"
                                      type="number"
                                      className="form-control"
                                      id="inputPrice"
                                      step="0.01"
                                      min="0"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  type="hidden"
                                  name="create_crop"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_online"
                                  onClick={this.checkForm}
                                  style={{ display: "block" }}
                                >
                                  Create
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.editmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Edit Crop</h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleCloseEditModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editinputName"
                                      className="bmd-label-floating"
                                    >
                                      Enter Name of Crop
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          name: event.target.value,
                                          error: "",
                                        })
                                      }
                                      value={this.state.name}
                                      type="text"
                                      className="form-control"
                                      id="editinputName"
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editinputAllocatedLoan"
                                      className="bmd-label-floating"
                                    >
                                      Enter Allocated Loan Per Hectare
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          allocated_loan_per_hectare:
                                            event.target.value,
                                          error: "",
                                        })
                                      }
                                      value={
                                        this.state.allocated_loan_per_hectare
                                      }
                                      type="text"
                                      className="form-control"
                                      id="editinputAllocatedLoan"
                                      min="0"
                                      step="0.01"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  id="editinputID"
                                  type="hidden"
                                  name="edit_crop"
                                  value=""
                                />
                                <button
                                  onClick={this.checkForm2}
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default CropPrice;
