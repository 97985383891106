import React from "react";
import moment from "moment";

const MonthsMap = ({ type, handleClick }) => {
  return moment()._locale._months.map((month) => (
    <li key={month}>
      <a
        href="#/"
        tabIndex="0"
        role="option"
        aria-disabled="false"
        aria-selected="false"
        className="dropdown-item"
        onClick={() => handleClick(type, month)}
      >
        <span className=" bs-ok-default check-mark" />
        <span className="text">{month}</span>
      </a>
    </li>
  ));
};

export default MonthsMap;
