import React, { Component } from "react";
import { list_cooperative_live } from "../api/api-cooperative";
import { list_cashloan_live } from "../api/api-cash_loan";
import { list_inputsupply_live } from "../api/api-input_supply";
import { list_farmer_live } from "../api/api-farmer";
import { list_recovery_center_cooperative_live } from "../api/api-recovery_center_cooperative";
import { list_farm_live } from "../api/api-farm";
import { list_crop_live } from "../api/api-crop";
import { list_farmer_crop_live } from "../api/api-farmer_crop";
import { list_farminput_live } from "../api/api-farm_inputs";
import NaijaStates from "naija-state-local-government";

class Home extends Component {
  state = {
    activity: false,
    cooperatives: "",
    cash_loan_total: "",
    input_supply_total: "",
    farmers: "",
    recovery: [],
    total_recovery_weight: "",
    total_farm_size: "",
    farmer: [],
    crops: [],
    farmer_crop: [],
    input_supply: [],
    farm_inputs: [],
  };
  componentDidMount() {
    this.handleCooperativeList();
    this.handleListCashLoans();
    this.handleListInputSupply();
    this.handleListFarmers();
    this.handleListRecovery();
    this.handleFarm();
    this.handleCropList();
    this.handleFarmerCrop();
    this.handleListFarmInput();
  }

  availableLGAs = () => {
    let districts = [
      "Akoko Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ];
    return districts;
  };

  handleListFarmInput = () => {
    list_farminput_live().then((data) => {
      if (!data) {
        this.setState({ farm_inputs: [] });
      }
      if (data) {
        this.setState({ farm_inputs: data });
      }
    });
  };

  handleCooperativeList = () => {
    list_cooperative_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
          cooperatives: 0,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data.error) {
        this.setState({
          mssg: data.error,
          error_notification: true,
          cooperatives: 0,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ cooperatives: data.length });
      }
      console.log(this.state.cooperatives);
    });
  };

  handleListCashLoans = () => {
    list_cashloan_live().then((data) => {
      if (!data) {
        //console.log(data.error);
        return;
      } else if (data) {
        if (data.length === 0) {
          this.setState({ cash_loan_total: 0 });
        }

        if (data.length !== 0) {
          let cash_loan_array = [];
          let total;
          let cash_loans = data;
          if (cash_loans.length !== 0) {
            let collected_cash_loan = cash_loans.filter(
              (cash_loan) => cash_loan.collected === true
            );
            collected_cash_loan.map((data) => {
              return cash_loan_array.push(Number(data.amount));
            });

            total = cash_loan_array.reduce((a, b) => a + b, 0);
          }
          this.setState({ cash_loan_total: total });
          if (cash_loans.length === 0) {
            this.setState({ cash_loan_total: 0 });
          }
        }
      }
    });
  };

  handleListInputSupply = () => {
    list_inputsupply_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        if (data.length === 0) {
          this.setState({ input_supply: [], input_supply_total: 0 });
        } else if (data.length !== 0) {
          let inputs_array = [];
          let total;
          let inputs = data;
          if (inputs.length !== 0) {
            let collected_input = inputs.filter(
              (input) => input.collected === true
            );
            collected_input.map((data) => {
              return inputs_array.push(Number(data.cost));
            });
            total = inputs_array.reduce((a, b) => a + b, 0);
            this.setState({ input_supply_total: total, input_supply: inputs });
          } else if (inputs.length === 0) {
            this.setState({
              input_supply_total: 0,
              input_supply: [],
            });
          }
        }
      }
      console.log(this.state.input_supply);
    });
  };

  handleListFarmers = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
          farmers: 0,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data !== []) {
        this.setState({
          farmers: data.length,
          farmer: data,
        });
      } else if (data === []) {
        this.setState({ farmers: 0, farmer: [] });
      }
    });
  };

  handleListRecovery = () => {
    list_recovery_center_cooperative_live().then((data) => {
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        let total_weight_array = [];
        let total_weight;
        let Recovery = data;
        Recovery.map((data) => {
          total_weight_array.push(Number(data.total_weight));
        });
        total_weight = total_weight_array.reduce((a, b) => a + b, 0);
        this.setState({
          total_recovery_weight: total_weight,
        });
      } else if (data.length === 0) {
        this.setState({ recovery: [] });
      }
    });
  };

  handleFarm = () => {
    list_farm_live().then((data) => {
      if (!data) {
        this.setState({
          total_farm_size: 0,
        });
      } else if (data.error) {
        this.setState({
          total_farm_size: 0,
        });
      } else if (data) {
        console.log(data);
        let all_farms = [];
        let farm = data;
        let total_size;
        farm.map((data) => {
          if (data.unit === "Hectare") {
            all_farms.push(Number(data.size));
            total_size = all_farms.reduce((a, b) => a + b, 0);
            this.setState({
              total_farm_size: total_size,
            });
          } else if ((data.unit = "Acres")) {
            all_farms.push(Number(data.size * 0.404686));
            total_size = all_farms.reduce((a, b) => a + b, 0);
            this.setState({
              total_farm_size: total_size,
            });
          } else if (data.unit == "Plot") {
            all_farms.push(Number(data.size * 0.066667));
            total_size = all_farms.reduce((a, b) => a + b, 0);
            this.setState({
              total_farm_size: total_size,
            });
          } else if (data.unit == "M2") {
            all_farms.push(Number(data.size * 0.0001));
            total_size = all_farms.reduce((a, b) => a + b, 0);
            this.setState({
              total_farm_size: total_size,
            });
          }
        });
      }
    });
  };

  handleCropList = () => {
    list_crop_live().then((data) => {
      if (!data) {
        this.setState({
          crops: [],
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ crops: data });
      }
    });
  };

  handleFarmerCrop() {
    list_farmer_crop_live().then((data) => {
      if (!data) {
        this.setState({
          farmer_crop: [],
        });
      } else {
        console.log(data)
        this.setState({ farmer_crop: data });
      }
      console.log(this.state.farmer_crop);
    });
  }

  render() {
    return (
      <>
        <div className="off-canvas-sidebar">
          <nav
            className="navbar navbar-transparent navbar-color-on-scroll fixed-top navbar-expand-lg"
            color-on-scroll="100"
            id="sectionsNav"
            style={{ color: "#0f8c3b" }}
          >
            <div className="container">
              <div className="navbar-translate">
                <a className="navbar-brand" href="/">
                  <strong> SupplyBase </strong>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      href="/admin"
                      className="nav-link"
                      style={{ color: "#0f8c3b" }}
                    >
                      <i className="material-icons">dashboard</i>
                      Back to Dashboard
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div
            className="page-header"
            data-parallax="true"
            style={{
              backgroundSize: "cover",
              background: "url('/static/c2c-logo.png') no-repeat",
              width: "100%",
              height: "250px",
            }}
          >
            {/* <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1 className="title text-white">SupplyBase</h1>
                  <h4>
                    This page gives an overview of the farmers benefitting from
                    this program, the money received by these farmer. The crops
                    recovered (harvest) by these farmers. The cooperatives these
                    farmers are under, their Farms (total farm size) etc.
                  </h4>
                  <br />
                </div>
              </div>
            </div> */}
          </div>
          <div className="main main-raised">
            <div className="container">
              <div className="section text-center">
                <div className="row">
                  <div className="col-md-8 ml-auto mr-auto">
                    <h2 className="title">Executive Summary </h2>
                  </div>
                </div>
                <div className="features" style={{ marginLeft: "8%" }}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="info">
                        <div className="icon icon-info">
                          <h1>
                            {" "}
                            ₦
                            {(
                              Number(this.state.input_supply_total) +
                              Number(this.state.cash_loan_total)
                            ).toLocaleString()}{" "}
                          </h1>
                        </div>
                        <h4 className="info-title">Total Money Received</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <div className="icon icon-success">
                          <h1>{this.state.total_farm_size}</h1>
                        </div>
                        <h4 className="info-title">Hectares of Farm Land</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <div className="icon icon-info">
                          <h1>{this.state.farmers.toLocaleString()}</h1>
                        </div>
                        <h4 className="info-title">Farmers</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="features">
                  <div className="row">
                    <div className="col-md-8" style={{ marginRight: "-180px" }}>
                      <div className="info">
                        <div className="icon icon-success">
                          <h1>
                            ₦{this.state.input_supply_total.toLocaleString()}
                          </h1>
                        </div>
                        <h4 className="info-title">Farm Inputs</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info">
                        <div className="icon icon-info">
                          <h1> {this.state.cooperatives}</h1>
                        </div>
                        <h4 className="info-title">Cooperatives</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "10%" }}>
            <div className="col-md-5">
              <div className="card">
                <div className="card-header card-header-primary card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">people</i>
                  </div>
                  <h4 className="card-title">Farmers per State</h4>
                </div>
                <div className="card-body">
                  <div className="material-datatables">
                    <div className="table-responsive-sm">
                      <table
                        id="datatables"
                        className="table table-plain table-no-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>State</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>State</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          {NaijaStates.states().map((data) => (
                            <tr>
                              <td>{data}</td>
                              <td>
                                {!this.state.farmer.length
                                  ? `0`
                                  : this.state.farmer.filter(
                                      (farmer) => farmer.state === data
                                    ).length}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header card-header-primary card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">grass</i>
                  </div>
                  <h4 className="card-title">Farmers per Crop</h4>
                </div>
                <div className="card-body">
                  <div className="material-datatables">
                    <div className="table-responsive-sm">
                      <table
                        id="datatables"
                        className="table table-plain table-no-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Crop</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>Crop</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          {!this.state.crops.length ? (
                            <tr>
                              <td>No Crops Available</td>
                            </tr>
                          ) : (
                            this.state.crops.map((data) => (
                              <tr>
                                <td>{data.name}</td>
                                <td>
                                  {!this.state.farmer_crop.length
                                    ? `0`
                                    : this.state.farmer_crop.filter((fcrop) =>
                                       fcrop.crops.map((f_crop)=>{
                                        return f_crop._id === data._id
                                       })
                                      ).length}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header card-header-primary card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">input</i>
                  </div>
                  <h4 className="card-title">Farmers per Farm Inputs </h4>
                </div>
                <div className="card-body">
                  <div className="material-datatables">
                    <div className="table-responsive-sm">
                      <table
                        id="datatables"
                        className="table table-plain table-no-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>Farm Inputs</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>Farm Inputs</th>
                            <th>Number of Farmers</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          {!this.state.farm_inputs.length ? (
                            <tr>
                              <td>No Farm Inputs Available</td>
                            </tr>
                          ) : (
                            this.state.farm_inputs.map((data) => (
                              <tr>
                                <td>{data.name}</td>
                                <td>
                                  {!this.state.input_supply.length
                                    ? `0`
                                    : this.state.input_supply.filter(
                                        (supply) =>
                                          supply.farmInputId === data._id
                                      ).length}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container">
            <nav className="float-left">
              <ul>
                <li>
                  <a href="/">SupplyBase</a>
                </li>
              </ul>
            </nav>
            <div className="copyright float-right">
              &copy; {new Date().getFullYear()}, made with{" "}
              <i className="material-icons">favorite</i> by{" "}
              <a
                href="http://www.crop2cash.com.ng/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Crop2Cash
              </a>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Home;
