import React, { Component } from "react";
import SideBar from "./SideBar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Nav from "./Nav";
import {
  create_farminput_live,
  list_farminput_live,
  update_farminput_live,
  remove_farminput,
} from "../../api/api-farm_inputs";
import { list_farminputprice_live } from "../../api/api-farm_input_price";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
class FarmInputs extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      deletemodal: false,
      editmodal: false,
      id: "",
      name: "",
      type: "",
      price: "",
      farm_inputs: [],
      farm_inputs_id: "",
      current_farm_input_price: "",
      current_farm_input_quantity: '',
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: '',
      warning_level: 0
    };
    this.handleList();
    this.handleListPrice()
  }

  componentDidMount() {
    document.title = "Farm Input";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleOpenDeleteModal = (id) => {
    this.setState({
      deletemodal: true,
      id: id,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

  handleOpenEditModal = (id, name, type, price) => {
    this.setState({
      editmodal: true,
      id: id,
      name: name,
      type: type,
      price: price,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
    });
  };

  handleList = () => {
    list_farminput_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({ farm_inputs: data });
      }
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  editForm = () => {
    const jwt = auth.isAuthenticated();

    const farminput = {
      _id: this.state.id,
      name: this.state.name,
      type: this.state.type,
      price: this.state.price,
    };
    update_farminput_live(
      farminput,{farmInputId: this.state.id},
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (data.error) {
        this.setState({ error: data.error });
        console.log(data.error)
      } else {
        console.log(data);
        this.handleList();
        this.setState({
          loading: false,
          update_notification: true,
          mssg: "Farm Input updated successfully",
        });
      }
    });
    this.handleCloseEditModal();
  };

  checkForm = () => {
    if (!this.state.name) {
      this.setState({
        error: "Farm Input Name is required",
      });
    }

    if (!this.state.type) {
      this.setState({
        error: "Farm Input Type is required",
      });
    }

    if (this.state.name && this.state.type) {
      this.submitForm();
    }

    if (!this.state.name && !this.state.type) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const farminput = {
        deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        name: this.state.name,
        type: this.state.type,
        price: 0,
        quantity: 0,
        inventory_warning_level: this.state.warning_level
      };

      create_farminput_live(farminput, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Farm Input created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  }

  handleListPrice = () => {
    if (navigator.onLine) {
      list_farminputprice_live().then((data) => {
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length !== 0) {
          this.setState({ current_farm_input_price: data });
        } else {
          this.setState({ current_farm_input_price: "" });
        }
      });
    } else if (!navigator.onLine) {
      /*
			list_farminputprice().then(data => {
				if (data.error) {
					console.log(data.error);
				}
				if (data.length !== 0) {
					this.setState({ current_farm_input_price: data });
				} else {
					this.setState({ current_farm_input_price: '' });
				}
			});
			*/
    }
  };

      
      /*
		create_farminput_live(farminput, {
			t: jwt.token,
		}).then(data => {
			if (!data) {
				return;
			}
			if (data) {
				
				this.handleList();
				this.setState({
					loading: false,
					success_notification: true,
					mssg: 'Farm Input created successfully',
				});
				this.handleModal();
				{/*
				const farminputprice = {
			deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
			createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
			farm_input_id: this.props.location.state.liveId,
			price: this.state.price,
		};
		create_farminputprice_live(farminputprice, {
			t: jwt.token,
		}).then(data => {
			if (!data) {
				return;
			}
			if (data) {
				
						const farminput = {
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
							price: this.state.price,
						};
						update_farminput_live(
							farminput,
							{ farmInputId: this.props.location.state.liveId },
							{
								t: jwt.token,
							}
						).then(data => {
							if (!data) {
								return;
							} if(data) {
								this.handleList();
								this.setState({
									loading: false,
									success_notification: true,
									mssg: 'Farm Input Price created successfully',
								});
						this.handleModal();
							}
						});
				/*
				const farminput = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					farmInputId: this.props.location.state.id,
					farmInputLiveId: this.props.location.state.liveId,
					price: this.state.price,
				};
				create_farminputprice(farminput, {
					t: jwt.token,
				}).then(data => {
					if (data.error) {
						this.setState({ error: data.error });
					} 
					if(data) {
						this.handleList();
						this.handleModal();
						const farminput = {
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
							price: this.state.price,
						};
						update_farminput_live(
							farminput,
							{ farmInputId: this.props.location.state.liveId },
							{
								t: jwt.token,
							}
						).then(data => {
							if (!data) {
								return;
							} if(data) {
								const farminput = {
									deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
									_id: this.props.location.state.id,
									price: this.state.price,
								};
								update_farminput_price(farminput, {
									t: jwt.token,
								}).then(data => {
									if (!data) {
										return;
									} if(data) {
										
									}
								});
							}
						});

					}
					
				});
				*
			}
		});
				
			}
		});
	
	}
	*/
  //   }
  // };

  deleteData = () => {
    remove_farminput({
      _id: this.state.farm_inputs_id,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Farm Input deleted successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });

    this.handleCloseDeleteModal();
  };
  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Farm Inputs</h4>
                      </div>
                      <div className="card-body">
                        {/* <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Farm Inputs
                          </button>
                        </div> */}
                         <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-secondary buttons-excel buttons-html5"
                                table="farminputs"
                                filename="farminputsxls"
                                sheet="farminputsxls"
                                buttonText="Export Excel"
                          />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Unit Price</th>
                                  <th>Qunatity</th>
                                  <th>Date Added</th>
                                  <th>Date Updated</th>
                                  {/* <th className="disabled-sorting text-right">
                                    Actions
                                  </th> */}
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Unit Price</th>
                                  <th>Qunatity</th>
                                  <th>Date Added</th>
                                  <th>Date Updated</th>
                                  {/* <th className="text-right">Actions</th> */}
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.farm_inputs.map((data, id) => (
                                  <tr key={id}>
                                    <td>{data.name}</td>
                                    <td>{data.type}</td>
                                    <td>₦{data.price}</td>
                                    <td>
                                      {data.quantity} {" "}
                                {data.quantity > data.inventory_warning_level ? <span  data-toggle="tooltip"
                                          data-placement="top"
                                          title="Good Level of Stock" class="badge badge-success">{" "}</span> : <span 
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Low level of Stock"
                                          class="badge badge-danger">{" "}</span> }
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.updated}
                                      </Moment>
                                    </td>
                                    {/* <td className="text-right inline-block">
                                      <div>
                                        <button
                                          onClick={() =>
                                            this.handleOpenEditModal(
                                              data._id,
                                              data.name,
                                              data.type,
                                              data.price
                                            )
                                          }
                                          className="btn btn-outline-primary btn-sm"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit Farm Input"
                                          // onclick="editFarmInputModal('1')"
                                        >
                                          Edit
                                          <div className="ripple-container" />
                                        </button>
                                      </div>
                                      <button
                                        type="submit"
                                        className="btn btn-link btn-just-icon btn-danger"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete Farm Input"
                                        onClick={() =>
                                          this.setState({
                                            farm_inputs_id: data._id,
                                            deletemodal: true,
                                          })
                                        }
                                      >
                                        <i className="material-icons">clear</i>
                                      </button>
                                    </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Delete Farm Input?
                          </h4>
                          <button
                            onClick={this.handleCloseDeleteModal}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div>
                                Are you sure you want to delete this Farm
                                Input ?
                              </div>

                              <button
                                type="submit"
                                onClick={() => this.deleteData(this.state.id)}
                                className="btn btn-fill btn-success"
                              >
                                Delete
                              </button>
                              <button
                                type="submit"
                                onClick={this.handleCloseDeleteModal}
                                className="btn btn-fill btn-danger"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                id="create_offline"
                                style={{ display: "none" }}
                              >
                                Create Offline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createFarmInputModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        {/* <div className="modal-header">
                          <h4 className="modal-title">Create Farm Input</h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div> */}
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputName"
                                      className="bmd-label-floating"
                                    >
                                      Enter Name of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("name")}
                                      name="name"
                                      type="text"
                                      className="form-control"
                                      id="inputName"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputType"
                                      className="bmd-label-floating"
                                    >
                                      Enter Type of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("type")}
                                      name="type"
                                      type="text"
                                      className="form-control"
                                      id="inputType"
                                    />
                                  </div>

                                  {/* <div className="form-group">
                                    <label
                                      htmlFor="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Unit Price of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("price")}
                                      name="price"
                                      type="number"
                                      className="form-control"
                                      id="inputPrice"
                                    />
                                  </div> */}
                                  <div className="form-group">
                                      <label
                                        hmtlFor="inputName"
                                        className="bmd-label-floating"
                                      >
                                        Enter Inventory Warning Level
                                      </label>
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            warning_level: event.target.value,
                                            error: "",
                                          })
                                        }
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="inputWarning"
                                        min="0"
                                      />
                                    </div>
                                    <p className="card-description text-center">
                                        The Inventory Warning Level indicate when the farm input is low and needs to be restocked. Enter a number to signify when the farm input inventory is low. The default is zero.
                              </p>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  type="hidden"
                                  name="create_farm_input"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_online"
                                  onClick={this.checkForm}
                                  style={{ display: "block" }}
                                >
                                  Create
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer justify-content-center">
                          <input type="hidden" value="1" />
                          <button
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                            onclick="insertIntoIndexedDB()"
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.editmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabIndex="-1"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Edit Farm Input</h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleCloseEditModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editinputName"
                                      className="bmd-label-floating"
                                    >
                                      Enter Name of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("name")}
                                      value={this.state.name}
                                      type="text"
                                      className="form-control"
                                      id="editinputName"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editinputType"
                                      className="bmd-label-floating"
                                    >
                                      Enter Type of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("type")}
                                      value={this.state.type}
                                      type="text"
                                      className="form-control"
                                      id="editinputType"
                                    />
                                  </div>

                                  {/* <div className="form-group">
                                    <label
                                      htmlFor="editinputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Unit Price of Farm Input
                                    </label>
                                    <input
                                      onChange={this.handleChange("price")}
                                      value={this.state.price}
                                      type="number"
                                      className="form-control"
                                      id="editinputPrice"
                                    />
                                  </div> */}
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  id="editinputID"
                                  type="hidden"
                                  name="edit_farm_input"
                                  value=""
                                />
                                <button
                                  onClick={this.editForm}
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default FarmInputs;
