import { fetchRequest } from "../utils";

export const create_shipped_crop = (shipped_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipped_crop/`,
    method: "POST",
    data: shipped_crop,
  });

export const list_shipped_crop = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipped_crop/`,
  });

export const read_shipped_crop = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/shipped_crop/` +
      params.shipped_crop_liveId,
  });

export const update_shipped_crop = (shipped_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipped_crop/`,
    method: "PUT",
    data: shipped_crop,
  });

export const remove_shipped_crop = (shipped_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/shipped_crop/`,
    method: "DELETE",
    data: shipped_crop,
  });

export const create_shipped_crop_live = (shipped_crop) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipped_crop/`,
    method: "POST",
    data: shipped_crop,
  });

export const list_shipped_crop_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipped_crop/`,
  });

export const remove_shipped_crop_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/shipped_crop/` +
      params.shipped_crop_liveId,
    method: "DELETE",
  });
