import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  list_shipment_live,
  update_shipment_live
} from '../../api/api-shipment';
import { list_warehouse_live } from "../../api/api-warehouse";
import { create_shipment_incoming_warehouse_live, read_shipment_incoming_warehouse_live, list_shipment_incoming_warehouse_live } from "../../api/api-shipment_incoming_crop";
import {list_warehouse_shipment_live, create_warehouse_shipment_live} from "../../api/api-warehouse_shipment";
import SideBar from '../WareHouse/SideBar';
import auth from "../../api/auth-helper";
import {read_quality_metric_live} from "../../api/api-quality_metric";
import Nav from '../WareHouse/Nav';
import Moment from 'react-moment';

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        shipment: {},
        redirectToShipment: false,
        modal: false,
        modals: {
          seasons: false,
          warehouse: false
        },
        shipment_incoming_crop:[],
        grn: "",
        warehouse_id:"",
        total_weight: "",
        loader_name: "",
        receiver_name: "",
        warehouses: [],
    };
    this.handleListShipment();
    this.handleListWarehouse();
    this.handleListIncomingCrop()
  }

   componentDidMount(){
    document.title = "Shipment Details"
  }

   handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleModals = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleListShipment = () => {
    list_shipment_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let shipment = data.filter(
          shipment => shipment._id === this.props.location.state.shipmentId
        );
        this.setState({
          status: shipment[0].status,
          shipment: shipment[0]
        });
      }
    });
  };


  handleListWarehouse = () => {
    list_warehouse_live().then((data)=> {
      if(!data) {
        return;
      }
      if (data) {
        this.setState({warehouses: data})
      }
    })
  }

  checkForm = () =>{
    if (!this.state.grn) {
      this.setState({
        error: "GRN is required",
      });
    }

    if (!this.state.warehouse) {
      this.setState({
        error: "Warehouse is required",
      });
    }

    if (!this.state.number_of_bags) {
      this.setState({
        error: "Number of Bags is required",
      });
    }



    if (!this.state.loader_name) {
      this.setState({
        error: "Loader's Name is required",
      });
    }

    if (!this.state.receiver_name) {
      this.setState({
        error: "Receiver's is required",
      });
    }

    if (!this.state.total_weight) {
      this.setState({
        error: "Total Weight is required",
      });
    }

    if (this.state.number_of_bags && this.state.grn && this.state.warehouse_id && this.state.total_weight & this.state.loader_name && this.state.receiver_name ) {
      this.handleSubmit();
    }

    if (!this.state.number_of_bags && !this.state.grn && !this.state.warehouse_id && !this.state.total_weight & !this.state.loader_name && !this.state.receiver_name) {
      this.setState({
        error: "All Fields are required",
      });
    }
  }

  handleListIncomingCrop = () => {
    list_shipment_incoming_warehouse_live().then(data => {
      if (data.error) {
        console.log(data.error);
      } else {
        let shipment_incoming_crop = data.filter(
          shipment_incoming_crop => shipment_incoming_crop.shipment_id === this.props.location.state.shipmentId
        );
        this.setState({
          shipment_incoming_crop: shipment_incoming_crop
        });
      }
    });
  };

  // GetIncomingCrop = () => {
  //   read_shipment_incoming_warehouse_live({shipmentId: this.props.location.state.shipmentId}).then((data) =>{
  //     if(!data){
  //       return
  //     }
  //     else if(data){
  //       let shipment_incoming_crop = data.filter((incoming_crop => incoming_crop._id))
  //       console.log(shipment_incoming_crop)
  //       this.setState({
  //         shipment_incoming_crop: data})
  //     }
  //   })
  // }

  handleSubmit(){
    const jwt= auth.isAuthenticated();
    const shipment_incoming_warehouse = {
      createdBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      grn: this.state.grn,
      warehouse_id: this.state.warehouse_id,
      shipment_id: this.props.location.state.shipmentId,
      number_of_bags: this.state.number_of_bags,
      crop: this.props.location.state.crop,
      total_weight: this.state.total_weight,
      loader_name: this.state.loader_name,
      receiver_name: this.state.receiver_name
    };
    create_shipment_incoming_warehouse_live(shipment_incoming_warehouse,{
      t: jwt.token,
    }).then ((data)=>{
      if(!data) {
        return;
      }
      if(data){
        this.handleListIncomingCrop();
        this.setState({
          success_notification: true,
          mssg: "Warehouse Shipment Approved successfully",
          error_notification: "",
          modal: false
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
    })
  }

  render() {
    return (
      <div className='wrapper '>
        <SideBar />
        <div className='main-panel'>
          <Nav />

          <div className='content'>
            <div className='content'>
              <Link
                className='btn btn-primary btn-round'
                to='/warehouse_shipment'
              >
                Go Back
              </Link>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>people</i>
                        </div>
                        <p className='card-category'>Party Name</p>
                        <h3 className='card-title'>{this.state.shipment.partyName}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_strong</i>
                        </div>
                        <p className='card-category'>Gross Weight</p>
                        <h3 className='card-title'>{this.state.shipment.gross_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>center_focus_weak</i>
                        </div>
                        <p className='card-category'>Tare Weight</p>
                        <h3 className='card-title'>{this.state.shipment.tare_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>crop_free</i>
                        </div>
                        <p className='card-category'>Net Weight</p>
                        <h3 className='card-title'>{this.state.shipment.net_weight || ""}</h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Incoming Crop</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Incoming Crop
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>GRN</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>Warehouse</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>GRN</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>WareHouse</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.shipment_incoming_crop.map((data) => (
                                 
                                    <tr key={data._id}>
                                    <td>{data.grn}</td>
                                    <td>{data.number_of_bags}</td>
                                    <td>{data.total_weight}</td>
                                    <td>{data.warehouse_id.name}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-danger"
                                          onClick={() =>
                                            this.setState({
                                                
                                              quality_metric_id: data._id,
                                              delete_modal: true,
                                            })
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Quality Metric"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addShipmentModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create Incoming Crop</h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() => this.setState({ modal: false })}
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="KzqureCJk7bTWLWJ58AHvaMO07xHken9p7t1UYi55HXOLPxa3hXjMLu8AUUNxtMq"
                              />

                              <div
                                className={
                                  this.state.grn
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="grn"
                                  className="bmd-label-floating"
                                >
                                  Enter GRN
                                </label>
                                <input
                                  required=""
                                  name="grn"
                                  type="text"
                                  className="form-control"
                                  id="grn"
                                  onChange={(event) =>
                                    this.setState({
                                      grn: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.loader_name
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="loader_name"
                                  className="bmd-label-floating"
                                >
                                  Enter Loader Name
                                </label>
                                <input
                                  required=""
                                  name="loader_name"
                                  type="text"
                                  className="form-control"
                                  id="loader_name"
                                  onChange={(event) =>
                                    this.setState({
                                      loader_name: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.receiver_name
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="receiver_name"
                                  className="bmd-label-floating"
                                >
                                  Enter Receiver Name
                                </label>
                                <input
                                  required=""
                                  name="receiver_name"
                                  type="text"
                                  className="form-control"
                                  id="receiver_name"
                                  onChange={(event) =>
                                    this.setState({
                                      receiver_name: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.number_of_bags
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="number_of_bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Number of Bags
                                </label>
                                <input
                                  required=""
                                  name="number_of_bags"
                                  type="text"
                                  className="form-control"
                                  id="number_of_bags"
                                  onChange={(event) =>
                                    this.setState({
                                      number_of_bags: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                               <div
                                className={
                                  this.state.number_of_bags
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="number_of_bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Total Weight of Incoming Crop
                                </label>
                                <input
                                  required=""
                                  name="number_of_bags"
                                  type="text"
                                  className="form-control"
                                  id="number_of_bags"
                                  onChange={(event) =>
                                    this.setState({
                                      total_weight: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                            <div
                              className={
                              !this.state.modals.warehouse
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                              }
                            >
                              <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("warehouse")}
                              >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.warehouse
                                      ? "Choose warehouse"
                                      : this.state.warehouse}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                              </button>
                            <div
                              className={
                                !this.state.modals.warehouse
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.warehouse || "Choose warehouse"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.warehouses.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.warehouse === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("warehouse");
                                            this.setState({
                                              warehouse: data.name,
                                              warehouse_id: data._id,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div></div>

                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_shipment"
                                value=""
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                © 2021 , made with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ShipmentDetail;
