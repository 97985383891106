import React from 'react';

import MonthsMap from './MonthsMap';

import { capitalize } from '../../../utils';

const ChooseMonth = ({
  type,
  formState,
  modalsState,
  toggleModal,
  handleMonth
}) => {
  const title = `Choose ${capitalize(type)} Month`;

  return (
    <div className='form-group'>
      <div className='dropdown bootstrap-select'>
        <button
          type='button'
          title={title}
          aria-expanded='true'
          data-toggle='dropdown'
          className='dropdown-toggle btn btn-round btn-info'
        >
          <div
            onClick={() => {
              toggleModal(type);
            }}
            className='filter-option'
          >
            <div className='filter-option-inner'>
              <div className='filter-option-inner-inner'>
                {formState[type + '_month'] || title}
              </div>
            </div>
          </div>
          <div className='ripple-container' />
        </button>
        <div
          id="dropdownMenu"
          className={`dropdown-menu${modalsState[type] ? ' show' : ''}`}
          role='combobox'
          aria-controls = "dropdownMenu"
          aria-expanded =  "true"
          x-placement='bottom-start'
          style={{
            maxHeight: '162px',
            overflow: 'hidden',
            minWidth: '189px',
            position: 'absolute',
            top: '46px',
            left: '1px',
            willChange: 'top, left'
          }}
        >
          <div
            className='inner'
            role='listbox'
            aria-expanded='true'
            tabIndex='-1'
            style={{
              maxHeight: '152px',
              overflowY: 'auto'
            }}
          >
            <ul className='dropdown-menu inner show'>
              <li className='disabled selected active'>
                <a
                  href= "#/"
                  role='option'
                  className='dropdown-item disabled selected '
                  aria-disabled='true'
                  tabIndex='-1'
                  aria-selected='true'
                >
                  <span className=' bs-ok-default check-mark' />
                  <span className='text'>{title}</span>
                </a>
              </li>
              <MonthsMap type={type} handleClick={handleMonth} />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseMonth;
