import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import { list_paddyprice_live } from "../../api/api-paddy_price";
import {
  list_open_market_supplier_live
} from "../../api/api-open_market_supplier";
import { list_OMS_cooperative_live } from "../../api/api-cooperative";
import { create_farmer_live, list_farmer_live } from "../../api/api-farmer";
import Moment from "react-moment";
class OpenMarketSuppliers extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      open_market_modal: false,
      price_modal: false,
      region_modal: false,
      gender_modal: false,
      title_modal: false,
      delete_modal: false,
      open_market_id: "",
      title: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      gender: "",
      region: "",
      bankmodal: false,
      bank: "",
      account_number: "",
      vehicle_no: "",
      type: "",
      party_name: "",
      price: "",
      paddy_price: [],
      farmers: [],
      currentPrice: "",
      dateUpdated: "",
      open_market_suppliers: [],
    };
    //this.handleListFarmers();
    // this.handleListPrice();

    this.handleListCooperatives()
  }

  componentDidMount() {
    document.title = "Market Suppliers";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleOpenDeleteModal = (id) => {
    this.setState({
      deletemodal: true,
      id: id,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

   handleListOpenMarket = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        console.log(data);
        this.setState({ open_market_suppliers: data });
        let oms = data.filter(
          oms => oms.cooperativeLiveId === this.state.OMScooperative._id
        )
        this.setState({ open_market_suppliers: oms})
      }
    });
  };

  handleListCooperatives = () => {
    list_OMS_cooperative_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        console.log(data);
        this.setState({OMScooperative: data})
        this.handleListOpenMarket()
      }
    });
  };


  handleListPrice = () => {
    list_paddyprice_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        this.setState({
          paddy_price: data,
          currentPrice: data[0].price,
          dateUpdated: data[0].createdAt,
        });
      }
    });
  };
  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header card-header-primary card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">people</i>
                      </div>
                      <h4 className="card-title">Open Market Suppliers</h4>
                    </div>
                    <div className="card-body">
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          <div
                            id="farmers_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="dt-buttons btn-group">
                              {" "}
                              <button
                                className="btn btn-secondary buttons-pdf buttons-html5"
                                tabIndex="0"
                                aria-controls="farmers"
                                type="button"
                              >
                                <span>Export PDF</span>
                              </button>{" "}
                              <button
                                className="btn btn-secondary buttons-excel buttons-html5"
                                tabIndex="0"
                                aria-controls="farmers"
                                type="button"
                              >
                                <span>Export Excel</span>
                              </button>{" "}
                            </div>
                            <div
                              id="farmers_filter"
                              className="dataTables_filter"
                            >
                              <label>
                                Search:
                                <span className="bmd-form-group bmd-form-group-sm">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="farmers"
                                  />
                                </span>
                              </label>
                            </div>
                            <table
                              title="Open Market Suppliers"
                              id="farmers"
                              className="table table-striped table-no-bordered table-hover dataTable"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                              role="grid"
                              aria-describedby="farmers_info"
                            >
                              <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Name: activate to sort column ascending"
                                      style={{ width: "77px" }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Phone Number: activate to sort column ascending"
                                      style={{ width: "109px" }}
                                    >
                                      Phone Number
                                    </th>
                                    {/* <th
                                      className='sorting'
                                      tabIndex='0'
                                      aria-controls='farmers'
                                      rowSpan='1'
                                      colSpan='1'
                                      aria-label='ID Card: activate to sort column ascending'
                                      style={{ width: '36px' }}
                                    >
                                      ID Card
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Community: activate to sort column ascending"
                                      style={{ width: "87px" }}
                                    >
                                      Address
                                    </th>
                                    
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="LGA: activate to sort column ascending"
                                      style={{ width: "53px" }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="State: activate to sort column ascending"
                                      style={{ width: "53px" }}
                                    >
                                      State
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Added: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "57px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Name
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Phone Number
                                    </th>
                                    {/* <th rowSpan='1' colSpan='1'>
                                      ID Card
                                    </th> */}
                                    <th rowSpan="1" colSpan="1">
                                      Address
                                    </th>
                                    
                                    <th rowSpan="1" colSpan="1">
                                      LGA
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      State
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                              <tbody>
                                {this.state.open_market_suppliers.map(
                                  (data, id) => (
                                    <tr
                                      role="row"
                                      className="odd"
                                      key={data._id}
                                    >
                                      <td>{`${data.first_name} ${data.last_name}`}</td>
                                      <td>{data.phone_number}</td>
                                      {/* <td>{data.Id_number}</td> */}
                                      <td>{data.address}</td>
                                      
                                      <td>{data.LGA}</td>
                                      <td>{data.state}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <Link
                                          className="btn btn-outline-primary btn-sm"
                                          to={{
                                           pathname:"/accountant_open_market_suppliers_detail",
                                           state: {
                                             farmerId: data._id,
                                              cooperativeId:
                                                data.cooperativeLiveId,
                                              liveId: data._id,
                                              direct: "farmer",
                                              crop: data.crop,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                           }

                                          }}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <div
                              className="dataTables_info"
                              id="farmers_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 1 of 1 entries
                            </div>
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="farmers_paginate"
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="farmers_previous"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="farmers_next"
                                >
                                  <a
                                    href="#/"
                                    aria-controls="farmers"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OpenMarketSuppliers;
