import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import {
  list_shipment_live,
  update_shipment_live
} from '../../api/api-shipment';
import {list_quality_metric_live, create_quality_metric_live, read_quality_metric_live} from "../../api/api-quality_metric";
import Nav from "./Nav";
import {create_shipment_quality_metric_live, list_shipment_quality_metric_live} from "../../api/api-shipment_quality_metric"
import {list_payment_schedule_live} from "../../api/api-payment_schedule"
import {list_recovery_shipment_live, remove_recovery_shipment_live} from "../../api/api-recovery_shipment";
import {create_payment_voucher_live, list_payment_voucher_live, update_payment_voucher_live} from "../../api/api-payment_voucher";

import auth from "../../api/auth-helper";
import Moment from "react-moment";
class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_modal: false,
      error: "",
      voucher_no: "",
      voucher_id: "",
      status:"",
      recovery_id:"",
      updatedBy: "",
      amount: "",
      shipment: {},
      redirectToShipment: false,
      status: "",
      vouchers: [],
      pending_payment_schedules: [],
      approved_payment_schedules: [],
      disapproved_payment_schedules: []
    }

    this.handleListPaymentSchedule()
    this.handleListVoucher()
 
  }

  componentDidMount(){
    document.title = "Payment Schedule"
  }

   handleEditModal = () => {
    this.setState((prevState) => ({
      edit_modal: !prevState.modal,
      error: "",
    }));
  };


  checkForm = () => {
    if(!this.state.amount) {
      this.setState({
        error: "Amount is required"
      })
    }

    if(!this.state.voucher_no) {
      this.setState({
        error: "Voucher Number is required"
      })
    }

    if(!this.state.amount && ! this.state.voucher_no){
      this.setState({
        error: "All Fields are required"
      })
    }

    if(this.state.amount && this.state.voucher_no){
      this.handleVoucherSubmit()
        this.handleEditModal()
    }
  }

   handleListVoucher = () => {
    list_payment_voucher_live().then((data) => {
      if (!data) {
        console.log(data.error);
      } else {
        this.setState({
          vouchers: data
        });
      }
    });
   };

   handleListPaymentSchedule = () => {
    list_payment_schedule_live().then((data) => {
      if (!data) {
        console.log(data.error);
      } else {
        this.setState({
          pending_payment_schedules: data.filter((data)=> data.auditor_status === 'pending' || !data.auditor),
          approved_payment_schedules: data.filter((data)=> data.auditor_status === "approved" ),
          disapproved_payment_schedules: data.filter((data)=> data.auditor_status === "disapproved")
        })
      }
    });
   };

    handleVoucherSubmit = () => {
    const jwt = auth.isAuthenticated();
    const payment_voucher ={
      createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
      status: "Unpaid",
      shipment_id: this.props.location.state.shipment_id,
      recovery: this.state.recovery_id,
      amount: this.state.amount,
      voucher_no: this.state.voucher_no
    }
    create_payment_voucher_live(payment_voucher,
    {
      t: jwt.token
    }
    ).then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.handleListVoucher()
      }
    })
  }

  UpdateVoucher (){
    const jwt = auth.isAuthenticated();

    const payment_voucher = {
      updatedBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      status: 'Paid',
    };
    update_payment_voucher_live(payment_voucher,{payment_voucherId: this.state.voucher_id}, {t: jwt.token})
    .then((data)=>{
      if(!data){
        return;
      }
      if(data){
        this.handleListVoucher()
      }
    })
  }

  render() {
    return (
      <div className='wrapper '>
        <SideBar />
        <div className='main-panel'>
          <Nav />

          <div className='content'>
            <div className='content'>
              <div className='container-fluid'>
              <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Pending Payment Schedule</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                        {/* <Link
                            className="btn btn-primary btn-round"
                            data-toggle="modal"
                            data-target="#addCooperativeModal"
                            to={{
                                pathname: '/auditor_payment_schedule_vouchers',
                                
                            }}
                          >
                            Create Payment Schedule
                          </Link> */}
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Accountant Status: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Accountant Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Auditor Status: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Auditor Status
                                        </th>
                                       
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Created By
                                        </th>
                                       
                                        
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Accountant Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Auditor Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Created By
                                        </th>
                                      
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                         <th className="text-center" rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                     {this.state.pending_payment_schedules.map((data) =>(
                                        
                                           <tr role='row' className='odd'>
                                          <td>{data.accountant_status}</td>
                                          <td>{data.auditor || "Pending"}</td>
                                          <td>{data.createdBy}</td>
                                          <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                        <td className="text-center"><div
                                        
                                      >
                                        <Link
                                          className="btn btn-link btn-info edit"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="View Payment Schedule Details"
                                          to={{
                                            pathname:
                                              "/auditor_payment_schedule_details",
                                            state: {
                                              payment_scheduleId: data._id,
                                              direct: "payment_schedule"
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                        
                                      </div></td>
                                        </tr>
                                       
                                      
                                       )  )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>



                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Disapproved Payment Schedule</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >

                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Accountant Status: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Accountant Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Auditor Status: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Auditor Status
                                        </th>
                                       
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Created By
                                        </th>
                                       
                                        
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Accountant Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Auditor Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Created By
                                        </th>
                                      
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                         <th className="text-center" rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody> 
                                    {this.state.disapproved_payment_schedules.map((data) =>(
                                        
                                        <tr role='row' className='odd'>
                                       <td>{data.accountant_status}</td>
                                       <td>{data.auditor || "Pending"}</td>
                                       <td>{data.createdBy}</td>
                                       <td>
                                     <Moment format="D MMM YYYY" withTitle>
                                       {data.created}
                                     </Moment>
                                   </td>
                                     <td className="text-center"><div
                                     
                                   >
                                     <Link
                                       className="btn btn-link btn-info edit"
                                       data-toggle="tooltip"
                                       data-placement="top"
                                       title="View Payment Schedule Details"
                                       to={{
                                         pathname:
                                           "/auditor_payment_schedule_details",
                                         state: {
                                           payment_scheduleId: data._id,
                                           direct: "payment_schedule"
                                         },
                                       }}
                                     >
                                       View
                                     </Link>
                                     
                                   </div></td>
                                     </tr>
                                    
                                   
                                    )  )}
                                 </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>

                


                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Approved Payment Schedule</h4>
                        
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'></div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <div
                              id='aggregations_wrapper'
                              className='dataTables_wrapper dt-bootstrap4'
                            >
                              <div className='row'>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    className='dataTables_length'
                                    id='aggregations_length'
                                  >

                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-6'>
                                  <div
                                    id='aggregations_filter'
                                    className='dataTables_filter'
                                  >
                                    <label>
                                      Search:
                                      <span className='bmd-form-group bmd-form-group-sm'>
                                        <input
                                          type='search'
                                          className='form-control form-control-sm'
                                          aria-controls='aggregations'
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12'>
                                  <table
                                    id='aggregations'
                                    title='Ambursa II Shipment Aggregations'
                                    className='table table-striped table-no-bordered table-hover dataTable'
                                    cellSpacing={0}
                                    width='100%'
                                    style={{ width: '100%' }}
                                    role='grid'
                                    aria-describedby='aggregations_info'
                                  >
                                    <thead>
                                      <tr role='row'>
                                        <th
                                          className='_asc'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-sort='ascending'
                                          aria-label='Accountant Status: activate to sort column descending'
                                          style={{ width: '76px' }}
                                        >
                                          Accountant Status
                                        </th>
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Auditor Status: activate to sort column ascending'
                                          style={{ width: '138px' }}
                                        >
                                          Auditor Status
                                        </th>
                                       
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Cost: activate to sort column ascending'
                                          style={{ width: '70px' }}
                                        >
                                          Created By
                                        </th>
                                       
                                        
                                        <th
                                          className=''
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Date: activate to sort column ascending'
                                          style={{ width: '88px' }}
                                        >
                                          Date
                                        </th>
                                        <th
                                          className='text-center'
                                          tabIndex={0}
                                          aria-controls='aggregations'
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label='Action: activate to sort column ascending'
                                          style={{ width: '118px' }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Accountant Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Auditor Status
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Created By
                                        </th>
                                      
                                        <th rowSpan={1} colSpan={1}>
                                          Date
                                        </th>
                                         <th className="text-center" rowSpan={1} colSpan={1}>
                                          Action
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody> 
                                    {this.state.approved_payment_schedules.map((data) =>(
                                        
                                        <tr role='row' className='odd'>
                                       <td>{data.accountant_status}</td>
                                       <td>{data.auditor || "Pending"}</td>
                                       <td>{data.createdBy}</td>
                                       <td>
                                     <Moment format="D MMM YYYY" withTitle>
                                       {data.created}
                                     </Moment>
                                   </td>
                                     <td className="text-center"><div
                                     
                                   >
                                     <Link
                                       className="btn btn-link btn-info edit"
                                       data-toggle="tooltip"
                                       data-placement="top"
                                       title="View Payment Schedule Details"
                                       to={{
                                         pathname:
                                           "/auditor_payment_schedule_details",
                                         state: {
                                           payment_scheduleId: data._id,
                                           direct: "payment_schedule"
                                         },
                                       }}
                                     >
                                       View
                                     </Link>
                                     
                                   </div></td>
                                     </tr>
                                    
                                   
                                    )  )}
                                 </tbody>
                                  </table>
                                </div>
                              </div>
                              {/* <div className='row'>
                                <div className='col-sm-12 col-md-5'>
                                  <div
                                    className='dataTables_info'
                                    id='aggregations_info'
                                    role='status'
                                    aria-live='polite'
                                  >
                                    Showing 1 to 2 of 2 entries
                                  </div>
                                </div>
                                <div className='col-sm-12 col-md-7'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='aggregations_paginate'
                                  >
                                    <ul className='pagination'>
                                      <li
                                        className='paginate_button page-item previous disabled'
                                        id='aggregations_previous'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className='paginate_button page-item active'>
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li
                                        className='paginate_button page-item next disabled'
                                        id='aggregations_next'
                                      >
                                        <a
                                          href='#/'
                                          aria-controls='aggregations'
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className='page-link'
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>

                
  {!this.state.edit_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="createFarmInputModal"
                    style={{ display: "block", paddingRight: "15px" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Create Payment Voucher
                          </h4>
                          <button
                            type="button"
                            className="close"
                            onClick={()=>{
                              this.setState({
                                edit_modal: false
                              })
                            }}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      for="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Voucher Number
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          voucher_no: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="price"
                                      type="number"
                                      className="form-control"
                                      id="inputPrice"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      for="inputPrice"
                                      className="bmd-label-floating"
                                    >
                                      Enter Amount
                                    </label>
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          amount: event.target.value,
                                          error: "",
                                        })
                                      }
                                      name="amount"
                                      type="number"
                                      className="form-control"
                                      id="inputamount"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer justify-content-center">
                                <input
                                  type="hidden"
                                  name="create_farm_input"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_online"
                                  onClick={this.checkForm}
                                  style={{ display: "block" }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
          <footer className='footer'>
            <div className='container-fluid'>
              <nav className='float-left'>
                <ul>
                  <li>
                    <a href='/'>SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className='copyright float-right'>
                © 2021 , made with <i className='material-icons'>favorite</i> by
                <a href='http://www.crop2cash.com.ng/' rel="noopener noreferrer" target='_blank'>
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ShipmentDetail;
