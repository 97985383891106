import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Pdf from "react-to-pdf";
import { list_cashloan_live } from "../../api/api-cash_loan";
import {
  create_cooperative_lead_farmer_live,
  list_cooperative_lead_farmer_live,
} from "../../api/api-cooperative_lead_farmer";
import { list_farmer_live, remove_farmer_live } from "../../api/api-farmer";
import { list_inputsupply_live } from "../../api/api-input_supply";
import { list_crop_price_live } from "../../api/api-crop_price";
import { list_crop_live } from "../../api/api-crop";
import { list_recovery_center_live } from "../../api/api-recovery_center";
import {
  create_recovery_center_cooperative_live,
  list_recovery_center_cooperative_live,
  remove_recovery_center_cooperative_live,
} from "../../api/api-recovery_center_cooperative";
import { list_shipment_live } from "../../api/api-shipment";
import {read_cooperative_season_live} from "../../api/api-cooperative_season"
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";

const ref = React.createRef();

class AdminCooperativeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      season_drop_down: false,
      recovery_farmer_modal: false,
      recovery_center_modal: false,
      recovery_delete_modal: false,
      recovery_modal: false,
      error_notification: false,
      mssg: "",
      loan: "21000",
      set_lead_famer_drop_down: false,
      farmers: [],
      recovery: [],
      recovery_center_list: [],
      recovery_center: "",
      recovery_id: "",
      cooperative_season_id: "",
      farmer: "",
      bags_recovered: "",
      total_weight: "",
      paddy_price: "",
      paddy_price_updated: "",
      lead_farmer_Id: "",
      selected_lead_farmer: "",
      selected_lead_farmer_id: "",
      selected_lead_farmer_info: [],
      lead_farmer: {},
      error: "",
      shipment: [],
      farmerId: "",
      total_recovery_weight: "",
      total_recovery_cost: "",
      input_supply_total: 0,
      cash_loan_total: 0,
      search: "",
      liveId: "",
      cooperative_lead_farmer: "",
      deletemodal: false,
      crops: [],
      crop: "",
      crop_id: "",
      crop_price: "",
      modals: {
        crop: false,
      },
      cropmodal: false,
    };
    this.handleListFarmers();
    this.handleListRecovery();
    this.handleListCashLoans();
    this.handleListRecoveryCenter();
    this.handleListRecovery();
    this.handleListShipment();
    this.handleListInputSupply();
    this.handleListLeadFarmer();
    this.handleCrop();
    this.handleCooperativeSeason();
  }

  componentDidMount() {
    document.title = "Cooperative Details";
    console.log(this.props.location);
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleCropModal = () => {
    this.setState((prevState) => ({
      cropmodal: !prevState.cropmodal,
    }));
  };

  handleCooperativeSeason = () => {
    read_cooperative_season_live({cooperative_id: this.props.location.state.cooperativeId}).then((data) => {
      if(!data) {
       this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
          console.log(data.cooperative_season)
          this.setState({
          cooperative_season_id: data.cooperative_season[0]._id
           });
           console.log(this.state.cooperative_season_id)  
        }     
    })
  }

  handleListShipment = () => {
    list_shipment_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({
          shipment: data.filter(
            (data) =>
              data.type === "Cooperative" &&
              data.cooperativeId === this.props.location.state.cooperativeId
          ),
        });
      }
    });
  };

  handleCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data) => {
        if (!data) {
          this.setState({
            error_notification: true,
            mssg: "There seems to be a problem, reload this page or login out",
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        }
        if (data.message) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
            crops: [],
          });
        }
        if (data.error) {
          this.setState({
            mssg: data.error,
            error_notification: true,
            crops: [],
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ crops: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  getCropPrice = () => {
    console.log(this.state.crop_id);
    list_crop_price_live().then((data) => {
      console.log(data);
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      }
      if (data) {
        const currentCrop = data.filter(
          (crop) => crop.crop === this.state.crop_id
        );
        console.log(this.state.crop_id);
        if (!currentCrop[0]) {
          this.setState({
            mssg: `This Farmer's crop doesn't have a price`,
            error_notification: true,
            error: "The Farmer's crop doesn't have a price",
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({
            crop_price: currentCrop[0].price,
          });
        }
      }
    });
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      deletemodal: !prevState.deletemodal,
    }));
  };

  deleteFarmer = () => {
    remove_farmer_live({
      farmerId: this.state.Id,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListFarmers();
        /*
				remove_farmer(
					{
						_id: this.state.liveId,
					},
					
				).then(data => {
					if (!data) {
						return;
					} if(data) {
						const farmer = {
							liveId: this.state.liveId,
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
						};
						create_deleted_farmer_live(farmer, {
							t: jwt.token,
						}).then(data => {
							if (!data) {
								return;
							} if(data) {
								console.log("farmer deleted")
							}
						})
						this.handleListFarmers();
					}
				});
				*/
      }
    });

    this.handleDeleteModal();
  };

  handleGetDeleteFarmerId = (farmerId, liveId) => {
    this.setState({
      Id: farmerId,
      liveId: liveId,
    });

    this.handleDeleteModal();
  };

  handleGetCropPrice = (crop_id) => {
    this.setState({
      crop_id: crop_id,
    });
  };
  handleListInputSupply = () => {
    list_inputsupply_live().then((data) => {
      console.log(data);
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data.length === 0) {
        this.setState({ input_supply: [], input_supply_total: 0 });
      } else if (data.length !== 0) {
        let farmerIds = [];
        this.state.farmers.forEach((farmer) => {
          farmerIds.push(farmer._id);
        });
        let inputs_array = [];
        let total;
        let inputs = [];
        farmerIds.map((farmerid) => {
          console.log(farmerid);
          inputs.push(
            ...data.filter((input) => input.farmerLiveId == farmerid)
          );
        });
        console.log(inputs);
        if (inputs.length !== 0) {
          let collected_input = inputs.filter(
            (input) => input.collected === true
          );

          collected_input.map((data) => {
            inputs_array.push(Number(data.cost));
          });
          total = inputs_array.reduce((a, b) => a + b, 0);
          this.setState({ input_supply: inputs, input_supply_total: total });
        } else if (inputs.length === 0) {
          this.setState({ input_supply: [], input_supply_total: 0 });
        }
      }
      console.log(this.state.input_supply_total);
    });
  };

  handleListCashLoans = () => {
    list_cashloan_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data.length === 0) {
        this.setState({ cash_loan_total: 0 });
      } else if (data.length !== 0) {
        let cash_loan_array = [];
        let total;
        let cash_loans = data.filter(
          (cash_loan) =>
            cash_loan.cooperativeLiveId ===
            this.props.location.state.cooperativeLiveId
        );
        if (cash_loans.length !== 0) {
          let collected_cash_loan = cash_loans.filter(
            (cash_loan) => cash_loan.collected === true
          );
          collected_cash_loan.map((data) => {
            cash_loan_array.push(Number(data.amount));
          });

          total = cash_loan_array.reduce((a, b) => a + b, 0);
        }
        this.setState({ cash_loan_total: total });
        if (cash_loans.length === 0) {
          this.setState({ cash_loan_total: 0 });
        }
        console.log(this.state.cash_loan_total);
      }
    });
  };

  handleRecoveryFarmerModal = () => {
    this.setState((prevState) => ({
      recovery_farmer_modal: !prevState.recovery_farmer_modal,
      error: "",
    }));
  };

  handleRecoveryDeleteModal = () => {
    this.setState((prevState) => ({
      recovery_delete_modal: !prevState.recovery_delete_modal,
      error: "",
    }));
  };

  handleRecoveryCenterModal = () => {
    this.setState((prevState) => ({
      recovery_center_modal: !prevState.recovery_center_modal,
      error: "",
    }));
  };

  handleListFarmers = () => {
    list_farmer_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data !== []) {
        const currentLeadFarmer = data.filter(
          (farmer) =>
            farmer.cooperativeLiveId ===
              this.props.location.state.cooperativeId && farmer.lead_farmer
        );
        const cooperativeFarmers = data.filter(
          (farmer) =>
            farmer.cooperativeLiveId ===
            this.props.location.state.cooperativeLiveId
        );
        this.setState({
          farmers: cooperativeFarmers,
          lead_farmer: currentLeadFarmer[0],
        });
      } else if (data === []) {
        this.setState({ farmers: [], lead_farmer: [] });
      }
    });
  };

  handleListRecovery = () => {
    list_recovery_center_cooperative_live().then((data) => {
      if (!data) {
        return;
      }
      if (data.length !== 0) {
        let total_weight_array = [];
        let total_cost_array = [];
        let total_weight;
        let total_cost;
        let cooperativeRecovery = data.filter(
          (recovery) =>
            recovery.cooperativeId === this.props.location.state.cooperativeId
        );
        cooperativeRecovery.map((data) => {
          total_weight_array.push(Number(data.total_weight));
          total_cost_array.push(Number(data.total_cost));
        });
        total_weight = total_weight_array.reduce((a, b) => a + b, 0);
        total_cost = total_cost_array.reduce((a, b) => a + b, 0);
        this.setState({
          recovery: cooperativeRecovery,
          total_recovery_weight: total_weight,
          total_recovery_cost: total_cost,
        });
      } else if (data.length === 0) {
        this.setState({ recovery: [] });
      }
    });
  };

  handleListRecoveryCenter = () => {
    list_recovery_center_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ recovery_center_list: data });
      }
    });
  };

  handleRecoveryModal = () => {
    this.setState((prevState) => ({
      recovery_modal: !prevState.recovery_modal,
    }));
  };

  chooseLeadFarmer = (id, liveId, first_name, last_name) => {
    this.setState({
      selected_lead_farmer: first_name + " " + last_name,
      selected_lead_farmer_id: id,
    });
    this.handleSetFarmerDropDown();
  };

  handleListLeadFarmer = () => {
    list_cooperative_lead_farmer_live().then((data) => {
      if (!data) {
        return;
      }
      if (data.length > 0) {
        let cooperative_lead_farmers = data.filter(
          (data) =>
            data.cooperativeId === this.props.location.state.cooperativeId
        );
        if (cooperative_lead_farmers.length !== 0) {
          this.setState({
            selected_lead_farmer:
              cooperative_lead_farmers[0].farmer.first_name +
              " " +
              cooperative_lead_farmers[0].farmer.last_name,
              selected_lead_farmer_info: cooperative_lead_farmers[0].farmer
          });
        }
      }
    });
  };

  addLeadFarmer = () => {
    const jwt = auth.isAuthenticated();
    const lead_farmer = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      cooperativeId: this.props.location.state.cooperativeLiveId,
      farmer_name: this.selected_lead_farmer,
      farmer: this.state.selected_lead_farmer_id,
      organization: auth.isAuthenticated().user.organization_id,
    };
    create_cooperative_lead_farmer_live(lead_farmer, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListLeadFarmer();
        this.setState({
          selected_lead_farmer: "",
        });
        /*
				const lead_farmer = {
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					cooperativeId: this.props.location.state.cooperativeId,
					farmer: this.state.selected_lead_farmer,
					
				};
				create_cooperative_lead_farmer(lead_farmer, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					}
					if (data) {
						this.handleListLeadFarmer();

						this.setState({
							selected_lead_farmer: '',
						});
					}
				});
				*/
      }
    });
  };

  checkForm = () => {
    if (!this.state.farmer) {
      this.setState({
        error: "Farmer is required",
      });
    }

    if (!this.state.bags_recovered) {
      this.setState({
        error: " Number Of Bags Recovered is required",
      });
    }

    if (!this.state.total_weight) {
      this.setState({
        error: "Total Weight is required",
      });
    }

    if (!this.state.recovery_center) {
      this.setState({
        error: "Recovery Center is required",
      });
    }

    if (
      this.state.farmer &&
      this.state.bags_recovered &&
      this.state.total_weight &&
      this.state.crop_price &&
      this.state.recovery_center
    ) {
      this.handleSubmitRecovery();
    }

    if (
      !this.state.farmer &&
      !this.state.bags_recovered &&
      !this.state.total_weight &&
      !this.state.crop_price &&
      !this.state.recovery_center
    ) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  handleSubmitRecovery = () => {
    const jwt = auth.isAuthenticated();
    const recovery = {
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      coop_name: this.props.location.state.name,
      cooperativeId: this.props.location.state.cooperativeLiveId,
      farmerId: this.state.farmerIdLive,
      farmer: this.state.farmer,
      num_of_bags: this.state.bags_recovered,
      total_weight: this.state.total_weight,
      price: this.state.crop_price,
      total_cost: this.state.crop_price * this.state.total_weight,
      recovery_center: this.state.recovery_center,
      crop: this.state.crop_id,
      cooperative_season_id: this.state.cooperative_season_id
    };
    create_recovery_center_cooperative_live(recovery, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListRecovery();
        this.handleRecoveryModal();

        /*
				const recovery = {
					liveId: data.data._id,
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					cooperativeId: this.props.location.state.cooperativeId,
					farmerId: this.state.farmerId,
					farmer: this.state.farmer,
					num_of_bags: this.state.bags_recovered,
					total_weight: this.state.total_weight,
					price: this.state.paddy_price,
					total_cost: this.state.paddy_price * this.state.total_weight,
					recovery_center: this.state.recovery_center,
				};
				create_recovery_center_cooperative(recovery, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					}
					if (data) {
						this.handleListRecovery();
						this.handleRecoveryModal();

						this.setState({
							farmer: '',
							num_of_bags: '',
							total_weight: '',
							price: '',
							recovery_center: '',
						});
					}
				});
				*/
      }
    });
  };

  handleSeasonDropDown = () => {
    this.setState((prevState) => ({
      season_drop_down: !prevState.season_drop_down,
    }));
  };

  handleSetFarmerDropDown = () => {
    this.setState((prevState) => ({
      set_lead_famer_drop_down: !prevState.set_lead_famer_drop_down,
    }));
  };

  deleteRecovery = (id, liveId) => {
    console.log(liveId);
    remove_recovery_center_cooperative_live({
      recoveryCenterCooperativeId: id,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListRecovery();
        /*
				remove_recovery_center_cooperative(
					{
						_id: liveId,
					},
					
				).then(data => {
					if (!data) {
						return;
					} if(data) {
						const recovery_center_cooperative = {
							liveId: liveId,
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
						};
						create_deleted_recovery_center_cooperative_live(recovery_center_cooperative, {
							t: jwt.token,
						}).then(data => {
							console.log("dannnn")
							console.log(data)
							if (!data) {
								return;
							} if(data) {
								console.log(data)
							}
						})
						this.handleListRecovery();
					}
				});
				*/
      }
    });

    this.setState({ recovery_delete_modal: false });
  };

  render() {
    const { search } = this.state;
    const farmers = this.state.farmers.filter((farmer) => {
      return farmer.first_name.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              {this.state.error_notification && (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              )}
              <Link
                className="btn btn-primary btn-round"
                to={{
                  pathname: "/admin_cooperative",
                  state: {
                    cooperativeId: this.props.location.state.cooperativeId,
                    name: this.props.location.state.name,
                    type: this.props.location.state.type,
                    start_month: this.props.location.state.start_month,
                    end_month: this.props.location.state.end_month,
                    year: this.props.location.state.year,
                  },
                }}
              >
                Go Back
              </Link>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-6">
                    <h3>{this.props.location.state.name}</h3>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.props.location.state.type
                            .slice(0, 1)
                            .toUpperCase() +
                            "-" +
                            this.props.location.state.start_month
                              .slice(0, 3)
                              .toUpperCase() +
                            "-" +
                            this.props.location.state.end_month
                              .slice(0, 3)
                              .toUpperCase() +
                            "-" +
                            this.props.location.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>

                      <div className="card-footer">
                        {/* <div className="stats">
                          <div className="dropdown">
                            <button
                              onClick={this.handleSeasonDropDown}
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                            >
                              Change Season
                            </button>

                            <div
                              className={
                                !this.state.season_drop_down
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              aria-labelledby="dropdownSeasonButton"
                            >
                              <h6 className="dropdown-header">Seasons</h6>

                              <a className="dropdown-item">Code</a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">arrow_downward</i>
                        </div>
                        <p className="card-category">Loan Received</p>
                        <h3 className="card-title">
                          ₦
                          {Number(this.state.input_supply_total) +
                            Number(this.state.cash_loan_total)}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          Loan Received by all the Farmers in this Cooperative.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">arrow_upward</i>
                        </div>
                        <p className="card-category">Loan Recovered</p>
                        <h3 className="card-title">
                          ₦{this.state.total_recovery_cost}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          Loan Recovered from all the Farmers in this
                          Cooperative.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-info card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <p className="card-category">Kgs Delivered</p>
                        <h3 className="card-title">
                          {this.state.total_recovery_weight} Kg
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          Tons Recovered from all the Farmers in this
                          Cooperative
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title">Farmers</h4>
                        <h6>Manage Cooperative Farmers</h6>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <Link
                            to={{
                              pathname: "/admin_add_farmer",
                              state: {
                                cooperativeId: this.props.location.state
                                  .cooperativeId,
                                cooperativeLiveId: this.props.location.state
                                  .cooperativeLiveId,
                                name: this.props.location.state.name,
                                type: this.props.location.state.type,
                                start_month: this.props.location.state
                                  .start_month,
                                end_month: this.props.location.state.end_month,
                                year: this.props.location.state.year,
                              },
                            }}
                            className="btn btn-primary btn-round"
                          >
                            Add Farmer
                          </Link>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="farmers_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div
                                id="farmers_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="farmers"
                                      onChange={(event) =>
                                        this.setState({
                                          search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                ref={ref}
                                id="farmers"
                                title="Farmers"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="farmers_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Name: activate to sort column ascending"
                                      style={{ width: "157px" }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Phone Number: activate to sort column ascending"
                                      style={{ width: "139px" }}
                                    >
                                      Phone Number
                                    </th>
                                    {/* <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="ID Card: activate to sort column ascending"
                                      style={{ width: "36px" }}
                                    >
                                      ID Card
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Community: activate to sort column ascending"
                                      style={{ width: "97px" }}
                                    >
                                      Address
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Zone: activate to sort column ascending"
                                      style={{ width: "98px" }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="LGA: activate to sort column ascending"
                                      style={{ width: "53px" }}
                                    >
                                      State
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Added: activate to sort column ascending"
                                      style={{ width: "100px" }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="farmers"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "57px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Name
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Phone Number
                                    </th>
                                    {/* <th rowSpan="1" colSpan="1">
                                      ID Card
                                    </th> */}
                                    <th rowSpan="1" colSpan="1">
                                      Address
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      LGA
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      State
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {farmers.map((data) => (
                                    <tr
                                      role="row"
                                      className="odd"
                                      key={data._id}
                                    >
                                      <td>{`${data.first_name} ${data.last_name}`}</td>
                                      <td>{data.phone_number}</td>
                                      {/* <td>{data.Id_number}</td> */}
                                      <td>{data.address}</td>
                                      <td>{data.LGA}</td>
                                      <td>{data.state}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td
                                        className="text-right inline-block"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View Farmer"
                                      >
                                        <Link
                                          to={{
                                            pathname: "/admin_farmer_detail",
                                            state: {
                                              direct: "cooperative",
                                              farmerId: data._id,
                                              cooperativeLiveId: this.props
                                                .location.state
                                                .cooperativeLiveId,
                                              name: this.props.location.state
                                                .name,
                                              type: this.props.location.state
                                                .type,
                                              start_month: this.props.location
                                                .state.start_month,
                                              end_month: this.props.location
                                                .state.end_month,
                                              year: this.props.location.state
                                                .year,
                                              crop: data.crop,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                        <div>
                                          <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value="MkOSHc7Cu2hIpeEzhyaeLxRVDMKBiKEJ20vVZ9YKX8mFCgHX8GQea3CeazM7DkH0"
                                          />
                                          <input
                                            type="hidden"
                                            name="delete_farmer"
                                            value="1"
                                          />
                                          <button
                                            onClick={() =>
                                              this.handleGetDeleteFarmerId(
                                                data._id,
                                                data._id
                                              )
                                            }
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Farmer"
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className="dataTables_info"
                                id="farmers_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 1 to 2 of 2 entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="farmers_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="farmers_previous"
                                  >
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="farmers_next"
                                  >
                                    <a
                                      aria-controls="farmers"
                                      data-dt-idx="2"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">directions_run</i>
                        </div>
                        <h4 className="card-title">Set Lead Farmer</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                         <h4>Current Lead Farmer: <Link to={{ pathname: "/farmer_detail", state:{ direct: "cooperative",
                                              farmerId: this.state.selected_lead_farmer_info._id,
                                              cooperativeLiveId: this.props
                                                .location.state
                                                .cooperativeLiveId,
                                              name: this.props.location.state
                                                .name,
                                              type: this.props.location.state
                                                .type,
                                              start_month: this.props.location
                                                .state.start_month,
                                              end_month: this.props.location
                                                .state.end_month,
                                              year: this.props.location.state
                                                .year,
                                              crop: this.state.selected_lead_farmer_info.crop,
                                              first_name: this.state.selected_lead_farmer_info.first_name,
                                              last_name: this.state.selected_lead_farmer_info.last_name,

                                          }}}>{this.state.selected_lead_farmer}</Link></h4>
                        </div>
                        <div className="">
                          <div>
                            <div className="form-group">
                              <div className="dropdown bootstrap-select show">
                                <button
                                  type="button"
                                  className="dropdown-toggle bs-placeholder btn btn-success"
                                  data-toggle="dropdown"
                                  role="button"
                                  onClick={this.handleSetFarmerDropDown}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        {this.state.selected_lead_farmer
                                          ? this.state.selected_lead_farmer
                                          : "Choose Farmer"}
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div className="ripple-container" />
                                </button>
                                <div
                                  className={
                                    !this.state.set_lead_famer_drop_down
                                      ? "dropdown-menu"
                                      : "dropdown-menu show"
                                  }
                                  role="combobox"
                                  x-placement="bottom-start"
                                  style={{
                                    overflow: "hidden",
                                    position: "absolute",
                                    top: "46px",
                                    left: "1px",
                                    willChange: "top, left",
                                  }}
                                >
                                  <div
                                    className="inner show"
                                    role="listbox"
                                    aria-expanded="true"
                                    tabIndex="-1"
                                    style={{
                                      maxHeight: "152px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="disabled">
                                        <a
                                          role="option"
                                          className="dropdown-item disabled"
                                          aria-disabled="true"
                                          tabIndex="-1"
                                          aria-selected="false"
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text"></span>
                                        </a>
                                      </li>
                                      {this.state.farmers.map((data) => (
                                        <li key={data._id}>
                                          <a
                                            role="option"
                                            className="dropdown-item"
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() => {
                                              console.log(data);
                                              this.chooseLeadFarmer(
                                                data._id,
                                                data.liveId,
                                                data.first_name,
                                                data.last_name,
                                                data
                                              );
                                            }}
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">{`${data.first_name} ${data.last_name}`}</span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                              onClick={() => this.addLeadFarmer()}
                              type="submit"
                              className="btn btn-fill btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">ballot</i>
                        </div>
                        <h4 className="card-title">Recovery</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <a
                            className="btn btn-primary btn-round"
                            data-toggle="modal"
                            data-target="#addAggregateModal"
                            onClick={() => this.handleRecoveryModal()}
                          >
                            Recover
                          </a>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              title="Recoveries"
                              id="aggregations"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Farmer</th>
                                  <th>Crop</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>Price/Kg</th>
                                  <th>Total Cost</th>
                                  <th>Recovery Centre</th>
                                  <th>Date</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Farmer</th>
                                  <th>Crop</th>
                                  <th>Number of Bags</th>
                                  <th>Total Weight</th>
                                  <th>Price/Kg</th>
                                  <th>Total Cost</th>
                                  <th>Recovery Centre</th>
                                  <th>Date</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {!this.state.recovery ? (
                                  <h3>Nothing to see here yet.</h3>
                                ) : (
                                  this.state.recovery.map((data) => (
                                    <tr key={data._id}>
                                      <td>{data.farmer}</td>
                                      <td>{data.crop.name}</td>
                                      <td>{data.num_of_bags}</td>
                                      <td>{data.total_weight}Kg</td>
                                      <td>₦{data.price}/Kg</td>
                                      <td>₦{data.total_cost}</td>
                                      <td>{data.recovery_center}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <div>
                                          {data.kg_status === "recovered" ? (
                                            <button
                                              type="submit"
                                              className="btn btn-link btn-just-icon btn-success"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                            >
                                              <i className="material-icons">
                                                done_all
                                              </i>
                                            </button>
                                          ) : (
                                            <button
                                              type="submit"
                                              className="btn btn-link btn-just-icon btn-danger"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Delete Aggregation"
                                              onClick={() =>
                                                this.setState({
                                                  recovery_id: data._id,
                                                  liveId: data.liveId,
                                                  recovery_delete_modal: true,
                                                })
                                              }
                                            >
                                              <i className="material-icons">
                                                clear
                                              </i>
                                            </button>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Mill Deliveries</h4>
                        <h6 className="card-title">
                          Apply Aggregations to Mills
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Party Name</th>
                                  <th>Date</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Party Name</th>
                                  <th>Date</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.shipment.map((data) => (
                                  <tr>
                                    <td>{data.party_name}</td>
                                    <td>{data.createdAt}</td>
                                    <td className="text-right inline-block">
                                      <Link
                                        to={{
                                          pathname: "/shipment_recovery",
                                          state: {
                                            direct: "cooperative",
                                            cooperativeId: this.props.location
                                              .state.cooperativeId,
                                            shipmentId: data._id,
                                            name: this.props.location.state
                                              .name,
                                            type: this.props.location.state
                                              .type,
                                            start_month: this.props.location
                                              .state.start_month,
                                            end_month: this.props.location.state
                                              .end_month,
                                            year: this.props.location.state
                                              .year,
                                          },
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                      >
                                        Apply Aggregations
                                      </Link>

                                      <Link
                                        to={{
                                          pathname: "/shipment_options",
                                          state: {
                                            direct: "cooperative",
                                            cooperativeId: this.props.location
                                              .state.cooperativeId,
                                            shipmentId: data._id,
                                            name: this.props.location.state
                                              .name,
                                            type: this.props.location.state
                                              .type,
                                            start_month: this.props.location
                                              .state.start_month,
                                            end_month: this.props.location.state
                                              .end_month,
                                            year: this.props.location.state
                                              .year,
                                          },
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Farmer?</h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this farmer
                                </div>

                                <button
                                  type="submit"
                                  onClick={this.deleteFarmer}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  farmer.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.recovery_delete_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Delete Recovery?</h4>
                          <button
                            onClick={this.handleRecoveryDeleteModal}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div>
                                Are you sure you want to delete this Recovery
                              </div>

                              <button
                                type="submit"
                                onClick={() =>
                                  this.deleteRecovery(
                                    this.state.recovery_id,
                                    this.state.liveId
                                  )
                                }
                                className="btn btn-fill btn-success"
                              >
                                Delete
                              </button>
                              <button
                                type="submit"
                                onClick={this.handleDeleteInputModal}
                                className="btn btn-fill btn-danger"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                id="create_offline"
                                style={{ display: "none" }}
                              >
                                Create Offline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.recovery_modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Recover a Farmer's Harvest
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={this.handleRecoveryModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div>
                                <div className="form-group">
                                <div
                                  className={
                                    !this.state.modals.recovery_modal
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                   
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn btn-success"
                                      data-toggle="dropdown"
                                      role="button"
                                      title="Choose Recovery Centre"
                                      aria-expanded="false"
                                      onClick={this.handleRecoveryCenterModal}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.recovery_center
                                              ? "Choose Recovery Centre"
                                              : this.state.recovery_center}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      className={
                                        !this.state.recovery_center_modal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      x-placement="bottom-start"
                                      style={{
                                        overflow: "hidden",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="false"
                                        tabIndex="-1"
                                        style={{ overflowY: "auto" }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.recovery_center || "Choose Recovery Center"}
                                              </span>
                                            </a>
                                          </li>
                                          {this.state.recovery_center_list.map(
                                            (data) => (
                                              <li>
                                                <a
                                                  role="option"
                                                  className={
                                                    this.state
                                                      .recovery_center !==
                                                    `${data.name}  ${data.LGA}`
                                                      ? "dropdown-item"
                                                      : "dropdown-item selected active"
                                                  }
                                                  aria-disabled="false"
                                                  tabIndex="0"
                                                  aria-selected="false"
                                                  onClick={() =>
                                                    this.setState({
                                                      recovery_center: `${data.name}  ${data.LGA}`,
                                                      recovery_center_modal: false,
                                                    })
                                                  }
                                                >
                                                  <span className=" bs-ok-default check-mark" />
                                                  <span className="text">
                                                    {data.name} {data.LGA}
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                <div
                                  className={
                                    !this.state.recovery_farmer_modal
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn btn-success"
                                      data-toggle="dropdown"
                                      role="button"
                                      title="Choose Farmer"
                                      aria-expanded="true"
                                      onClick={this.handleRecoveryFarmerModal}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.farmer
                                              ? "Choose Farmer"
                                              : this.state.farmer}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      className={
                                        !this.state.recovery_farmer_modal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      x-placement="bottom-start"
                                      style={{
                                        overflow: "hidden",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{ overflowY: "auto" }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Choose Farmer
                                              </span>
                                            </a>
                                          </li>
                                          {this.state.farmers.map((data) => (
                                            <li>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.recovery_center !==
                                                  `${data.name}  ${data.LGA}`
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {
                                                  this.setState({
                                                    farmer: `${data.first_name}  ${data.last_name}`,
                                                    farmerIdLive: data._id,
                                                    farmerId: data._id,
                                                    recovery_farmer_modal: false,
                                                  });
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.first_name}{" "}
                                                  {data.last_name}
                                                </span>
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    !this.state.modals.crop
                                      ? "dropdown bootstrap-select"
                                      : "dropdown bootstrap-select show"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-success"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                    onClick={() => this.handleModal("crop")}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.crop
                                            ? "Choose Crop"
                                            : this.state.crop}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.modals.crop
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      minWidth: "250px",
                                      overflow: "hidden",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled">
                                          <a
                                            role="option"
                                            className="dropdown-item disabled"
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="false"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              {this.state.crop || "Choose Crop"}
                                            </span>
                                          </a>
                                        </li>
                                        {this.state.crops.map((data) => {
                                          return (
                                            <li key={data._id}>
                                              <a
                                                role="option"
                                                className={
                                                  this.state.crop === ""
                                                    ? "dropdown-item"
                                                    : "dropdown-item selected active"
                                                }
                                                aria-disabled="false"
                                                tabIndex="0"
                                                aria-selected="false"
                                                onClick={() => {
                                                  console.log(data._id);
                                                  this.handleModal("crop");
                                                  this.setState({
                                                    crop: data.name,
                                                    crop_id: data._id,
                                                    cropmodal: false,
                                                  });
                                                  this.handleGetCropPrice(
                                                    data._id
                                                  );
                                                }}
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {data.name}
                                                </span>
                                              </a>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    this.state.bags_recovered
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    htmlFor="bagsField"
                                    className="bmd-label-floating"
                                  >
                                    Enter the number of bags recovered.
                                  </label>
                                  <input
                                    onChange={(event) => {
                                      this.setState({
                                        bags_recovered: event.target.value,
                                      });
                                      this.getCropPrice();
                                    }}
                                    required=""
                                    name="bags"
                                    type="number"
                                    className="form-control"
                                    step="1"
                                    id="bagsField"
                                    min="0"
                                  />
                                </div>
                                <div
                                  className={
                                    this.state.total_weight
                                      ? "is-filled form-group bmd-form-group"
                                      : `form-group bmd-form-group `
                                  }
                                >
                                  <label
                                    htmlFor="weightField"
                                    className="bmd-label-floating"
                                  >
                                    Enter the total weight of the bags.
                                  </label>
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        total_weight: event.target.value,
                                      })
                                    }
                                    required=""
                                    name="weight"
                                    type="number"
                                    className="form-control"
                                    step="0.01"
                                    id="weightField"
                                    min="0"
                                  />
                                </div>
                                <input
                                  type="hidden"
                                  name="aggregate"
                                  value="1"
                                />
                                <button
                                  onClick={() => this.checkForm()}
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                >
                                  Recover
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by{" "}
                <a
                  href="http://www.crop2cash.com.ng/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AdminCooperativeDetails;
