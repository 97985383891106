import React, { Component } from "react";
import Loader from "react-loader-spinner";
import {CSVLink} from "react-csv";
import Nav from "./Nav";
import SideBar from "./SideBar";
import SingleSeason from "../Shared/Seasons/SingleSeason";
import NotificationComp from "../Shared/NotificationComp";
import CreateFarmingSeasonForm from "../Shared/Seasons/CreateFarmingSeasonForm";

import { listseasons_live, removeSeason_live } from "../../api/api-seasons";

class AdminSeason extends Component {
  state = {
    message: "",
    seasons: [],
    loading: false,
    deleteModal: false,
    showFormModal: false,
    seasonIdToDelete: "",
    notifications: {
      error: false,
      success: false,
    },
    csv_header: [],
    csv_data :[]
  };

  componentDidMount() {
    this.handleList();
    this.handleCSV()
    document.title = "Seasons";
  }

  addNotification = (type, message) => {
    const { notifications } = this.state;

    this.setState({
      message,
      notifications: {
        ...notifications,
        [type]: true,
      },
    });
    setTimeout(() => this.setState({ notifications: { [type]: false } }), 3000);
  };

  toggleCreateSeasonModal = () => {
    this.setState((prevState) => ({
      showFormModal: !prevState.showFormModal,
    }));
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deleteModal: false,
      seasonIdToDelete: "",
    });
  };

  handleList = async () => {
    if (navigator.onLine) {
      try {
        const seasons = await listseasons_live();
        this.setState({ seasons });
      } catch (error) {
        this.setState(({ notifications }) => ({
          message: `Couldn't fetch seasons.`,
          notifications: { ...notifications, error: true },
        }));
        setTimeout(() => this.setState({ notifications: { error: false } }), 3000);
      }
    }
  };

  hideNotifications = () => {
    const notifications = {
      error: false,
      success: false,
    };

    this.setState({ notifications });
  };

  handleCSV = async () => {
    await listseasons_live().then(async (data)=> {
      if(!data || data.message) {
        return
      }
      else if (data.length !== 0){
        const headers = [
          {label:"Type", key: "type"},
          {label:"Start_Month", key: "start_month"},
          {label:"End_Month", key: "end_month"},
          {label:"Year", key: "year"},
        ];

        const all_data = data;
        let csvdata = []

        await all_data.map(async (data)=>{
          let csvData ={
            type: data.type,
            start_month: data.start_month,
            end_month: data.end_month,
            year: data.year
          };
          csvdata.push(csvData)
          console.log(csvdata)
          this.setState({
            csv_header: headers,
            csv_data: csvdata,
          });
        })
      }else{
        this.setState({seasons :[]})
      }
    })
  }

  deleteData = async () => {
    this.setState({ loading: true });
    const { seasonIdToDelete } = this.state;

    try {
      await removeSeason_live(seasonIdToDelete);

      this.handleList();
      this.handleCloseDeleteModal();

      this.setState(({ notifications }) => ({
        message: "Season deleted successfully",
        notifications: { ...notifications, success: true },
      }));
      setTimeout(() => this.setState({ notifications: { error: false } }), 3000);
    } catch (error) {
      console.error(error);

      this.setState(({ notifications }) => ({
        message: "Season could not be deleted.",
        notifications: { ...notifications, error: true },
      }));
    }

    this.setState({ loading: false });
  };

  render() {
    const { loading, deleteModal, showFormModal } = this.state;

    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <NotificationComp
                  message={this.state.message}
                  handleClick={this.hideNotifications}
                  notifications={this.state.notifications}
                />
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Farming Seasons</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.toggleCreateSeasonModal}
                          >
                            Create Farming Season
                          </button>
                        </div>

                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Seasons.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                              </div>
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Start Month</th>
                                  <th>End Month</th>
                                  <th>Year</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Type</th>
                                  <th>Start Month</th>
                                  <th>End Month</th>
                                  <th>Year</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.seasons.length > 0 &&
                                  this.state.seasons.map((data) => (
                                    <SingleSeason
                                      {...data}
                                      key={data._id}
                                      handleClick={() => {
                                        this.setState({
                                          deleteModal: true,
                                          seasonIdToDelete: data._id,
                                        });
                                      }}
                                    />
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {deleteModal && (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Delete This Season?
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            {!loading ? (
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    Are you sure you want to delete this Season?
                                  </div>

                                  <button
                                    type="submit"
                                    onClick={() => this.deleteData()}
                                    className="btn btn-fill btn-success"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="submit"
                                    onClick={this.handleCloseDeleteModal}
                                    className="btn btn-fill btn-danger"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <Loader
                                style={{ padding: "11vh", textAlign: "center" }}
                                type="Oval"
                                color="green"
                                height={50}
                                width={50}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  record.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {showFormModal && (
                  <CreateFarmingSeasonForm
                    handleList={this.handleList}
                    addNotification={this.addNotification}
                    toggleCreateSeasonModal={this.toggleCreateSeasonModal}
                  />
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default AdminSeason;
