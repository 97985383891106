import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import Nav from "./Nav";
import Moment from "react-moment";
import { list_shipment_live } from "../../api/api-shipment";
import { listseasons_live } from "../../api/api-seasons";
class Shipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipments: [],
      current_season: {},
      nothing_shipment: [],
      waiting_shipment: [],
      approved_shipment: [],
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
    };
    this.handleListShipment();
    this.handleListSeason();
  }

  componentDidMount() {
    document.title = "Shipment";
  }

  handleListShipment = () => {
    list_shipment_live().then((data) => {
      if (!data) {
        return;
      } else {
        console.log(data);
        this.setState({
          // shipments: data,
          nothing_shipment: data.filter((data) => data.status === "nothing"),
          waiting_shipment: data.filter(
            (data) => data.status === "waiting" || data.status === "disapproved"
          ),
          shipments: data.filter((data) => data.status === "approved"),
        });
      }
    });
  };

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
            loan_per_acre: "",
          });
        }
      }
    });
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />
        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <div className="dropdown">
                            {/* <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Change Season
                            </button> */}
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownSeasonButton"
                            >
                              <h6 className="dropdown-header">Seasons</h6>
                              <a
                                className="dropdown-item"
                                href="/accounts/shipments/?season=D-JAN-MAY-18"
                              >
                                {this.state.type.slice(0, 1).toUpperCase() +
                                  "-" +
                                  this.state.start_month
                                    .slice(0, 3)
                                    .toUpperCase() +
                                  "-" +
                                  this.state.end_month
                                    .slice(0, 3)
                                    .toUpperCase() +
                                  "-" +
                                  this.state.year
                                    .toString()
                                    .slice(2, 4)
                                    .toUpperCase()}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">ballot</i>
                        </div>
                        <p className="card-category">Shipments To Aggregate</p>
                        <h3 className="card-title">
                          {this.state.nothing_shipment.length}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-success card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">done</i>
                        </div>
                        <p className="card-category">
                          Shipments Awaiting Approval
                        </p>
                        <h3 className="card-title">
                          {this.state.waiting_shipment.length}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-info card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">done_all</i>
                        </div>
                        <p className="card-category">Approved Shipments</p>
                        <h3 className="card-title">
                          {this.state.approved_shipment.length}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          {/*        Here you can write extra buttons/actions for the toolbar              */}
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="shipments_length"
                                        aria-controls="shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          aria-controls="shipments"
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="D-JAN-MAY-18 Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing={0}
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Vehicle No: activate to sort column ascending"
                                          style={{ width: "68px" }}
                                        >
                                          Vehicle No
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Party Name: activate to sort column ascending"
                                          style={{ width: "192px" }}
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "77px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Gross Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Gross Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Tare Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Tare Wt
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Net Wt: activate to sort column ascending"
                                          style={{ width: "67px" }}
                                        >
                                          Net Wt
                                        </th>
                                        {/* <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Rejected Bags: activate to sort column ascending"
                                          style={{ width: "76px" }}
                                        >
                                          Rejected Bags
                                        </th> */}
                                        <th
                                          className="sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Date Added: activate to sort column ascending"
                                          style={{ width: "62px" }}
                                        >
                                          Date Added
                                        </th>
                                        <th
                                          className="disabled-sorting text-right sorting"
                                          tabIndex={0}
                                          aria-controls="shipments"
                                          rowSpan={1}
                                          colSpan={1}
                                          aria-label="Actions: activate to sort column ascending"
                                          style={{ width: "57px" }}
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan={1} colSpan={1}>
                                          Vehicle No
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Party Name
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Type
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Gross Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Tare Wt
                                        </th>
                                        <th rowSpan={1} colSpan={1}>
                                          Net Wt
                                        </th>
                                        {/* <th rowSpan={1} colSpan={1}>
                                          Rejected Bags
                                        </th> */}
                                        <th rowSpan={1} colSpan={1}>
                                          Date Added
                                        </th>
                                        <th
                                          className="disabled-sorting text-right"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {this.state.shipments.map((data, id) => (
                                        <tr key={id} role="row" className="odd">
                                          <td className="sorting_1">
                                            {data.vehicleNumber}
                                          </td>
                                          <td>{data.partyName}</td>

                                          <td>{data.type}</td>
                                          <td>{data.gross_weight}kg</td>
                                          <td>{data.tare_weight ? `${data.tare_weight}kg` : `No Tare Weight`}</td>
                                           <td>{data.net_weight ? `${data.net_weight}kg` : `No Net Weight`}</td>
                                          {/* <td>{data.rejected_bags ?`${data.rejected_bags}Bags` : 'No Rejected Bags'}</td> */}
                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                          <td className="text-right inline-block">
                                            <Link to={{
                                              pathname:"/accountant_shipment_detail",
                                              state: {
                                              direct: 'shipment',
                                              shipment_id: data._id,
                                              
                                          }
                                              }}>
                                              View
                                            </Link>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 1 to 10 of 18 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="shipments"
                                          data-dt-idx={0}
                                          tabIndex={0}
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li className="paginate_button page-item active">
                                        <a
                                          href="#/"
                                          aria-controls="shipments"
                                          data-dt-idx={1}
                                          tabIndex={0}
                                          className="page-link"
                                        >
                                          1
                                        </a>
                                      </li>
                                      <li className="paginate_button page-item ">
                                        <a
                                          href="#/"
                                          aria-controls="shipments"
                                          data-dt-idx={2}
                                          tabIndex={0}
                                          className="page-link"
                                        >
                                          2
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next"
                                        id="shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="shipments"
                                          data-dt-idx={3}
                                          tabIndex={0}
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end content*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                © 2021 , made with <i className="material-icons">favorite</i> by
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Shipment;
