import { fetchRequest } from "../utils";

export const create_cooperative = (cooperative) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperatives`,
    method: "POST",
    data: cooperative,
  });

export const list_cooperatives = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperatives`,
  });

export const read_cooperative = ({ cooperativeId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperatives/` + cooperativeId,
  });

export const update_cooperative = (cooperative) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperatives/`,
    method: "PUT",
    data: cooperative,
  });

export const remove_cooperative = (cooperative) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperatives/`,
    method: "DELETE",
    data: cooperative,
  });

export const create_cooperative_live = (cooperative) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperatives`,
    method: "POST",
    data: cooperative,
  });

export const list_cooperative_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperatives`,
  });

export const list_OMS_cooperative_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperatives/OMS`,
  });

export const update_cooperative_live = (cooperatives, { cooperativeId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperatives/` +
      cooperativeId,
    method: "PUT",
    data: cooperatives,
  });

export const remove_cooperative_live = ({ cooperativeId }) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperatives/` +
      cooperativeId,
    method: "DELETE",
  });

export const create_deleted_cooperative_live = (cooperative) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_cooperative`,
    method: "POST",
    data: cooperative,
  });

export const list_deleted_cooperative_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_cooperative`,
  });
