export const signIn = async (user) => {
  const base = process.env.REACT_APP_LIVE_SERVER_PROD;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  const response = await fetch(base + "/auth/signin/", options);
  const data = await response.json();

  if (data.error) {
    const adminResponse = await fetch(base + "/api/admins/login", options);
    const adminData = await adminResponse.json();

    if (adminData.error) {
      throw new Error(adminData.error);
    } else return adminData;
  }

  return data;
};

/*
const signin = (user) => {
  return fetch('http://localhost:9001/auth/signin/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user)
  })
  .then((response) => {
    return response.json()
  }).catch((err) => console.log(err))
}
*/

export const signUp = async (user) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/organizations`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    const { data } = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const signout = () => {
  return fetch(`${process.env.REACT_APP_LIVE_SERVER_PROD}auth/signout/`, {
    method: "GET",
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
