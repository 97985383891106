import React, { Component } from "react";
import Moment from "react-moment";
import Loader from "react-loader-spinner";
import {CSVLink} from "react-csv";
import Nav from "./Nav";
import SideBar from "./SideBar";

import {
  remove_live,
  create_live,
  resetPasswordLive,
  list_extension_managers_live,
  list_qc_live,
  list_warehouse_live,
  list_accountants_live,
  list_weighbridge_live,
  list_ext_plus,
  list_auditor_live,
  list_live
} from "../../api/api-users";
import auth from "../../api/auth-helper";

class ExtensionManagers extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      deletemodal: false,
      resetmodal: false,
      alert_success: false,
      alert_danger: false,
      id: "",
      liveId: "",
      first_name: "",
      last_name: "",
      email: "",
      role: "",
      rolemodal: false,
      extension_managers: [],
      accountant_managers: [],
      warehouse_managers:[],
      weighbridge_managers: [],
      ext_plus_managers: [],
      qc_managers: [],
      auditors:[],
      loading: false,
      success_notification: false,
      error_notification: false,
      mssg: "",
      csv_data: [],
      csv_header:[]
    };
    this.handleExtPlusList();
    this.handleExtensionList();
    this.handleAccountantList();
    this.handleWarehouseList();
    this.handleWeighbridgeList();
    this.handleQCList();
    this.handleAuditorList();
    this.handleCSV()
  }

  componentDidMount() {
    document.title = "Managers";
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: ""
    }));
  };

  handleExtensionModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "extension_manager"
    }));
  };

  handleAuditorModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "auditor"
    }));
  };

   handleExtPlusModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "ext_plus_manager"
    }));
  };

  handleQCModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "qc_manager"
    }));
  };


  handleAccountantModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "accountant"
    }));
  };

  handleWarehouseModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "warehouse_manager"
    }));
  };

  handleWeighbridgeModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
      role: "weighbridge_manager"
    }));
  };


  handleRoleModal = () => {
    this.setState((prevState) => ({
      rolemodal: !prevState.rolemodal,
    }));
  };

  handleCloseResetModal = () => {
    this.setState({
      resetmodal: false,
      id: "",
      liveId: "",
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
      liveId: "",
    });
  };

  handleCSV = async () => {
    await list_live().then(async (data)=> {
      if(!data || data.message) {
        return
      }
      else if (data.length !== 0){
        console.log(data)
        const headers = [
          {label:"First_Name", key: "first_name"},
          {label:"Last_Name", key: "last_name"},
          {label:"Email_Adress", key: "email"},
          {label:"Role", key: "role"}
        ];

        const all_data = data.users.workers;
        let csvdata = []

        await all_data.map(async (data)=>{
          let csvData ={
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            role: data.role
          };
          csvdata.push(csvData)
          console.log(csvdata)
          this.setState({
            csv_header: headers,
            csv_data: csvdata,
          });
        })
      }else{
        return
      }
    })
  }

  handleExtensionList = () => {
    if (navigator.onLine) {
      list_extension_managers_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ extension_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleAuditorList = () => {
    if (navigator.onLine) {
      list_auditor_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ auditors: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

   handleExtPlusList = () => {
    if (navigator.onLine) {
      list_ext_plus().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            ext_plus_managers:[],
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ ext_plus_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };


  handleWeighbridgeList = () => {
    if (navigator.onLine) {
      list_weighbridge_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ weighbridge_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleQCList = () => {
    if (navigator.onLine) {
      list_qc_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ qc_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleAccountantList = () => {
    if (navigator.onLine) {
      list_accountants_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ accountant_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleWarehouseList = () => {
    if (navigator.onLine) {
      list_warehouse_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else {
          this.setState({ warehouse_managers: data });
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  generatePassword = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  editForm = () => {
    // const pass = this.generatePassword();
    const jwt = auth.isAuthenticated();

    const user = {
      id: this.state.id,
      // email: email,
      // password: pass,
    };
    resetPasswordLive(user, {
      t: jwt.token,
    }).then((data) => {
      if (data.error) {
        this.setState({ alert_danger: true, alert_success: false });
        setTimeout(() => this.setState({ alert_danger: false }), 3000);
      } else {
        this.handleExtensionList();
        this.handleAccountantList();
        this.handleWarehouseList();
        this.handleQCList();
        this.handleExtPlusList();
        this.handleAuditorList()
        this.setState({
          alert_success: true,
        });
        setTimeout(() => this.setState({ alert_success: false }), 3000);
      }
    });
    this.handleCloseResetModal();
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    const pass = this.generatePassword();
    if (navigator.onLine) {
      if (this.state.first_name !== "" && this.state.last_name !== "") {
        const user = {
          deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
          createdBy:
            auth.isAuthenticated().user.first_name +
            " " +
            auth.isAuthenticated().user.last_name,
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          role: this.state.role,
          password: pass,
        };
        create_live(user, {
          t: jwt.token,
        }).then((data) => {
          console.log(data);
          if (data.error) {
            this.handleExtensionList();
            this.setState({
              loading: false,
              error_notification: true,
              mssg: data.error,
            });
            setTimeout(
              () => this.setState({ error_notification: false }),
              3000
            );
            this.handleModal();
          } else {
            this.handleExtensionList();
            this.handleWarehouseList();
            this.handleAccountantList();
            this.handleQCList();
            this.handleExtPlusList();
            this.handleAuditorList();
            this.setState({
              loading: false,
              success_notification: true,
              mssg: "Manager created successfully",
            });
            setTimeout(
              () => this.setState({ success_notification: false }),
              3000
            );
            this.handleModal();
          }
        });
      }
    }
  };

  deleteData = () => {
    this.setState({loading: true})
    if (navigator.onLine) {
      remove_live({
        userId: this.state.id,
      }).then((data) => {
        if (data.error) {
          this.setState({
            loading: false,
            error_notification: true,
            mssg: "Could not delete extension manager",
          });
          setTimeout(() => this.setState({ error_notification: false }), 3000);
        } else {
          this.handleExtensionList();
          this.handleAccountantList();
          this.handleWarehouseList();
          this.handleExtPlusList();
          this.handleQCList();
          this.handleAuditorList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Extension Manager deleted successfully",
          });
          setTimeout(() => this.setState({ success_notification: false }), 3000);
          this.handleCloseDeleteModal();
        }
      });
    }

    this.setState({loading: false})
  };

  checkForm = () => {
    if (!this.state.email) {
      this.setState({
        error: "Email field is required",
      });
    }

    if (!this.state.first_name) {
      this.setState({
        error: "First Name field is required",
      });
    }

    if (!this.state.last_name) {
      this.setState({
        error: "Last Name field is required",
      });
    }

    if (this.state.email && this.state.first_name && this.state.last_name) {
      this.submitForm();
    }

    if (!this.state.email && !this.state.first_name && !this.state.last_name) {
      this.setState({
        error: "All fields are required",
      });
    }
  };

  render() {
    const {loading} = this.state
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Managers.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                  </div>
                {!this.state.alert_success ? (
                  ""
                ) : (
                  <div className="alert alert-success">
                    <button
                      onClick={() => this.setState({ alert_success: false })}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>Successful, New Password has been sent</span>
                  </div>
                )}

                {!this.state.alert_danger ? (
                  ""
                ) : (
                  <div className="alert alert-danger">
                    <button
                      onClick={() => this.setState({ alert_danger: false })}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>Error, New Password Creation Failed</span>
                  </div>
                )}

                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Extension Managers</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleExtensionModal}
                          >
                            Create Extension Manager
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.extension_managers.map((data) => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                          onClick={() => {
                                            this.setState({
                                              resetmodal: true,
                                              id: data._id,
                                              liveId: data._id,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                            });
                                          }}
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-warning"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Reset Password"
                                        >
                                          <i className="material-icons">
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <button className="btn btn-link btn-just-icon btn-danger">
                                          <i
                                            className="material-icons"
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id,
                                                liveId: data._id,
                                              })
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Manager"
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Extension Plus Managers</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleExtPlusModal}
                          >
                            Create Extension Plus Manager
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.ext_plus_managers.map((data) => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                          onClick={() => {
                                            this.setState({
                                              resetmodal: true,
                                              id: data._id,
                                              liveId: data._id,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                            });
                                          }}
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-warning"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Reset Password"
                                        >
                                          <i className="material-icons">
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <button className="btn btn-link btn-just-icon btn-danger">
                                          <i
                                            className="material-icons"
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id,
                                                liveId: data._id,
                                              })
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Manager"
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>assignment</i>
                        </div>
                        <h4 className='card-title'>WeighBridge Managers</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                          <button
                            className='btn btn-primary btn-round'
                            onClick={this.handleWeighbridgeModal}
                          >
                            Create WeighBridge Manager
                          </button>
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='datatables'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='text-right'>Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.weighbridge_managers.map(data => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <div>
                                        <button
                                          onClick={() =>{
                                            this.setState({
                                              resetmodal: true
                                            })
                                            this.editForm(
                                              data._id,
                                              data.email,
                                              data.first_name,
                                              data.last_name
                                            )
                                          }
                                          }
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-warning'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Reset Password'
                                        >
                                          <i className='material-icons'>
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <input
                                          type='hidden'
                                          name='delete_manager'
                                        />
                                        <button
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-danger'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Delete Manager'
                                        >
                                          <i
                                            className='material-icons'
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id
                                              })
                                            }
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Auditor</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleAuditorModal}
                          >
                            Create Auditor
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="datatables"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="disabled-sorting text-right">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.auditors.map((data) => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className="text-right inline-block">
                                      <div>
                                        <button
                                          onClick={() => {
                                            this.setState({
                                              resetmodal: true,
                                              id: data._id,
                                              liveId: data._id,
                                              first_name: data.first_name,
                                              last_name: data.last_name,
                                            });
                                          }}
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-warning"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Reset Password"
                                        >
                                          <i className="material-icons">
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <button className="btn btn-link btn-just-icon btn-danger">
                                          <i
                                            className="material-icons"
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id,
                                                liveId: data._id,
                                              })
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Manager"
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>assignment</i>
                        </div>
                        <h4 className='card-title'>Quality Control Managers</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                          <button
                            className='btn btn-primary btn-round'
                            onClick={this.handleQCModal}
                          >
                            Create Quality Control Manager
                          </button>
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='datatables'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='text-right'>Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.qc_managers.map(data => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <div>
                                        <button
                                          onClick={() =>
                                          
                                            this.editForm(
                                              data._id,
                                              data.email,
                                              data.first_name,
                                              data.last_name
                                            )
                                            
                                          }
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-warning'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Reset Password'
                                        >
                                          <i className='material-icons'>
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <input
                                          type='hidden'
                                          name='delete_manager'
                                        />
                                        <button
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-danger'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Delete Manager'
                                        >
                                          <i
                                            className='material-icons'
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id
                                              })
                                            }
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>assignment</i>
                        </div>
                        <h4 className='card-title'>WareHouse Managers</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                          <button
                            className='btn btn-primary btn-round'
                            onClick={this.handleWarehouseModal}
                          >
                            Create WareHouse Manager
                          </button>
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='datatables'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='text-right'>Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.warehouse_managers.map((data, id) => (
                                  <tr key={id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <div>
                                        <button
                                          onClick={() =>
                                            this.editForm(
                                              data._id,
                                              data.email,
                                              data.first_name,
                                              data.last_name
                                            )
                                          }
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-warning'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Reset Password'
                                        >
                                          <i className='material-icons'>
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <input
                                          type='hidden'
                                          name='delete_manager'
                                        />
                                        <button
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-danger'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Delete Manager'
                                        >
                                          <i
                                            className='material-icons'
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id
                                              })
                                            }
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>assignment</i>
                        </div>
                        <h4 className='card-title'>Accounting Managers</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar'>
                          <button
                            className='btn btn-primary btn-round'
                            onClick={this.handleAccountantModal}
                          >
                            Create Accounting Manager
                          </button>
                        </div>
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              id='datatables'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Date Added</th>
                                  <th className='text-right'>Actions</th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.accountant_managers.map(data => (
                                  <tr key={data._id}>
                                    <td>{`${data.first_name} ${data.last_name}`}</td>
                                    <td>{data.email}</td>
                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <div>
                                        <button
                                          onClick={() =>
                                          
                                            this.editForm(
                                              data._id,
                                              data.email,
                                              data.first_name,
                                              data.last_name
                                            )
                                            
                                          }
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-warning'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Reset Password'
                                        >
                                          <i className='material-icons'>
                                            cached
                                          </i>
                                        </button>
                                      </div>
                                      <div>
                                        <input
                                          type='hidden'
                                          name='delete_manager'
                                        />
                                        <button
                                          type='submit'
                                          className='btn btn-link btn-just-icon btn-danger'
                                          data-toggle='tooltip'
                                          data-placement='top'
                                          title='Delete Manager'
                                        >
                                          <i
                                            className='material-icons'
                                            onClick={() =>
                                              this.setState({
                                                deletemodal: true,
                                                id: data._id
                                              })
                                            }
                                          >
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                

                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Delete Manager?
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            {!this.state.loading? (
                              <div className="card ">
                                <div className="card-body ">
                                  <div>
                                    Are you sure you want to delete this Manager?
                                  </div>

                                  <button
                                    type="submit"
                                    onClick={() => this.deleteData()}
                                    className="btn btn-fill btn-success"
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="submit"
                                    onClick={this.handleCloseDeleteModal}
                                    className="btn btn-fill btn-danger"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <Loader
                                style={{ padding: "11vh", textAlign: "center" }}
                                type="Oval"
                                color="green"
                                height={50}
                                width={50}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete
                                  extension manager.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.resetmodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Reset Extension Manager's Password?
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to reset their Password
                                </div>

                                <button
                                  type="submit"
                                  onClick={() => this.editForm()}
                                  className="btn btn-fill btn-success"
                                >
                                  Reset
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleCloseResetModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to reset
                                  Password
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Create New Manager</h4>
                          <button
                            type="button"
                            className="close"
                            onClick={this.handleModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <h4 style={{ color: "red", alignSelf: "center" }}>
                          {this.state.error}
                        </h4>
                        <div>
                          {!this.state.loading ? (
                            <div className="modal-body">
                              <span className="bmd-form-group">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="material-icons">email</i>
                                    </span>
                                  </div>
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                        error: "",
                                      })
                                    }
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email..."
                                  />
                                </div>
                              </span>
                              <span className="bmd-form-group">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="material-icons">face</i>
                                    </span>
                                  </div>
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        first_name: event.target.value,
                                        error: "",
                                      })
                                    }
                                    name="first_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name..."
                                  />
                                </div>
                              </span>
                              <span className="bmd-form-group">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="material-icons">face</i>
                                    </span>
                                  </div>
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        last_name: event.target.value,
                                        error: "",
                                      })
                                    }
                                    name="last_name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name..."
                                  />
                                </div>
                              </span> 
                              <p className="card-description text-center">
                                An email will be sent to the Extension Manager
                                you are about to create with their default
                                password. Please inform them to change the
                                password immediately they login.
                              </p>
                            </div>
                          ) : (
                            <Loader
                              style={{ padding: "11vh", textAlign: "center" }}
                              type="Oval"
                              color="green"
                              height={50}
                              width={50}
                            />
                          )}
                          {!this.state.loading ? (
                            <div className="modal-footer justify-content-center">
                              <input
                                type="hidden"
                                name="create_manager"
                                value="1"
                              />
                              <button
                                type="submit"
                                className="btn btn-round"
                                onClick={this.checkForm}
                              >
                                Create
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ExtensionManagers;
