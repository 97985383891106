import auth from './api/auth-helper';
import { useState } from 'react';

export const fetchRequest = async ({ url, data, method = 'GET' }) => {
  const jwt = auth.isAuthenticated();
  console.log(jwt);
  console.log(jwt.token);
  const fetchObject = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + jwt.token
    }
  };

  if (method === 'POST') {
    const { organization_id = '' } = localStorage.getItem('user');
    if (organization_id) data.organization_id = organization_id;
  }

  if (data) fetchObject.body = JSON.stringify(data);

  try {
    const response = await fetch(url, fetchObject);
    const results = await response.json();
    console.log(results);
    return results;
  } catch (err) {
    console.error(`Request failed: ${err}`);
  }
};

export const capitalize = word =>
  typeof word === 'string' && !!word
    ? word[0].toUpperCase() + word.slice(1).toLowerCase()
    : word;

window.capitalize = capitalize;

export const useFormState = fields => {
  const initialState = fields.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {});

  // initialize state to object containing all fields as empty fields
  const [state, setState] = useState(initialState);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ ...state, [name]: value });
  };

  const clearForm = () => {
    setState(initialState);
  };

  return [state, handleChange, clearForm];
};
