import { fetchRequest } from "../utils";

export const create_warehouse = (warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse/`,
    method: "POST",
    data: warehouse,
  });

export const list_warehouse = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse/` });

export const read_warehouse = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse/` +
      params.warehouseId,
  });

export const update_warehouse = (warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse/`,
    method: "PUT",
    data: warehouse,
  });

export const remove_warehouse = (warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/warehouse/`,
    method: "DELETE",
    data: warehouse,
  });

export const create_warehouse_live = (warehouse) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse/`,
    method: "POST",
    data: warehouse,
  });

export const list_warehouse_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse/`,
  });

export const remove_warehouse_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/warehouse/` +
      params.warehouseId,
    method: "DELETE",
  });
