import React, { Component } from "react";
import Pdf from "react-to-pdf";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import Nav from "./Nav";
import SideBar from "./SideBar";

import {
  read_cooperative,
  list_cooperative_live,
  create_cooperative_live,
  remove_cooperative_live,
  update_cooperative_live,
} from "../../api/api-cooperative";
import { remove_all_non_season_cooperative } from "../../api/api-non_season_cooperative";
import { listseasons_live } from "../../api/api-seasons";
import { remove_all_season_cooperative } from "../../api/api-season_cooperative";
import UserContext from "../UserContext";
import NaijaStates from "naija-state-local-government";

class Cooperative extends Component {
  state = {
    currentPage: 1,
    search: "",
    cooperatives: [],
    modal: false,
    editmodal: false,
    deletemodal: false,
    update_the_season_cooperative: false,
    Id: "",
    id: "",
    name: "",
    address: "",
    LGA: "",
    state: "",
    error: "",
    add_to_table: true,
    current_season_id: "",
    seasons: [],
    type: "",
    start_month: "",
    end_month: "",
    year: "",
    seasonLiveId: "",
    seasonId: "",
    success_notification: false,
    error_notification: false,
    update_notification: false,
    mssg: "",
    modals: {
      LGA: false,
    },
  };

  ref = React.createRef();

  componentDidMount = () => {
    this.handleGetCurrentSeason();

    this.handleList();
    this.handleListSeason();
    document.title = "Cooperative";
  };

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  handleLGAModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasonLiveId: data[0].liveId,
            seasonId: data[0]._id,
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
          });
        }
      }
    });
  };

  handleDistrictModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleGetCurrentSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `Something is wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ current_season_id: data[0] });
      }
    });
  };

  handleList = () => {
    list_cooperative_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
          cooperative: [],
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else if (data.error) {
        this.setState({
          mssg: data.error,
          error_notification: true,
          cooperatives: [],
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      } else {
        this.setState({ cooperatives: data });
      }
    });
  };

  updateForm = () => {
    console.log(this.state.liveId);
    const cooperative = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      Id: "1",
      name: this.state.name,
      address: this.state.address,
      LGA: this.state.LGA,
      state: this.state.state,
    };

    update_cooperative_live(cooperative, {
      cooperativeId: this.state.liveId,
    }).then((data) => {
      if (data) {
        this.handleList();
        this.handleEditModal();
        this.setState({
          success_notification: "true",
          mssg: "Cooperative Updated Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      } else if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not Update Cooperative",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };

  handleGetDetails = (Id) => {
    read_cooperative({ cooperativeId: Id }).then((data) => {
      if (data) {
        this.setState({
          dataAvailable: true,
          Id: "1",
          name: data.name,
          address: data.address,
          LGA: data.LGA,
          state: data.state,
        });
      }
    });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
      error: "",
    });
  };

  handleCommunity = (event) => {
    this.setState({
      address: event.target.value,
      error: "",
    });
  };

  handleDistrict = (event) => {
    this.setState({
      LGA: event.target.value,
      error: "",
    });
  };

  handleZone = (event) => {
    this.setState({
      state: event.target.value,
      error: "",
    });
  };

  handleTick = () => {
    this.setState({
      add_to_table: true,
    });
  };

  deleteSeason = () => {
    remove_cooperative_live({
      cooperativeId: this.state.liveId,
    }).then((data) => {
      if (data) {
        this.handleList();
        this.setState({
          mssg: "Cooperative Delete Successfully",
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not Delete Cooperative",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });

    this.handleDeleteModal();
  };

  handleDeleteNonCooperative = () => {
    remove_all_non_season_cooperative({
      cooperative_id: this.state.Id,
    });
  };

  handleDeleteCooperative = () => {
    remove_all_season_cooperative({
      cooperative_id: this.state.Id,
    }).then((data) => {
      if (!data) {
        console.log(data.error);
      }
    });
  };

  checkForm = () => {
    if (!this.state.name) {
      this.setState({
        error: "All fields are required",
      });
    }

    if (!this.state.address) {
      this.setState({
        error: "All fields are required",
      });
    }

    if (!this.state.LGA) {
      this.setState({
        error: "All fields are required",
      });
    }

    if (!this.state.state) {
      this.setState({
        error: "All fields are required",
      });
    }

    if (
      this.state.name &&
      this.state.address &&
      this.state.LGA &&
      this.state.state
    ) {
      this.submitForm();
    }
  };

  submitForm = () => {
    const {
      user: { first_name, last_name },
    } = this.context;

    const {
      name,
      LGA,
      state,
      address,
      add_to_table,
      current_season_id,
    } = this.state;

    const cooperative = {
      name,
      LGA,
      address,
      Id: "1",
      seasonId: current_season_id._id,
      createdBy: `${first_name} ${last_name}`,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      state,
    };

    if (add_to_table) cooperative.seasonId = current_season_id._id;
    console.log(cooperative.seasonId);

    create_cooperative_live(cooperative).then((data) => {
      if (data) {
        this.handleList();
        this.setState({
          success_notification: "true",
          mssg: "Cooperative Created Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not Create Cooperative",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });

    this.handleModal();
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleGetDeleteSeasonId = (id, liveId) => {
    this.setState({
      Id: id,
      liveId: id,
    });
    this.handleDeleteModal();
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      deletemodal: !prevState.deletemodal,
    }));
  };

  handleGetEditDetails = (id, name, address, state, LGA, liveId) => {
    console.log(liveId);
    this.setState({
      id: id,
      name: name,
      address: address,
      state: state,
      LGA: LGA,
      liveId: id,
    });
    this.handleEditModal();
  };

  handleEditModal = () => {
    this.setState((prevState) => ({
      editmodal: !prevState.editmodal,
    }));
  };

  render() {
    const { search } = this.state;

    const cooperatives = this.state.cooperatives.filter((cooperative) =>
      cooperative.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.error_notification &&
                !this.state.success_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={this.handleCloseError}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span> {this.state.mssg} </span>
                  </div>
                ) : !this.state.error_notification &&
                  this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={this.handleCloseSuccess}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span> {this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                          - Cooperatives
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            data-toggle="modal"
                            data-target="#addCooperativeModal"
                            onClick={this.handleModal}
                          >
                            Add Cooperative
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="cooperatives_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div
                                id="farmers_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="farmers"
                                      onChange={(event) =>
                                        this.setState({
                                          search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                ref={this.ref}
                                title="Cooperatives"
                                id="cooperatives"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="cooperatives_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Name: activate to sort column ascending"
                                      style={{ width: "52px" }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                      style={{ width: "149px" }}
                                    >
                                      Address
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="District: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="District: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      State
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Added: activate to sort column ascending"
                                      style={{ width: "171px" }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "66px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Name
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Address
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      LGA
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      State
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Date Added
                                    </th>
                                    <th
                                      className="text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {cooperatives.map((data) => (
                                    <tr key={data.created}>
                                      <td>{data.name}</td>
                                      <td>{data.address}</td>
                                      <td>{data.LGA}</td>
                                      <td>{data.state}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <button
                                          className="btn btn-link btn-just-icon btn-primary"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit Cooperative"
                                          onClick={() =>
                                            this.handleGetEditDetails(
                                              data._id,
                                              data.name,
                                              data.address,
                                              data.state,
                                              data.LGA,
                                              data.liveId
                                            )
                                          }
                                        >
                                          <i className="material-icons">
                                            create
                                          </i>
                                        </button>
                                        <Link
                                          className="btn btn-link btn-info edit"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="View Cooperative Details"
                                          to={{
                                            pathname: "/ext_plus_cooperative_details",
                                            state: {
                                              cooperativeId: data._id,
                                              cooperativeLiveId: data._id,
                                              name: data.name,
                                              type: this.state.type,
                                              start_month: this.state
                                                .start_month,
                                              end_month: this.state.end_month,
                                              year: this.state.year,
                                            },
                                          }}
                                        >
                                          View
                                        </Link>
                                        <div>
                                          <button
                                            onClick={() =>
                                              this.handleGetDeleteSeasonId(
                                                data._id,
                                                data._id
                                              )
                                            }
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Cooperative"
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className="dataTables_info"
                                id="cooperatives_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 1 to 1 of 1 entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="cooperatives_paginate"
                              >
                                {/*
																<Pagination
																	total={total}
																	limit={limit}
																	pageCount={pageCount}
																	currentPage={currentPage}
																>
																	{({
																		pages,
																		currentPage,
																		hasNextPage,
																		hasPreviousPage,
																		previousPage,
																		nextPage,
																		totalPages,
																		getPageItemProps,
																	}) => (
																		<div>
																			<button
																				{...getPageItemProps({
																					pageValue: 1,
																					onPageChange: this.handlePageChange,
																				})}
																			>
																				first
																			</button>

																			{hasPreviousPage && (
																				<button
																					{...getPageItemProps({
																						pageValue: previousPage,
																						onPageChange: this
																							.handlePageChange,
																					})}
																				>
																					{'<'}
																				</button>
																			)}

																			{pages.map(page => {
																				let activePage = null;
																				if (currentPage === page) {
																					activePage = {
																						backgroundColor: '#fdce09',
																					};
																				}
																				return (
																					<button
																						{...getPageItemProps({
																							pageValue: page,
																							key: page,
																							style: activePage,
																							onPageChange: this
																								.handlePageChange,
																						})}
																					>
																						{page}
																					</button>
																				);
																			})}

																			{hasNextPage && (
																				<button
																					{...getPageItemProps({
																						pageValue: nextPage,
																						onPageChange: this
																							.handlePageChange,
																					})}
																				>
																					{'>'}
																				</button>
																			)}

																			<button
																				{...getPageItemProps({
																					pageValue: totalPages,
																					onPageChange: this.handlePageChange,
																				})}
																			>
																				last
																			</button>
																		</div>
																	)}
																</Pagination>
																*/}
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="cooperatives_previous"
                                  >
                                    <a
                                      aria-controls="cooperatives"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      aria-controls="cooperatives"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="cooperatives_next"
                                  >
                                    <a
                                      aria-controls="cooperatives"
                                      data-dt-idx="2"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!this.state.modal ? (
                    ""
                  ) : (
                    <div
                      className="modal fade show"
                      id="addCooperativeModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      style={{ display: "block", paddingRight: "15px" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Create New Cooperative
                            </h4>

                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <h4 style={{ color: "red", alignSelf: "center" }}>
                            {this.state.error}
                          </h4>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputName"
                                      className="bmd-label-floating"
                                    >
                                      Enter Name of Cooperative
                                    </label>

                                    <input
                                      required={this.state.name ? false : true}
                                      onChange={this.handleName}
                                      name="name"
                                      type="text"
                                      className="form-control"
                                      id="inputName"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputCommunity"
                                      className="bmd-label-floating"
                                    >
                                      Enter Address of Cooperative
                                    </label>
                                    <input
                                      name="address"
                                      required={
                                        this.state.address ? false : true
                                      }
                                      onChange={this.handleCommunity}
                                      type="text"
                                      className="form-control"
                                      id="inputCommunity"
                                    />
                                  </div>
                                  <div
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("state")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.state
                                                ? "Choose State"
                                                : this.state.state}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="states"
                                        className={
                                          !this.state.modals.state
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="states"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.state ||
                                                    "Choose State"}
                                                </span>
                                              </a>
                                            </li>
                                            {NaijaStates.states().map(
                                              (state, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.state === ""
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleLGAModal(
                                                        "state"
                                                      );
                                                      this.setState({
                                                        state: state,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {state}
                                                    </span>
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown bootstrap-select"
                                          : "dropdown bootstrap-select show"
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="dropdown-toggle btn  btn-success"
                                        data-toggle="dropdown"
                                        aria-expanded="true"
                                        onClick={() =>
                                          this.handleLGAModal("LGA")
                                        }
                                      >
                                        <div className="filter-option">
                                          <div className="filter-option-inner">
                                            <div className="filter-option-inner-inner">
                                              {!this.state.LGA
                                                ? "Choose LGA"
                                                : this.state.LGA}
                                            </div>
                                          </div>{" "}
                                        </div>
                                        <div className="ripple-container" />
                                      </button>
                                      <div
                                        id="LGAs"
                                        className={
                                          !this.state.modals.LGA
                                            ? "dropdown-menu"
                                            : "dropdown-menu show"
                                        }
                                        role="combobox"
                                        aria-control="LGAs"
                                        aria-expanded="true"
                                        x-placement="bottom-start"
                                        style={{
                                          maxHeight: "182px",
                                          overflow: "hidden",
                                          minWidth: "302px",
                                          position: "absolute",
                                          top: "46px",
                                          left: "1px",
                                          willChange: "top, left",
                                        }}
                                      >
                                        <div
                                          className="inner show"
                                          role="listbox"
                                          aria-expanded="true"
                                          tabIndex="-1"
                                          style={{
                                            maxHeight: "172px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <ul className="dropdown-menu inner show">
                                            <li className="disabled">
                                              <a
                                                role="option"
                                                className="dropdown-item disabled"
                                                aria-disabled="true"
                                                tabIndex="-1"
                                                aria-selected="false"
                                              >
                                                <span className=" bs-ok-default check-mark" />
                                                <span className="text">
                                                  {this.state.LGA ||
                                                    "Choose LGA"}
                                                </span>
                                              </a>
                                            </li>
                                            {!this.state.state
                                              ? " "
                                              : NaijaStates.lgas(
                                                  this.state.state
                                                ).lgas.map((LGA, id) => (
                                                  <li key={id}>
                                                    <a
                                                      role="option"
                                                      className={
                                                        this.state.LGA === ""
                                                          ? "dropdown-item"
                                                          : "dropdown-item selected active"
                                                      }
                                                      aria-disabled="false"
                                                      tabIndex="0"
                                                      aria-selected="false"
                                                      onClick={() => {
                                                        this.handleLGAModal(
                                                          "LGA"
                                                        );
                                                        this.setState({
                                                          LGA: LGA,
                                                        });
                                                      }}
                                                    >
                                                      <span className=" bs-ok-default check-mark" />
                                                      <span className="text">
                                                        {LGA}
                                                      </span>
                                                    </a>
                                                  </li>
                                                ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        name="add_to_season"
                                        defaultChecked
                                        onChange={(event) =>
                                          this.setState({
                                            add_to_table: event.target.checked,
                                          })
                                        }
                                        className="form-check-input"
                                        type="checkbox"
                                        value="0"
                                        id="add_to_season"
                                      />
                                      Add to Current Season
                                      <span className="form-check-sign">
                                        <span className="check" />
                                      </span>
                                    </label>
                                  </div>

                                  <button
                                    type="submit"
                                    onClick={this.checkForm}
                                    className="btn btn-fill btn-primary"
                                  >
                                    Create
                                  </button>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!this.state.deletemodal ? (
                    ""
                  ) : (
                    <div
                      className="modal fade show"
                      id="addCooperativeModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      style={{
                        display: "block",
                        paddingRight: "5px",
                        paddingTop: "100px",
                      }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Delete this Cooperative?
                            </h4>
                            <button
                              onClick={this.handleDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this
                                  cooperative
                                </div>

                                <button
                                  type="submit"
                                  onClick={this.deleteSeason}
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!this.state.editmodal ? (
                    ""
                  ) : (
                    <div
                      className="modal fade show"
                      id="editCooperativeModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      style={{ display: "block", paddingRight: "15px" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Edit Cooperative</h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="false"
                              onClick={this.handleEditModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>

                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editName"
                                      className="bmd-label-floating"
                                    >
                                      Enter Name of Cooperative
                                    </label>
                                    <input
                                      required
                                      name="name"
                                      type="text"
                                      className="form-control"
                                      id="editName"
                                      onChange={this.handleName}
                                      value={this.state.name}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="editCommunity"
                                      className="bmd-label-floating"
                                    >
                                      Enter Address of Cooperative
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleCommunity}
                                      value={this.state.address}
                                    />
                                  </div>
                                  <div
                                    className={
                                      !this.state.modals.state
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() =>
                                        this.handleLGAModal("state")
                                      }
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.state
                                              ? "Choose State"
                                              : this.state.state}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="states"
                                      className={
                                        !this.state.modals.state
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="states"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.state ||
                                                  "Choose State"}
                                              </span>
                                            </a>
                                          </li>
                                          {NaijaStates.states().map(
                                            (state, id) => (
                                              <li key={id}>
                                                <a
                                                  role="option"
                                                  className={
                                                    this.state.state === ""
                                                      ? "dropdown-item"
                                                      : "dropdown-item selected active"
                                                  }
                                                  aria-disabled="false"
                                                  tabIndex="0"
                                                  aria-selected="false"
                                                  onClick={() => {
                                                    this.handleLGAModal(
                                                      "state"
                                                    );
                                                    this.setState({
                                                      state: state,
                                                    });
                                                  }}
                                                >
                                                  <span className=" bs-ok-default check-mark" />
                                                  <span className="text">
                                                    {state}
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      !this.state.modals.LGA
                                        ? "dropdown bootstrap-select"
                                        : "dropdown bootstrap-select show"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn  btn-success"
                                      data-toggle="dropdown"
                                      aria-expanded="true"
                                      onClick={() => this.handleLGAModal("LGA")}
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.LGA
                                              ? "Choose LGA"
                                              : this.state.LGA}
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="ripple-container" />
                                    </button>
                                    <div
                                      id="LGAs"
                                      className={
                                        !this.state.modals.LGA
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-control="LGAs"
                                      aria-expanded="true"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "182px",
                                        overflow: "hidden",
                                        minWidth: "302px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "172px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                {this.state.LGA || "Choose LGA"}
                                              </span>
                                            </a>
                                          </li>
                                          {!this.state.state
                                            ? " "
                                            : NaijaStates.lgas(
                                                this.state.state
                                              ).lgas.map((LGA, id) => (
                                                <li key={id}>
                                                  <a
                                                    role="option"
                                                    className={
                                                      this.state.LGA === ""
                                                        ? "dropdown-item"
                                                        : "dropdown-item selected active"
                                                    }
                                                    aria-disabled="false"
                                                    tabIndex="0"
                                                    aria-selected="false"
                                                    onClick={() => {
                                                      this.handleLGAModal(
                                                        "LGA"
                                                      );
                                                      this.setState({
                                                        LGA: LGA,
                                                      });
                                                    }}
                                                  >
                                                    <span className=" bs-ok-default check-mark" />
                                                    <span className="text">
                                                      {LGA}
                                                    </span>
                                                  </a>
                                                </li>
                                              ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <input
                                    id="edit_cooperative"
                                    type="hidden"
                                    name="edit_cooperative"
                                    value=""
                                  />
                                  <button
                                    onClick={this.updateForm}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <footer className="footer">
              <div className="container-fluid">
                <nav className="float-left">
                  <ul>
                    <li>
                      <a href="/">SupplyBase</a>
                    </li>
                  </ul>
                </nav>
                <div className="copyright float-right">
                  &copy;
                  <script>document.write(new Date().getFullYear())</script>,
                  made with <i className="material-icons">favorite</i> by {" "}
                  <a
                    href="http://www.crop2cash.com.ng/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Crop2Cash
                  </a>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

Cooperative.contextType = UserContext;

export default Cooperative;
