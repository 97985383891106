import { fetchRequest } from "../utils";

export const create = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/`,
    method: "POST",
    data: user,
  });

export const list = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/` });

export const list_extension_managers = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/extension_managers`,
  });

export const list_weighbridge = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/weighbridge_managers/`,
  });

  export const list_auditor = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/auditor/`,
  });

export const list_qc = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/qc_managers/`,
  });

export const list_ext_plus = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/ext_plus_managers/`,
  });

export const list_warehouse = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/warehouse_managers/`,
  });

export const list_accountants = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/accountants/`,
  });

export const read = (params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/` + params.userId,
  });

export const resetPassword = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/reset/`,
    method: "POST",
    data: user,
  });

export const update = (user) => {
  const { _id } = JSON.parse(localStorage.getItem("user"));

  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/${_id}`,
    method: "PUT",
    data: user,
  });
};

export const updateOrg = (user, id) => {
  console.log(id);
  return fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/organizations/` + id,
    method: "PATCH",
    data: user,
  });
};

export const remove = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/`,
    method: "DELETE",
    data: user,
  });

export const remove_user_deleted_live = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/delete`,
    method: "DELETE",
    data: user,
  });

//Live Server Api

export const create_live = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/`,
    method: "POST",
    data: user,
  });

export const list_live = () =>
  fetchRequest({ url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/` });

export const list_extension_managers_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/extension_managers`,
  });

export const list_weighbridge_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/weighbridge_managers/`,
  });

export const list_qc_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/qc_managers/`,
  });

export const list_auditor_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/auditor/`,
  });

export const list_warehouse_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/warehouse_managers/`,
  });

export const list_accountants_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/accountants/`,
  });

export const remove_live = (params) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/` + params.userId,
    method: "DELETE",
  });

export const changePassword = (data) =>
  fetchRequest({
    data,
    method: "POST",
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/changePassword`,
  });

export const resetPasswordLive = (user) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/users/reset`,
    method: "POST",
    data: user,
  });
