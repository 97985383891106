import React, { useState, useContext } from "react";
import Loader from "react-loader-spinner";

import UserContext from "../../UserContext";

import ChooseMonth from "./ChooseMonth";

import { capitalize } from "../../../utils";
import { create_season_live } from "../../../api/api-seasons";

const CreateFarmingSeasonForm = ({
  handleList,
  addNotification,
  toggleCreateSeasonModal,
}) => {
  const initialFormState = {
    type: "",
    year: "",
    end_month: "",
    start_month: "",
    pre_financed: "",
  };

  const initialModalsState = {
    end: false,
    year: false,
    type: false,
    start: false,
    delete: false,
    pre_financed: false,
  };

  const [error, setError] = useState("");
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const [modalsState, setModalsState] = useState(initialModalsState);

  const toggleModal = (name) => {
    let newModalsState;

    if (modalsState[name]) {
      newModalsState = initialModalsState;
    } else {
      newModalsState = { ...initialModalsState, [name]: true };
    }

    setModalsState(newModalsState);
  };

  const resetModals = () => {
    setModalsState(initialModalsState);
  };

  const updateFormState = ([name, value]) => {
    setFormState({ ...formState, [name]: value });
  };

  const validateForm = () => {
    const reducedFormValues = Object.values(formState).reduce(
      (acc, cur) => acc && cur
    );

    const removeSlug = (slug) => {
      return slug.includes("_")
        ? slug.split("_").map(capitalize).join(" ")
        : capitalize(slug);
    };

    if (reducedFormValues) {
      submitForm();
    } else {
      let formError = "";

      Object.entries(formState).forEach(([key, value]) => {
        if (!value) {
          if (formError) formError += "</br>";
          formError += `${removeSlug(key)} is required.`;
        }
      });

      setError(formError);
    }
  };

  const submitForm = async () => {
    setLoading(true);

    const { first_name, last_name, organization_id } = user;

    const season_live = {
      ...formState,
      organization_id,
      createdBy: `${first_name} ${last_name}`,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
    };

    try {
      const data = await create_season_live(season_live);

      if (data.message) {
        handleList();
        addNotification("success", "Season created successfully.");
        toggleCreateSeasonModal();
      } else throw new Error(`Couldn't create season.`);
    } catch (err) {
      console.error(`Couldn't create season: ${err}`);
      addNotification("error", `Couldn't create season.`);
    }

    setLoading(false);
  };

  const handleMonth = (type, month) => {
    toggleModal(type);
    updateFormState([type + "_month", month]);
  };

  const availableYears = () => {
    const thisYear = new Date().getFullYear();
    return [thisYear - 1, thisYear, thisYear + 1];
  };

  return (
    <div
      className="modal fade show"
      id="createSeasonModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      style={{ display: "block", paddingRight: "15px" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create New Farming Season</h4>
            <button
              type="button"
              className="close"
              onClick={toggleCreateSeasonModal}
            >
              <i className="material-icons">clear</i>
            </button>
          </div>
          <h4
            style={{
              color: "red",
              alignSelf: "center",
              margin: "15px 0 -25px",
            }}
            dangerouslySetInnerHTML={{ __html: error }}
          ></h4>
          <div>
            <div className="modal-body">
              {!loading ? (
                <div className="card ">
                  <div className="card-body ">
                    <div className="form-group">
                      <div className="dropdown bootstrap-select">
                        <button
                          type="button"
                          className="dropdown-toggle btn btn-round btn-info"
                          data-toggle="dropdown"
                          title="Choose Type"
                        >
                          <div
                            onClick={() => {
                              toggleModal("type");
                            }}
                            className="filter-option"
                          >
                            <div className="filter-option-inner">
                              <div className="filter-option-inner-inner">
                                {formState.type || "Choose Type"}
                              </div>
                            </div>
                          </div>
                          <div className="ripple-container" />
                        </button>
                        <div
                          className={`dropdown-menu${
                            modalsState.type ? " show" : ""
                          }`}
                          role="combobox"
                          x-placement="bottom-start"
                          style={{
                            maxHeight: "162px",
                            minWidth: "189px",
                            overflow: "hidden",
                            position: "absolute",
                            top: "46px",
                            left: "1px",
                            willChange: "top, left",
                          }}
                        >
                          <div
                            className="inner"
                            role="listbox"
                            aria-expanded="false"
                            tabIndex="-1"
                            style={{ overflowY: "auto" }}
                          >
                            <ul className="dropdown-menu inner show">
                              <li className="disabled selected active">
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item disabled selected "
                                  aria-disabled="true"
                                  tabIndex="-1"
                                  aria-selected="true"
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">Choose Type</span>
                                </a>
                              </li>
                              <li>
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item"
                                  aria-disabled="false"
                                  tabIndex="0"
                                  aria-selected="false"
                                  onClick={() => {
                                    updateFormState(["type", "Dry"]);
                                    resetModals();
                                  }}
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">Dry</span>
                                </a>
                              </li>
                              <li>
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item"
                                  aria-disabled="false"
                                  tabIndex="0"
                                  aria-selected="false"
                                  onClick={() => {
                                    updateFormState(["type", "Wet"]);
                                    resetModals();
                                  }}
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">Wet</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="dropdown bootstrap-select">
                        <button
                          type="button"
                          className="dropdown-toggle btn btn-round btn-info"
                          data-toggle="dropdown"
                          role="button"
                          title="Choose Pre-financed Status"
                        >
                          <div
                            onClick={() => {
                              toggleModal("pre_financed");
                            }}
                            className="filter-option"
                          >
                            <div className="filter-option-inner">
                              <div className="filter-option-inner-inner">
                                {formState.pre_financed ||
                                  "Choose Pre-financed Status"}
                              </div>
                            </div>
                          </div>
                          <div className="ripple-container" />
                        </button>
                        <div
                          className={`dropdown-menu${
                            modalsState.pre_financed ? " show" : ""
                          }`}
                          role="combobox"
                          x-placement="bottom-start"
                          style={{
                            maxHeight: "162px",
                            minWidth: "189px",
                            overflow: "hidden",
                            position: "absolute",
                            top: "46px",
                            left: "1px",
                            willChange: "top, left",
                          }}
                        >
                          <div
                            className="inner"
                            role="listbox"
                            aria-expanded="false"
                            tabIndex="-1"
                            style={{ overflowY: "auto" }}
                          >
                            <ul className="dropdown-menu inner show">
                              <li className="disabled selected active">
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item disabled selected "
                                  aria-disabled="true"
                                  tabIndex="-1"
                                  aria-selected="true"
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">
                                    Choose Pre-financed Status
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item"
                                  aria-disabled="false"
                                  tabIndex="0"
                                  aria-selected="false"
                                  onClick={() => {
                                    updateFormState(["pre_financed", "true"]);
                                    resetModals();
                                  }}
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">True</span>
                                </a>
                              </li>
                              <li>
                                <a href ="#/"
                                  role="option"
                                  className="dropdown-item"
                                  aria-disabled="false"
                                  tabIndex="0"
                                  aria-selected="false"
                                  onClick={() => {
                                    updateFormState(["pre_financed", "false"]);
                                    resetModals();
                                  }}
                                >
                                  <span className=" bs-ok-default check-mark" />
                                  <span className="text">False</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ChooseMonth
                      type="start"
                      formState={formState}
                      handleMonth={handleMonth}
                      toggleModal={toggleModal}
                      modalsState={modalsState}
                    />

                    <ChooseMonth
                      type="end"
                      formState={formState}
                      handleMonth={handleMonth}
                      toggleModal={toggleModal}
                      modalsState={modalsState}
                    />

                    <div className="form-group">
                      <div className="dropdown bootstrap-select">
                        <button
                          type="button"
                          className="dropdown-toggle bs-placeholder btn btn-round btn-info"
                          data-toggle="dropdown"
                          role="button"
                          title="Choose Year"
                          aria-expanded="false"
                        >
                          <div
                            onClick={() => {
                              toggleModal("year");
                            }}
                            className="filter-option"
                          >
                            <div className="filter-option-inner">
                              <div className="filter-option-inner-inner">
                                {formState.year || "Choose Year"}
                              </div>
                            </div>{" "}
                          </div>
                          <div className="ripple-container" />
                        </button>
                        <div
                          className={`dropdown-menu${
                            modalsState.year ? " show" : ""
                          }`}
                          role="combobox"
                          x-placement="bottom-start"
                          style={{
                            overflow: "hidden",
                            position: "absolute",
                            top: "46px",
                            left: "1px",
                            willChange: "top, left",
                          }}
                        >
                          <div
                            className="inner show"
                            role="listbox"
                            aria-expanded="false"
                            tabIndex="-1"
                            style={{ overflowY: "auto" }}
                          >
                            <ul className="dropdown-menu inner show">
                              {availableYears().map((year, id) => (
                                <li key={id}>
                                  <a href ="#/"
                                    role="option"
                                    className="dropdown-item"
                                    aria-disabled="false"
                                    tabIndex="0"
                                    aria-selected="false"
                                    onClick={() => {
                                      toggleModal("year");
                                      updateFormState(["year", year]);
                                    }}
                                  >
                                    <span className=" bs-ok-default check-mark" />
                                    <span className="text">{year}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader
                  style={{ padding: "11vh", textAlign: "center" }}
                  type="Oval"
                  color="green"
                  height={50}
                  width={50}
                />
              )}
            </div>

            <div className="modal-footer justify-content-center">
              <input type="hidden" name="create_season" value="1" />
              <button
                type="submit"
                onClick={validateForm}
                style={{ width: "35%" }}
                className="btn btn-round"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFarmingSeasonForm;
