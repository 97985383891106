import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from '../../api/auth-helper';

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      cooperative: false,
      farmer: false
    };
  }

  handleCooperative = () => {
    this.setState(prevState => ({
      cooperative: !prevState.cooperative
    }));
  };

  handleFarmer = () => {
    this.setState(prevState => ({
      farmer: !prevState.farmer
    }));
  };

  render() {
    return (
      <div
        className='sidebar'
        data-color='green'
        data-background-color='black'
        data-image='/static/assets/img/sidebar-6.jpg'
      >
        <div className='logo'>
          <a href='/warehouse/' className='simple-text logo-mini'>
            SB
          </a>

          <a href='/warehouse/' className='simple-text logo-normal'>
            Warehouse
          </a>
        </div>

        <div className='sidebar-wrapper'>
          <div className='user'>
            <div className='user-info'>
              <Link to='/warehouse' className='username'>
                <span>
                  {` ${auth.isAuthenticated().user.first_name}  ${
                    auth.isAuthenticated().user.last_name
                  } `}
                </span>
              </Link>
            </div>
          </div>
          <ul className='nav'>
            <li className='nav-item {{ shipments_page }}'>
              <Link className='nav-link' to='/warehouse_shipment'>
                <i className='material-icons'>airport_shuttle</i>
                <p> Shipment </p>
              </Link>
            </li>

            <li className='nav-item {{ coop_page }}'>
              <Link className='nav-link' to='/warehouses'>
                <i className='material-icons'>store</i>
                <p> Warehouses</p>
              </Link>
            </li>

            {/* <li
              className='nav-item {{ farmers_page }}'
              onClick={this.handleFarmer}
            >
              <Link className='nav-link' to='/warehouse_cooperatives'>
                <i className='material-icons'>store</i>
                <p> Cooperatives</p>
              </Link>
            </li> */}
            {/* <li
              className='nav-item {{ farmers_page }}'
              onClick={this.handleFarmer}
            >
              <Link className='nav-link' to='/warehouse_farmers'>
                <i className='material-icons'>store</i>
                <p> Farmers</p>
              </Link>
            </li> */}
            {/* <li
              className='nav-item {{ farmers_page }}'
              onClick={this.handleFarmer}
            >
              <Link className='nav-link' to='/open_market_suppliers'>
                <i className='material-icons'>store</i>
                <p> Open Market Suppliers</p>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default SideBar;
