import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import Pdf from "react-to-pdf";
import { listseasons_live } from "../../api/api-seasons";
import {
  list_shipment_live,
  remove_shipment_live,
} from "../../api/api-shipment";
import { list_warehouse_live } from "../../api/api-warehouse";
import { create_shipment_incoming_warehouse_live, read_shipment_incoming_warehouse_live } from "../../api/api-shipment_incoming_crop";
import {list_warehouse_shipment_live, create_warehouse_shipment_live} from "../../api/api-warehouse_shipment";
import {CSVLink} from "react-csv";
import Nav from "./Nav";
import SideBar from "./SideBar";
import auth from "../../api/auth-helper";

const ref = React.createRef();
class Shipments extends Component {
  constructor() {
    super();
    this.state = {
      shipments: [],
      shipment_id: "",
      shipment_live_id: "",
      delete_modal: false,
      seasons: [],
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      search: "",
      grn: "",
      warehouse_id:"",
      warehouses: [],
      shipment_incoming_crop:[],
      warehouse_shipment: [],
      warehouse: '',
      loan_per_acre: "",
      number_of_bags: '',
      total_weight: "",
      modal: false,
      modals: {
        seasons: false,
        warehouse: false
      },
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      csv_data: [],
      csv_header: []
    };
    
    this.handleListSeason();
    this.handleListWarehouse();
    this.handleWarehouseShipment();
    this.handleCSV()
  }

  componentDidMount() {
    document.title = "Shipments";
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  
  handleCSV = async () => {
    await list_shipment_live().then(async (data)=> {
      if(!data || data.message) {
        return
      }
      else if (data.length !== 0){
        const headers = [
          {label:"Vehicle Number", key: "vehicleNumber"},
          {label: "Weighbridge Number", key: "weighbridge_number"},
          {label:"Party Name", key: "partyName"},
          {label:"Type", key: "type"},
          {label:"Gross Weight", key: "gross_weight"},
          {label:"Net Weight", key: "net_weight"},
          {label:"Tare Weight", key: "tare_weight"},
          {label: "Crop", key: "crop"},
          {label: "Crop Price", key: "crop_price"},
          {label: "Status", key: "status"},
          {label: "Created By", key:"createdBy"},
          {label: "Date Created", key: "created"}
        ];

        const all_data = data;
        let csvdata = []

        await all_data.map(async (data)=>{
          let csvData ={
            vehicleNumber: data.vehicleNumber,
            weighbridge_number: data.weighbridge_number,
            partyName: data.partyName,
            type: data.type,
            gross_weight: data.gross_weight,
            net_weight: data.net_weight,
            tare_weight: data.tare_weight,
            crop: data.crop.name,
            crop_price: data.crop_price,
            status: data.status,
            createdBy: data.createdBy,
            created: data.created.substr(0, 9)
          };
          csvdata.push(csvData)
          console.log(csvdata)
          this.setState({
            csv_header: headers,
            csv_data: csvdata,
          });
        })
      }else{
        this.setState({shipments :[]})
      }
    })
  }

  handleListShipment = () => {
    let warehouse_shipment = this.state.warehouse_shipment
    list_shipment_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
         let shipments =[]
         console.log(warehouse_shipment)
         let warehouse_shipmentIDS = []
          warehouse_shipment.map(warehouse_shipment => {
              warehouse_shipmentIDS.push(warehouse_shipment.shipment_id._id)
          })
         
          data.map((data)=>{
           if(warehouse_shipmentIDS.includes(data._id) === false){
             if(shipments.includes(data)){
                   return
                 }else{
                   shipments.push(data)
                 }
           }        
          })   
          console.log(shipments)
          
        if (shipments.length !== 0){
          this.setState({
            shipments: shipments
          })
        }
        else {
          return;
        }
      }
    });
  };

  GetIncomingCrop = () => {
    read_shipment_incoming_warehouse_live({shipmentId: this.state.shipment_id}).then((data) =>{
      if(!data){
        return
      }
      else if(data){
        let shipment_incoming_crop = data.filter((incoming_crop => incoming_crop._id))
        console.log(shipment_incoming_crop)
        this.setState({
          shipment_incoming_crop: data})
          this.submitWarehouseShipment()
      }
    })
  }

  handleWarehouseShipment () {
    list_warehouse_shipment_live().then((data)=>{
      if(!data){
        return;
      }
      if (data) {
        this.setState({
          warehouse_shipment: data
        })
        this.handleListShipment();
      }
    })
  }

  submitWarehouseShipment (){
    const jwt = auth.isAuthenticated();

    const warehouse_shipment = {
      createdBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      shipment_id: this.state.shipment_id,
      shipment_incoming_crop: this.state.shipment_incoming_crop,
      status: 'Approved',
      crop: this.state.crop
    };
    create_warehouse_shipment_live(warehouse_shipment, {t: jwt.token})
    .then((data)=>{
      if(!data){
        return;
      }
      if(data){
        this.handleWarehouseShipment()
      }
    })
  }

  handleListWarehouse = () => {
    list_warehouse_live().then((data)=> {
      if(!data) {
        return;
      }
      if (data) {
        this.setState({warehouses: data})
      }
    })
  }

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: "",
            start_month: "",
            end_month: "",
            year: "",
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
            loan_per_acre: "",
          });
        }
      }
    });
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      delete_modal: !prevState.delete_modal,
    }));
  };

  handleDeleteShipment = () => {
    remove_shipment_live({
      shipmentId: this.state.shipment_id,
    }).then((data) => {
      if (!data) {
        return;
      } else {
        this.handleListShipment();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Shipment successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleDeleteModal();
      }
    });
  };

  checkForm = () =>{
    if (!this.state.grn) {
      this.setState({
        error: "GRN is required",
      });
    }

    if (!this.state.warehouse) {
      this.setState({
        error: "Warehouse is required",
      });
    }

    if (!this.state.number_of_bags) {
      this.setState({
        error: "Number of Bags is required",
      });
    }

    if (!this.state.total_weight) {
      this.setState({
        error: "Total Weight is required",
      });
    }

    if (this.state.number_of_bags && this.state.grn && this.state.warehouse_id && this.state.total_weight) {
      this.handleSubmit();
    }

    if (!this.state.number_of_bags && !this.state.grn && !this.state.warehouse_id && !this.state.total_weight) {
      this.setState({
        error: "All Fields are required",
      });
    }
  }

  handleSubmit(){
    const jwt= auth.isAuthenticated();
    const shipment_incoming_warehouse = {
      createdBy: auth.isAuthenticated().user.first_name + " " + auth.isAuthenticated().user.last_name,
      grn: this.state.grn,
      warehouse_id: this.state.warehouse_id,
      shipment_id: this.state.shipment_id,
      number_of_bags: this.state.number_of_bags,
      crop: this.state.crop,
      total_weight: this.state.total_weight
    };
    create_shipment_incoming_warehouse_live(shipment_incoming_warehouse,{
      t: jwt.token,
    }).then ((data)=>{
      if(!data) {
        return;
      }
      if(data){
        this.handleListShipment();
        this.setState({
          success_notification: true,
          mssg: "Warehouse Shipment Approved successfully",
          error_notification: "",
          modal: false
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
    })
  }
  render() {
    const { search } = this.state;
    const shipments = this.state.shipments.filter((shipment) => {
      return shipment.partyName.toLowerCase().includes(search.toLowerCase());
    });
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">gradient</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {this.state.type.slice(0, 1).toUpperCase() +
                            "-" +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <div className="dropdown">
                            {/* <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              onClick={() => this.handleModal("seasons")}
                            >
                              Change Season
                            </button> */}
                            <div
                              className={
                                !this.state.modals.seasons
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "452px",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      href="#/"
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        Select Season
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.seasons.map((season, id) => (
                                    <li key={id}>
                                      <a
                                        href = "#/"
                                        role="option"
                                        className="dropdown-item"
                                        aria-disabled="false"
                                        tabIndex="0"
                                        aria-selected="false"
                                        onClick={() => {
                                          let {
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          } = season;
                                          this.setState({
                                            type,
                                            start_month,
                                            end_month,
                                            year,
                                          });
                                          this.handleModal("seasons");
                                        }}
                                      >
                                        <span className=" bs-ok-default check-mark" />
                                        <span className="text">
                                          {season.type}
                                          {"  "}
                                          {season.year}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <p className="card-category">Total Shipments</p>
                        <h3 className="card-title">
                          {Number(this.state.shipments.length)+Number(this.state.warehouse_shipment.length)}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dt-buttons btn-group">
                                 {" "}
                                
                    <CSVLink
                      data={this.state.csv_data}
                      headers={this.state.csv_header}
                      filename={"Shipments.csv"}
                      className="btn btn-secondary buttons-pdf buttons-html5"
                      target="_blank"
                    >
                      Export Excel
                    </CSVLink>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            {/*<h3>Nothing to see here yet.</h3>*/}
                            <div
                              id="updated_shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="updated_shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="updated_shipments_length"
                                        aria-controls="updated_shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="updated_shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="updated_shipments"
                                          onChange={(event) =>
                                            this.setState({
                                              search: event.target.value,
                                            })
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="W-APR-NOV-19 Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="updated_shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Vehicle No: activate to sort column descending"
                                          style={{ width: "101px" }}
                                        >
                                          Vehicle No
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop Price: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop Price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Party Name: activate to sort column ascending"
                                          style={{ width: "112px" }}
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "47px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          style={{ width: "128px" }}
                                        >
                                          Date Updated
                                        </th>
                                        <th
                                          className="sorting text-center"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Actions: activate to sort column ascending"
                                          style={{ width: "74px" }}
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan="1" colSpan="1">
                                          Vehicle No
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop Price
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Party Name
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Type
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Date Updated
                                        </th>
                                        <th className="text-center" rowSpan="1" colSpan="1">
                                          Actions
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {shipments.map((data, id) => (
                                        <tr key={id}>
                                          <td className="sorting_1">
                                            {data.vehicleNumber}
                                          </td>
                                          <td>{data.crop.name}</td>
                                          <td>₦{data.crop_price}</td>
                                          <td>{data.partyName}</td>
                                          <td>{data.type}</td>

                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                          <td className="text-right inline-block">
                                      {/* <button
                                        className="btn"
                                        onClick={() =>
                                          this.setState({
                                            shipment_id: data._id,
                                            crop: data.crop,
                                            modal: true,
                                          })
                                        }
                                      >
                                        Create Incoming Crop
                                      </button> */}
                                      <Link
                                        to={{
                                          pathname: '/warehouse_shipment_details',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                            crop: data.crop
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                      <button
                                          data-toggle="tooltip"
                                        data-placement="top"
                                        title="Approve Shipment"
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-success"
                                          onClick={()=>{
                                            this.GetIncomingCrop()
                                            this.setState({shipment_id:data._id, crop: data.crop})
                                          }}
                                        >
                                          <i className="material-icons">
                                            {!data.collected
                                              ? "done"
                                              : "done_all"}
                                          </i>
                                        </button>
                                    </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="updated_shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="updated_shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="updated_shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="0"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next disabled"
                                        id="updated_shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="1"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">airport_shuttle</i>
                        </div>
                        <h4 className="card-title">Completed Shipments</h4>
                      </div>
                      <div className="card-body">
                        <div className="toolbar" />
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            {/*<h3>Nothing to see here yet.</h3>*/}
                            <div
                              id="updated_shipments_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                             
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="updated_shipments_length"
                                  >
                                    <label>
                                      Show{" "}
                                      <select
                                        name="updated_shipments_length"
                                        aria-controls="updated_shipments"
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>{" "}
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    id="updated_shipments_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      Search:
                                      <span className="bmd-form-group bmd-form-group-sm">
                                        <input
                                          type="search"
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="updated_shipments"
                                          onChange={(event) =>
                                            this.setState({
                                              search: event.target.value,
                                            })
                                          }
                                        />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <table
                                    title="W-APR-NOV-19 Shipments"
                                    id="shipments"
                                    className="table table-striped table-no-bordered table-hover dataTable"
                                    cellSpacing="0"
                                    width="100%"
                                    style={{ width: "100%" }}
                                    role="grid"
                                    aria-describedby="updated_shipments_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_asc"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-sort="ascending"
                                          aria-label="Vehicle No: activate to sort column descending"
                                          style={{ width: "101px" }}
                                        >
                                          Vehicle No
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Crop Price: activate to sort column ascending"
                                          style={{ width: "101px" }}
                                        >
                                          Crop Price
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Party Name: activate to sort column ascending"
                                          style={{ width: "112px" }}
                                        >
                                          Party Name
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Type: activate to sort column ascending"
                                          style={{ width: "47px" }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="sorting"
                                          tabIndex="0"
                                          aria-controls="updated_shipments"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Date Updated: activate to sort column ascending"
                                          style={{ width: "128px" }}
                                        >
                                          Date Updated
                                        </th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th rowSpan="1" colSpan="1">
                                          Vehicle No
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Crop Price
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Party Name
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Type
                                        </th>
                                        <th rowSpan="1" colSpan="1">
                                          Date Updated
                                        </th>
                                      </tr>
                                    </tfoot>
                                    <tbody>
                                      {this.state.warehouse_shipment.map((data, id) => (
                                        <tr key={id}>
                                          <td className="sorting_1">
                                            {data.shipment_id.vehicleNumber}
                                          </td>
                                          <td>{data.crop.name}</td>
                                          <td>₦{data.shipment_id.crop_price}</td>
                                          <td>{data.shipment_id.partyName}</td>
                                          <td>{data.shipment_id.type}</td>

                                          <td>
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                            >
                                              {data.created}
                                            </Moment>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-5">
                                  <div
                                    className="dataTables_info"
                                    id="updated_shipments_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing 0 to 0 of 0 entries
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="updated_shipments_paginate"
                                  >
                                    <ul className="pagination">
                                      <li
                                        className="paginate_button page-item previous disabled"
                                        id="updated_shipments_previous"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="0"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Previous
                                        </a>
                                      </li>
                                      <li
                                        className="paginate_button page-item next disabled"
                                        id="updated_shipments_next"
                                      >
                                        <a
                                          href="#/"
                                          aria-controls="updated_shipments"
                                          data-dt-idx="1"
                                          tabIndex="0"
                                          className="page-link"
                                        >
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!this.state.modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addShipmentModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Create Incoming Crop</h4>
                        <button
                          type="button"
                          className="close"
                          onClick={() => this.setState({ modal: false })}
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <h4 style={{ color: "red", alignSelf: "center" }}>
                        {this.state.error}
                      </h4>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="KzqureCJk7bTWLWJ58AHvaMO07xHken9p7t1UYi55HXOLPxa3hXjMLu8AUUNxtMq"
                              />

                              <div
                                className={
                                  this.state.grn
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="grn"
                                  className="bmd-label-floating"
                                >
                                  Enter GRN
                                </label>
                                <input
                                  required=""
                                  name="grn"
                                  type="text"
                                  className="form-control"
                                  id="grn"
                                  onChange={(event) =>
                                    this.setState({
                                      grn: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.number_of_bags
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="number_of_bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Number of Bags
                                </label>
                                <input
                                  required=""
                                  name="number_of_bags"
                                  type="text"
                                  className="form-control"
                                  id="number_of_bags"
                                  onChange={(event) =>
                                    this.setState({
                                      number_of_bags: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                               <div
                                className={
                                  this.state.number_of_bags
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="number_of_bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Total Weight of Incoming Crop
                                </label>
                                <input
                                  required=""
                                  name="number_of_bags"
                                  type="text"
                                  className="form-control"
                                  id="number_of_bags"
                                  onChange={(event) =>
                                    this.setState({
                                      total_weight: event.target.value,
                                      error: "",
                                    })
                                  }
                                />
                              </div>

                            <div
                              className={
                              !this.state.modals.warehouse
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                              }
                            >
                              <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("warehouse")}
                              >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.warehouse
                                      ? "Choose warehouse"
                                      : this.state.warehouse}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                              </button>
                            <div
                              className={
                                !this.state.modals.warehouse
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.warehouse || "Choose warehouse"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.warehouses.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.warehouse === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("warehouse");
                                            this.setState({
                                              warehouse: data.name,
                                              warehouse_id: data._id,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div></div>

                              <input
                                id="createShipment"
                                type="hidden"
                                name="create_shipment"
                                value=""
                              />
                              <button
                                type="submit"
                                onClick={this.checkForm}
                                className="btn btn-fill btn-primary"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {!this.state.delete_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                id="addCooperativeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{
                  display: "block",
                  paddingRight: "5px",
                  paddingTop: "100px",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete Shipment?</h4>
                      <button
                        onClick={() => this.setState({ delete_modal: false })}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Shipment
                          </div>

                          <button
                            type="submit"
                            onClick={this.handleDeleteShipment}
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            className="btn btn-fill btn-danger"
                            onClick={() =>
                              this.setState({ delete_modal: false })
                            }
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Shipments;
