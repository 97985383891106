import { fetchRequest } from '../utils';

export const create_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/season_cooperative/',
    method: 'POST',
    data: season
  });

export const list_season_cooperative = () =>
  fetchRequest({ url: 'http://localhost:9001/api/season_cooperative/' });

export const read_season_cooperative = params =>
  fetchRequest({
    url: 'http://localhost:9001/api/season_cooperative/' + params.seasonId
  });

export const update_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/season_cooperative/',
    method: 'PUT',
    data: season
  });

export const remove_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/season_cooperative/',
    method: 'DELETE',
    data: season
  });

export const remove_all_season_cooperative = season =>
  fetchRequest({
    url: 'http://localhost:9001/api/all/season_cooperative/',
    method: 'DELETE',
    data: season
  });
