import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import ReactToPdf from "react-to-pdf";
import { list_incoming_processed_crop_live } from "../../api/api-incoming_processed_crop";
import { list_incoming_crop_live } from "../../api/api-incoming_crop";
import {
  create_shipped_crop_live,
  list_shipped_crop_live,
  remove_shipped_crop_live,
} from "../../api/api-shipped_crop";
import Moment from "react-moment";
import { read_crop_live, list_crop_live } from "../../api/api-crop";
import { list_outgoing_processed_crop_live } from "../../api/api-outgoing_processed_crop";
import { list_outgoing_crop_live } from "../../api/api-outgoing_crop";
import {
  create_warehouse_live,
  list_warehouse_live,
  remove_warehouse_live,
} from "../../api/api-warehouse";
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";

const ref = React.createRef();
class WareHouses extends Component {
  constructor() {
    super();
    this.state = {
      warehouse_search: "",
      shipped_crop_search: "",
      warehouse_modal: false,
      warehouses: [],
      warehouse_name: "",
      warehouse_delete_modal: false,
      warehouse_id: "",
      shipped_crop_modal: false,
      shipped_crop_delete_modal: false,
      shipped_crop: [],
      shipped_crop_id: "",
      category_modal: false,
      line_modal: false,
      error: "",
      quantity: "",
      Req: "",
      category: "",
      line: "",
      total_bags: "",
      total_5_bags: "",
      total_10_bags: "",
      total_25_bags: "",
      total_50_bags: "",
      total_incoming_crop: "",
      total_outgoing_crop: "",
      total_incoming_processed_crop: "",
      total_outgoing_processed_crop: "",
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      crop: "",
      crops: [],
      selected_crops:[],
      crop_id:"",
      modals : {
        crop: false
      }
    }
    this.handleListWarehouse();
    this.handleListCrop();
    this.handleListShippedCrop();
    this.handleListIncomingCrop();
    this.handleListOutgoingCrop();
    this.handleListIncomingProcessedCrop();
    this.handleListOutgoingProcessedCrop();
  }

  componentDidMount() {
    document.title = "WareHouses";
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleListIncomingCrop = () => {
    list_incoming_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        const paddy = [];
        data.map((data) => paddy.push(Number(data.quantity)));
        const total = paddy.reduce((a, b) => a + b, 0) / 1000;
        this.setState({ total_incoming_crop: total });
      }
    });
  };

  handleListOutgoingCrop = () => {
    list_outgoing_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        const paddy = [];
        data.map((data) => paddy.push(Number(data.quantity)));
        const total = paddy.reduce((a, b) => a + b, 0) / 1000;
        this.setState({ total_outgoing_crop: total });
      }
    });
  };

  handleListIncomingProcessedCrop = () => {
    list_incoming_processed_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        const paddy = [];
        data.map((data) => paddy.push(Number(data.quantity)));
        const total = paddy.reduce((a, b) => a + b, 0);
        this.setState({ total_incoming_processed_crop: total });
      }
    });
  };

  handleListOutgoingProcessedCrop = () => {
    list_outgoing_processed_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        const paddy = [];
        data.map((data) => paddy.push(Number(data.quantity)));
        const total = paddy.reduce((a, b) => a + b, 0);
        this.setState({ total_outgoing_processed_crop: total });
      }
    });
  };

  handleWarehouseModal = () => {
    this.setState((prevState) => ({
      warehouse_modal: !prevState.warehouse_modal,
      error: "",
    }));
  };

  handleCategoryModal = () => {
    this.setState((prevState) => ({
      category_modal: !prevState.category_modal,
      error: "",
    }));
  };

  handleIntakeModal = () => {
    this.setState((prevState) => ({
      line_modal: !prevState.line_modal,
      error: "",
    }));
  };

  handleDeleteWarehouseModal = () => {
    this.setState((prevState) => ({
      warehouse_delete_modal: !prevState.warehouse_delete_modal,
      error: "",
    }));
  };

  handleLoadedHeadRiceModal = () => {
    this.setState((prevState) => ({
      shipped_crop_modal: !prevState.shipped_crop_modal,
      error: "",
    }));
  };

  handleDeleteLoadedHeadRiceModal = () => {
    this.setState((prevState) => ({
      shipped_crop_delete_modal: !prevState.shipped_crop_delete_modal,
      error: "",
    }));
  };

  checkForm = () => {
    if (!this.state.warehouse_name) {
      this.setState({
        error: "Warehouse Name is required",
      });
    }

    if (this.state.warehouse_name) {
      this.handleSubmitWarehouse();
    }
  };

  handleSubmitWarehouse = () => {
    const jwt = auth.isAuthenticated();

    const warehouse = {
      name: this.state.warehouse_name,
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      paddy: "0.0",
      headRice: "0",
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
    };
    create_warehouse_live(warehouse, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListWarehouse();
        this.handleWarehouseModal();

        this.setState({
          success_notification: true,
          mssg: "Warehouse Created successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        /*
				const warehouse = {
					warehouse_name: this.state.warehouse_name,
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					paddy: '0.0',
					head_rice: '0',
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
				};
				create_warehouse(warehouse, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} if(data) {
						this.handleListWarehouse();
					}
				});
				this.handleWarehouseModal();
		
				this.setState({
					warehouse_name: '',
				});
				*/
      }
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not create Warehouse",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };

  checkForm2 = () => {
    if (!this.state.quantity) {
      this.setState({
        error: "Quantity is required",
      });
    }

    if (!this.state.category) {
      this.setState({
        error: "Category is required",
      });
    }

    if (!this.state.crop) {
      this.setState({
        error: "Crop is required",
      });
    }

    if (!this.state.line) {
      this.setState({
        error: "Intake is required",
      });
    }

    if (this.state.quantity && this.state.category && this.state.line && this.state.crop) {
      this.handleSubmitLoadedHeadRice();
    }

    if (!this.state.quantity && !this.state.category && !this.state.line) {
      this.setState({
        error: "All Fields are required",
      });
    }
  };

  handleSubmitLoadedHeadRice = () => {
    const jwt = auth.isAuthenticated();
    const shipped_crop = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      quantity: this.state.quantity,
      req: this.state.Req,
      category: this.state.category,
      line: this.state.line,
      crop: this.state.crop_id,
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
    };
    create_shipped_crop_live(shipped_crop, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListShippedCrop();
        this.handleLoadedHeadRiceModal();

        this.setState({
          quantity: "",
          Req: "",
          category: "",
          line: "",
          success_notification: true,
          mssg: "Shipped Crop Created Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        /*
				const shipped_crop = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					quantity: this.state.quantity,
					Req: this.state.Req,
					category: this.state.category,
					line: this.state.line,
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
				};
				create_shipped_crop(shipped_crop, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} if(data) {
						this.handleListShippedCrop();
					}
				});
				this.handleLoadedHeadRiceModal();
		
				this.setState({
					quantity: '',
					Req: '',
					category: '',
					line: '',
				});
				*/
      }
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not create Shipped Crop",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };

  handleDeleteWarehouse = (id, liveId) => {
    remove_warehouse_live({
      warehouseId: liveId,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListWarehouse();
        this.handleDeleteWarehouseModal();
        this.setState({
          success_notification: false,
          mssg: "Warehouse Deleted Successfully",
          error_notification: true,
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);

        /*
                   remove_warehouse(
				{
					_id: id,
				},
				
			).then(data => {
				if (!data) {
					return;
				} if(data) {
				}
				this.handleListWarehouse();
			});
	
			this.handleDeleteWarehouseModal();
				*/
      }
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not delete Warehouse",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };

  handleListWarehouse = () => {
    list_warehouse_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        console.log(data);
        this.setState({ warehouses: data });
      }
    });
  };

  handleListShippedCrop = () => {
    list_shipped_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      }
      if (data.length !== 0) {
        let bags = [];
        let total_5_bags = [];
        let total_10_bags = [];
        let total_25_bags = [];
        let total_50_bags = [];
        data.map((data) => {
          bags.push(Number(data.quantity));
          if (data.category === "5") {
            total_5_bags.push(Number(data.quantity));
          }
          if (data.category === "10") {
            total_10_bags.push(Number(data.quantity));
          }
          if (data.category === "25") {
            total_25_bags.push(Number(data.quantity));
          }
          if (data.category === "50") {
            total_50_bags.push(Number(data.quantity));
          }
        });
        let total = bags.reduce((a, b) => a + b);
        this.setState({
          shipped_crop: data,
          total_bags: total,
          total_5_bags: total_5_bags.reduce((a, b) => a + b, 0),
          total_10_bags: total_10_bags.reduce((a, b) => a + b, 0),
          total_25_bags: total_25_bags.reduce((a, b) => a + b, 0),
          total_50_bags: total_50_bags.reduce((a, b) => a + b, 0),
        });
      }
      if (data.length === 0) {
        this.setState({
          shipped_crop: [],
          total_bags: [],
          total_5_bags: 0,
          total_10_bags: 0,
          total_25_bags: 0,
          total_50_bags: 0,
        });
      }
    });
  };

  handleDeleteLoadedHeadRice = (id, liveId) => {
    remove_shipped_crop_live({
      shipped_cropId: liveId,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        this.handleListShippedCrop();
        this.setState({
          shipped_crop_delete_modal: false,
          success_notification: false,
          mssg: "Shipped Crop Deleted Successfully",
          error_notification: true,
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);

        /*
				remove_shipped_crop(
					{
						_id: id,
					},
					
				).then(data => {
					if (!data) {
						return;
					} if(data) {
						this.handleListShippedCrop();
					}
				});
		
				this.setState({ shipped_crop_delete_modal: false });
				*/
      }
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not Delete Shipped Crop",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };
  render() {
    const { warehouse_search, shipped_crop_search } = this.state;
    const warehouses = this.state.warehouses.filter((warehouse) => {
      return warehouse.name
        .toLowerCase()
        .includes(warehouse_search.toLowerCase());
    });

    const shipped_crop = this.state.shipped_crop.filter(
      (shipped_crop) => {
        return shipped_crop.line
          .toLowerCase()
          .includes(shipped_crop_search.toLowerCase());
      }
    );
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="container-fluid">
              {this.state.success_notification ? (
                <div className="alert alert-success">
                  <button
                    onClick={() =>
                      this.setState({ success_notification: false })
                    }
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : this.state.error_notification ? (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="card card-stats">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">filter_vintage</i>
                      </div>
                      <p className="card-category">Crops in Inventory</p>
                      <h3 className="card-title">
                        {(
                          this.state.total_incoming_crop -
                          this.state.total_outgoing_crop
                        ).toFixed(2)}{" "}
                        Tons
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Total Crop in All Warehouses</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="card card-stats">
                    <div className="card-header card-header-info card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">shopping_basket</i>
                      </div>
                      <p className="card-category">Processed Crops in Inventory</p>
                      <h3 className="card-title">
                        {(
                          this.state.total_incoming_processed_crop -
                          this.state.total_outgoing_processed_crop
                        ).toFixed(2)}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Total Processed Crops in All Warehouses</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="card card-stats">
                    <div className="card-header card-header-primary card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">airport_shuttle</i>
                      </div>
                      <p className="card-category">Shipped Crop</p>
                      <h3 className="card-title">
                        {this.state.total_bags} Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Total Shipped Crops from Lines</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-warning card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_stream</i>
                    </div>
                    <p className="card-category">5Kg Processed Crops</p>
                    <h3 className="card-title">0 Bags</h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 5Kg Processed Crops in all Warehouses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-warning card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_week</i>
                    </div>
                    <p className="card-category">10Kg Processed Crops</p>
                    <h3 className="card-title">0 Bags</h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 10Kg Processed Crops in all Warehouses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-warning card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_quilt</i>
                    </div>
                    <p className="card-category">25Kg Processed Crops</p>
                    <h3 className="card-title">0 Bags</h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 25Kg Processed Crops in all Warehouses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-warning card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_module</i>
                    </div>
                    <p className="card-category">50Kg Processed Crops</p>
                    <h3 className="card-title">0 Bags</h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 50Kg Processed Crops in all Warehouses</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-success card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">store</i>
                    </div>
                    <h4 className="card-title">Warehouses</h4>
                  </div>
                  <div className="card-body">
                    <div className="toolbar">
                      <button
                        className="btn btn-success btn-round"
                        data-toggle="modal"
                        data-target="#createWarehouseModal"
                        onClick={this.handleWarehouseModal}
                      >
                        Create Warehouse
                      </button>
                    </div>
                    <div className="material-datatables">
                      <div className="table-responsive-sm">
                        <div
                          id="warehouses_wrapper"
                          className="dataTables_wrapper dt-bootstrap4"
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div
                                className="dataTables_length"
                                id="warehouses_length"
                              >
                                <ReactToPdf targetRef={ref} filename="warehouses.pdf">
                                  {({ toPdf }) => (
                                    <button
                                      className="btn btn-secondary buttons-pdf buttons-html5"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      type="button"
                                      onClick={toPdf}
                                    >
                                      <span>Export PDF</span>
                                    </button>
                                  )}
                                </ReactToPdf>{" "}
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  table="warehouses"
                                  filename="warehousesxls"
                                  sheet="warehousesxls"
                                  buttonText="Export Excel"
                                />
                                <label>
                                  Show{" "}
                                  <select
                                    name="warehouses_length"
                                    aria-controls="warehouses"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>{" "}
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div
                                id="warehouses_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="warehouses"
                                      onChange={(event) =>
                                        this.setState({
                                          warehouse_search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                id="warehouses"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="warehouses_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Warehouse: activate to sort column descending"
                                      style={{ width: "86px" }}
                                    >
                                      Warehouse
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Crop (Tons): activate to sort column ascending"
                                      style={{ width: "101px" }}
                                    >
                                      Crop (Tons)
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Processed Crops (Bags): activate to sort column ascending"
                                      style={{ width: "132px" }}
                                    >
                                      Processed Crops (Bags)
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Created By: activate to sort column ascending"
                                      style={{ width: "83px" }}
                                    >
                                      Created By
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="warehouses"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Warehouse
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Crop (Tons)
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Processed Crops (Bags)
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Created By
                                    </th>
                                    <th
                                      className="disabled-sorting text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {warehouses.map((data, id) => (
                                    <tr key={id} role="row" className="odd">
                                      <td className="sorting_1">
                                        {data.name}
                                      </td>
                                      <td>
                                        {data.paddy} Tons
                                      </td>
                                      <td>
                                        {data.head_rice} Bags
                                      </td>
                                      <td>
                                        {data.createdBy}
                                      </td>
                                      <td className="text-right inline-block">
                                        <Link
                                          to={{
                                            pathname: "/warehouse_detail",
                                            state: { 
                                              warehouseId: data._id, 
                                              direct:"warehouse" },
                                          }}
                                          className="btn btn-outline-primary btn-sm"
                                        >
                                          View
                                        </Link>
                                        {/* <div>
                                          <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value="1kFssuZRL8AlCJTyjJUHB3jm3czAyQppRoHDGTCWWd9NDf1ymASBbSBZQlNnaY9l"
                                          />
                                          <input
                                            type="hidden"
                                            name="delete_warehouse"
                                            value="1"
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Warehouse"
                                            onClick={() =>
                                              this.setState({
                                                warehouse_id: data._id,
                                                warehouse_live_id: data._id,
                                                warehouse_delete_modal: true,
                                              })
                                            }
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div> */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5">
                              <div
                                className="dataTables_info"
                                id="warehouses_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 0 to 0 of 0 entries
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="warehouses_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="warehouses_previous"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="warehouses"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="warehouses_next"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="warehouses"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_stream</i>
                    </div>
                    <p className="card-category"> Loaded 5Kg Processed Crops</p>
                    <h3 className="card-title">
                      {!this.state.total_5_bags ? "0" : this.state.total_5_bags}{" "}
                      Bags
                    </h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 5Kg Processed Crops Loaded</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_week</i>
                    </div>
                    <p className="card-category"> Loaded 10Kg Processed Crops</p>
                    <h3 className="card-title">
                      {!this.state.total_10_bags
                        ? "0"
                        : this.state.total_10_bags}{" "}
                      Bags
                    </h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 10Kg Processed Crops Loaded</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_quilt</i>
                    </div>
                    <p className="card-category"> Loaded 25Kg Processed Crops</p>
                    <h3 className="card-title">
                      {!this.state.total_25_bags
                        ? "0"
                        : this.state.total_25_bags}{" "}
                      Bags
                    </h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 25Kg Processed Crops Loaded</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="card card-stats">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">view_module</i>
                    </div>
                    <p className="card-category"> Loaded 50Kg Processed Crop</p>
                    <h3 className="card-title">
                      {!this.state.total_50_bags
                        ? "0"
                        : this.state.total_50_bags}{" "}
                      Bags
                    </h3>
                  </div>
                  <div className="card-footer">
                    <div className="stats">
                      <p>Total 50Kg Processed Crop Loaded</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">airport_shuttle</i>
                    </div>
                    <h4 className="card-title">Shipped Crop</h4>
                  </div>
                  <div className="card-body">
                    <div className="toolbar">
                      <button
                        className="btn btn-primary btn-round"
                        data-toggle="modal"
                        data-target="#createLoadedModal"
                        onClick={this.handleLoadedHeadRiceModal}
                      >
                        Create Shipped Crop
                      </button>
                    </div>
                    <div className="material-datatables">
                      <div className="table-responsive-sm">
                        {/*<h3>Nothing to see here yet.</h3>*/}
                        <div
                          id="loaded_rice_wrapper"
                          className="dataTables_wrapper dt-bootstrap4"
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div
                                className="dataTables_length"
                                id="loaded_rice_length"
                              >
                                <ReactToPdf targetRef={ref} filename="shipped_crop.pdf">
                                  {({ toPdf }) => (
                                    <button
                                      className="btn btn-secondary buttons-pdf buttons-html5"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      type="button"
                                      onClick={toPdf}
                                    >
                                      <span>Export PDF</span>
                                    </button>
                                  )}
                                </ReactToPdf>{" "}
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  table="shipped_crop"
                                  filename="shipped_cropxls"
                                  sheet="shipped_cropxls"
                                  buttonText="Export Excel"
                                />
                                <label>
                                  Show{" "}
                                  <select
                                    name="loaded_rice_length"
                                    aria-controls="loaded_rice"
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>{" "}
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div
                                id="loaded_rice_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="loaded_rice"
                                      onChange={(event) =>
                                        this.setState({
                                          shipped_crop_search:
                                            event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                id="loaded_rice"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="loaded_rice_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="Quantity (Bags): activate to sort column descending"
                                      style={{ width: "35px" }}
                                    >
                                      Quantity (Bags)
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Crop: activate to sort column ascending"
                                      style={{ width: "54px" }}
                                    >
                                      Crop
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Line: activate to sort column ascending"
                                      style={{ width: "34px" }}
                                    >
                                      Line
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="REQ: activate to sort column ascending"
                                      style={{ width: "35px" }}
                                    >
                                      REQ
                                    </th>
                                    <th
                                      className="sorting text-center"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Created By: activate to sort column ascending"
                                      style={{ width: "88px" }}
                                    >
                                      Created By
                                    </th>
                                    <th
                                      className="sorting text-right"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Created: activate to sort column ascending"
                                      style={{ width: "86px" }}
                                    >
                                      Date Created
                                    </th>
                                    {/* <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="loaded_rice"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "64px" }}
                                    >
                                      Actions
                                    </th> */}
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      Quantity (Bags)
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                        Crop
                                      </th>
                                    <th rowSpan="1" colSpan="1">
                                      Line
                                    </th>
                                    
                                    <th rowSpan="1" colSpan="1">
                                      REQ
                                    </th>
                                    <th className="text-center" rowSpan="1" colSpan="1">
                                      Created By
                                    </th>
                                    <th className="text-right" rowSpan="1" colSpan="1">
                                      Date Created
                                    </th>
                                    {/* <th
                                      className="disabled-sorting text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th> */}
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {shipped_crop.map((data, id) => (
                                    <tr key={id} role="row" className="odd">
                                      <td className="sorting_1">
                                        {data.quantity}Bags
                                      </td>
                                  <td>{data.crop.name}</td>
                                      <td>{data.line}</td>
                                      <td>{data.req}</td>
                                      <td className="text-center">{data.createdBy}</td>
                                      <td className="text-right"><Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment></td>
                                      {/* <td className="text-right inline-block">
                                        <div>
                                          <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value="1kFssuZRL8AlCJTyjJUHB3jm3czAyQppRoHDGTCWWd9NDf1ymASBbSBZQlNnaY9l"
                                          />
                                          <input
                                            type="hidden"
                                            name="delete_loaded_rice"
                                            value="1"
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-info"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete Rice"
                                            onClick={() =>
                                              this.setState({
                                                shipped_crop_id: data._id,
                                                shipped_crop_live_id:
                                                  data._id,
                                                shipped_crop_delete_modal: true,
                                              })
                                            }
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5">
                              <div
                                className="dataTables_info"
                                id="loaded_rice_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 0 to 0 of 0 entries
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="loaded_rice_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="loaded_rice_previous"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="loaded_rice"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="loaded_rice_next"
                                  >
                                    <a
                                      href="#/"
                                      aria-controls="loaded_rice"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!this.state.warehouse_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                style={{ display: "block", paddingRight: "15px" }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Create New Warehouse</h4>

                      <button
                        type="button"
                        className="close"
                        onClick={this.handleWarehouseModal}
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <h4 style={{ color: "red", alignSelf: "center" }}>
                      {this.state.error}
                    </h4>
                    <div>
                      <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value="IByM50v4u8RfPAAZeKRVNFKDzKaWdCxofCLxVz12eqpBJTd6HSB27yINsKVFL78i"
                      />
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <form method="post" action=".">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="IByM50v4u8RfPAAZeKRVNFKDzKaWdCxofCLxVz12eqpBJTd6HSB27yINsKVFL78i"
                              />
                              <div className="form-group">
                                <label
                                  htmlFor="price"
                                  className="bmd-label-floating"
                                >
                                  Enter Enter Warehouse Name e.g. 001
                                </label>
                                <input
                                  required
                                  name="price"
                                  onChange={(event) =>
                                    this.setState({
                                      warehouse_name: event.target.value,
                                      error: "",
                                    })
                                  }
                                  type="text"
                                  className="form-control"
                                  id="price"
                                />
                              </div>
                            </form>
                          </div>
                          <div className="card-footer justify-content-center">
                            <input
                              type="hidden"
                              name="create_price"
                              value="1"
                            />
                            <button
                              onClick={this.checkForm}
                              type="submit"
                              className="btn btn-fill btn-primary"
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.warehouse_delete_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                id="addCooperativeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{
                  display: "block",
                  paddingRight: "5px",
                  paddingTop: "100px",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete Recovery?</h4>
                      <button
                        onClick={this.handleDeleteWarehouseModal}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Warehouse
                          </div>

                          <button
                            type="submit"
                            onClick={() =>
                              this.handleDeleteWarehouse(
                                this.state.warehouse_id,
                                this.state.warehouse_live_id
                              )
                            }
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            onClick={this.handleDeleteWarehouseModal}
                            className="btn btn-fill btn-danger"
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.shipped_crop_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                id="createLoadedModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{ display: "block", paddingRight: "15px" }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Record Loaded Processed Crop</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={this.handleLoadedHeadRiceModal}
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <h4 style={{ color: "red", alignSelf: "center" }}>
                      {this.state.error}
                    </h4>
                    <div>
                      <input
                        type="hidden"
                        name="csrfmiddlewaretoken"
                        value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                      />
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <input
                              type="hidden"
                              name="csrfmiddlewaretoken"
                              value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                            />
                            <div
                              className={
                                this.state.quantity
                                  ? "is-filled form-group bmd-form-group"
                                  : `form-group bmd-form-group `
                              }
                            >
                              <label
                                htmlFor="bags"
                                className="bmd-label-floating"
                              >
                                Enter Quantity in Bags e.g. 32450
                              </label>
                              <input
                                required=""
                                name="bags"
                                type="number"
                                step="0.01"
                                className="form-control"
                                id="bags"
                                onChange={(event) =>
                                  this.setState({
                                    quantity: event.target.value,
                                    error: "",
                                  })
                                }
                              />
                            </div>
                            <div
                              className={
                                this.state.Req
                                  ? "is-filled form-group bmd-form-group"
                                  : `form-group bmd-form-group `
                              }
                            >
                              <label
                                htmlFor="req"
                                className="bmd-label-floating"
                              >
                                Enter REQ (Optional) e.g. 001
                              </label>
                              <input
                                name="req"
                                type="text"
                                className="form-control"
                                id="req"
                                onChange={(event) =>
                                  this.setState({
                                    Req: event.target.value,
                                    error: "",
                                  })
                                }
                              />
                            </div>
                            <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                            <div className="form-group">
                              <div className="dropdown bootstrap-select show">
                                <select
                                  required=""
                                  name="category"
                                  className="selectpicker"
                                  data-size="4"
                                  data-style="btn btn-round btn-rose"
                                  title="Choose Category"
                                  tabIndex="-98"
                                >
                                  <option
                                    className="bs-title-option"
                                    defaultValue=""
                                  />
                                  <option disabled="">Choose Category</option>
                                  <option value="5Kg">5Kg</option>
                                  <option value="10Kg">10Kg</option>
                                  <option value="25Kg">25Kg</option>
                                  <option value="50Kg">50Kg</option>
                                </select>
                                <button
                                  type="button"
                                  className="dropdown-toggle btn btn-round btn-rose"
                                  data-toggle="dropdown"
                                  title="Choose Category"
                                  aria-expanded="true"
                                  onClick={this.handleCategoryModal}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        {!this.state.category
                                          ? "Choose Category"
                                          : `${this.state.category}Kg`}
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div className="ripple-container" />
                                </button>
                                <div
                                  className={
                                    !this.state.category_modal
                                      ? "dropdown-menu"
                                      : "dropdown-menu show"
                                  }
                                  role="combobox"
                                  x-placement="bottom-start"
                                  style={{
                                    maxHeight: "162px",
                                    overflow: "hidden",
                                    minWidth: "220px",
                                    position: "absolute",
                                    top: "46px",
                                    left: "1px",
                                    willChange: "top, left",
                                  }}
                                >
                                  <div
                                    className="inner show"
                                    role="listbox"
                                    aria-expanded="true"
                                    tabIndex="-1"
                                    style={{
                                      maxHeight: "152px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="disabled selected active">
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            !this.state.category
                                              ? "dropdown-item disabled selected active"
                                              : "dropdown-item disabled"
                                          }
                                          aria-disabled="true"
                                          tabIndex="-1"
                                          aria-selected="true"
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            Choose Category
                                          </span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.category !== "5"
                                              ? "dropdown-item"
                                              : "dropdown-item active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              category: "5",
                                              category_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">5Kg</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.category !== "10"
                                              ? "dropdown-item"
                                              : "dropdown-item active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              category: "10",
                                              category_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">10Kg</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.category !== "25"
                                              ? "dropdown-item"
                                              : "dropdown-item active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              category: "25",
                                              category_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">25Kg</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.category !== "50"
                                              ? "dropdown-item"
                                              : "dropdown-item active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              category: "50",
                                              category_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">50Kg</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="dropdown bootstrap-select dropup">
                                <select
                                  required=""
                                  name="intake"
                                  className="selectpicker"
                                  data-size="4"
                                  data-style="btn btn-round btn-rose"
                                  title="Choose Intake"
                                  tabIndex="-98"
                                >
                                  <option
                                    className="bs-title-option"
                                    defaultValue=""
                                  />
                                  <option disabled="">Choose Intake</option>
                                  <option value="Line 1">Line 1</option>
                                  <option value="Line 2">Line 2</option>
                                  <option value="Line 3">Line 3</option>
                                  <option value="Line 4">Line 4</option>
                                </select>
                                <button
                                  type="button"
                                  className="dropdown-toggle btn btn-round btn-rose"
                                  data-toggle="dropdown"
                                  title="Choose Intake"
                                  aria-expanded="false"
                                  onClick={this.handleIntakeModal}
                                >
                                  <div className="filter-option">
                                    <div className="filter-option-inner">
                                      <div className="filter-option-inner-inner">
                                        {!this.state.line
                                          ? "Choose Intake"
                                          : this.state.line}
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div className="ripple-container" />
                                </button>
                                <div
                                  className={
                                    !this.state.line_modal
                                      ? "dropdown-menu"
                                      : "dropdown-menu show"
                                  }
                                  role="combobox"
                                  x-placement="top-start"
                                  style={{
                                    maxHeight: "162px",
                                    overflow: "hidden",
                                    minWidth: "220px",
                                    position: "absolute",
                                    top: "46px",
                                    left: "1px",
                                    willChange: "top, left",
                                  }}
                                >
                                  <div
                                    className="inner show"
                                    role="listbox"
                                    aria-expanded="false"
                                    tabIndex="-1"
                                    style={{ overflowY: "auto", maxHeight:"162px" }}
                                  >
                                    <ul className="dropdown-menu inner show">
                                      <li className="disabled selected active">
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            !this.state.line
                                              ? "dropdown-item disabled selected active"
                                              : "dropdown-item disabled"
                                          }
                                          aria-disabled="true"
                                          tabIndex="-1"
                                          aria-selected="true"
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            Choose Intake
                                          </span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.line !== "Line 1"
                                              ? "dropdown-item"
                                              : "dropdown-item show"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              line: "Line 1",
                                              line_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">Line 1</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.line !== "Line 2"
                                              ? "dropdown-item"
                                              : "dropdown-item show"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              line: "Line 2",
                                              line_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">Line 2</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.line !== "Line 3"
                                              ? "dropdown-item"
                                              : "dropdown-item show"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              line: "Line 3",
                                              line_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">Line 3</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          role="option"
                                          className={
                                            this.state.line !== "Line 4"
                                              ? "dropdown-item"
                                              : "dropdown-item show"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() =>
                                            this.setState({
                                              line: "Line 4",
                                              line_modal: false,
                                            })
                                          }
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">Line 4</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer justify-content-center">
                            <input
                              type="hidden"
                              name="create_loaded_rice"
                              value="1"
                            />
                            <button
                              type="submit"
                              className="btn btn-fill btn-primary"
                              onClick={this.checkForm2}
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.shipped_crop_delete_modal ? (
              ""
            ) : (
              <div
                className="modal fade show"
                id="addCooperativeModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{
                  display: "block",
                  paddingRight: "5px",
                  paddingTop: "100px",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Delete Loaded Processed Crop?</h4>
                      <button
                        onClick={this.handleDeleteLoadedHeadRiceModal}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card ">
                        <div className="card-body ">
                          <div>
                            Are you sure you want to delete this Loaded Head
                            Rice
                          </div>

                          <button
                            type="submit"
                            onClick={() =>
                              this.handleDeleteLoadedHeadRice(
                                this.state.shipped_crop_id,
                                this.state.shipped_crop_live_id
                              )
                            }
                            className="btn btn-fill btn-success"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            onClick={this.handleDeleteWarehouseModal}
                            className="btn btn-fill btn-danger"
                          >
                            Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-fill btn-primary"
                            id="create_offline"
                            style={{ display: "none" }}
                          >
                            Create Offline
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default WareHouses;
