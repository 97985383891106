import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import auth from "../../api/auth-helper";
import {
  create_paddyprice_live,
  list_paddyprice_live,
  remove_paddyprice_live,
} from "../../api/api-paddy_price";
import {list_crop_live} from "../../api/api-crop"
import { listseasons_live } from "../../api/api-seasons";
import Moment from "react-moment";
class PaddyPrice extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      deletemodal: false,
      price: "",
      currentPrice: "",
      paddy_price: [],
      extension_managers: [],
      previousPrice: "",
      seasons: [],
      type: "",
      crops:[],
      start_month: "",
      end_month: "",
      year: "",
      seasonId: "",
      liveId: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      mssg: "",
    };

    this.handleListSeason();
    this.handleListCrop()
  }

  componentDidMount() {
    document.title = "Crop Price";
  }


  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleListSeason = () => {
    listseasons_live().then((data) => {
      console.log(data);
      if (data === undefined) {
        this.setState({
          seasons: [],
          type: "",
          start_month: "",
          end_month: "",
          year: "",
        });
      }
      if (data !== undefined) {
        this.setState({
          seasons: data,
          type: data[0].type,
          start_month: data[0].start_month,
          end_month: data[0].end_month,
          year: data[0].year,
          seasonId: data[0]._id,
        });
      }
    });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleOpenDeleteModal = (id, liveId) => {
    this.setState({
      deletemodal: true,
      id: id,
      liveId: liveId,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

  handlePrice = (event) => {
    this.setState({
      price: event.target.value,
    });
  };

  deleteData = (id, _id) => {
   if (navigator.onLine) {
      remove_paddyprice_live({
        paddyPriceId: _id,
      }).then((data) => {
        if (data.length === 0) {
          return;
        } else {
          this.handleList();
          this.setState({
            loading: false,
            error_notification: true,
            mssg: "Crop Price deleted successfully",
          });
          setTimeout(() => this.setState({ error_notification: false }), 3000);
          this.handleCloseDeleteModal();
        }
      });
    }
  };

  // handleList = () => {
  //   if (navigator.onLine) {
  //     list_paddyprice_live().then((data) => {
  //       if (data.error) {
  //         console.log(data.error);
  //       }
  //       if (data[1] !== undefined && data[0] !== undefined) {
  //         this.setState({
  //           paddy_price: data,
  //           currentPrice: data[0].price,
  //           previousPrice: data[1].price,
  //         });
  //       }
  //       if (data[0] === undefined) {
  //         this.setState({
  //           paddy_price: [],
  //           currentPrice: "",
  //           previousPrice: "",
  //         });
  //       }
  //       if (data[1] === undefined && data[0] !== undefined) {
  //         this.setState({
  //           paddy_price: data,
  //           currentPrice: data[0].price,
  //           previousPrice: "",
  //         });
  //       }
  //     });
  //   } else if (!navigator.onLine) {
  //     /*
	// 		list_paddyprice().then(data => {
	// 			if (data.error) {
	// 				console.log(data.error);
	// 			}
	// 			if (data[1] !== undefined && data[0] !== undefined) {
	// 				this.setState({ paddy_price: data, currentPrice: data[0].price, previousPrice: data[1].price });
	// 			}
	// 			if (data[0] === undefined) {
	// 				this.setState({ paddy_price: [], currentPrice: '', previousPrice: '' });
	// 			}
	// 			if (data[1] === undefined && data[0] !== undefined) {
	// 				this.setState({ paddy_price: data, currentPrice: data[0].price, previousPrice: '' });
	// 			}
	// 		});
	// 		*/
  //   }
  // };

  submitForm = () => {
    const jwt = auth.isAuthenticated();
    if (navigator.onLine) {
      const paddyprice = {
        deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
        createdBy:
          auth.isAuthenticated().user.first_name +
          " " +
          auth.isAuthenticated().user.last_name,
        price: this.state.price,
        seasonId: this.state.seasonId,
      };
      create_paddyprice_live(paddyprice, {
        t: jwt.token,
      }).then((data) => {
        if (!data) {
          return;
        }
        if (data) {
          this.handleList();
          this.setState({
            loading: false,
            success_notification: true,
            mssg: "Crop Price created successfully",
          });
          setTimeout(
            () => this.setState({ success_notification: false }),
            3000
          );
          this.handleModal();
        }
      });
    }
  };
  render() {
    const { type, year, start_month, end_month } = this.state;
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            <div className="content">
              <div className="container-fluid">
                {this.state.success_notification ? (
                  <div className="alert alert-success">
                    <button
                      onClick={() =>
                        this.setState({ success_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : this.state.error_notification ? (
                  <div className="alert alert-danger">
                    <button
                      onClick={() =>
                        this.setState({ error_notification: false })
                      }
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <span>{this.state.mssg} </span>
                  </div>
                ) : (
                  ""
                )}
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <button
                      onClick={() => document.location.reload()}
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="68"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
                      </svg>
                    </button>
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-warning card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">weekend</i>
                        </div>
                        <p className="card-category">Season</p>
                        <h3 className="card-title">
                          {type.slice(0, 1).toUpperCase() +
                            "-" +
                            start_month.slice(0, 3).toUpperCase() +
                            "-" +
                            end_month.slice(0, 3).toUpperCase() +
                            "-" +
                            year.toString().slice(2, 4).toUpperCase()}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          {/* <div className="dropdown">
                            <button
                              className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                              type="button"
                              id="dropdownSeasonButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Change Season
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownSeasonButton"
                            >
                              <h6 className="dropdown-header">Seasons</h6>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">equalizer</i>
                        </div>
                        <p className="card-category">Current Crop Price</p>
                        <h3 className="card-title">
                          #{this.state.currentPrice} / Kg
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats"></div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="card card-stats">
                      <div className="card-header card-header-rose card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">equalizer</i>
                        </div>
                        <p className="card-category">Price Difference</p>
                        <h3 className="card-title">
                          #
                          {this.state.previousPrice
                            ? this.state.currentPrice - this.state.previousPrice
                            : "0"}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <p className="card-category">
                            This is the difference between the last price and
                            the current price of crop
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">assignment</i>
                        </div>
                        <h4 className="card-title">Crop Prices</h4>
                      </div>
                      <div className="card-body">
                        {/* <div className="toolbar">
                          <button
                            className="btn btn-primary btn-round"
                            onClick={this.handleModal}
                          >
                            Create Crop Price
                          </button>
                        </div> */}
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <table
                              id="prices"
                              className="table table-striped table-no-bordered table-hover"
                              cellSpacing="0"
                              width="100%"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>Crop</th>
                                  <th>Current Price / Kg</th>
                                  <th>Previous Price / Kg</th>
                                  <th>Created By</th>
                                  <th>Date Created</th>
                                  <th>Date Updated</th>
                                  {/* <th className="disabled-sorting text-right">
                                    Actions
                                  </th> */}
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Crop</th>
                                  <th>Price / Kg</th>
                                  <th>Previous Price / Kg</th>
                                  <th>Created By</th>
                                  <th>Date Created</th>
                                  <th>Date Updated</th>
                                  {/* <th className="disabled-sorting text-right">
                                    Actions
                                  </th> */}
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.crops.map((data, id) => (
                                  <tr key={id}>
                                    <td>{data.name}</td>
                                    <td>₦{data.price[0].price}</td>
                                    {!data.price[1] ? <td>No Previous Price</td> :
                                    <td>₦{data.price[1].price}</td>}
                                    <td>{`${data.createdBy}`}</td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.createdAt}
                                      </Moment>
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY" withTitle>
                                        {data.updated}
                                      </Moment>
                                    </td>

                                    {/* <td className="text-right inline-block">
                                      <div>
                                        <input
                                          type="hidden"
                                          name="csrfmiddlewaretoken"
                                          value="IByM50v4u8RfPAAZeKRVNFKDzKaWdCxofCLxVz12eqpBJTd6HSB27yINsKVFL78i"
                                        />
                                        <input
                                          type="hidden"
                                          name="delete_price"
                                          value="1"
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-link btn-just-icon btn-info"
                                          onClick={() =>
                                            this.handleOpenDeleteModal(
                                              data._id,
                                              data._id
                                            )
                                          }
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete Price"
                                        >
                                          <i className="material-icons">
                                            clear
                                          </i>
                                        </button>
                                      </div>
                                    </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">Delete Crop Price?</h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div>
                                  Are you sure you want to delete this Crop
                                  Price?
                                </div>

                                <button
                                  type="submit"
                                  onClick={() =>
                                    this.deleteData(
                                      this.state.id,
                                      this.state.liveId
                                    )
                                  }
                                  className="btn btn-fill btn-success"
                                >
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  onClick={this.handleCloseDeleteModal}
                                  className="btn btn-fill btn-danger"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  id="create_offline"
                                  style={{ display: "none" }}
                                >
                                  Create Offline
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleCloseDeleteModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to delete this
                                  paddybase.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    style={{ display: "block", paddingRight: "15px" }}
                  >
                    <div className="modal-dialog">
                      {navigator.onLine ? (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Create New Crop Price
                            </h4>
                            <p>
                              Extension Department will be notified by email.
                            </p>
                            <button
                              type="button"
                              className="close"
                              onClick={this.handleModal}
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div>
                            <input
                              type="hidden"
                              name="csrfmiddlewaretoken"
                              value="IByM50v4u8RfPAAZeKRVNFKDzKaWdCxofCLxVz12eqpBJTd6HSB27yINsKVFL78i"
                            />
                            <div className="modal-body">
                              <div className="card ">
                                <div className="card-body ">
                                  <form method="post" action=".">
                                    <input
                                      type="hidden"
                                      name="csrfmiddlewaretoken"
                                      value="IByM50v4u8RfPAAZeKRVNFKDzKaWdCxofCLxVz12eqpBJTd6HSB27yINsKVFL78i"
                                    />
                                    <div className="form-group">
                                      <label
                                        htmlFor="price"
                                        className="bmd-label-floating"
                                      >
                                        Enter New Price of Crop e.g. 135
                                      </label>
                                      <input
                                        required
                                        name="price"
                                        onChange={this.handlePrice}
                                        type="text"
                                        className="form-control"
                                        id="price"
                                      />
                                    </div>
                                  </form>
                                </div>
                                <div className="card-footer justify-content-center">
                                  <input
                                    type="hidden"
                                    name="create_price"
                                    value="1"
                                  />
                                  <button
                                    onClick={this.submitForm}
                                    type="submit"
                                    className="btn btn-fill btn-primary"
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              No Internet Connection
                            </h4>
                            <button
                              onClick={this.handleModal}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            >
                              <i className="material-icons">clear</i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <h5>
                                  Internet connection is needed to create this
                                  paddybase.
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default PaddyPrice;
