import { fetchRequest } from "../utils";

export const create_open_market_supplier = (open_market_supplier) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/open_market_supplier/`,
    method: "POST",
    data: open_market_supplier,
  });

export const list_open_market_supplier = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/open_market_supplier/`,
  });

export const read_open_market_supplier = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/open_market_supplier/` +
      params.open_market_supplierId,
  });

export const update_open_market_supplier = (open_market_supplier) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/open_market_supplier/`,
    method: "PUT",
    data: open_market_supplier,
  });

export const remove_open_market_supplier = (open_market_supplier) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/open_market_supplier/`,
    method: "DELETE",
    data: open_market_supplier,
  });

export const create_open_market_supplier_live = (open_market_supplier) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/open_market_supplier/`,
    method: "POST",
    data: open_market_supplier,
  });

export const list_open_market_supplier_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/open_market_supplier/`,
  });

export const remove_open_market_supplier_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/open_market_supplier/` +
      params.openMarketSupplierId,
    method: "DELETE",
  });
