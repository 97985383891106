import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  create_incoming_processed_crop_live,
  list_incoming_processed_crop_live,
  remove_incoming_processed_crop,
  remove_incoming_processed_crop_live,
} from "../../api/api-incoming_processed_crop";
import {
  list_shipment_incoming_warehouse_live,
} from "../../api/api-shipment_incoming_crop";
import {
  create_outgoing_processed_crop_live,
  list_outgoing_processed_crop_live,
  remove_outgoing_processed_crop,
} from "../../api/api-outgoing_processed_crop";
import {
  create_outgoing_crop_live,
  list_outgoing_crop_live,
  remove_outgoing_crop,
} from "../../api/api-outgoing_crop";
import { read_crop_live, list_crop_live } from "../../api/api-crop";
import auth from "../../api/auth-helper";
import Nav from "./Nav";
import SideBar from "./SideBar";

class WareHouseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: "",
      quantity: "",
      req: "",
      grn: "",
      category: "",
      line: "",
      line_modal: false,
      category_modal: false,
      incoming_crop_modal: false,
      outgoing_crop_modal: false,
      incoming_processed_crop_modal: false,
      outgoing_processed_crop_modal: false,
      delete_modal: false,
      incoming_crop: [],
      incoming_crop_id: "",
      outgoing_crop: [],
      outgoing_crop_id: "",
      total_weight: "",
      incoming_processed_crop: [],
      incoming_processed_crop_id: "",
      outgoing_processed_crop: [],
      outgoing_processed_crop_id: "",
      total_incoming_crop: "",
      total_incoming_processed_crop: "",
      total_outgoing_processed_crop: "",
      total_5_bags_incoming_processed_crop: "",
      total_10_bags_incoming_processed_crop: "",
      total_25_bags_incoming_processed_crop: "",
      total_50_bags_incoming_processed_crop: "",
      total_5_bags_outgoing_processed_crop: "",
      total_10_bags_outgoing_processed_crop: "",
      total_25_bags_outgoing_processed_crop: "",
      total_50_bags_outgoing_processed_crop: "",
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
      modals:{
        crop:false
      },
      crops: [],
      crop_id:"",
      crop:"",
      warehouseId: this.props.location.state.warehouseId,
      waybill_number: "",
      loader_name: "",
      loading_slip_number: ""
    };
    this.handleListIncomingPaddyPrice();
    this.handleListOutgoingPaddyPrice();
    this.handleListIncomingHeadRice();
    this.handleListOutgoingHeadRice();
    this.handleListCrop()
    console.log(this.state.warehouseId)
    //console.log(this.props.location.state.warehouse_id)
  }

  componentDidMount() {
    document.title = "Warehouse Detail";
  }

  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleListCrop = () => {
    if (navigator.onLine) {
      list_crop_live().then((data, res) => {
        if (!data) {
          return;
        } else {
          console.log(data);
          this.setState({ crops: data });
          console.log(this.state.crops);
        }
      });
    } else if (!navigator.onLine) {
    }
  };

  handleIntakeModal = () => {
    this.setState((prevState) => ({
      line_modal: !prevState.line_modal,
    }));
  };

  handleCategoryModal = () => {
    this.setState((prevState) => ({
      category_modal: !prevState.category_modal,
    }));
  };

  handleIncomingPaddyPriceModal = () => {
    this.setState((prevState) => ({
      incoming_crop_modal: !prevState.incoming_crop_modal,
    }));
  };

  // handleSubmitIncomingPaddyPrice = () => {
  //   const jwt = auth.isAuthenticated();
  //   const incoming_crop = {
  //     deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
  //     warehouseId: this.state.warehouseId,
  //     quantity: this.state.quantity,
  //     grn: this.state.grn,
  //     crop: this.state.crop_id,
  //     createdBy:
  //       auth.isAuthenticated().user.first_name +
  //       " " +
  //       auth.isAuthenticated().user.last_name,
  //   };
  //   create_incoming_crop_live(incoming_crop, {
  //     t: jwt.token,
  //   }).then((data) => {
  //     console.log(data);
  //     if (!data) {
  //       return;
  //     }
  //     if (data) {
  //       this.handleListIncomingPaddyPrice();
  //       this.handleIncomingPaddyPriceModal();
  //       this.setState({
  //         quantity: "",
  //         grn: "",
  //         req: "",
  //         category: "",
  //         line: "",
  //         success_notification: true,
  //         mssg: "Incoming Crop Created Successfully",
  //         error_notification: "",
  //       });
  //       setTimeout(() => this.setState({ success_notification: false }), 3000);
  //       /*
	// 			const incoming_crop = {
	// 				liveId: data.data._id,
	// 				deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
	// 				warehouseId: this.state.warehouseId,
	// 				quantity: this.state.quantity,
	// 				grn: this.state.grn,
	// 				createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
	// 			};
	// 			create_incoming_crop(incoming_crop, {
	// 				t: jwt.token,
	// 			}).then(data => {
	// 				console.log(data);
	// 				if (!data) {
	// 					return;
	// 				} if(data) {
	// 					this.handleListIncomingPaddyPrice();
	// 					this.handleIncomingPaddyPriceModal();
		
	// 			this.setState({
	// 				quantity: '',
	// 				grn: '',
	// 				req: '',
	// 				category: '',
	// 				line: '',
	// 			});
	// 				}
	// 			});
	// 			*/
  //     }
  //     if (data.error) {
  //       this.handleListIncomingPaddyPrice();
  //       this.setState({
  //         loading: false,
  //         error_notification: true,
  //         mssg: "Could not Create Incoming Crop",
  //       });
  //       setTimeout(() => this.setState({ error_notification: false }), 3000);
  //     }
  //   });
  // };

  // handleDeleteIncomingPaddyPrice = () => {
  //   remove_incoming_crop({
  //     _id: this.state.incoming_crop_id,
  //   }).then((data) => {
  //     if (data.error) {
  //       console.log(data.error);
  //     } else {
  //       this.handleListIncomingPaddyPrice();
  //       this.setState({
  //         success_notification: true,
  //         mssg: "Incoming Crop Deleted Successfully",
  //         error_notification: "",
  //       });
  //       setTimeout(() => this.setState({ success_notification: false }), 3000);
  //     }
  //   });

  //   this.setState({ delete_modal: false, deleteModal: "" });
  // };

  handleListIncomingPaddyPrice = () => {
    list_shipment_incoming_warehouse_live().then((data) => {
      if (!data) {
        return;
      } else if (data.length !== 0) {
        console.log(data);
        let paddy = [];
        let incoming_crop_for_warehouse = data.filter(
          (incoming_crop) =>
            incoming_crop.warehouse_id === this.state.warehouseId
        );
        incoming_crop_for_warehouse.map((data) =>
          paddy.push(Number(data.number_of_bags))
        );
        let total = paddy.reduce((a, b) => a + b, 0) / 1000;
        this.setState({
          incoming_crop: incoming_crop_for_warehouse,
          total_incoming_crop: total,
        });
      } else if (data.length === 0) {
        this.setState({ incoming_crop: [], total_incoming_crop: 0 });
      }
    });

  };

  handleOutgoingPaddyPriceModal = () => {
    this.setState((prevState) => ({
      outgoing_crop_modal: !prevState.outgoing_crop_modal,
    }));
  };

  handleSubmitOutgoingPaddyPrice = () => {
    const jwt = auth.isAuthenticated();
    const outgoing_crop = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      warehouseId: this.state.warehouseId,
      quantity: this.state.quantity,
      line: this.state.line,
      total_weight:this.state.total_weight,
      req: this.state.req,
      crop: this.state.crop_id,
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
    };
    create_outgoing_crop_live(outgoing_crop, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListOutgoingPaddyPrice();
        this.handleOutgoingPaddyPriceModal();

        this.setState({
          quantity: "",
          grn: "",
          req: "",
          category: "",
          line: "",
          success_notification: "true",
          mssg: "Outgoing Crop Created Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        /*
				const outgoing_crop = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					warehouseId: this.state.warehouseId,
					quantity: this.state.quantity,
					line: this.state.line,
					req: this.state.req,
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
				};
				create_outgoing_crop(outgoing_crop, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (data.error) {
						this.setState({ error: data.error });
					} else {
						this.handleListOutgoingPaddyPrice();
					}
				});
				this.handleOutgoingPaddyPriceModal();

				this.setState({
					quantity: '',
					grn: '',
					req: '',
					category: '',
					line: '',
				});
				*/
      }
      if (data.error) {
        this.handleListOutgoingPaddyPrice();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not Create Outgoing Crop",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
    });
  };

  handleDeleteOutgoingPaddyPrice = () => {
    remove_outgoing_crop({
      _id: this.state.outgoing_crop_id,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
        this.handleListOutgoingPaddyPrice();
        this.setState({
          error_notification: true,
          mssg: "Could not Delete Outgoing Crop",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else {
        this.handleListOutgoingPaddyPrice();
        this.setState({
          success_notification: true,
          mssg: "Outgoing Crop Deleted Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
    });

    this.setState({ delete_modal: false, deleteModal: "" });
  };

  handleListOutgoingPaddyPrice = () => {
    list_outgoing_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      }
      if (data.length !== 0) {
        console.log(data)
        let paddy = [];
        let outgoing_crop_for_warehouse = data.filter(
          (outgoing_crop) =>
            outgoing_crop.warehouseId === this.state.warehouseId
        );
        console.log(outgoing_crop_for_warehouse)
        outgoing_crop_for_warehouse.map((data) =>
          paddy.push(Number(data.quantity))
        );
        let total = paddy.reduce((a, b) => a + b, 0) / 1000;
        this.setState({
          outgoing_crop: outgoing_crop_for_warehouse,
          total_outgoing_crop: total,
        });
      }
      if (data.length === 0) {
        this.setState({ outgoing_crop: [], total_outgoing_crop: 0 });
      }
    });
  };

  handleIncomingHeadRiceModal = () => {
    this.setState((prevState) => ({
      incoming_processed_crop_modal: !prevState.incoming_processed_crop_modal,
    }));
  };

  handleSubmitIncomingHeadRice = () => {
    const jwt = auth.isAuthenticated();
    const incoming_processed_crop = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      warehouseId: this.state.warehouseId,
      quantity: this.state.quantity,
      grn: this.state.grn,
      category: this.state.category,
      total_weight: this.state.total_weight,
      crop:  this.state.crop_id,
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
    };
    create_incoming_processed_crop_live(incoming_processed_crop, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListIncomingHeadRice();
        this.handleIncomingHeadRiceModal();

        this.setState({
          quantity: "",
          grn: "",
          req: "",
          category: "",
          line: "",
          success_notification: true,
          mssg: "Incoming Processed Crop Created Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        /*
				const incoming_processed_crop = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					warehouseId: this.state.warehouseId,
					quantity: this.state.quantity,
					grn: this.state.grn,
					category: this.state.category,
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
				};
				create_incoming_processed_crop(incoming_processed_crop, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					}
					if (data) {
						this.handleListIncomingHeadRice();
					}
				});
				this.handleIncomingHeadRiceModal();

				this.setState({
					quantity: '',
					grn: '',
					req: '',
					category: '',
					line: '',
				});
				*/
      }
      if (data.error) {
        this.handleListIncomingHeadRice();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not create Incoming Processed Crop",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
    });
  };

  handleDeleteIncomingHeadRice = () => {
    remove_incoming_processed_crop_live({
      _id: this.state.incoming_processed_crop_id,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
        this.handleSubmitIncomingHeadRice();
        this.setState({
          error_notification: true,
          mssg: "Could not Delete Head Rice",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else {
        this.handleListIncomingHeadRice();
        this.setState({
          success_notification: true,
          mssg: "Incoming Processed Crop Deleted Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
    });

    this.setState({ delete_modal: false, deleteModal: "" });
  };

  handleListIncomingHeadRice = () => {
    list_incoming_processed_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else if (data.length !== 0) {
        let incoming_processed_crop_for_warehouse = data.filter(
          (incoming_processed_crop) =>
            incoming_processed_crop.warehouseId ===
            this.state.warehouseId
        );
        console.log(incoming_processed_crop_for_warehouse)
        let head_rice = [];
        let total_5_bags_incoming_processed_crop = [];
        let total_10_bags_incoming_processed_crop = [];
        let total_25_bags_incoming_processed_crop = [];
        let total_50_bags_incoming_processed_crop = [];
        data.map((data) => {
         head_rice.push(Number(data.quantity));
          if (data.category === "5") {
            total_5_bags_incoming_processed_crop.push(Number(data.quantity));
          }
          if (data.category === "10") {
            total_10_bags_incoming_processed_crop.push(Number(data.quantity));
          }
          if (data.category === "25") {
            total_25_bags_incoming_processed_crop.push(Number(data.quantity));
          }
          if (data.category === "50") {
            total_50_bags_incoming_processed_crop.push(Number(data.quantity));
          }
        });
        let total = head_rice.reduce((a, b) => a + b, 0);
        this.setState({
          incoming_processed_crop: incoming_processed_crop_for_warehouse,
          total_incoming_processed_crop: total,
          total_5_bags_incoming_processed_crop: total_5_bags_incoming_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_10_bags_incoming_processed_crop: total_10_bags_incoming_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_25_bags_incoming_processed_crop: total_25_bags_incoming_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_50_bags_incoming_processed_crop: total_50_bags_incoming_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
        });
      } else if (data.length === 0) {
        this.setState({
          incoming_processed_crop: [],
          total_incoming_processed_crop: 0,
          total_5_bags_incoming_processed_crop: 0,
          total_10_bags_incoming_processed_crop: 0,
          total_25_bags_incoming_processed_crop: 0,
          total_50_bags_incoming_processed_crop: 0,
        });
      }
    });
  };

  handleOutgoingHeadRiceModal = () => {
    this.setState((prevState) => ({
      outgoing_processed_crop_modal: !prevState.outgoing_processed_crop_modal,
    }));
  };

  handleSubmitOutgoingHeadRice = () => {
    const jwt = auth.isAuthenticated();
    const outgoing_processed_crop = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      warehouseId: this.state.warehouseId,
      quantity: this.state.quantity,
      category: this.state.category,
      total_weight:this.state.total_weight,
      req: this.state.req,
      crop: this.state.crop_id,
      loader_name: this.state.loader_name,
      loading_slip_number: this.state.loading_slip_number,
      waybill_number: this.state.waybill_number,
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
    };
    create_outgoing_processed_crop_live(outgoing_processed_crop, {
      t: jwt.token,
    }).then((data) => {
      console.log(data);
      if (!data) {
        return;
      }
      if (data) {
        this.handleListOutgoingHeadRice();
        this.handleOutgoingHeadRiceModal();

        this.setState({
          quantity: "",
          grn: "",
          req: "",
          category: "",
          line: "",
          success_notification: "true",
          mssg: "Created Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);

        /*
				const outgoing_processed_crop = {
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					warehouseId: this.state.warehouseId,
					quantity: this.state.quantity,
					category: this.state.category,
					req: this.state.req,
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
				};
				create_outgoing_processed_crop(outgoing_processed_crop, {
					t: jwt.token,
				}).then(data => {
					console.log(data);
					if (!data) {
						return;
					} else {
						this.handleListOutgoingHeadRice();
					}
				});
				this.handleOutgoingHeadRiceModal();

				this.setState({
					quantity: '',
					grn: '',
					req: '',
					category: '',
					line: '',
				});
				*/
      }
      if (data.error) {
        this.handleListOutgoingHeadRice();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could Not Create",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleModal();
      }
    });
  };

  handleDeleteOutgoingHeadRice = () => {
    remove_outgoing_processed_crop({
      _id: this.state.outgoing_processed_crop_id,
    }).then((data) => {
      if (data.error) {
        console.log(data.error);
        this.setState({
          error_notification: true,
          mssg: "Could not Delete",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else {
        this.handleListOutgoingHeadRice();
        this.setState({
          success_notification: "true",
          mssg: "Delete Successfully",
          error_notification: "",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
      }
    });

    this.setState({ delete_modal: false, deleteModal: "" });
  };

  handleListOutgoingHeadRice = () => {
    list_outgoing_processed_crop_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else if (data.length !== 0) {
        let outgoing_processed_crop_for_warehouse = data.filter(
          (outgoing_processed_crop) =>
            outgoing_processed_crop.warehouseId ===
            this.state.warehouseId
        );
          console.log(outgoing_processed_crop_for_warehouse)
        let head_rice = [];
        let total_5_bags_outgoing_processed_crop = [];
        let total_10_bags_outgoing_processed_crop = [];
        let total_25_bags_outgoing_processed_crop = [];
        let total_50_bags_outgoing_processed_crop = [];
        data.map((data2) => {
          head_rice.push(Number(data2.quantity));
          if (data2.category === "5") {
            total_5_bags_outgoing_processed_crop.push(Number(data2.quantity));
          }
          if (data2.category === "10") {
            total_10_bags_outgoing_processed_crop.push(Number(data2.quantity));
          }
          if (data2.category === "25") {
            total_25_bags_outgoing_processed_crop.push(Number(data2.quantity));
          }
          if (data2.category === "50") {
            total_50_bags_outgoing_processed_crop.push(Number(data2.quantity));
          }
        });
        
        let total = head_rice.reduce((a, b) => a + b, 0);
        this.setState({
          outgoing_processed_crop: outgoing_processed_crop_for_warehouse,
          total_outgoing_processed_crop: total,
          total_5_bags_outgoing_processed_crop: total_5_bags_outgoing_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_10_bags_outgoing_processed_crop: total_10_bags_outgoing_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_25_bags_outgoing_processed_crop: total_25_bags_outgoing_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
          total_50_bags_outgoing_processed_crop: total_50_bags_outgoing_processed_crop.reduce(
            (a, b) => a + b,
            0
          ),
        });
      } else if (data.length === 0) {
        this.setState({
          outgoing_processed_crop: [],
          total_outgoing_processed_crop: 0,
          total_5_bags_outgoing_processed_crop: 0,
          total_10_bags_outgoing_processed_crop: 0,
          total_25_bags_outgoing_processed_crop: 0,
          total_50_bags_outgoing_processed_crop: 0,
        });
      }
    });
  };

  render() {
    return (
      <div className="wrapper ">
        <SideBar />

        <div className="main-panel">
          <Nav />
          <div className="content">
            {this.state.success_notification ? (
              <div className="alert alert-success">
                <button
                  onClick={() => this.setState({ success_notification: false })}
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
                <span>{this.state.mssg} </span>
              </div>
            ) : this.state.error_notification ? (
              <div className="alert alert-danger">
                <button
                  onClick={() => this.setState({ error_notification: false })}
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                </button>
                <span>{this.state.mssg} </span>
              </div>
            ) : (
              ""
            )}
            <Link className="btn btn-primary btn-round" to="/warehouses">
              Go Back
            </Link>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-stats">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">filter_vintage</i>
                      </div>
                      <p className="card-category">Crop Balance</p>
                      <h3 className="card-title">
                        {(
                          this.state.total_incoming_crop -
                          this.state.total_outgoing_crop
                        ).toFixed(2)}{" "}
                        Tons
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card card-stats">
                    <div className="card-header card-header-info card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">shopping_basket</i>
                      </div>
                      <p className="card-category">Processed Crop Balance</p>
                      <h3 className="card-title">
                        {(
                          this.state.total_incoming_processed_crop -
                          this.state.total_outgoing_processed_crop
                        ).toFixed(2)}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_down</i>
                      </div>
                      <p className="card-category">Total Incoming Crop</p>
                      <h3 className="card-title">
                        {this.state.total_incoming_crop} Tons
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_up</i>
                      </div>
                      <p className="card-category">Total Outgoing Crop</p>
                      <h3 className="card-title">
                        {this.state.total_outgoing_crop} Tons
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_down</i>
                      </div>
                      <p className="card-category">Total Incoming Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_incoming_processed_crop} Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_up</i>
                      </div>
                      <p className="card-category">Total Outgoing Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_outgoing_processed_crop} Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats">
                        <p>Showing all data</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">view_stream</i>
                      </div>
                      <p className="card-category">5Kg Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_5_bags_incoming_processed_crop -
                          this.state.total_5_bags_outgoing_processed_crop}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">view_week</i>
                      </div>
                      <p className="card-category">10Kg Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_10_bags_incoming_processed_crop -
                          this.state.total_10_bags_outgoing_processed_crop}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">view_quilt</i>
                      </div>
                      <p className="card-category">25Kg Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_25_bags_incoming_processed_crop -
                          this.state.total_25_bags_outgoing_processed_crop}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  <div className="card card-stats">
                    <div className="card-header card-header-warning card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">view_module</i>
                      </div>
                      <p className="card-category">50Kg Processed Crop</p>
                      <h3 className="card-title">
                        {this.state.total_50_bags_incoming_processed_crop -
                          this.state.total_50_bags_outgoing_processed_crop}{" "}
                        Bags
                      </h3>
                    </div>
                    <div className="card-footer">
                      <div className="stats" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="stats">
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle btn btn-sm btn-primary btn-round btn-block"
                      type="button"
                      id="dropdownSeasonButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter Data
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownSeasonButton"
                    >
                      <h6 className="dropdown-header">Periods</h6>
                      <a
                        className="dropdown-item"
                        href="/warehouse/warehouses/1/?period=this_month"
                      >
                        This Month
                      </a>
                      <a
                        className="dropdown-item"
                        href="/warehouse/warehouses/1/?period=last_month"
                      >
                        Last Month
                      </a>
                      <a
                        className="dropdown-item"
                        href="/warehouse/warehouses/1/?period=last_2_months"
                      >
                        Last 2 Months
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_down</i>
                      </div>
                      <h4 className="card-title">Incoming Crop</h4>
                    </div>
                    <div className="card-body">
                      {/* <div className="toolbar">
                        <button
                          className="btn btn-success btn-round"
                          data-toggle="modal"
                          data-target="#createIncomingPaddyModal"
                          onClick={this.handleIncomingPaddyPriceModal}
                        >
                          Record Incoming Crop
                        </button>
                      </div> */}
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          {/* <h3>Nothing to see here yet.</h3> */}
                          <div
                            id="incoming_crop_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <div
                                  className="dataTables_length"
                                  id="incoming_crop_length"
                                >
                                  <label>
                                    Show{" "}
                                    <select
                                      name="incoming_crop_length"
                                      aria-controls="incoming_crop"
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>{" "}
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <div
                                  id="incoming_crop_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    Search:
                                    <span className="bmd-form-group bmd-form-group-sm">
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="incoming_crop"
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="incoming_crop"
                                  className="table table-striped table-no-bordered table-hover dataTable"
                                  cellSpacing="0"
                                  width="100%"
                                  style={{ width: "100%" }}
                                  role="grid"
                                  aria-describedby="incoming_crop_info"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Kgs): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Number of Bags 
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Kgs): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Total Weight (Kgs)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Crop: activate to sort column ascending"
                                        style={{ width: "35px" }}
                                      >
                                        Crop
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="GRN: activate to sort column ascending"
                                        style={{ width: "35px" }}
                                      >
                                        GRN
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Created By: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Created By
                                      </th>
                                      <th
                                        className=" text-right"
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Date Created: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right "
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Actions: activate to sort column ascending"
                                        style={{ width: "57px" }}
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <th rowSpan="1" colSpan="1">
                                        Number of Bags
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Total Weight (Kg)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Crop
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        GRN
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Created By
                                      </th>
                                      <th className="text-right" rowSpan="1" colSpan="1">
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </tfoot>
                                  <tbody>
                                    {this.state.incoming_crop.map((data) => (
                                      <tr>
                                        <td className="_1">
                                          {data.number_of_bags} Bags
                                        </td>
                                        <td className="_1">
                                          {data.total_weight} Kg
                                        </td>
                                        <td>{data.crop.name}</td>
                                        <td>{data.grn}</td>
                                        <td>{data.createdBy}</td>
                                        <td className="text-right">
                                        <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                        </td>
                                        {/* <td className="text-right inline-block">
                                          <div>
                                            <input
                                              type="hidden"
                                              name="csrfmiddlewaretoken"
                                              value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                            />
                                            <input
                                              type="hidden"
                                              name="delete_incoming_crop"
                                              value="1"
                                            />
                                            <button
                                              type="submit"
                                              className="btn btn-link btn-just-icon btn-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Delete Entry"
                                              onClick={() =>
                                                this.setState({
                                                  incoming_crop_id: data._id,
                                                  delete_modal: true,
                                                  deleteModal: "incoming_crop",
                                                })
                                              }
                                            >
                                              <i className="material-icons">
                                                clear
                                              </i>
                                            </button>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-5">
                                <div
                                  className="dataTables_info"
                                  id="incoming_crop_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing 0 to 0 of 0 entries
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-7">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="incoming_crop_paginate"
                                >
                                  <ul className="pagination">
                                    <li
                                      className="paginate_button page-item previous disabled"
                                      id="incoming_crop_previous"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="incoming_crop"
                                        data-dt-idx="0"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Previous
                                      </a>
                                    </li>
                                    <li
                                      className="paginate_button page-item next disabled"
                                      id="incoming_crop_next"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="incoming_crop"
                                        data-dt-idx="1"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_up</i>
                      </div>
                      <h4 className="card-title">Outgoing Crop</h4>
                    </div>
                    <div className="card-body">
                      <div className="toolbar">
                        <button
                          className="btn btn-rose btn-round"
                          data-toggle="modal"
                          data-target="#createOutgoingPaddyModal"
                          onClick={this.handleOutgoingPaddyPriceModal}
                        >
                          Record Outgoing Crop
                        </button>
                      </div>
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          {/* <h3>Nothing to see here yet.</h3> */}
                          <div
                            id="outgoing_crop_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <div
                                  className="dataTables_length"
                                  id="outgoing_crop_length"
                                >
                                  <label>
                                    Show{" "}
                                    <select
                                      name="outgoing_crop_length"
                                      aria-controls="outgoing_crop"
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>{" "}
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <div
                                  id="outgoing_crop_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    Search:
                                    <span className="bmd-form-group bmd-form-group-sm">
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="outgoing_crop"
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="outgoing_crop"
                                  className="table table-striped table-no-bordered table-hover dataTable"
                                  cellSpacing="0"
                                  width="100%"
                                  style={{ width: "100%" }}
                                  role="grid"
                                  aria-describedby="outgoing_crop_info"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Number of Bags: activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Number of Bags 
                                      </th>
                                       <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Total weight: activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Total Weight(Kg)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Crop: activate to sort column ascending"
                                        style={{ width: "32px" }}
                                      >
                                        Crop
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="REQ: activate to sort column ascending"
                                        style={{ width: "32px" }}
                                      >
                                        REQ
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Intake: activate to sort column ascending"
                                        style={{ width: "46px" }}
                                      >
                                        Intake
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Created By: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Created By
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Date Created: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right "
                                        tabIndex="0"
                                        aria-controls="outgoing_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Actions: activate to sort column ascending"
                                        style={{ width: "57px" }}
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <th rowSpan="1" colSpan="1">
                                        Number of Bags
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Total Weight (Kg)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Crop
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        REQ
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Intake
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Created By
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </tfoot>
                                  <tbody>
                                    {this.state.outgoing_crop.map((data) => (
                                      <tr>
                                        <td className="_1">
                                          {data.quantity} Bags
                                        </td>
                                        <td className="_1">
                                          {data.total_weight} Kg
                                        </td>
                                    <td>{data.crop.name}</td>
                                        <td>{data.req}</td>
                                        <td>{data.line}</td>
                                        <td>{data.createdBy}</td>
                                        <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                        </td>
                                        {/* <td className="text-right inline-block">
                                          <div>
                                            <input
                                              type="hidden"
                                              name="csrfmiddlewaretoken"
                                              value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                            />
                                            <input
                                              type="hidden"
                                              name="delete_incoming_crop"
                                              value="1"
                                            />
                                            <button
                                              type="submit"
                                              className="btn btn-link btn-just-icon btn-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Delete Entry"
                                              onClick={() =>
                                                this.setState({
                                                  outgoing_crop_id: data._id,
                                                  delete_modal: true,
                                                  deleteModal: "outgoing_crop",
                                                })
                                              }
                                            >
                                              <i className="material-icons">
                                                clear
                                              </i>
                                            </button>
                                          </div>
                                        </td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-5">
                                <div
                                  className="dataTables_info"
                                  id="outgoing_crop_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing 0 to 0 of 0 entries
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-7">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="outgoing_crop_paginate"
                                >
                                  <ul className="pagination">
                                    <li
                                      className="paginate_button page-item previous disabled"
                                      id="outgoing_crop_previous"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="outgoing_crop"
                                        data-dt-idx="0"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Previous
                                      </a>
                                    </li>
                                    <li
                                      className="paginate_button page-item next disabled"
                                      id="outgoing_crop_next"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="outgoing_crop"
                                        data-dt-idx="1"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header card-header-success card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_down</i>
                      </div>
                      <h4 className="card-title">Incoming Processed Crop</h4>
                    </div>
                    <div className="card-body">
                      <div className="toolbar">
                        <button
                          className="btn btn-success btn-round"
                          data-toggle="modal"
                          data-target="#createIncomingRiceModal"
                          onClick={this.handleIncomingHeadRiceModal}
                        >
                          Record Incoming Processed Crop
                        </button>
                      </div>
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          {/* <h3>Nothing to see here yet.</h3> */}
                          <div
                            id="incoming_rice_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <div
                                  className="dataTables_length"
                                  id="incoming_rice_length"
                                >
                                  <label>
                                    Show{" "}
                                    <select
                                      name="incoming_rice_length"
                                      aria-controls="incoming_rice"
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>{" "}
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <div
                                  id="incoming_rice_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    Search:
                                    <span className="bmd-form-group bmd-form-group-sm">
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="incoming_rice"
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="incoming_rice"
                                  className="table table-striped table-no-bordered table-hover dataTable"
                                  cellSpacing="0"
                                  width="100%"
                                  style={{ width: "100%" }}
                                  role="grid"
                                  aria-describedby="incoming_rice_info"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Bags): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Quantity (Bags)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Kgs): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Total Weight (Kgs)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Crop: activate to sort column ascending"
                                        style={{ width: "35px" }}
                                      >
                                        Crop
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="GRN: activate to sort column ascending"
                                        style={{ width: "35px" }}
                                      >
                                        GRN
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Category: activate to sort column ascending"
                                        style={{ width: "68px" }}
                                      >
                                        Category
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Created By: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Created By
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Date Created: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right "
                                        tabIndex="0"
                                        aria-controls="incoming_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Actions: activate to sort column ascending"
                                        style={{ width: "57px" }}
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <th rowSpan="1" colSpan="1">
                                        Quantity (Bags)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Total Weight (Kg)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Crop
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        GRN
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Category
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Created By
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </tfoot>
                                  <tbody>
                                    {this.state.incoming_processed_crop.map(
                                      (data) => (
                                        <tr>
                                          <td className="_1">
                                            {data.quantity} Bags
                                          </td>
                                          <td>{data.total_weight}Kgs</td>
                                      <td>{data.crop.name}</td>
                                          <td>{data.grn}</td>
                                          <td>{data.category}Kg</td>
                                          <td>{data.createdBy}</td>
                                          <td>
                                          <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                          </td>
                                          {/* <td className="text-right inline-block">
                                            <div>
                                              <input
                                                type="hidden"
                                                name="csrfmiddlewaretoken"
                                                value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                              />
                                              <input
                                                type="hidden"
                                                name="delete_incoming_crop"
                                                value="1"
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-link btn-just-icon btn-info"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Delete Entry"
                                                onClick={() =>
                                                  this.setState({
                                                    incoming_processed_crop_id:
                                                      data._id,
                                                    delete_modal: true,
                                                    deleteModal:
                                                      "incoming_processed_crop",
                                                  })
                                                }
                                              >
                                                <i className="material-icons">
                                                  clear
                                                </i>
                                              </button>
                                            </div>
                                          </td> */}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-5">
                                <div
                                  className="dataTables_info"
                                  id="incoming_rice_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing 0 to 0 of 0 entries
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-7">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="incoming_rice_paginate"
                                >
                                  <ul className="pagination">
                                    <li
                                      className="paginate_button page-item previous disabled"
                                      id="incoming_rice_previous"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="incoming_rice"
                                        data-dt-idx="0"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Previous
                                      </a>
                                    </li>
                                    <li
                                      className="paginate_button page-item next disabled"
                                      id="incoming_rice_next"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="incoming_rice"
                                        data-dt-idx="1"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header card-header-rose card-header-icon">
                      <div className="card-icon">
                        <i className="material-icons">trending_up</i>
                      </div>
                      <h4 className="card-title">Outgoing Processed Crop</h4>

                    </div>
                    <div className="card-body">
                      <div className="toolbar">
                        <button
                          className="btn btn-rose btn-round"
                          data-toggle="modal"
                          data-target="#createOutgoingRiceModal"
                          onClick={this.handleOutgoingHeadRiceModal}
                        >
                          Record Outgoing Processed Crop
                        </button>
                      </div>
                      <div className="material-datatables">
                        <div className="table-responsive-sm">
                          {/* <h3>Nothing to see here yet.</h3> */}
                          <div
                            id="outgoing_rice_wrapper"
                            className="dataTables_wrapper dt-bootstrap4"
                          >
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <div
                                  className="dataTables_length"
                                  id="outgoing_rice_length"
                                >
                                  <label>
                                    Show{" "}
                                    <select
                                      name="outgoing_rice_length"
                                      aria-controls="outgoing_rice"
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>{" "}
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <div
                                  id="outgoing_rice_filter"
                                  className="dataTables_filter"
                                >
                                  <label>
                                    Search:
                                    <span className="bmd-form-group bmd-form-group-sm">
                                      <input
                                        type="search"
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="outgoing_rice"
                                      />
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="outgoing_rice"
                                  className="table table-striped table-no-bordered table-hover dataTable"
                                  cellSpacing="0"
                                  width="100%"
                                  style={{ width: "100%" }}
                                  role="grid"
                                  aria-describedby="outgoing_rice_info"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Bags): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Quantity (Bags)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="incoming_crop"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-sort="ascending"
                                        aria-label="Quantity (Kgs): activate to sort column descending"
                                        style={{ width: "63px" }}
                                      >
                                        Total Weight (Kgs)
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Crop: activate to sort column ascending"
                                        style={{ width: "35px" }}
                                      >
                                        Crop
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="REQ: activate to sort column ascending"
                                        style={{ width: "32px" }}
                                      >
                                        REQ
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Created By: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Category
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Created By: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Created By
                                      </th>
                                      <th
                                        className=""
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Date Created: activate to sort column ascending"
                                        style={{ width: "59px" }}
                                      >
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right "
                                        tabIndex="0"
                                        aria-controls="outgoing_rice"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Actions: activate to sort column ascending"
                                        style={{ width: "57px" }}
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <th rowSpan="1" colSpan="1">
                                        Quantity (Bags)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Total Weight (Kg)
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Crop
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        REQ
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Category
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Created By
                                      </th>
                                      <th rowSpan="1" colSpan="1">
                                        Date Created
                                      </th>
                                      {/* <th
                                        className="disabled- text-right"
                                        rowSpan="1"
                                        colSpan="1"
                                      >
                                        Actions
                                      </th> */}
                                    </tr>
                                  </tfoot>
                                  <tbody>
                                    {this.state.outgoing_processed_crop.map(
                                      (data) => (
                                        <tr>
                                          <td className="_1">
                                            {data.quantity} Bags
                                          </td>
                                          <td>{data.total_weight}</td>
                                      <td>{data.crop.name}</td>
                                          <td>{data.req}</td>
                                          <td>{data.category}Kg</td>
                                          <td>{data.createdBy}</td>
                                          <td>
                                          <Moment format="D MMM YYYY" withTitle>
                                        {data.created}
                                      </Moment>
                                          </td>
                                          {/* <td className="text-right inline-block">
                                            <div>
                                              <input
                                                type="hidden"
                                                name="csrfmiddlewaretoken"
                                                value="oyjdf73X1mVoyWpJ3ZHigjunWjBHL5T0bKcqEypVOVO4hBappkKPWAJzCR6nSK3z"
                                              />
                                              <input
                                                type="hidden"
                                                name="delete_incoming_crop"
                                                value="1"
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-link btn-just-icon btn-info"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Delete Entry"
                                                onClick={() =>
                                                  this.setState({
                                                    outgoing_processed_crop_id:
                                                      data._id,
                                                    delete_modal: true,
                                                    deleteModal:
                                                      "outgoing_processed_crop",
                                                  })
                                                }
                                              >
                                                <i className="material-icons">
                                                  clear
                                                </i>
                                              </button>
                                            </div>
                                          </td> */}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-5">
                                <div
                                  className="dataTables_info"
                                  id="outgoing_rice_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Showing 0 to 0 of 0 entries
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-7">
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="outgoing_rice_paginate"
                                >
                                  <ul className="pagination">
                                    <li
                                      className="paginate_button page-item previous disabled"
                                      id="outgoing_rice_previous"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="outgoing_rice"
                                        data-dt-idx="0"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Previous
                                      </a>
                                    </li>
                                    <li
                                      className="paginate_button page-item next disabled"
                                      id="outgoing_rice_next"
                                    >
                                      <a
                                        href="#/"
                                        aria-controls="outgoing_rice"
                                        data-dt-idx="1"
                                        tabIndex="0"
                                        className="page-link"
                                      >
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!this.state.incoming_crop_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="createLoadedModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{ display: "block", paddingRight: "15px" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Record Incoming Crop</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleIncomingPaddyPriceModal}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                        />
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                              />
                              <div
                                className={
                                  this.state.quantity
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Quantity in Bags e.g. 32450
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      quantity: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.grn
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="req"
                                  className="bmd-label-floating"
                                >
                                  Enter GRN e.g. 001
                                </label>
                                <input
                                onChange={(event) =>
                                  this.setState({ grn: event.target.value })
                                }
                                  name="req"
                                  type="text"
                                  className="form-control"
                                  id="req"
                                  value={this.state.grn}
                                  
                                />
                              </div>
                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                            </div>
                            <div className="card-footer justify-content-center">
                              <input
                                type="hidden"
                                name="create_loaded_rice"
                                value="1"
                              />
                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                onClick={this.handleSubmitIncomingPaddyPrice}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.outgoing_crop_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="createLoadedModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{ display: "block", paddingRight: "15px" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Record Outgoing Crop</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleOutgoingPaddyPriceModal}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                        />
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                              />
                              <div
                                className={
                                  this.state.quantity
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Number of Bags e.g. 32450
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      quantity: event.target.value,
                                    })
                                  }
                                />
                              </div>
                               <div
                                className={
                                  this.state.total_weight
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Total weight e.g. 5000Kg
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      total_weight: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.req
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Req e.g. 001
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      req: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select dropup">
                                  <select
                                    required=""
                                    name="intake"
                                    className="selectpicker"
                                    data-size="4"
                                    data-style="btn btn-round btn-rose"
                                    title="Choose Intake"
                                    tabIndex="-98"
                                  >
                                    <option
                                      className="bs-title-option"
                                      defaultValue="Choose Intake"
                                    />
                                    <option disabled="">Choose Intake</option>
                                    <option value="Line 1">Line 1</option>
                                    <option value="Line 2">Line 2</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-round btn-rose"
                                    data-toggle="dropdown"
                                    title="Choose Intake"
                                    aria-expanded="false"
                                    onClick={this.handleIntakeModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.line
                                            ? "Choose Intake"
                                            : this.state.line}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.line_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="top-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="false"
                                      tabIndex="-1"
                                      style={{ overflowY: "auto" }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              !this.state.line
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Intake
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.line !== "Line 1"
                                                ? "dropdown-item"
                                                : "dropdown-item show"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                line: "Line 1",
                                                line_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">Line 1</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.line !== "Line 2"
                                                ? "dropdown-item"
                                                : "dropdown-item show"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                line: "Line 2",
                                                line_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">Line 2</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer justify-content-center">
                              <input
                                type="hidden"
                                name="create_loaded_rice"
                                value="1"
                              />
                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                onClick={this.handleSubmitOutgoingPaddyPrice}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.incoming_processed_crop_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="createLoadedModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{ display: "block", paddingRight: "15px" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">
                          Record Incoming Processed Crop
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleIncomingHeadRiceModal}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                        />
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                              />
                              <div
                                className={
                                  this.state.quantity
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Quantity in Bags e.g. 32450
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      quantity: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.total_weight
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Total weight e.g. 5000Kg
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      total_weight: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.grn
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="req"
                                  className="bmd-label-floating"
                                >
                                  Enter GRN e.g. 001
                                </label>
                                <input
                                onChange={(event) =>
                                  this.setState({ grn: event.target.value })
                                }
                                  name="req"
                                  type="text"
                                  className="form-control"
                                  id="req"
                                  value={this.state.grn}
                                  
                                />
                              </div>

                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select show">
                                  <select
                                    required=""
                                    name="category"
                                    className="selectpicker"
                                    data-size="4"
                                    data-style="btn btn-round btn-rose"
                                    title="Choose Category"
                                    tabIndex="-98"
                                  >
                                    <option
                                      className="bs-title-option"
                                      defaultValue="Choose Category"
                                    />
                                    <option disabled="">Choose Category</option>
                                    <option value="5Kg">5Kg</option>
                                    <option value="10Kg">10Kg</option>
                                    <option value="25Kg">25Kg</option>
                                    <option value="50Kg">50Kg</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-round btn-rose"
                                    data-toggle="dropdown"
                                    title="Choose Category"
                                    aria-expanded="true"
                                    onClick={this.handleCategoryModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.category
                                            ? "Choose Category"
                                            : `${this.state.category}Kg`}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.category_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              !this.state.category
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Category
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "5"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "5",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">5Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "10"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "10",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">10Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "25"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "25",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">25Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "50"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "50",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">50Kg</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer justify-content-center">
                              <input
                                type="hidden"
                                name="create_loaded_rice"
                                value="1"
                              />
                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                onClick={this.handleSubmitIncomingHeadRice}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.outgoing_processed_crop_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="createLoadedModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{ display: "block", paddingRight: "15px" }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">
                          Record Outgoing Processed Crop
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleOutgoingHeadRiceModal}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="hidden"
                          name="csrfmiddlewaretoken"
                          value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                        />
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <input
                                type="hidden"
                                name="csrfmiddlewaretoken"
                                value="TkbB78eMccBHEz8DHyGxXwZGb1TQ9LluGw4OwzAKZLunneTj3TJ4DNeSRzowgqv3"
                              />
                              <div
                                className={
                                  this.state.quantity
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Quantity in Bags e.g. 32450
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      quantity: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.total_weight
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Total weight e.g. 5000Kg
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      total_weight: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className={
                                  this.state.req
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Req e.g. 001
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      req: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              
                              <div
                                className={
                                  this.state.loader_name
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Loader's Name
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      loader_name: event.target.value,
                                    })
                                  }
                                />
                              </div>

                              <div
                                className={
                                  this.state.waybill_number
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Waybill Number
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      waybill_number: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              
                              <div
                                className={
                                  this.state.loading_slip_number
                                    ? "is-filled form-group bmd-form-group"
                                    : `form-group bmd-form-group `
                                }
                              >
                                <label
                                  htmlFor="bags"
                                  className="bmd-label-floating"
                                >
                                  Enter Loading Slip Number
                                </label>
                                <input
                                  required=""
                                  name="bags"
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  id="bags"
                                  onChange={(event) =>
                                    this.setState({
                                      loading_slip_number: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div
                            className={
                              !this.state.modals.crop
                                ? "dropdown bootstrap-select"
                                : "dropdown bootstrap-select show"
                            }
                          >
                            <button
                              type="button"
                              className="dropdown-toggle btn btn-success"
                              data-toggle="dropdown"
                              aria-expanded="true"
                              onClick={() => this.handleModal("crop")}
                            >
                              <div className="filter-option">
                                <div className="filter-option-inner">
                                  <div className="filter-option-inner-inner">
                                    {!this.state.crop
                                      ? "Choose Crop"
                                      : this.state.crop}
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="ripple-container" />
                            </button>
                            <div
                              className={
                                !this.state.modals.crop
                                  ? "dropdown-menu"
                                  : "dropdown-menu show"
                              }
                              role="combobox"
                              x-placement="bottom-start"
                              style={{
                                maxHeight: "162px",
                                minWidth: "250px",
                                overflow: "hidden",
                                position: "absolute",
                                top: "46px",
                                left: "1px",
                                willChange: "top, left",
                              }}
                            >
                              <div
                                className="inner show"
                                role="listbox"
                                aria-expanded="true"
                                tabIndex="-1"
                                style={{
                                  maxHeight: "152px",
                                  overflowY: "auto",
                                }}
                              >
                                <ul className="dropdown-menu inner show">
                                  <li className="disabled">
                                    <a
                                      role="option"
                                      className="dropdown-item disabled"
                                      aria-disabled="true"
                                      tabIndex="-1"
                                      aria-selected="false"
                                    >
                                      <span className=" bs-ok-default check-mark" />
                                      <span className="text">
                                        {this.state.crop || "Choose Crop"}
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.crops.map((data) => {
                                    console.log(data);

                                    return (
                                      <li key={data._id}>
                                        <a
                                          role="option"
                                          className={
                                            this.state.crop === ""
                                              ? "dropdown-item"
                                              : "dropdown-item selected active"
                                          }
                                          aria-disabled="false"
                                          tabIndex="0"
                                          aria-selected="false"
                                          onClick={() => {
                                            this.handleModal("crop");
                                            this.setState({
                                              crop: data.name,
                                              crop_id: data._id,
                                              cropmodal: false,
                                            });
                                          }}
                                        >
                                          <span className=" bs-ok-default check-mark" />
                                          <span className="text">
                                            {data.name}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>

                              <div className="form-group">
                                <div className="dropdown bootstrap-select show">
                                  <select
                                    required=""
                                    name="category"
                                    className="selectpicker"
                                    data-size="4"
                                    data-style="btn btn-round btn-rose"
                                    title="Choose Category"
                                    tabIndex="-98"
                                  >
                                    <option
                                      className="bs-title-option"
                                      defaultValue="Choose Category"
                                    />
                                    <option disabled="">Choose Category</option>
                                    <option value="5Kg">5Kg</option>
                                    <option value="10Kg">10Kg</option>
                                    <option value="25Kg">25Kg</option>
                                    <option value="50Kg">50Kg</option>
                                  </select>
                                  <button
                                    type="button"
                                    className="dropdown-toggle btn btn-round btn-rose"
                                    data-toggle="dropdown"
                                    title="Choose Category"
                                    aria-expanded="true"
                                    onClick={this.handleCategoryModal}
                                  >
                                    <div className="filter-option">
                                      <div className="filter-option-inner">
                                        <div className="filter-option-inner-inner">
                                          {!this.state.category
                                            ? "Choose Category"
                                            : `${this.state.category}Kg`}
                                        </div>
                                      </div>{" "}
                                    </div>
                                    <div className="ripple-container" />
                                  </button>
                                  <div
                                    className={
                                      !this.state.category_modal
                                        ? "dropdown-menu"
                                        : "dropdown-menu show"
                                    }
                                    role="combobox"
                                    x-placement="bottom-start"
                                    style={{
                                      maxHeight: "162px",
                                      overflow: "hidden",
                                      minWidth: "220px",
                                      position: "absolute",
                                      top: "46px",
                                      left: "1px",
                                      willChange: "top, left",
                                    }}
                                  >
                                    <div
                                      className="inner show"
                                      role="listbox"
                                      aria-expanded="true"
                                      tabIndex="-1"
                                      style={{
                                        maxHeight: "152px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <ul className="dropdown-menu inner show">
                                        <li className="disabled selected active">
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              !this.state.category
                                                ? "dropdown-item disabled selected active"
                                                : "dropdown-item disabled"
                                            }
                                            aria-disabled="true"
                                            tabIndex="-1"
                                            aria-selected="true"
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">
                                              Choose Category
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "5"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "5",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">5Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "10"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "10",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">10Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "25"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "25",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">25Kg</span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#/"
                                            role="option"
                                            className={
                                              this.state.category !== "50"
                                                ? "dropdown-item"
                                                : "dropdown-item active"
                                            }
                                            aria-disabled="false"
                                            tabIndex="0"
                                            aria-selected="false"
                                            onClick={() =>
                                              this.setState({
                                                category: "50",
                                                category_modal: false,
                                              })
                                            }
                                          >
                                            <span className=" bs-ok-default check-mark" />
                                            <span className="text">50Kg</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer justify-content-center">
                              <input
                                type="hidden"
                                name="create_loaded_rice"
                                value="1"
                              />
                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                onClick={this.handleSubmitOutgoingHeadRice}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!this.state.delete_modal ? (
                ""
              ) : (
                <div
                  className="modal fade show"
                  id="addCooperativeModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="myModalLabel"
                  style={{
                    display: "block",
                    paddingRight: "5px",
                    paddingTop: "100px",
                  }}
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Delete Recovery?</h4>
                        <button
                          onClick={() =>
                            this.setState({
                              delete_modal: false,
                              deleteModal: "",
                            })
                          }
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="card ">
                          <div className="card-body ">
                            <div>
                              Are you sure you want to delete this Warehouse
                            </div>

                            <button
                              type="submit"
                              onClick={
                                this.state.deleteModal === "incoming_crop"
                                  ? () => this.handleDeleteIncomingPaddyPrice()
                                  : this.state.deleteModal === "outgoing_crop"
                                  ? () => this.handleDeleteOutgoingPaddyPrice()
                                  : this.state.deleteModal ===
                                    "incoming_processed_crop"
                                  ? () => this.handleDeleteIncomingHeadRice()
                                  : this.state.deleteModal ===
                                    "outgoing_processed_crop"
                                  ? () => this.handleDeleteOutgoingHeadRice()
                                  : ""
                              }
                              className="btn btn-fill btn-success"
                            >
                              Delete
                            </button>
                            <button
                              type="submit"
                              className="btn btn-fill btn-danger"
                              onClick={() =>
                                this.setState({
                                  delete_modal: false,
                                  deleteModal: "",
                                })
                              }
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="btn btn-fill btn-primary"
                              id="create_offline"
                              style={{ display: "none" }}
                            >
                              Create Offline
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WareHouseDetail;
