import { fetchRequest } from "../utils";

export const create_payment_schedule = (payment_schedule) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_schedule/`,
    method: "POST",
    data: payment_schedule,
  });

export const list_payment_schedule = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_schedule/`,
  });

export const read_payment_schedule = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_schedule/` +
      params.payment_scheduleId,
  });

export const update_payment_schedule = (payment_schedule) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_schedule/`,
    method: "PUT",
    data: payment_schedule,
  });

export const remove_payment_schedule = (payment_schedule) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/payment_schedule/`,
    method: "DELETE",
    data: payment_schedule,
  });

export const create_payment_schedule_live = (payment_schedule) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_schedule/`,
    method: "POST",
    data: payment_schedule,
  });

export const read_payment_schedule_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_schedule/` +
      params.shipmentId,
  });

export const list_payment_schedule_live = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_schedule/`,
  });

export const update_payment_schedule_live = (payment_schedule, params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_schedule/` +
      params.payment_scheduleId,
    method: "PUT",
    data: payment_schedule,
  });

export const remove_payment_schedule_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/payment_schedule/` +
      params.payment_scheduleId,
    method: "DELETE",
  });

// export const create_deleted_payment_schedule_live = (payment_schedule) =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_payment_schedule/`,
//     method: "POST",
//     data: payment_schedule,
//   });

// export const list_deleted_payment_schedule_live = () =>
//   fetchRequest({
//     url: `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/deleted_payment_schedule/`,
//   });
