import React, { Component } from "react";
import SideBar from "./SideBar";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import {
  list_cooperative_live,
  update_cooperative_live,
} from "../../api/api-cooperative";

import Pdf from "react-to-pdf";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const ref = React.createRef();
class ExtensionSeasonCooperative extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      deletemodal: false,
      season_cooperatives: [],
      cooperative_modal: false,
      cooperative_name: "",
      cooperative_community: "",
      cooperative_district: "",
      cooperative_zone: "",
      cooperative_data: {},
      cooperative_id: "",
      season_id: "",
      type: "",
      start_month: "",
      end_month: "",
      year: "",
      non_id: "",
      non_season_cooperatives: [],
      search: "",
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
    };
    this.handleListCooperative();

    //this.handleListNonSeasonCooperative()
  }

  componentDidMount() {
    document.title = "Season Cooperative";
  }

  handleListCooperative = () => {
    list_cooperative_live().then((data) => {
      if (!data) {
        this.setState({
          mssg: `There's something wrong, reload this page or log out`,
          error_notification: true,
        });
        setTimeout(() => this.setState({ error_notification: false }), 5000);
      }
      if (data) {
        console.log(data);
        let season_cooperatives = data.filter(
          (cooperative) =>
            cooperative.seasonId === this.props.location.state.seasonLiveId
        );
        let non_season_cooperatives = data.filter(
          (cooperative) =>
            cooperative.seasonId !== this.props.location.state.seasonLiveId
        );
        this.setState({
          season_cooperatives: season_cooperatives,
          non_season_cooperatives: non_season_cooperatives,
        });
      }
    });
  };

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      deletemodal: !prevState.deletemodal,
    }));
  };

  handleCooperativeModal = () => {
    this.setState((prevState) => ({
      cooperative_modal: !prevState.cooperative_modal,
    }));
  };

  addCooperativeToSeason = () => {
    const jwt = auth.isAuthenticated();

    const cooperative = {
      seasonId: this.props.location.state.seasonLiveId,
    };

    update_cooperative_live(
      cooperative,
      { cooperativeId: this.state.cooperative_live_id },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (!data) {
        this.setState({
          error_notification: true,
          mssg: "Could not Delete Cooperative",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
      if (data) {
        console.log(data);
        this.handleListCooperative();
        this.setState({
          cooperative_name: "",
          cooperative_community: "",
          cooperative_district: "",
          cooperative_zone: "",
          success_notification: true,
          cooperative_modal: false,
          mssg: "Cooperative Added successfully",
        });
        setTimeout(() => this.setState({ success_notification: false }), 3000);
        this.handleModal();
        /*
				const cooperative = {
					_id: this.state.cooperative_live_id,
					name: this.state.cooperative_name,
					community: this.state.cooperative_community,
					zone: this.state.cooperative_zone,
                    district: this.state.cooperative_district,
					seasonId: this.props.location.state.seasonLiveId
				};
				
				update_cooperative(cooperative, {
					t: jwt.token,
				}).then(data => {
					if (!data) {
						return;
					} if(data) {
						this.handleListCooperative();
					}
					this.handleModal();
					this.setState({
						cooperative_name: "",
						cooperative_community: "",
						cooperative_district: "",
						cooperative_zone: ""
					})
				});
				*/
      }
    });
  };

  removeCooperativeFromSeason = () => {
    const jwt = auth.isAuthenticated();

    const cooperative = {
      seasonId: "",
    };

    update_cooperative_live(
      cooperative,
      { cooperativeId: this.state.cooperative_live_id },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (!data) {
        this.setState({
          error_notification: true,
          mssg: "Could not Delete Cooperative",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      }
      if (data) {
        this.handleListCooperative();
        this.handleDeleteModal();
        this.setState({
          cooperative_name: "",
          cooperative_community: "",
          cooperative_district: "",
          cooperative_zone: "",
          error_notification: true,
          mssg: "Cooperative Deleted Successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        /*
				console.log(this.state.cooperative_live_id)
				const cooperative = {
					_id: this.state.cooperative_live_id,
					name: this.state.cooperative_name,
					community: this.state.cooperative_community,
					zone: this.state.cooperative_zone,
                    district: this.state.cooperative_district,
					seasonId: ""
				};
				
				update_cooperative(cooperative, {
					t: jwt.token,
				}).then(data => {
					if (!data) {
						return;
					} if(data) {
						this.handleListCooperative();
					}
					this.handleDeleteModal();
					this.setState({
						cooperative_name: "",
						cooperative_community: "",
						cooperative_district: "",
						cooperative_zone: ""
					})
				});
				*/
      }
    });
  };

  render() {
    const { search } = this.state;
    const season_cooperatives = this.state.season_cooperatives.filter(
      (cooperative) => {
        return cooperative.name.toLowerCase().includes(search.toLowerCase());
      }
    );
    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel">
          <Nav />

          <div className="content">
            <div className="content">
              {this.state.success_notification ? (
                <div className="alert alert-success">
                  <button
                    onClick={() =>
                      this.setState({ success_notification: false })
                    }
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : this.state.error_notification ? (
                <div className="alert alert-danger">
                  <button
                    onClick={() => this.setState({ error_notification: false })}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : this.state.update_notification ? (
                <div className="alert alert-warning">
                  <button
                    onClick={() =>
                      this.setState({ update_notification: false })
                    }
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <i className="material-icons">close</i>
                  </button>
                  <span>{this.state.mssg} </span>
                </div>
              ) : (
                ""
              )}
              <Link
                className="btn btn-primary btn-round"
                to="/extension_season"
              >
                Go Back
              </Link>
              <div className="container-fluid">
                {!navigator.onLine ? (
                  <div id="offlinealert" className="alert alert-warning">
                    <span>You are not connected to the internet. </span>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header card-header-primary card-header-icon">
                        <div className="card-icon">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title">
                          {" "}
                          {this.props.location.state.type
                            .slice(0, 1)
                            .toUpperCase() +
                            "-" +
                            this.props.location.state.start_month
                              .slice(0, 3)
                              .toUpperCase() +
                            "-" +
                            this.props.location.state.end_month
                              .slice(0, 3)
                              .toUpperCase() +
                            "-" +
                            this.props.location.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}{" "}
                          - Cooperatives
                        </h4>
                      </div>
                      <div className="card-body">
                        <div onClick={this.handleModal}>
                          <button className="btn btn-primary btn-round">
                            Add Cooperative
                          </button>
                        </div>
                        <div className="material-datatables">
                          <div className="table-responsive-sm">
                            <div
                              id="cooperatives_wrapper"
                              className="dataTables_wrapper dt-bootstrap4"
                            >
                              <div className="dt-buttons btn-group">
                                {" "}
                                <Pdf
                                  targetRef={ref}
                                  filename="cooperatives.pdf"
                                >
                                  {({ toPdf }) => (
                                    <button
                                      className="btn btn-secondary buttons-pdf buttons-html5"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      type="button"
                                      onClick={toPdf}
                                    >
                                      <span>Export PDF</span>
                                    </button>
                                  )}
                                </Pdf>{" "}
                                <ReactHTMLTableToExcel
                                  id="test-table-xls-button"
                                  className="btn btn-secondary buttons-excel buttons-html5"
                                  table="cooperatives"
                                  filename="cooperativesxls"
                                  sheet="cooperativesxls"
                                  buttonText="Export Excel"
                                />{" "}
                              </div>
                              <div
                                id="cooperatives_filter"
                                className="dataTables_filter"
                              >
                                <label>
                                  Search:
                                  <span className="bmd-form-group bmd-form-group-sm">
                                    <input
                                      type="search"
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="cooperatives"
                                      onChange={(event) =>
                                        this.setState({
                                          search: event.target.value,
                                        })
                                      }
                                    />
                                  </span>
                                </label>
                              </div>
                              <table
                                title="W-APR-NOV-19 Cooperatives"
                                id="cooperatives"
                                className="table table-striped table-no-bordered table-hover dataTable"
                                cellSpacing="0"
                                width="100%"
                                style={{ width: "100%" }}
                                role="grid"
                                aria-describedby="cooperatives_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="sorting_asc"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-sort="ascending"
                                      aria-label="ID: activate to sort column descending"
                                      style={{ width: "57px" }}
                                    >
                                      ID
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Name: activate to sort column ascending"
                                      style={{ width: "52px" }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Type: activate to sort column ascending"
                                      style={{ width: "149px" }}
                                    >
                                      Address
                                    </th>
                                    {/* <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="District: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      State
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="District: activate to sort column ascending"
                                      style={{ width: "60px" }}
                                    >
                                      LGA
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Date Added: activate to sort column ascending"
                                      style={{ width: "171px" }}
                                    >
                                      Date Added
                                    </th>
                                    <th
                                      className="disabled-sorting text-right sorting"
                                      tabIndex="0"
                                      aria-controls="cooperatives"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Actions: activate to sort column ascending"
                                      style={{ width: "66px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tfoot>
                                  <tr>
                                    <th rowSpan="1" colSpan="1">
                                      ID
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Name
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Address
                                    </th>
                                    {/* <th rowSpan="1" colSpan="1">
                                      State
                                    </th> */}
                                    <th rowSpan="1" colSpan="1">
                                      LGA
                                    </th>
                                    <th rowSpan="1" colSpan="1">
                                      Date Added
                                    </th>
                                    <th
                                      className="text-right"
                                      rowSpan="1"
                                      colSpan="1"
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </tfoot>
                                <tbody>
                                  {season_cooperatives.map((data, id) => (
                                    <tr key={data._id}>
                                      <td>{id + 1}</td>
                                      <td>{data.name}</td>
                                      <td>{data.address}</td>
                                      {/* <td>{data.zone}</td> */}
                                      <td>{data.LGA}</td>
                                      <td>
                                        <Moment format="D MMM YYYY" withTitle>
                                          {data.created}
                                        </Moment>
                                      </td>
                                      <td className="text-right inline-block">
                                        <div>
                                          <input
                                            type="hidden"
                                            name="remove_cooperative"
                                          />
                                          <button
                                            type="submit"
                                            className="btn btn-link btn-just-icon btn-danger"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Remove Cooperative"
                                            onClick={() =>
                                              this.setState({
                                                cooperative_name: data.name,
                                                cooperative_community:
                                                  data.community,
                                                cooperative_zone: data.zone,
                                                cooperative_district:
                                                  data.district,
                                                deletemodal: true,
                                                cooperative_live_id: data._id,
                                              })
                                            }
                                          >
                                            <i className="material-icons">
                                              clear
                                            </i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className="dataTables_info"
                                id="cooperatives_info"
                                role="status"
                                aria-live="polite"
                              >
                                Showing 1 to 1 of 1 entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="cooperatives_paginate"
                              >
                                <ul className="pagination">
                                  <li
                                    className="paginate_button page-item previous disabled"
                                    id="cooperatives_previous"
                                  >
                                    <a
                                      
                                      aria-controls="cooperatives"
                                      data-dt-idx="0"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      
                                      aria-controls="cooperatives"
                                      data-dt-idx="1"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li
                                    className="paginate_button page-item next disabled"
                                    id="cooperatives_next"
                                  >
                                    <a
                                      
                                      aria-controls="cooperatives"
                                      data-dt-idx="2"
                                      tabIndex="0"
                                      className="page-link"
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.deletemodal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{
                      display: "block",
                      paddingRight: "5px",
                      paddingTop: "100px",
                    }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Remove this Cooperative from this season?
                          </h4>
                          <button
                            onClick={this.handleDeleteModal}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="card ">
                            <div className="card-body ">
                              <div>
                                Are you sure you want to remove this cooperative
                              </div>

                              <button
                                type="submit"
                                onClick={this.removeCooperativeFromSeason}
                                className="btn btn-fill btn-success"
                              >
                                Delete
                              </button>
                              <button
                                type="submit"
                                onClick={this.handleDeleteModal}
                                className="btn btn-fill btn-danger"
                              >
                                Cancel
                              </button>

                              <button
                                type="submit"
                                className="btn btn-fill btn-primary"
                                id="create_offline"
                                style={{ display: "none" }}
                              >
                                Create Offline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!this.state.modal ? (
                  ""
                ) : (
                  <div
                    className="modal fade show"
                    id="addCooperativeModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    style={{ paddingRight: "15px", display: "block" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Add Cooperative to Farm Season
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                            onClick={this.handleModal}
                          >
                            <i className="material-icons">clear</i>
                          </button>
                        </div>
                        <div>
                          <input
                            type="hidden"
                            name="csrfmiddlewaretoken"
                            value="dHYyNaa1Nl0JN71FQPr2JXQQK5QGvh17Da3g6ArKfYZ4lPcoltE81GlaygQzaXj7"
                          />
                          <div className="modal-body">
                            <div className="card ">
                              <div className="card-body ">
                                <div className="form-group">
                                  <div className="dropdown bootstrap-select show">
                                    <button
                                      type="button"
                                      className="dropdown-toggle btn btn-round btn-info"
                                      data-toggle="dropdown"
                                      title="Choose Bank"
                                      onClick={() =>
                                        this.handleCooperativeModal()
                                      }
                                    >
                                      <div className="filter-option">
                                        <div className="filter-option-inner">
                                          <div className="filter-option-inner-inner">
                                            {!this.state.cooperative_name
                                              ? "Choose Cooperative"
                                              : this.state.cooperative_name}
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </button>
                                    <div
                                      id="cooperative"
                                      className={
                                        !this.state.cooperative_modal
                                          ? "dropdown-menu"
                                          : "dropdown-menu show"
                                      }
                                      role="combobox"
                                      aria-expanded = "true"
                                      aria-controls = "cooperative"
                                      x-placement="bottom-start"
                                      style={{
                                        maxHeight: "162px",
                                        overflow: "hidden",
                                        minWidth: "452px",
                                        position: "absolute",
                                        top: "46px",
                                        left: "1px",
                                        willChange: "top, left",
                                      }}
                                    >
                                      <div
                                        className="inner show"
                                        role="listbox"
                                        aria-expanded="true"
                                        tabIndex="-1"
                                        style={{
                                          maxHeight: "152px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        <ul className="dropdown-menu inner show">
                                          <li className="disabled">
                                            <a
                                              
                                              role="option"
                                              className="dropdown-item disabled"
                                              aria-disabled="true"
                                              tabIndex="-1"
                                              aria-selected="false"
                                            >
                                              <span className=" bs-ok-default check-mark" />
                                              <span className="text">
                                                Choose Cooperative
                                              </span>
                                            </a>
                                          </li>
                                          {this.state.non_season_cooperatives.map(
                                            (data) => (
                                              <li key={data._id}>
                                                <a
                                                  
                                                  role="option"
                                                  className={
                                                    this.state
                                                      .cooperative_name !==
                                                    data.name
                                                      ? "dropdown-item"
                                                      : "dropdown-item selected active"
                                                  }
                                                  aria-disabled="false"
                                                  tabIndex="0"
                                                  aria-selected="false"
                                                  onClick={() =>
                                                    this.setState({
                                                      cooperative_name:
                                                        data.name,
                                                      cooperative_community:
                                                        data.community,
                                                      cooperative_district:
                                                        data.district,
                                                      cooperative_zone:
                                                        data.zone,
                                                      cooperative_modal: false,
                                                      cooperative_id: data._id,
                                                      cooperative_live_id:
                                                        data._id,
                                                    })
                                                  }
                                                >
                                                  <span className=" bs-ok-default check-mark" />
                                                  <span className="text">
                                                    {data.name}
                                                  </span>
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <input
                                  type="hidden"
                                  name="add_cooperative"
                                  value="1"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-fill btn-primary"
                                  onClick={this.addCooperativeToSeason}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">SupplyBase</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy;
                <script>document.write(new Date().getFullYear())</script>, made
                with <i className="material-icons">favorite</i> by {" "}
                <a href="http://www.crop2cash.com.ng/" rel="noopener noreferrer" target="_blank">
                  Crop2Cash
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ExtensionSeasonCooperative;
