import { fetchRequest } from "../utils";

export const list_cooperative_season = () =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_season/`,
  });

export const read_cooperative_season = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_season/` +
      params.cooperative_id,
  });

// export const update_cooperative_season = (cooperative_season, params) => {
//   console.log(params);
//   return fetchRequest({
//     url:
//       `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_season/` +
//       params.cooperative_id,
//     method: "PUT",
//     data: cooperative_season,
//   });
// };

export const remove_cooperative_season = (cooperative_season) =>
  fetchRequest({
    url: `${process.env.REACT_APP_LOCAL_SERVER}/api/cooperative_season/`,
    method: "DELETE",
    data: cooperative_season,
  });

export const list_cooperative_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_season/` +
      params.cooperative_id,
  });

  export const read_cooperative_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_season/` +
      params.cooperative_id,
  });

export const remove_cooperative_season_live = (params) =>
  fetchRequest({
    url:
      `${process.env.REACT_APP_LIVE_SERVER_PROD}/api/cooperative_season/` +
      params.cooperative_seasonId,
    method: "DELETE",
  });
