import React, { Component } from "react";
import {
  create_farminputinventory_live,
  remove_farminputinventory_live,
  list_farminputinventory_live,
} from "../../api/api-farm_input_inventory";
import { update_farminput_live } from "../../api/api-farm_inputs";
import auth from "../../api/auth-helper";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
class FarmInputinventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deletemodal: false,
      editmodal: false,
      id: "",
      name: "",
      type: "",
      inventory: "",
      error: "",
      return_to_farm_input: false,
      farm_input_inventory: [],
      current_farm_input_inventory: 0,
      liveId: "",
      loading: false,
      success_notification: false,
      error_notification: false,
      update_notification: false,
      mssg: "",
    };
    this.handleList();
  }

  componentDidMount() {
    document.title = "Farm Input inventory";
    console.log(this.props);
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      error: "",
    }));
  };

  handleOpenDeleteModal = (id, liveId) => {
    this.setState({
      deletemodal: true,
      id: id,
      liveId: id,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      deletemodal: false,
      id: "",
    });
  };

  handleOpenEditModal = (id, name, type, inventory) => {
    this.setState({
      editmodal: true,
      id: this.props.props.location.state.id,
      name: this.props.props.location.state.name,
      type: this.props.props.location.state.type,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      editmodal: false,
      id: "",
      name: "",
      type: "",
    });
  };

  handleList = () => {
    if (navigator.onLine) {
      list_farminputinventory_live().then((data) => {
        console.log(data);
        if (!data) {
          this.setState({
            mssg: `There's something wrong, reload this page or log out`,
            error_notification: true,
          });
          setTimeout(() => this.setState({ error_notification: false }), 5000);
        } else if (data.length !== 0) {
          let inventories = data.filter(
            (data2) =>
              data2.farm_input_id === this.props.props.location.state.id
          );
          this.setState({
            farm_input_inventory: inventories,
          });
          console.log(inventories);
          if (inventories.length !== 0) {
            this.setState({
              current_farm_input_inventory: inventories[0].quantity,
            });
          }
          console.log(data);
          console.log(this.state.current_farm_input_inventory);
        } else {
          this.setState({
            farm_input_inventory: [],
            current_farm_input_inventory: 0,
          });
        }
      });
    } else if (!navigator.onLine) {
      /*
			read_farminputinventory({ farminputinventoryId: this.props.location.state.id }).then(data => {
				console.log(data);
				if (data.error) {
					console.log(data.error);
				} else {
					this.setState({ farm_input_inventory: data });
					console.log(data);
				}
			});
			*/
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  editForm = () => {
    const jwt = auth.isAuthenticated();

    const farminput = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      name: this.state.name,
      type: this.state.type,
      quantity: this.state.farm_input_inventory,
    };
    update_farminput_live(
      farminput,
      { farmInputId: this.props.props.location.state.liveId },
      {
        t: jwt.token,
      }
    ).then((data) => {
      if (data.error) {
        this.handleCloseEditModal();
        this.setState({
          error_notification: true,
          mssg: "Farm Input inventory could not be updated",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
      } else {
        this.handleCloseEditModal();
        this.setState({
          return_to_farm_input: true,
        });
      }
      /*
				const farminput = {
					_id: this.state.id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					name: this.state.name,
					type: this.state.type,
				};
				update_farminput(farminput, {
					t: jwt.token,
				}).then(data => {
					if (!data) {
						return;
					} if(data) {
						this.setState({
							return_to_farm_input: true,
						});
					}
				});
				this.handleCloseEditModal();
				*/
    });
  };

  checkForm = () => {
    if (!this.state.inventory) {
      this.setState({
        error: "Farm Input inventory is required",
      });
    }

    if (this.state.inventory < 0) {
      this.setState({
        error: "Enter a Valid Input",
      });
    }

    if (this.state.inventory > 0) {
      this.submitForm();
    }
  };

  checkForm2 = () => {
    if (!this.state.name) {
      this.setState({
        error: "Farm Input Name is required",
      });
    }

    if (!this.state.type) {
      this.setState({
        error: "Farm Input Type is required",
      });
    }

    if (!this.state.type && !this.state.name) {
      this.setState({
        error: "All Fields are required",
      });
    }

    if (this.state.name && this.state.type) {
      this.editForm();
    }
  };

  submitForm = () => {
    const jwt = auth.isAuthenticated();

    const farminputinventory = {
      deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
      createdBy:
        auth.isAuthenticated().user.first_name +
        " " +
        auth.isAuthenticated().user.last_name,
      farm_input_id: this.props.props.location.state.liveId,
      quantity: Number(this.state.inventory),
    };
    create_farminputinventory_live(farminputinventory, {
      t: jwt.token,
    }).then((data) => {
      if (!data) {
        return;
      }
      if (data) {
        const farminput = {
          deviceId: window.navigator.userAgent.replace(/\D+/g, ""),
          quantity:
            Number(this.state.inventory) +
            Number(this.state.current_farm_input_inventory),
        };
        update_farminput_live(
          farminput,
          { farmInputId: this.props.props.location.state.liveId },
          {
            t: jwt.token,
          }
        ).then((data) => {
          if (data.error) {
            this.handleList();
            this.setState({
              loading: false,
              error_notification: true,
              mssg: "Could not create Farm Input inventory",
            });
            setTimeout(
              () => this.setState({ error_notification: false }),
              3000
            );
            this.handleModal();
          } else {
            this.handleList();
            this.setState({
              loading: false,
              success_notification: true,
              mssg: "Farm Input inventory created successfully",
            });
            setTimeout(
              () => this.setState({ success_notification: false }),
              3000
            );
            this.handleModal();
          }
        });
        /*
				const farminput = {
					liveId: data.data._id,
					deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
					createdBy: auth.isAuthenticated().user.first_name + ' ' + auth.isAuthenticated().user.last_name,
					farmInputId: this.props.location.state.id,
					farmInputLiveId: this.props.location.state.liveId,
					inventory: this.state.inventory,
				};
				create_farminputinventory(farminput, {
					t: jwt.token,
				}).then(data => {
					if (data.error) {
						this.setState({ error: data.error });
					} 
					if(data) {
						this.handleList();
						this.handleModal();
						const farminput = {
							deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
							inventory: this.state.inventory,
						};
						update_farminput_live(
							farminput,
							{ farmInputId: this.props.location.state.liveId },
							{
								t: jwt.token,
							}
						).then(data => {
							if (!data) {
								return;
							} if(data) {
								const farminput = {
									deviceId: window.navigator.userAgent.replace(/\D+/g, ''),
									_id: this.props.location.state.id,
									inventory: this.state.inventory,
								};
								update_farminput_inventory(farminput, {
									t: jwt.token,
								}).then(data => {
									if (!data) {
										return;
									} if(data) {
										
									}
								});
							}
						});

					}
					
				});
				*/
      }
    });
  };

  deleteData = () => {
    console.log(this.state.id);
    remove_farminputinventory_live({
      farmInputInventoryId: this.state.id,
    }).then((data) => {
      if (data.error) {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Could not delete Farm Input inventory",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleCloseDeleteModal();
      } else {
        this.handleList();
        this.setState({
          loading: false,
          error_notification: true,
          mssg: "Farm Input inventory deleted successfully",
        });
        setTimeout(() => this.setState({ error_notification: false }), 3000);
        this.handleCloseDeleteModal();
      }
    });
  };
  render() {
    if (this.state.return_to_farm_input) {
      return (
        <Redirect
          to={{
            pathname: "/extension_farm_inputs",
            state: { update_notification: true },
          }}
        />
      );
    }
    return (
      <div className="container-fluid">
        {this.state.success_notification ? (
          <div className="alert alert-success">
            <button
              onClick={() => this.setState({ success_notification: false })}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <span>{this.state.mssg} </span>
          </div>
        ) : this.state.error_notification ? (
          <div className="alert alert-danger">
            <button
              onClick={() => this.setState({ error_notification: false })}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <span>{this.state.mssg} </span>
          </div>
        ) : this.state.update_notification ? (
          <div className="alert alert-warning">
            <button
              onClick={() => this.setState({ update_notification: false })}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <i className="material-icons">close</i>
            </button>
            <span>{this.state.mssg} </span>
          </div>
        ) : (
          ""
        )}
        {!navigator.onLine ? (
          <div id="offlinealert" className="alert alert-warning">
            <button
              onClick={() => document.location.reload()}
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
              </svg>
            </button>
            <span>You are not connected to the internet. </span>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header card-header-primary card-header-icon">
                <div className="card-icon">
                  <i className="material-icons">assignment</i>
                </div>
                {/* <h4 className="card-title">
                          {`${this.props.location.state.name} / ${this.props.location.state.type} inventory`}{" "}
                        </h4> */}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary btn-round"
                      onClick={this.handleModal}
                    >
                      Add Farm Input inventory
                    </button>
                  </div>
                </div>

                <div className="material-datatables">
                  <div className="table-responsive-sm">
                    <table
                      id="datatables"
                      className="table table-striped table-no-bordered table-hover"
                      cellSpacing="0"
                      width="100%"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Quantity</th>
                          <th>Created By</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th>Quantity</th>
                          <th>Created By</th>
                          <th>Date Added</th>
                        </tr>
                      </tfoot>
                      <tbody>
                        {this.state.farm_input_inventory.map((data) => (
                          <tr key={data._id}>
                            <td>{data.quantity}</td>
                            <td>{data.createdBy}</td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {data.created}
                              </Moment>
                            </td>
                            {/* <td className="text-right inline-block">
                              <div>
                                <input
                                  type="hidden"
                                  name="delete_farm_input"
                                  value="{{ farm_input.pk }}"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-link btn-just-icon btn-danger"
                                  onClick={() =>
                                    this.handleOpenDeleteModal(
                                      data._id,
                                      data._id
                                    )
                                  }
                                >
                                  <i className="material-icons">clear</i>
                                </button>
                              </div>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.deletemodal ? (
          ""
        ) : (
          <div
            className="modal fade show"
            id="addCooperativeModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            style={{
              display: "block",
              paddingRight: "5px",
              paddingTop: "100px",
            }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Delete Farm Input?</h4>
                  <button
                    onClick={this.handleCloseDeleteModal}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <i className="material-icons">clear</i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card ">
                    <div className="card-body ">
                      <div>
                        Are you sure you want to delete this Farm Input ?
                      </div>

                      <button
                        type="submit"
                        onClick={() => this.deleteData()}
                        className="btn btn-fill btn-success"
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        onClick={this.handleCloseDeleteModal}
                        className="btn btn-fill btn-danger"
                      >
                        Cancel
                      </button>

                      <button
                        type="submit"
                        className="btn btn-fill btn-primary"
                        id="create_offline"
                        style={{ display: "none" }}
                      >
                        Create Offline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.modal ? (
          ""
        ) : (
          <div
            className="modal fade show"
            id="createFarmInputModal"
            style={{ display: "block", paddingRight: "15px" }}
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Create Farm Input inventory</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.handleModal}
                  >
                    <i className="material-icons">clear</i>
                  </button>
                </div>
                <h4 style={{ color: "red", alignSelf: "center" }}>
                  {this.state.error}
                </h4>
                <div>
                  <div className="modal-body">
                    <div className="card ">
                      <div className="card-body ">
                        <div>
                          <div className="form-group">
                            <label
                              for="inputinventory"
                              className="bmd-label-floating"
                            >
                              Enter Quantity of Farm Input{" "}
                              <p style={{ color: "red" }}>
                                Note that this will be added to the previous
                                quantity
                              </p>
                            </label>
                            <input
                              onChange={(event) =>
                                this.setState({
                                  inventory: event.target.value,
                                  error: "",
                                })
                              }
                              name="inventory"
                              type="number"
                              className="form-control"
                              id="inputinventory"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer justify-content-center">
                        <input
                          type="hidden"
                          name="create_farm_input"
                          value="1"
                        />
                        <button
                          type="submit"
                          className="btn btn-fill btn-primary"
                          id="create_online"
                          onClick={this.checkForm}
                          style={{ display: "block" }}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.editmodal ? (
          ""
        ) : (
          <div
            className="modal fade show"
            style={{ display: "block", paddingRight: "15px" }}
            tabindex="-1"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Edit Farm Input</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={this.handleCloseEditModal}
                  >
                    <i className="material-icons">clear</i>
                  </button>
                </div>
                <h4 style={{ color: "red", alignSelf: "center" }}>
                  {this.state.error}
                </h4>
                <div>
                  <div className="modal-body">
                    <div className="card ">
                      <div className="card-body ">
                        <div>
                          <div className="form-group">
                            <label
                              for="editinputName"
                              className="bmd-label-floating"
                            >
                              Enter Name of Farm Input
                            </label>
                            <input
                              onChange={(event) =>
                                this.setState({
                                  name: event.target.value,
                                  error: "",
                                })
                              }
                              value={this.state.name}
                              type="text"
                              className="form-control"
                              id="editinputName"
                            />
                          </div>
                          <div className="form-group">
                            <label
                              for="editinputType"
                              className="bmd-label-floating"
                            >
                              Enter Type of Farm Input
                            </label>
                            <input
                              onChange={(event) =>
                                this.setState({
                                  type: event.target.value,
                                  error: "",
                                })
                              }
                              value={this.state.type}
                              type="text"
                              className="form-control"
                              id="editinputType"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer justify-content-center">
                        <input
                          id="editinputID"
                          type="hidden"
                          name="edit_farm_input"
                          value=""
                        />
                        <button
                          onClick={this.checkForm2}
                          type="submit"
                          className="btn btn-fill btn-primary"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FarmInputinventory;
