import React, { Component } from 'react';
import SideBar from './SideBar';
import Nav from './Nav';
import Moment from 'react-moment';
import { list_shipment_live} from '../../api/api-shipment';
import { listseasons_live } from '../../api/api-seasons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { list_OMS_cooperative_live } from "../../api/api-cooperative";
import {CSVLink} from "react-csv";
class Shipment extends Component {
  constructor() {
    super();
    this.state = {
      shipments: [],
      current_season: {},
      nothing_shipment: [],
      waiting_shipment: [],
      approved_shipment: [],
      seasons: [],
      type: '',
      start_month: '',
      end_month: '',
      year: '',
      modals: {
        seasons: false
      },
      OMScooperative: [],
      omsShipment: [],
      csv_data: [],
      csv_header: []
    };
    this.handleListShipment();
    this.handleListSeason();
    this.handleListOMSShipment();
    this.handleCSV()
  }

  componentDidMount(){
    document.title = "Shipments"
  }


  handleModal = (type) => {
    let newModals;
    if (this.state.modals[type])
      newModals = { ...this.state.modals, [type]: false };
    else newModals = { ...this.state.modals, [type]: true };
    console.log(newModals);
    this.setState({ modals: newModals });
  };

  handleCSV = async () => {
    await list_shipment_live().then(async (data)=> {
      if(!data || data.message) {
        return
      }
      else if (data.length !== 0){
        const headers = [
          {label:"Vehicle Number", key: "vehicleNumber"},
          {label: "Weighbridge Number", key: "weighbridge_number"},
          {label:"Party Name", key: "partyName"},
          {label:"Type", key: "type"},
          {label:"Gross Weight", key: "gross_weight"},
          {label:"Net Weight", key: "net_weight"},
          {label:"Tare Weight", key: "tare_weight"},
          {label: "Crop", key: "crop"},
          {label: "Crop Price", key: "crop_price"},
          {label: "Status", key: "status"},
          {label: "Created By", key:"createdBy"},
          {label: "Date Created", key: "created"}
        ];

        const all_data = data;
        let csvdata = []

        await all_data.map(async (data)=>{
          let csvData ={
            vehicleNumber: data.vehicleNumber,
            weighbridge_number: data.weighbridge_number,
            partyName: data.partyName,
            type: data.type,
            gross_weight: data.gross_weight,
            net_weight: data.net_weight,
            tare_weight: data.tare_weight,
            crop: data.crop.name,
            crop_price: data.crop_price,
            status: data.status,
            createdBy: data.createdBy,
            created: data.created.substr(0, 9)
          };
          csvdata.push(csvData)
          console.log(csvdata)
          this.setState({
            csv_header: headers,
            csv_data: csvdata,
          });
        })
      }else{
        this.setState({shipments :[]})
      }
    })
  }

  handleListShipment = () => {
    list_shipment_live().then((data) => {
      if (!data) {
        return;
      } else {
        this.setState({
          nothing_shipment: data.filter((data) => data.status === 'nothing'),
          waiting_shipment: data.filter(
            (data) => data.status === 'waiting' || data.status === 'disapproved'
          ),
          approved_shipment: data.filter((data) => data.status === 'approved')
        });
        console.log(this.state.approved_shipment)
      }
    });
  };

  handleListOMSShipment = () => {
      list_shipment_live().then((data)=>{
        if(!data){
          return
        } else{
          let omsShipment = data.filter(
            omsShipment => omsShipment.type === "Open Market Supplier"
          )
          this.setState({omsShipment: omsShipment})
        }
      })
  }

  handleListSeason = () => {
    listseasons_live().then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        if (data[0] === undefined) {
          this.setState({
            seasons: [],
            type: '',
            start_month: '',
            end_month: '',
            year: ''
          });
        }
        if (data[0] !== undefined) {
          this.setState({
            seasons: data,
            type: data[0].type,
            start_month: data[0].start_month,
            end_month: data[0].end_month,
            year: data[0].year,
            loan_per_acre: ''
          });
        }
      }
    });
  };

  render() {
    return (
      <div className='wrapper '>
        <SideBar />

        <div className='main-panel'>
          <Nav />
          <div className='content'>
            <div className='content'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-warning card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>gradient</i>
                        </div>
                        <p className='card-category'>Season</p>
                        <h3 className='card-title'>
                          {this.state.type.slice(0, 1).toUpperCase() +
                            '-' +
                            this.state.start_month.slice(0, 3).toUpperCase() +
                            '-' +
                            this.state.end_month.slice(0, 3).toUpperCase() +
                            '-' +
                            this.state.year
                              .toString()
                              .slice(2, 4)
                              .toUpperCase()}
                        </h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats'>
                          <div className='dropdown'>
                            <button
                              className='dropdown-toggle btn btn-sm btn-primary btn-round btn-block'
                              type='button'
                              id='dropdownSeasonButton'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                              onClick={() => this.handleModal('seasons')}
                            >
                              Change Season
                            </button>
                            <div
                              className={
                                !this.state.modals.seasons
                                  ? 'dropdown-menu'
                                  : 'dropdown-menu show'
                              }
                              role='combobox'
                              aria-controls = 'listbox'
                              x-placement='bottom-start'
                              style={{
                                maxHeight: '162px',
                                minWidth: '452px',
                                position: 'absolute',
                                top: '46px',
                                left: '1px',
                                willChange: 'top, left'
                              }}
                            >
                              <div
                               id="listbox"
                                className='inner show'
                                role='listbox'
                                aria-expanded='true'
                                tabIndex='-1'
                                style={{
                                  maxHeight: '152px',
                                  overflowY: 'auto'
                                }}
                              >
                                <ul className='dropdown-menu inner show'>
                                  <li className='disabled'>
                                    <a
                                      href = "#'/"
                                      role='option'
                                      className='dropdown-item disabled'
                                      aria-disabled='true'
                                      tabIndex='-1'
                                      aria-selected='false'
                                    >
                                      <span className=' bs-ok-default check-mark' />
                                      <span className='text'>
                                        Select Season
                                      </span>
                                    </a>
                                  </li>
                                  {this.state.seasons.map((season, id) => (
                                    <li key={id}>
                                      <a
                                        href= "#'/"
                                        role='option'
                                        className='dropdown-item'
                                        aria-disabled='false'
                                        tabIndex='0'
                                        aria-selected='false'
                                        onClick={() => {
                                          let {
                                            type,
                                            start_month,
                                            end_month,
                                            year
                                          } = season;
                                          this.setState({
                                            type,
                                            start_month,
                                            end_month,
                                            year
                                          });
                                          this.handleModal('seasons');
                                        }}
                                      >
                                        <span className=' bs-ok-default check-mark' />
                                        <span className='text'>
                                          {season.type}
                                          {'  '}
                                          {season.year}
                                        </span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-rose card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <p className='card-category'>Shipments To Aggregate</p>
                        <h3 className='card-title'>
                          {this.state.nothing_shipment.length}
                        </h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-success card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>done</i>
                        </div>
                        <p className='card-category'>
                          Shipments Awaiting Approval
                        </p>
                        <h3 className='card-title'>
                          {this.state.waiting_shipment.length}
                        </h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 col-sm-6'>
                    <div className='card card-stats'>
                      <div className='card-header card-header-info card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>done_all</i>
                        </div>
                        <p className='card-category'>Approved Shipments</p>
                        <h3 className='card-title'>
                          {this.state.approved_shipment.length}
                        </h3>
                      </div>
                      <div className='card-footer'>
                        <div className='stats' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dt-buttons btn-group">
                                 {" "}
                                
                                <CSVLink
                                  data={this.state.csv_data}
                                  headers={this.state.csv_header}
                                  filename={"Shipments.csv"}
                                  className="btn btn-secondary buttons-pdf buttons-html5"
                                  target="_blank"
                                >
                                  Export Excel
                                </CSVLink>
                              </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Open Market Supplies.</h4>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar' />
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              title='W-APR-NOV-19 Shipments'
                              id='unaggregated'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.omsShipment.map((data, id) => (
                                  <tr key={id}>
                                    <td className='sorting_1'>
                                      {data.vehicleNumber}
                                    </td>
                                    <td>
                                        
                                        {data.partyName}
                                      
                                    </td>
                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <Link
                                        to={{
                                          pathname: '/shipment_options',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                            cooperativeId: data.cooperativeId
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>ballot</i>
                        </div>
                        <h4 className='card-title'>Shipments To Aggregate</h4>
                        <h6 className='card-title'>
                          Shipments requiring Recovery Reconcilement.
                        </h6>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar' />
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              title='W-APR-NOV-19 Shipments'
                              id='unaggregated'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.nothing_shipment.map((data, id) => (
                                  <tr key={id}>
                                    <td className='sorting_1'>
                                      {data.vehicleNumber}
                                    </td>
                                    <td>{data.partyName}</td>

                                    <td>
                                      <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <Link
                                        to={{
                                          pathname: '/shipment_options',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                            cooperativeId: data.cooperativeId
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>done</i>
                        </div>
                        <h4 className='card-title'>
                          Shipments Awaiting Approval
                        </h4>
                        <h6 className='card-title'>
                          Aggregated Shipments that are waiting for approval.
                        </h6>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar' />
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              title='W-APR-NOV-19 Shipments'
                              id='awaiting_approval'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.waiting_shipment.map((data) => (
                                  <tr>
                                    <td className='sorting_1'>
                                      {data.vehicleNumber}
                                    </td>
                                    <td>{data.partyName}</td>

                                    <td>
                                       <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <Link
                                        to={{
                                          pathname: '/shipment_options',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                            cooperativeId: data.cooperativeId
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header card-header-primary card-header-icon'>
                        <div className='card-icon'>
                          <i className='material-icons'>done_all</i>
                        </div>
                        <h4 className='card-title'>Approved Shipments</h4>
                        <h6 className='card-title'>
                          Shipments that the Extension Department has approved.
                        </h6>
                      </div>
                      <div className='card-body'>
                        <div className='toolbar' />
                        <div className='material-datatables'>
                          <div className='table-responsive-sm'>
                            <table
                              title='W-APR-NOV-19 Shipments'
                              id='approved_shipments'
                              className='table table-striped table-no-bordered table-hover'
                              cellSpacing='0'
                              width='100%'
                              style={{ width: '100%' }}
                            >
                              <thead>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <th>Vehicle No</th>
                                  <th>Party Name</th>
                                  <th>Date Added</th>
                                  <th className='disabled-sorting text-right'>
                                    Actions
                                  </th>
                                </tr>
                              </tfoot>
                              <tbody>
                                {this.state.approved_shipment.map((data) => (
                                  <tr>
                                    <td className='sorting_1'>
                                      {data.vehicleNumber}
                                    </td>
                                    <td>{data.partyName}</td>

                                    <td>
                                       <Moment format='D MMM YYYY' withTitle>
                                        {data.created}
                                      </Moment>
                                    </td>
                                    <td className='text-right inline-block'>
                                      <Link
                                        to={{
                                          pathname: '/shipment_options',
                                          state: {
                                            direct: 'shipment',
                                            shipmentId: data._id,
                                            cooperativeId: data.cooperativeId
                                          }
                                        }}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Shipment;
